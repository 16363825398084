import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IRSS } from '../_interfaces/RSS';
import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

//import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';


@Injectable()
export class RSSService extends CRUDBasicService {
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'summary', type: 'string'},
    {name: 'publishDate', type: 'DateTimeOffset'},
    {name: 'title', type: 'string'},
    {name: 'link', type: 'string'}
  ];
    
  getRSSCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ RSS: IRSS[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'RSS?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { RSS: <IRSS[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  initializeRSS(): IRSS {
    return {
      id: 0,
      createdBy: '',
      created: '',
      modifiedBy: '',
      modified: '',
      rowVersion: '',
      summary: '',
      publishDate: '',
      title: '',
      link: '',
    };
  }

}

