<div class="ui-fluid">
    <div class="ui-g">
        <div class="ui-g-12">
            <div class="ui-messages ui-widget ui-corner-all ui-messages-success" style="margin: 0 0 1em 0">
                <span class="ui-messages-icon pi pi-fw pi-check"></span>
                <ul>
                    <li>
                        <span class="ui-messages-summary" style="font-size:16px">Ultima provides a theme for all 80+ PrimeNG Components. This page contains samples of the commonly used components for demo purposes.</span>
                    </li>
                </ul>
            </div>
            <div class="card no-margin">
                <h1>Form Elements</h1>
                <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-2">
                        <label for="input">Input</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <span class="md-inputfield">
                            <input id="input" type="text" pInputText/>
                            <label>Username</label>
                        </span>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        <label for="textarea">Textarea</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <textarea id="textarea" [rows]="3" [cols]="30" pInputTextarea autoResize="autoResize"></textarea>
                    </div>

                    <div class="ui-g-12 ui-md-2">
                        <label for="calendar">Calendar</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-calendar id="calendar"></p-calendar>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        <label for="autocomplete">AutoComplete</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-autoComplete id="autocomplete" [(ngModel)]="country" [suggestions]="filteredCountries" (completeMethod)="filterCountry($event)" field="name"
                            placeholder="Countries" [minLength]="1"></p-autoComplete>
                    </div>

                    <div class="ui-g-12 ui-md-2">
                        <label for="dropdown">Dropdown</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-dropdown id="dropdown" [options]="cities1" [(ngModel)]="selectedCity1"></p-dropdown>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        <label for="password">Password</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <input id="password" pPassword type="password"/>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        Button
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <button pButton type="button" label="Edit" icon="ui-icon-edit"></button>
                    </div>

                    <div class="ui-g-12 ui-md-2">
                        SplitButton
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-splitButton label="Save" icon="ui-icon-save" [model]="splitButtonItems"></p-splitButton>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        <label for="multiselect">MultiSelect</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-multiSelect id="multiselect" [options]="carOptions" [(ngModel)]="selectedMultiSelectCars"></p-multiSelect>
                    </div>

                    <div class="ui-g-12 ui-md-2">
                        <label for="slider">Slider</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-slider id="slider"></p-slider>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        Checkbox
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <div class="ui-g">
                            <div class="ui-g-12"><p-checkbox name="cg" value="Ultima" label="Ultima" [(ngModel)]="checkboxValues"></p-checkbox></div>
                            <div class="ui-g-12"><p-checkbox name="cg" value="Icarus" label="Icarus" [(ngModel)]="checkboxValues"></p-checkbox></div>
                            <div class="ui-g-12"><p-checkbox name="cg" value="Omega" label="Omega" [(ngModel)]="checkboxValues"></p-checkbox></div>
                        </div>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        RadioButton
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <div class="ui-g">
                            <div class="ui-g-12"><p-radioButton name="rg" value="Ultima" label="Ultima" [(ngModel)]="radioValue"></p-radioButton></div>
                            <div class="ui-g-12"><p-radioButton name="rg" value="Icarus" label="Icarus" [(ngModel)]="radioValue"></p-radioButton></div>
                            <div class="ui-g-12"><p-radioButton name="rg" value="Omega" label="Omega" [(ngModel)]="radioValue"></p-radioButton></div>
                        </div>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        <label for="mask">Mask</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-inputMask id="mask" mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="Date"></p-inputMask>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        <label for="spinner">Spinner</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-spinner id="spinner"></p-spinner>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        ToggleButton
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-toggleButton [(ngModel)]="toggleButtonChecked"></p-toggleButton>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        SelectButton
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-selectButton [options]="types" [(ngModel)]="selectedType"></p-selectButton>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        Dialog
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <button type="button" label="Login" icon="ui-icon-open-in-new" (click)="dialogVisible=true" pButton></button>
                    </div>
                    <div class="ui-g-12 ui-md-2">
                        <label for="listbox">Listbox</label>
                    </div>
                    <div class="ui-g-12 ui-md-4">
                        <p-listbox id="listbox" [options]="cities2" [(ngModel)]="selectedCity2"></p-listbox>
                    </div>
                </div>

                <p-dialog header="Login" [resizable]="false" responsive="true" [(visible)]="dialogVisible">
                    <div class="ui-g form-group" style="margin-bottom: 16px;">
                        <div class="ui-g-12">
                            <span class="md-inputfield">
                                <input type="text" pInputText>
                                <label>Name</label>
                            </span>
                        </div>
                        <div class="ui-g-12">
                            <span class="md-inputfield">
                                <input type="password" pInputText>
                                <label>Password</label>
                            </span>
                        </div>
                    </div>

                    <p-footer>
                        <div class="ui-dialog-buttonpane ui-helper-clearfix">
                            <button type="button" label="Login" icon="ui-icon-person" (click)="dialogVisible=false" pButton></button>
                        </div>
                    </p-footer>
                </p-dialog>
            </div>

            <div class="card card-w-title">
                <h1>DataTable</h1>
                <p-table [columns]="cols" [value]="cars" selectionMode="single" dataKey="vin" [style]="{'margin-bottom':'20px'}" [(selection)]="selectedCar3">
                    <ng-template pTemplate="caption">
                      DataTable
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                              {{col.header}}
                              <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns">
                              {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="ui-g-12 ui-lg-6">
            <!-- Left Side -->
            <div class="card card-w-title">
                <h1>DataView</h1>
                <p-dataView #dv [value]="carsLarge" [paginator]="true" [rows]="10" paginatorPosition="both" filterBy="brand">
                    <p-header>
                        <div class="ui-helper-clearfix">
                            <div class="ui-g">
                                <div class="ui-g-12 ui-md-6">
                                    <div style="position:relative">
                                        <input type="search" pInputText placeholder="Search by brand" (keyup)="dv.filter($event.target.value)">
                                    </div>
                                </div>
                                <div class="ui-g-12 ui-md-6" style="text-align:right">
                                    <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                                </div>
                            </div>
                        </div>
                    </p-header>
                    <ng-template let-car pTemplate="listItem">
                        <div class="ui-g" style="padding: 2em;border-bottom: 1px solid #d9d9d9">
                            <div class="ui-g-12 ui-md-3" style="text-align:center">
                                <img src="assets/demo/images/car/{{car.brand}}.gif">
                            </div>
                            <div class="ui-g-12 ui-md-8 car-details">
                                <div class="ui-g">
                                    <div class="ui-g-2 ui-sm-6">Vin: </div>
                                    <div class="ui-g-10 ui-sm-6">
                                        <b>{{car.vin}}</b>
                                    </div>

                                    <div class="ui-g-2 ui-sm-6">Year: </div>
                                    <div class="ui-g-10 ui-sm-6">
                                        <b>{{car.year}}</b>
                                    </div>

                                    <div class="ui-g-2 ui-sm-6">Brand: </div>
                                    <div class="ui-g-10 ui-sm-6">
                                        <b>{{car.brand}}</b>
                                    </div>

                                    <div class="ui-g-2 ui-sm-6">Color: </div>
                                    <div class="ui-g-10 ui-sm-6">
                                        <b>{{car.color}}</b>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-g-12 ui-md-1 search-icon">
                                <button pButton type="button" icon="pi pi-search" ></button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template let-car pTemplate="gridItem">
                        <div style="padding:.5em" class="ui-g-12 ui-md-3">
                            <p-panel [header]="car.vin" [style]="{'text-align':'center'}">
                                <img src="assets/demo/images/car/{{car.brand}}.gif">
                                <div class="car-detail">{{car.year}} - {{car.color}}</div>
                                <hr class="ui-widget-content" style="border-top:0">
                                <button pButton type="button" icon="pi pi-search" style="margin-top:0"></button>
                            </p-panel>
                        </div>
                    </ng-template>
                </p-dataView>
            </div>
        </div>
        <div class="ui-g-12 ui-lg-6">
            <!-- Right Side -->
            <div class="card card-w-title">
                <h1>PickList</h1>
                <p-pickList [source]="sourceCars" [target]="targetCars" sourceHeader="Available" targetHeader="Selected" [responsive]="true">
                    <ng-template let-car pTemplate="item">
                        <span>{{car.brand}}</span>
                    </ng-template>
                </p-pickList>
            </div>

            <div class="card card-w-title">
                <h1>OrderList</h1>
                <p-orderList [value]="orderListCars" [listStyle]="{'height':'250px'}" [responsive]="true" header="OrderList">
                    <ng-template let-car pTemplate="item">
                        <div class="ui-helper-clearfix">
                            <img src="assets/demo/images/car/{{car.brand}}.gif" style="display:inline-block;margin:2px 0 2px 2px; width: 50px;" />
                            <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{car.brand}} - {{car.year}} - {{car.color}}</div>
                        </div>
                    </ng-template>
                </p-orderList>
            </div>

            <div class="card card-w-title">
                <h1>Accordion Panel</h1>
                <p-accordion>
                    <p-accordionTab header="Godfather I" [selected]="true">
                        The story begins as Don Vito Corleone, the head of a New York Mafia family, overseeshis daughter's wedding. His beloved son
                        ichael has just come home from the war, but does not intend to become part of his father's business.
                        T hrough Michael's life the nature of the family business becomes clear. The business of the family
                        is just like the head of the family, kind and benevolent to those who give respect, but given to
                        ruthless violence whenever anything stands against the good of the family.
                    </p-accordionTab>
                    <p-accordionTab header="Godfather II">
                        Francis Ford Coppola's legendary continuation and sequel to his landmark 1972 film, The_Godfather parallels the young Vito
                        Corleone's rise with his son Michael's spiritual fall, deepening The_Godfather's depiction of the
                        dark side of the American dream. In the early 1900s, the child Vito flees his Sicilian village for
                        America after the local Mafia kills his family. Vito struggles to make a living, legally or illegally,
                        for his wife and growing brood in Little Italy, killing the local Black Hand Fanucci after he demands
                        his customary cut of the tyro's business. With Fanucci gone, Vito's communal stature grows.
                    </p-accordionTab>
                    <p-accordionTab header="Godfather III">
                        After a break of more than 15 years, director Francis Ford Coppola and writer Mario Puzo returned to the well for this third
                        and final story of the fictional Corleone crime family. Two decades have passed, and crime kingpin
                        Michael Corleone, now divorced from his wife Kay has nearly succeeded in keeping his promise that
                        his family would one day be completely legitimate.
                    </p-accordionTab>
                </p-accordion>
            </div>

            <div class="card card-w-title">
                <h1>Panel</h1>
                <p-panel header="Godfather I" [toggleable]="true">
                    The story begins as Don Vito Corleone, the head of a New York Mafia family, oversees his daughter's wedding. His beloved
                    son Michael has just come home from the war, but does not intend to become part of his father's business.
                    Through Michael's life the nature of the family business becomes clear. The business of the family is
                    just like the head of the family, kind and benevolent to those who give respect, but given to ruthless
                    violence whenever anything stands against the good of the family.
                </p-panel>
            </div>

            <div class="card card-w-title">
                <h1>ProgressBar - Static Display</h1>
                <p-progressBar [value]="50"></p-progressBar>
            </div>

            <div class="card card-w-title">
                <h1>Tree</h1>
                <p-tree [value]="filesTree"></p-tree>
            </div>

            <div class="card card-w-title">
                <h1>Menu</h1>
                <p-menu [model]="menuItems" [style]="{'width':'100%'}"></p-menu>
            </div>

            <div class="card card-w-title">
                <h1>PanelMenu</h1>
                <p-panelMenu [model]="panelMenuItems"></p-panelMenu>
            </div>
        </div>

        <div class="ui-g-12">
            <div class="card card-w-title">
                <h1>Carousel</h1>
                <p-carousel [value]="carouselCars" [numVisible]="4" [numScroll]="3" [circular]="false" [responsiveOptions]="responsiveOptions">
                    <ng-template let-car pTemplate="item">
                        <div class="car-details">
                            <div class="p-grid p-nogutter">
                                <div class="p-col-12">
                                    <img src="assets/demo/images/car/{{car.brand}}-big.gif" />
                                </div>
                                <div class="p-col-12 car-data">
                                    <div class="car-title">{{car.brand}}</div>
                                    <div class="car-subtitle">{{car.year}} | {{car.color}}</div>

                                    <div class="car-buttons">
                                        <button pButton type="button" class="ui-button-secondary" icon="ui-icon-search"></button>
                                        <button pButton type="button" class="ui-button-secondary" icon="ui-icon-star"></button>
                                        <button pButton type="button" class="ui-button-secondary" icon="ui-icon-settings"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </div>
    </div>
</div>
