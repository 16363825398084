<strong>Vorschau Dashboard:</strong><br><br>
<p-carousel [value]="pseudoArr" [numVisible]="6" [numScroll]="3" [circular]="false" [autoplayInterval]="0"
[responsiveOptions]="responsiveOptions" class="dashboardContainer">
<ng-template let-artikel pTemplate="item">
    <div class="p-grid p-nogutter">
        <app-werbeanzeige-darstellung-carousel [CRUDItem]="CRUDItem">
        </app-werbeanzeige-darstellung-carousel>
    </div>
</ng-template>
</p-carousel>

<br><br>
<strong>Vorschau Übersicht / Suche:</strong><br><br>
<p-dataView class="DataView" #dv [value]="pseudoArr" [totalRecords]="6" [rows]="4"
    layout="grid" [paginator]="true" paginatorPosition="both">
    <ng-template let-artikel pTemplate="gridItem" >
        <div class="ui-g-12 ui-md-3">
            <app-werbeanzeige-darstellung-uebersicht [CRUDItem]="CRUDItem">
            </app-werbeanzeige-darstellung-uebersicht>
        </div>
    </ng-template>
</p-dataView>

<br><br>
<strong>Vorschau Übersicht Mobil:</strong><br><br>
<app-werbeanzeige-darstellung-scroller [CRUDItem]="CRUDItem">
</app-werbeanzeige-darstellung-scroller>

<!--<p-virtualScroller 
[value]="pseudoArr"
[scrollHeight]="'500px'" [itemSize]="250" [rows]="1" 
>
<ng-template let-artikel pTemplate="item">
    {{artikel.bezeichnung}}
  <div
    [style.height]="'250px'"
    [style.maxHeight]="'250px'"
    [style.borderBottom]="'1px solid lightgrey'"
    [style.background]="true ? 'yellow' : null"
    >
   <div
        [style.height]="'250px'"
        [style.maxHeight]="'250px'"
        [style.marginLeft]="'10px'"
        [style.marginRight]="'10px'"
        [style.background]="true == true ? 'green' : null"
        >   bla
            <app-werbeanzeige-darstellung-scroller [CRUDItem]="CRUDItem">
            </app-werbeanzeige-darstellung-scroller>
        </div>
      </div>
    </ng-template>
</p-virtualScroller>
-->






<br>
<br>
<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="box" style="display: flex;">
            <div style="width: 100%">

                <div style="float: right;">
                    <button id="Vorschau" pButton type="button" label="Zurück" class="ui-button-success"
                        style="margin-right: 5px;" (click)="vorschauZurueckClicked()"></button>
                    <button *ngIf="mode == null || mode != 'import'" styleClass="ui-button-warning" pButton type="button" [icon]="'pi pi-check'"
                        [label]="translate.instant('Speichern',true)" (click)="vorschauSpeichernClicked()"></button>
                </div>
            </div>
        </div>
    </div>
</div>

