import {TreeNode} from 'primeng/api';

export class ArtikelGruppeFindTreeNode {

    artikelGruppeFindTreeNodeInChilds(artikelGruppeTreeNodes: TreeNode[], artikelGruppeId:number) { // treeNode finden, egal wie tief verschachtelt (childs)
      //console.log("ArtikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds() artikelGruppeId/artikelGruppeTreeNodes:", artikelGruppeId, this.artikelGruppeTreeNodes);
      if(artikelGruppeTreeNodes == null) return null;
      let foundTree = artikelGruppeTreeNodes.find(f => f.data.id == artikelGruppeId);
      if(foundTree != null) {
        //console.log("ArtikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds() found:", foundTree);
        return foundTree; // gefunden !
      }
      else { // nicht gefunden -> childs durchsuchen
        let treesWithChilds = artikelGruppeTreeNodes.filter(f => f.children != null && f.children.length > 0);
        for(let i:number = 0; i < treesWithChilds.length; i++ ) { // forEach "not breakable / return"
          let child = treesWithChilds[i];
          let foundTreeInChild:TreeNode = this.artikelGruppeFindTreeNodeInChilds_pt2(1, child, artikelGruppeId);
          if(foundTreeInChild != null) {
            //console.log("ArtikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds() found (in children):", foundTreeInChild);
            return foundTreeInChild;
          }
        }
      }
      //console.log("ArtikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds() didn't found tree!");
      return null; // nicht gefunden!
    }
    artikelGruppeFindTreeNodeInChilds_pt2(level:number, treeNode:TreeNode, artikelGruppeId:number) { 
      //console.log("ArtikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds_pt2() level/treeNode/artikelGruppeId:", level, treeNode, artikelGruppeId);
      let foundTree = treeNode.children.find(f => f.data.id == artikelGruppeId);
      if(foundTree != null) {
        //console.log("ArtikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds_pt2() found (in children) level/tree:", level, foundTree);
        return foundTree; // gefunden !
      }
      else { // nicht gefunden -> childs durchsuchen
        let treesWithChilds = treeNode.children.filter(f => f.children != null && f.children.length > 0);
        for(let i:number = 0; i < treesWithChilds.length; i++) { // forEach "not breakable / return"
        let child = treesWithChilds[i];
          let foundTreeInChild:TreeNode = this.artikelGruppeFindTreeNodeInChilds_pt2(level+1, child, artikelGruppeId);
          if(foundTreeInChild != null) {
            //console.log("ArtikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds_pt2() found (in children) level/tree:", level, foundTreeInChild);
            return foundTreeInChild;
          }
        }
      }
      //console.log("ArtikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds_pt2() didn't found tree!");
      return null; // nicht gefunden!
    }
  
}
