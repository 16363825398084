<div class="ui-g">
    <div class="ui-g-12">
        <div class="card no-margin">
            <h1>Toast</h1>
            <p-toast key="tst" [style]="{marginTop: '70px'}"></p-toast>

            <button type="button" pButton (click)="showInfoViaToast()" label="Info" class="ui-button-info"></button>
            <button type="button" pButton (click)="showSuccessViaToast()" label="Success" class="ui-button-success"></button>
            <button type="button" pButton (click)="showWarnViaToast()" label="Warn" class="ui-button-warning"></button>
            <button type="button" pButton (click)="showErrorViaToast()" label="Error" class="ui-button-danger"></button>
            <button type="button" pButton (click)="showMultipleViaToast()" label="Multiple"></button>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card no-margin">
            <h1>Messages</h1>
            <button type="button" pButton (click)="showInfoViaMessages()" label="Info" class="ui-button-info"></button>
            <button type="button" pButton (click)="showSuccessViaMessages()" label="Success" class="ui-button-success"></button>
            <button type="button" pButton (click)="showWarnViaMessages()" label="Warn" class="ui-button-warning"></button>
            <button type="button" pButton (click)="showErrorViaMessages()" label="Error" class="ui-button-danger"></button>
            <button type="button" pButton (click)="showMultipleViaMessages()" label="Multiple"></button>

            <p-messages [value]="msgs"></p-messages>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card no-margin">
            <h1>Inline Message</h1>
            <div>
                <p-message severity="info" text="PrimeNG Rocks" [style]="{marginLeft: 0}"></p-message>
                <p-message severity="success" text="Record Saved"></p-message>
                <p-message severity="warn" text="Are you sure?"></p-message>
                <p-message severity="error" text="Field is required"></p-message>
            </div>

            <div style="margin-top:30px">
                <input type="text" pInputText placeholder="Username" style="vertical-align: bottom" class="ng-dirty ng-invalid">
                <p-message severity="error" text="Field is required"></p-message>
            </div>

            <div style="margin-top:30px">
                <input type="text" pInputText placeholder="Email" style="vertical-align: bottom" class="ng-dirty ng-invalid">
                <p-message severity="error"></p-message>
            </div>
        </div>
    </div>


</div>
