import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '../_services/translate.service';
import { MessageWrapperService } from '../_services/message-wrapper.service';
import { Router } from '@angular/router';
import { debounceTime } from '../../../node_modules/rxjs/operators';
//import { GlobalService } from '../_services/global.service'; 
import { LazyLoadEvent } from "primeng/api";

/***************************************************************************************
ACHTUNG: die component ist bisher nicht dazu gedacht irgendwo verwendet zu werden
         - Ausser innerhalb CRUDBasicAutocomplete.
           Sie ist z.B. auch nicht form-fähig

***************************************************************************************/

@Component({
  selector: 'crud-basic-select',
  templateUrl: './crud-basic-select.component.html',
  styleUrls: ['./crud-basic-select.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class CRUDBasicSelectComponent implements OnInit {

  // MODI im Vergelich zu select-firma
  @Input('options') options: any; 
  optionsFiltered: any; // wie options, aber filtered
  @Input('visible') visible: boolean; 
  @Input('search') search: string; 
  @Input('label') label: string; // Name der Property die dargestellt udn durchsucht werden soll (in der Regel "bezeichnung")
  @Input('label2') label2: string; // Name der 2. Property die dargestellt udn durchsucht werden soll 
  @Input('title') title: string; 
  @Output('onSelect') onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output('onClose') onClose: EventEmitter<any> = new EventEmitter<any>();
  
  //https://ngdev.space/angular-2-input-property-changes-detection-3ccbf7e366d2
  ngOnChanges(changes: SimpleChanges) { // monitoring Input-Changes - nur monitoring/debug - ohne weitere Funktion!!! (kann man weglassen!)
    if(this.debugMode==true) console.log("CRUDBasicSelectComponent.ngOnChanges():", changes);

    if(changes.options != null || changes.search != null) {
      if(this.debugMode==true) console.log("CRUDBasicSelectComponent.ngOnChanges().it's options or search (or both)!!!");      
      if(this.debugMode==true) console.log("CRUDBasicSelectComponent.ngOnChanges() changes:", changes);
      //this.filterItems(this.search);
      this.filterItemsVirtualScroller(this.search);
    }
    if(changes.visible != null) {
      if(this.debugMode==true) console.log("CRUDBasicSelectComponent.ngOnChanges().it's visible !!! (important for (un)registerHotKeyHandler)");      
      if(this.debugMode==true) console.log("CRUDBasicSelectComponent.ngOnChanges().visible.currentvalue=", changes.visible.currentValue);
      if(changes.visible.currentValue == true) {
        this.registerHotkeyHandler();
      }
      else {
        this.unRegisterHotkeyHandler();
      }
    }
    else {
      if(this.debugMode==true) console.log("CRUDBasicSelectComponent.ngOnChanges().something else changed.");      
    }
  }

  @ViewChild("ds", { static: true }) dataScroller: any;
  
    errorMessage: string;
  
    items: any[];
    cols: any[];
  
    loading: boolean;
    totalRecords: number;
    rows: number = 30;
    currentPage: number;
  
    //search: string = '';
    searchControl = new FormControl();
    formCtrlSub: Subscription;

    hotkeyHandlerIsRegistered = false;

    debugMode: boolean = false;
  
    constructor(
      public translateService: TranslateService,
      private messageWrapperService: MessageWrapperService,
      private router: Router,
      //private globalService: GlobalService
    ) { }
  
    ngOnDestroy() {
      if(this.debugMode==true) console.log("CRUDBasicSelect.ngOnDestroy()");
      this.formCtrlSub.unsubscribe();

      this.unRegisterHotkeyHandler();
    }
  
    ngOnInit() {
      if(this.debugMode==true) console.log("CRUDBasicSelect.ngOnInit()");
  

      // für debug: options vervielfachen (Demodaten)
      if(this.debugMode==true) {
        for(let i=0; i<12000; i++) {
          let newOption : any = {};
          let random0bis19 = Math.floor(Math.random() * Math.floor(19));
          newOption[this.label] = "debug/demo "+i+" "+ "x".repeat(random0bis19);
          if(this.label2 != null) newOption[this.label2] = "debug/demo "+i+" "+ "x".repeat(random0bis19);
          this.options.push(newOption);
        }
        console.log("CRUDBasicSelect.ngOnInit() options.length:", this.options.length);
      }

      this.optionsFiltered = this.options;

      this.formCtrlSub = this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(val => {
        //this.filterItems(val);
        this.filterItemsVirtualScroller(val);
      });

      this.registerHotkeyHandler();

      this.currentPage = 0;
      //this.search = "";

      // VirtualScroller arbeitet anders als DataScroller:
      // bei DataScroller, war das darzustellende Array zu Beginn leer und wurde mit jedem
      //   Aufruf von "loadData" erweitert - bis "totalRecords" erreicht wurde.
      // bei VirtualScroller muss schon zu Beginn das Array mit leeren Objekten initalisiert sein!
      //   Grösse des Arrays = totalRecords! (totalRecords als Parameter entfällt)
      //   bei einem "loadData" muss mit .splice im Array genau der Teil (Page, Rows) mit den echten Objekten ersetzt werden
      //this.items = [];
      this.items = Array.from({ length: this.options.length });
      if (this.debugMode == true) console.log("CRUDBasicSelect.ngOnInit() options:", this.options);
      if (this.debugMode == true) console.log("CRUDBasicSelect.ngOnInit() items:", this.items);
    }

    ngAfterViewInit(): void {
      // primeNG 10 Fix: Bei LazyLoad in z.B. Dialogen ist ein Resize nötig - weil PrimeNg sonst nur 1 Satz darstellt (optisches Problem bei Lazyload: siehe auch: https://github.com/primefaces/primeng/issues/9766)
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1);
    }
    
    /*onItemsRetrieved(items: any[]) {
      console.log("CRUDBasicSelect.onItemsRetrieved() DEPRECATED!");
      if (this.debugMode == true) console.log("CRUDBasicSelect.onItemsRetrieved() items:", items);
      if(this.debugMode==true) console.log("CRUDBasicSelect.onItemsRetrieved() ... sorting ...");
      this.items = [...this.items, ...items].sort((a, b) => (""+a[this.label]).localeCompare((""+b[this.label])));
      if(this.debugMode==true) console.log("CRUDBasicSelect.onItemsRetrieved() ... done sorting! items: ", this.items);
      this.loading = false;
      if (this.debugMode == true) console.log("CRUDBasicSelect.onItemsRetrieved() this.items:", this.items);
    }*/
  
    selectItem(item: any) {
      if(this.debugMode==true) console.log("CRUDBasicSelect.selectItem()");
      this.onSelect.emit(item);
      this.unRegisterHotkeyHandler();      
      this.visible = false;
    }

    dialogClose() {
      if(this.debugMode==true) console.log("CRUDBasicSelect.dialogClose()");
      this.onClose.emit();
      this.unRegisterHotkeyHandler();
      this.visible = false;
    }
  
    /*loadMore(filterValue: string, append: boolean) {
      console.log("CRUDBasicSelect.loadMore() DEPRECATED!");
      debugger;
      if(this.debugMode==true) console.log("CRUDBasicSelect.loadMore() options:", this.options);
      if(this.options == null) {
        if(this.debugMode==true) console.log("CRUDBasicSelect.loadMore() options are null ... skip!");
      }
      else {
        this.currentPage++;
        if(this.debugMode==true) console.log("CRUDBasicSelect.loadMore() currentpage/rows:", this.currentPage, this.rows);
        if(this.debugMode==true) console.log("CRUDBasicSelect.loadMore() label/label2:", this.label, this.label2);

        //if(this.debugMode==true) console.log("CRUDBasicSelect.loadMore() options:", this.options);
        let optionsForThisPage = this.options.filter(f => this.filterSingleRecord(f, filterValue, this.label, this.label2)==true)
        .slice((this.currentPage-1)*this.rows, this.currentPage*this.rows);
        if(this.debugMode==true) console.log("CRUDBasicSelect.loadMore() optionsForThisPage:", optionsForThisPage);
        this.totalRecords = this.options.length;
        if (!append) {
          this.items = [];
        }
        this.onItemsRetrieved(optionsForThisPage)
      }
      if(this.debugMode==true) console.log("CRUDBasicSelect.loadMore() done!");
    }*/
  
    filterSingleRecord(item: any, filterValue: string, label: string, label2: string) {
      //if(this.debugMode==true) console.log("CRUDBasicSelect.filterSingleRecord() label/label2/item:", label, label2, item);
      if(label == null) {
        //if(this.debugMode==true) console.log("CRUDBasicSelect.filterSingleRecord() ... return false, since label == null");
        return false;
      }
      if((""+item[label]).toLowerCase().indexOf(""+filterValue.toLowerCase())>-1) {
        //if(this.debugMode==true) console.log("CRUDBasicSelect.filterSingleRecord() ... return true, since found in label");
        return true;
      }
      if(label2!=null && (""+item[label2]).toLowerCase().indexOf(""+filterValue.toLowerCase())>-1) {
        //if(this.debugMode==true) console.log("CRUDBasicSelect.filterSingleRecord() ... return true, since found in label2");
        return true;
      }
      //if(this.debugMode==true) console.log("CRUDBasicSelect.filterSingleRecord() ... return false");
      return false;
    }

    loadDataVirtualScroller(event: LazyLoadEvent) {
      if(this.debugMode==true) console.log("CRUDBasicSelect.loadDataVirtualScroller() event:", event);
      // simulate remote connection with a timeout
      setTimeout(() => {
        //load data of required page
        let loadedItems = this.optionsFiltered.slice(
          event.first,
          event.first + event.rows
        );
        if(this.debugMode==true) console.log("CRUDBasicSelect.loadDataVirtualScroller() loadedItems:", loadedItems);
        
        //populate page of virtual cars
        Array.prototype.splice.apply(this.items, [
          ...[event.first, event.rows],
          ...loadedItems
        ]);
        
        //trigger change detection
        this.items = [...this.items];
      
        if(this.debugMode==true) console.log("CRUDBasicSelect.loadDataVirtualScroller() items:", this.items);
      }, 1000);
    }

    /*loadData(event: LazyLoadEvent) {
      console.log("CRUDBasicSelect.loadData() DEPRECATED!");
      debugger;
      if(this.debugMode==true) console.log("CRUDBasicSelect.loadData()");
      this.loadMore(this.search, true);
      if(this.debugMode==true) console.log("CRUDBasicSelect.loadData() done!");
    }*/

    /*filterItems(filterValue: string) {
      console.log("CRUDBasicSelect.filterItems() DEPRECATED!");
      if(this.debugMode==true) console.log("CRUDBasicSelect.filterItems()");
      this.currentPage = 0;
      this.search = filterValue;
      this.loadMore(this.search, false);
      if(this.debugMode==true) console.log("CRUDBasicSelect.filterItems() done!");
    }*/

    filterItemsVirtualScroller(filterValue: string) {
      if(this.debugMode==true) console.log("CRUDBasicSelect.filterItemsVirtualScroller() filterValue:", filterValue);
      this.optionsFiltered = this.options.filter(f => this.filterSingleRecord(f, filterValue, this.label, this.label2)==true)
        ;//.slice((this.currentPage-1)*this.rows, this.currentPage*this.rows);
      if(this.debugMode==true) console.log("CRUDBasicSelect.filterItemsVirtualScroller() toptionsFiltered:", this.optionsFiltered);
      this.totalRecords = this.optionsFiltered.length;
      this.items = Array.from({ length: this.optionsFiltered.length });
      if (this.debugMode == true) console.log("CRUDBasicSelect.filterItemsVirtualScroller() options:", this.options);
      if (this.debugMode == true) console.log("CRUDBasicSelect.filterItemsVirtualScroller() items:", this.items);
      let lazyLoadEvent : LazyLoadEvent = {
        first: 0,
        rows: this.rows
      }
      this.dataScroller.scrollToIndex(0, 'smooth');
      this.loadDataVirtualScroller(lazyLoadEvent);
    }


    registerHotkeyHandler() {
      if(this.hotkeyHandlerIsRegistered==false) {
        if(this.debugMode==true) console.log("CRUDBasicSelectComponent registering hotkey-handler ...");      
        //this.globalService.registerHotKeyHandler(this);
        this.hotkeyHandlerIsRegistered=true;
      }
    }
    unRegisterHotkeyHandler() {
      if(this.hotkeyHandlerIsRegistered==true) {
        if(this.debugMode==true) console.log("CRUDBasicSelectComponent UN-registering hotkey-handler ...");      
        //this.globalService.unRegisterHotKeyHandler(this);
        this.hotkeyHandlerIsRegistered=false;
      }
    }

    hotkeys(event) {
      if(event.repeat == true) {
        // // repeated events interessieren uns generell überhaupt nicht!
        // z.B. bei CTRL (keydown) kommt der event endlos oft vor - so lange man auf CTRL bleibt
      }
      else {
        //if(this.debugMode == true) if(this.debugMode==true) console.log("CRUDBasicSelect.hotkeys() event:", event);
        //if(this.globalService != null) this.globalService.handleHotKeys(this, event);
      }
    }
  
    handleHotkeys(event) {
      if (event.keyCode == 27) {
        this.dialogClose();
        event.preventDefault();
      }
    }
 
  
    handleError(error: any) {
      this.loading = false;
      let summary = this.translateService.instant('Fehler', true);
  
      if (error.status === 422) {
        summary += ' (422)';
        if (error != null) {
          this.errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || error.error.PasswordTooShort || error.error.PasswordRequiresNonAlphanumeric || 'Server Error';
        }
        else {
          this.errorMessage = "Server Error";
        }
      }
      else if (error.status === 401) {
        summary += ' (401)';
        this.errorMessage = "Unauthorized";
        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
      }
      else {
        this.errorMessage = <any>error
      }
  
      this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: this.errorMessage });
  
      this.loading = false;
    }
}
