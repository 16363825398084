import { Component, OnInit, Inject, forwardRef, ViewChild, Injector, Input } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';

import { AppComponent } from '../app.component';

import { CarService } from '../demo/service/carservice';
import { Car } from '../demo/domain/car';
import { Lagerbestand } from '../demo/domain/lagerbestand';
import { SelectItem } from 'primeng/api';
import { DataViewModule, DataView } from 'primeng/dataview';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { InputSwitchModule } from 'primeng/inputswitch';
import { IArtikel } from '../_interfaces/artikel';
import { ArtikelService } from '../_services/artikel.service';
import { IArtikelbild } from 'src/app/_interfaces/artikelbild';
import { ArtikelbildService } from 'src/app/_services/artikelbild.service';
import {MenuItem, TreeNode, MessageService} from 'primeng/api';
import { OnDestroy } from '@angular/core';

import { IArtikelgruppe } from '../_interfaces/artikelgruppe';
import { ArtikelgruppeService } from '../_services/artikelgruppe.service';
//import { Super } from 'node_modules_alt/@babel/types/lib';
import { IHersteller } from 'src/app/_interfaces/hersteller';
import { IGeodaten } from 'src/app/_interfaces/geodaten';
import { IArtikelLieferart } from '../_interfaces/artikel-lieferart';
import { TranslateService } from '../_services/translate.service';
import { ArtikelDetailComponent } from 'src/app/artikel-detail/artikel-detail.component';
import { LieferartService } from '../_services/lieferart.service';
import { GeodatenService } from '../_services/geodaten.service';
import { ArtikelLieferartService } from '../_services/artikel-lieferart.service';
import { IUnternehmen } from 'src/app/_interfaces/unternehmen';
import {SliderModule} from 'primeng/slider';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { LazyLoadEvent } from "primeng/api";
import { ILieferart } from 'src/app/_interfaces/lieferart';
import * as cloneDeep from 'lodash/cloneDeep'; 
import { ILager } from '../_interfaces/lager';
import { FavoritService } from '../_services/favorit.service';
import { ArtikelGruppeFindTreeNode } from '../_helpers/artikelgruppe-find-treenode';
// import { $ } from 'protractor';
declare var $ : any;



@Component({

    selector: 'app-artikel-uebersicht',
    templateUrl: './artikel-uebersicht.component.html',
    styleUrls: ['./artikel-uebersicht.component.css'],
    styles: [`      
  .filter-container {
      text-align: center;
  }

  .car-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2em;
      border-bottom: 1px solid #d9dad9;
  }

  .car-details > div {
      display: flex;
      align-items: center;
  }

  .car-details > div img {
      margin-right: 14px;
  }

  .car-detail {
      padding: 0 1em 1em 1em;
      border-bottom: 1px solid #d9dad9;
      margin: 1em;
  }

  .ui-panel-content {
      padding: 1em;
  }

  .dark-theme :host ::ng-deep .car-details,
  .dark-theme :host ::ng-deep .car-detail {
      border-bottom: 1px solid #191919;
  }
  
  @media (max-width: 1024px) {
      .car-details img {
            width: 75px;
      }

      .filter-container {
          text-align: left;
      }
  }
`]
})
export class ArtikelUebersichtComponent implements OnInit, OnDestroy   {
    @ViewChild('dv') public dataView: DataView;
    @Input('CRUDItemAsParameter') CRUDItemAsParameter: any;

  

    pageTitle: string = null; // this._translate.instant("Übersicht", true); // siehe ngOnInit
    CRUDItemRoutePlural: string = null; //"artikel-uebersicht"; // siehe initAccordingToType

    //Bool
    debugMode: boolean = true;
    showElements: boolean = false;
    displayDialog: boolean;
    filterOpen: boolean = false;
    marginTop: boolean = false;
    public mobileAnsicht: boolean = false;


    selectedLagerbestand: Lagerbestand;
    lagerbestaende: Lagerbestand[];
    cols: any[];

    // DataView
    artikel: IArtikel[];
    totalRecords: number;
    paginatorPosition: string;
    rows: number = 40;
    dataviewFill: boolean = false; // erst verzögert einblenden, nämlich erst, wenn Location geklärt ist!
    paginationText: string = "";
    responsiveOptions;

 

   

    sortOptions: SelectItem[];

    sortKey: string;

    // "Form"-Suchkriterien
        geodaten: IGeodaten = null;
        hersteller: IHersteller = null;
        unternehmen: IUnternehmen = null;
        onlyMyUser: boolean = null; // siehe queryparm "overviewmode"
        onlyAktive: boolean = null; // siehe queryparm "overviewmode"
        onlyLagerbestand: boolean = null; // siehe queryparm "overviewmode"
        onlyFavoriten: boolean = null; // siehe queryparm "overviewmode"
        auchLagerbestand: boolean = null; 
        lager: IUnternehmen = null;
        artikelLieferartenDisplayAuswahlPopUp: boolean;
        artikelGruppeLbl: string = "Artikelgruppe";
        artikelNumLbl: string = "Artikelnummer";
        herstellerLbl: string = "Hersteller";
        lieferartLbl: string ="Lieferart";
        verkaeuferLbl: string = "Verkäufer";
        lagerLbl: string = "Lager";
        ortLbl: string = "Postleitzahl Ort";
        //plzLbl: string = "PLZ";
        umkreisLbl: string = "Umkreis";
      artikelLieferartenSelectedAsString: string = "";
      artikelLieferartenSelected: IArtikelLieferart[]; 
      artikelLieferartenItems: IArtikelLieferart[];
      umkreisKm: number;
      //suchanfragen: boolean = null; 
      typ: string = null;
      
    
    // letzte Suchkriterien - zum Zeitpunkt von "Aktualisieren"
    // die müssten gepuffert werden, und beim lazyloading genommen werden - statt der aktuellen Werte!
    // Weil: Blättern soll er ja mit den zuletzt abgerufenen Werten!
    lastSearch_geodatenId: number;    
    lastSearch_umkreisKm: number;
    lastSearch_warMitUmkreis: boolean = false; // wenn ja, wird Distanz angezeigt
    lastSearch_artikelgruppeId: number;
    lastSearch_unternehmenId: number;
    lastSearch_onlyMyUser: boolean;
    lastSearch_onlyAktive: boolean;
    lastSearch_onlyLagerbestand: boolean;
    lastSearch_onlyFavoriten: boolean;
    lastSearch_auchLagerbestand: boolean;
    lastSearch_artikelnummer: string;
    lastSearch_lieferarten: ILieferart[];
    lastSearch_herstellerId: number;
    lastSearch_lagerId: number;
    lastSearch_volltextSuche: string;
    lastSearch_sortKey: string;
    //lastSearch_suchanfragen: boolean;
    lastSearch_typ: string;

    loading: boolean = true;

    //ClearVar
    sortField: string;
    artikelnummerNg: string;
    clearLieferart: string;
    clearOrt: string;
    clearPlz: string;
    sortOrder: number;
    volltextSuche: string;
    // Multiple Items
   
   


    // filterModel: {filterBezeichnung: string, checked: boolean, displayType: string, data: {id: number, label: string, value: string}[]}[];
    artikelGruppeDisplayAuswahlPopUp: boolean;
    artikelGruppeBreadCrumbItems: MenuItem[];
    artikelGruppeTreeNodes: TreeNode[];
    artikelGruppeSelected: TreeNode; 
    artikelGruppeItems: IArtikelgruppe[];
    

    artikelGruppeVorauswahlId: number = 0; // laut URL parameter artikelgruppeId
    artikelGruppeVorauswahlTreeNode: TreeNode = null; // zu artikelGruppeVorauswahlId gehörige TreeNode

    artikelGruppeBreadCrumbHome = {icon: 'pi pi-home'/*, routerLink: '/'*/};
   
    artikelGruppeFindTreeNode: ArtikelGruppeFindTreeNode = new ArtikelGruppeFindTreeNode();
    
    //TEST
    istNurEinTest: SelectItem[];

    //   isActive: boolean = true;  
    checked: boolean = true;
  

 


    
    constructor(@Inject(forwardRef(() => AppComponent))public _app, public app: AppComponent,
        private carService: CarService,
        private router: Router,
        public artikelService: ArtikelService,
        private artikelgruppeService: ArtikelgruppeService,
        public _translate: TranslateService,
        public _crudItemService: ArtikelService,
        public artikelLieferartService: ArtikelLieferartService,
        public lieferartService: LieferartService,
        public geodatenService: GeodatenService,
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
        private favoritService: FavoritService,
        private _injector: Injector,

        private artikelbildService: ArtikelbildService) { 
          this.breadcrumbService.setItems([
            { label: 'Components' },
            { label: 'Übersicht', routerLink: ['/artikel-uebersicht'] }
            
        ]);
        }
        
    initAccordingToType() {
      let _thisInstance = this;
      this.route.queryParams.subscribe(params => {
        let urlParmType = params['overviewmode'];
        _thisInstance.onlyMyUser = false;
        _thisInstance.onlyAktive = false;
        _thisInstance.onlyLagerbestand = false;
        _thisInstance.onlyFavoriten = false;
        switch (urlParmType) {
          case "myRequests":
            _thisInstance.CRUDItemRoutePlural = "suche-uebersicht";
            //_thisInstance.suchanfragen = true;
            _thisInstance.typ = "S";
            _thisInstance.onlyMyUser = true;
            _thisInstance.auchLagerbestand = false;
            if(this.app != null && this.app.benutzer != null) _thisInstance.unternehmen = this.app.benutzer.unternehmen

            _thisInstance.pageTitle = this._translate.instant("Meine Suchanfragen", true);
            this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;
            break;
          case "myItems":
          _thisInstance.CRUDItemRoutePlural = "suche-artikel";
          //_thisInstance.suchanfragen = false;
          _thisInstance.typ = "A";
            _thisInstance.onlyMyUser = true;
            _thisInstance.auchLagerbestand = true;
            if(this.app != null && this.app.benutzer != null) _thisInstance.unternehmen = this.app.benutzer.unternehmen

            _thisInstance.pageTitle = this._translate.instant("Meine Artikel", true);
            this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;
            break;
          case "myActive":
            _thisInstance.CRUDItemRoutePlural = "suche-artikel";
            //_thisInstance.suchanfragen = false;
            _thisInstance.typ = "A";
            _thisInstance.onlyAktive = true;
            _thisInstance.onlyMyUser = true;
            _thisInstance.auchLagerbestand = false;
            if(this.app != null && this.app.benutzer != null) _thisInstance.unternehmen = this.app.benutzer.unternehmen

            _thisInstance.pageTitle = this._translate.instant("Meine aktiven Artikel", true);
            this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;
            break;
          case "myStock":
            _thisInstance.CRUDItemRoutePlural = "suche-artikel";
            //_thisInstance.suchanfragen = false;
            _thisInstance.typ = "A";
            _thisInstance.onlyLagerbestand = true;
            _thisInstance.onlyMyUser = true;
            _thisInstance.auchLagerbestand = false;
            if(this.app != null && this.app.benutzer != null) _thisInstance.unternehmen = this.app.benutzer.unternehmen

            _thisInstance.pageTitle = this._translate.instant("Mein Lagerbestand", true);
            this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;
            break;
          case "myFavorites":
            _thisInstance.CRUDItemRoutePlural = "suche-artikel";
            //_thisInstance.suchanfragen = false;
            _thisInstance.typ = "A";
            _thisInstance.onlyFavoriten = true;
            _thisInstance.auchLagerbestand = true;
            
            _thisInstance.pageTitle = this._translate.instant("Meine Favoriten", true);
            this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;
            break;
          case "myAdverts":
            _thisInstance.CRUDItemRoutePlural = "suche-werbeanzeige";
            //_thisInstance.suchanfragen = false;
            _thisInstance.typ = "W";
            _thisInstance.onlyFavoriten = false;
            _thisInstance.auchLagerbestand = true;
              
            _thisInstance.pageTitle = this._translate.instant("Meine Werbeanzeigen", true);
            this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;
            break;            
          default:
            _thisInstance.CRUDItemRoutePlural = "suche-artikel";
            //_thisInstance.suchanfragen = false;
            _thisInstance.typ = "A_W";
            _thisInstance.onlyAktive = true;
            _thisInstance.onlyMyUser = false;
            _thisInstance.auchLagerbestand = false;
            _thisInstance.pageTitle = this._translate.instant("Übersicht", true);
            this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;
        }
      });
    }

    ngOnInit(): void {
      
        this.app.setArtikelUebersicht(this);

        this.initAccordingToType();
        
        //DataView
        //this.carService.getCarsLarge().then(cars => this.cars = cars);

        //this.artikel = []; // bei dataview-lazy-Logik nicht nötig, bei virtualscroller etc. schon!

        this.sortOptions = [
          { label: 'Entfernung', value: 'distance' },
          { label: 'Neueste zuerst', value: 'Created' },
          { label: 'Älteste zuerst', value: '!Created' },
          { label: 'Favoriten zuerst', value: 'favorite_distance' }
        ];

        let thisInstance = this;

        let subscription:Subscription = this.route.queryParams.subscribe(
          params => {
            let id = +params['artikelgruppeId'] || 0;
            thisInstance.artikelGruppeVorauswahlId = id;
            if(thisInstance.artikelGruppeVorauswahlId != 0) thisInstance.filterOpen = true;
            console.log("ArtikelUebersicht.ngOnInit() artikelGruppeVorauswahlId:", thisInstance.artikelGruppeVorauswahlId);
        });

        this.artikelgruppeService.getArtikelgruppenChildrenForTree().subscribe(function (response) {
          thisInstance.artikelGruppeItems = response.artikelgruppen;
          console.log("ArtikelUebersicht.ngOnInit() getArtikelgruppenCollection thisInstance.artikelGruppeItems:", thisInstance.artikelGruppeItems);
          thisInstance.artikelGruppeTreeNodes = [];

          thisInstance.artikelGruppeItems.filter(f => f.artikelgruppeId == null).forEach(artikelGruppe => {
            //thisInstance.artikelGruppeTreeNodes.push(newTreeNode);
            thisInstance.artikelGruppeTreeNodes.push(thisInstance.artikelGruppePrepareTreeNode(thisInstance, 0, artikelGruppe));
          });
          console.log("ArtikelUebersicht.ngOnInit() getArtikelgruppenChildrenForParent thisInstance.artikelGruppeTreeNodes:", thisInstance.artikelGruppeTreeNodes);

          if(thisInstance.artikelGruppeVorauswahlTreeNode != null) {
            let newEvent: any = {
              node: thisInstance.artikelGruppeVorauswahlTreeNode
            };
            thisInstance.artikelGruppeOnSelected(newEvent, thisInstance);
            //thisInstance.refresh(); // passiert in ngOnInit_ArtikelUebersichtComponent_pt3 sowieso
            thisInstance.ngOnInit_ArtikelUebersichtComponent_pt2(thisInstance);
          }
          else {
            thisInstance.ngOnInit_ArtikelUebersichtComponent_pt2(thisInstance);
          }
        }, function (error) { return this.handleError(error); });
   

      // auf reuse (RouteReuseStrategy) reagieren: https://stackoverflow.com/questions/50392691/angular-5-how-to-reload-current-data-when-i-use-routereusestrategy
      this.router.onSameUrlNavigation = 'reload';
      this.router.events.subscribe(event => {
        if ((event instanceof NavigationEnd)) {
          let navigationEnd: NavigationEnd = <NavigationEnd>event;
          if (navigationEnd.url != null) {
            //console.log("CRUDBasicListComponent event NavigationEnd.url:", navigationEnd.url);

            let url: string = navigationEnd.url;
            if (url.startsWith("/" + this.CRUDItemRoutePlural) && !url.startsWith("/" + this.CRUDItemRoutePlural + "/")) {
              // beim reUse wird der pageTitle nicht autom. gesetzt -> übernehmen!
              //console.log("CRUDBasicListComponent.NavigationEnd... resetting pageTitle", event);
              /*if (this.CRUDDisablePageTitleUpdates == false)*/ this.app.setPageTitle(this['pageTitle']);
            }
          }
        }
        
      });
      this.mobileAnsicht = window.innerWidth <= 540;

      window.onresize = () => {
        this.mobileAnsicht = window.innerWidth <= 540;
        // this.showElements = window.innerWidth <= 414;
      };
     

    }
    marginForMobileView() {
      if(this.mobileAnsicht && this.showElements){
        $('#showElementsButtonRow').removeClass('marginForMobileTop');
        $('#showElementsButtonRow').removeClass('marginForiPadTop');
      }
      else if (this.mobileAnsicht && !this.showElements){
        $('#showElementsButtonRow').addClass('marginForMobileTop');
        $('#showElementsButtonRow').removeClass('marginForiPadTop');
      }
      else if(!this.mobileAnsicht && this.showElements){
        $('#showElementsButtonRow').removeClass('marginForMobileTop');
        $('#showElementsButtonRow').removeClass('marginForiPadTop');
      }
      else if(!this.mobileAnsicht && !this.showElements){
        $('#showElementsButtonRow').removeClass('marginForMobileTop');
        $('#showElementsButtonRow').addClass('marginForiPadTop');
      }
    }
    mehrAnzeigenToggle() {
      this.showElements=!this.showElements;
    }
    
   
   
    lieferOrt(lager: ILager) {
       lager.geodaten.ort;
    }
    isAbholung(artikel: IArtikel) {
      var isAbholbar = false;
    if (artikel.artikelLieferarten != null && artikel.artikelLieferarten.length > 0) {
      artikel.artikelLieferarten.forEach(artikelLieferart => {
        if (artikelLieferart.lieferart != null && artikelLieferart.lieferart.versand == false) {
          isAbholbar = true;
        }
      })
    }
    return isAbholbar;
    }
    isLieferung(artikel: IArtikel) {
      var isVersand = false;
      if (artikel.artikelLieferarten != null && artikel.artikelLieferarten.length > 0) {
        artikel.artikelLieferarten.forEach(artikelLieferart => {
          if (artikelLieferart.lieferart != null && artikelLieferart.lieferart.versand == true) {
            isVersand = true;
          }
        })
      }
      return isVersand;
    }



    selectArtikel(event: Event, artikel: IArtikel): void {
      // wenn Suchanfrage, dann direkt in Bearbeiten-Modus
      debugger;
      if(this.typ == "S") {
        this.router.navigate(['/suche', artikel.id], { queryParamsHandling: "merge" });
      }
      else if(this.typ == "W") {
        this.router.navigate(['/werbeanzeige', artikel.id], { queryParamsHandling: "merge" });
      }
      else if(this.typ == "A_W" && artikel.typ == "W") {
        if(this.app != null && this.app.benutzer != null && this.app.benutzer.unternehmen.id == artikel.unternehmenId) {
          this.router.navigate(['/werbeanzeige', artikel.id], { queryParamsHandling: "merge" });
        }
        else {
          window.open(artikel.url, artikel.url /*windowname*/);
        }
      }
      else {
        this.router.navigate(['/artikel-ansicht', artikel.id], { queryParamsHandling: "merge" });
      }
      //this.selectedCar = car;
      //this.displayDialog = true;
      event.preventDefault();
    }

    //DataView
    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        }
        else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }
  


    newArtikel() {
        //this.router.navigate(['/produkt-details', '0']);
        if(this.typ == "S") {
          this.router.navigate(['/suche', '0'], { queryParamsHandling: "merge" });
        }
        else if(this.typ == "W") {
          this.router.navigate(['/werbeanzeige', '0'], { queryParamsHandling: "merge" });
        }
        else {
          this.router.navigate(['/artikel', '0'], { queryParamsHandling: "merge" });
        }
        event.preventDefault();
    }

    artikelTitelBildURL(artikel: IArtikel) {
        //console.log("artikelUebersicht.artikelTitelBildURL() artikel:", artikel);
        return artikel != null ? this.artikelbildService.getThumbDownloadUrlForTitelbildArtikel(artikel.id, artikel.rowVersion) : null;
    }
    // inputToggleSwitch() {
      
    //     this.filterModel.forEach(toggleFilterSwitchElement => {
    //         toggleFilterSwitchElement.checked = false;
    //     });

    // }
    //Artikelgruppe
    artikelGruppeShowAuswahlPopUp() {
        this.artikelGruppeDisplayAuswahlPopUp = true;
      }
      artikelGruppeBuildBreadCrumb() {
        console.log("ArtikelUebersicht.artikelGruppeBuildBreadCrumb()");
        this.artikelGruppeBreadCrumbItems = [];
        let item = this.artikelGruppeSelected;
        if(item == null) return;
        this.artikelGruppeBreadCrumbItems.push({
          label: item.label,
          id: item.data.id
        });
        console.log("ArtikelUebersicht.artikelGruppeBuildBreadCrumb() item:", item);
        while(item.data.artikelgruppeId != null) {
          console.log("ArtikelUebersicht.artikelGruppeBuildBreadCrumb() searching for id="+item.data.artikelgruppeId+" in this.artikelGruppeItems:", this.artikelGruppeItems);
          let artikelGruppeParent = this.artikelGruppeItems.find(f => f.id == item.data.artikelgruppeId);
          if(artikelGruppeParent != null) {
            item = {
              data: { // data enthält id und parentId der artikelGruppe
                id: artikelGruppeParent.id, 
                artikelgruppeId: artikelGruppeParent.artikelgruppeId
              },
              label: artikelGruppeParent.bezeichnung
            }
            this.artikelGruppeBreadCrumbItems.unshift({
              label: item.label,
              id: item.data.id
            }); // unshift statt push -> an ANFANG des Arrays!
          }
          else { // parent (warum auch immer) nicht gefunden -> break
            break;
          }
        }
        console.log("ArtikelUebersicht.artikelGruppeBuildBreadCrumb() artikelGruppeBreadCrumbItems:", this.artikelGruppeBreadCrumbItems);
      }
      public artikelGruppeOnSelected(event, thisInstance:any) { // wird auch aus appMenu aufgerufen!
        console.log("ArtikelUebersicht.artikelGruppeOnSelected() event/thisInstance", event, thisInstance);
        this.artikelGruppeSelected = event.node;
        this.artikelGruppeDisplayAuswahlPopUp = false;
        this.artikelGruppeBuildBreadCrumb();
    
        //this/*Instance*/.displayMessageForm = {}; // validity für Artikelgruppe neu prüfen
        //this/*Instance*/.CRUDForm.updateValueAndValidity(); // validity für Artikelgruppe neu prüfen
      }
      public artikelGruppeClear() { 
        console.log("ArtikelUebersicht.artikelGruppeClear()");
        this.artikelGruppeSelected = null;
        this.artikelGruppeDisplayAuswahlPopUp = false;
        this.artikelGruppeBuildBreadCrumb();
    
        //this/*Instance*/.displayMessageForm = {}; // validity für Artikelgruppe neu prüfen
        //this/*Instance*/.CRUDForm.updateValueAndValidity(); // validity für Artikelgruppe neu prüfen
      }
      public artikelGruppeCloseDialog() { 
        console.log("ArtikelUebersicht.artikelGruppeCloseDialog()");
        this.artikelGruppeDisplayAuswahlPopUp = false;
      }
      //Breadcrumb 
      artikelGruppePrepareTreeNode(instance: any, level:number, artikelGruppe:IArtikelgruppe) {
        //console.log("ArtikelUebersicht.prepareArtikelGruppeTreeNode() level/artikelGruppe", level, artikelGruppe.bezeichnung);
        let children:TreeNode[] = [];
        artikelGruppe.inverseArtikelgruppe.forEach(child => {
          children.push(this.artikelGruppePrepareTreeNode(this, level+1, child));
        });
        let newTreeNode:TreeNode = {
          children: children,
          collapsedIcon: "ui-icon-folder",
          data: { // data enthält id und parentId der artikelGruppe
            id: artikelGruppe.id, 
            artikelgruppeId: artikelGruppe.artikelgruppeId
          },
          expandedIcon: "ui-icon-folder-open",
          label: artikelGruppe.bezeichnung /*+ " (id: "+artikelGruppe.id+")"*/,
          key: ""+artikelGruppe.id // ohne den "key" funktioniert die Children-Auswahl NACH FILTER nicht! https://forum.primefaces.org/viewtopic.php?t=58524
        };
        console.log("ArtikelUebersicht.artikelGruppePrepareTreeNode() artikelGruppeVorauswahlId/data", instance.artikelGruppeVorauswahlId, newTreeNode.data)
        if(newTreeNode.data./*artikelgruppeId*/id == instance.artikelGruppeVorauswahlId) {
          instance.artikelGruppeVorauswahlTreeNode = newTreeNode;
          console.log("ArtikelUebersicht.artikelGruppePrepareTreeNode() artikelGruppeVorauswahlId == data!");
        }
        
        return newTreeNode;
      }
      //Artikellieferarten
      artikelLieferartShowAuswahlPopUp() {
        this.artikelLieferartenDisplayAuswahlPopUp = true;
      }
      onCRUDItemRetrieved(CRUDItem: any): void {
        if(this.artikelLieferartenItems != null && this.artikelLieferartenItems.length > 0) {
          // if(CRUDItem.artikelLieferarten != null && CRUDItem.artikelLieferarten.length > 0) {
          //   let i = 0;
          //   this.artikelLieferartenItems.forEach(artikelLieferartItem => {
          //     console.log("ArtikelUebersicht.onCRUDItemRetrieved() checking artikelLieferartItem:", artikelLieferartItem);
          //     let idx = CRUDItem.artikelLieferarten.findIndex(f => f.lieferartId == artikelLieferartItem.lieferartId);
          //     if(idx >= 0) {
          //       console.log("ArtikelUebersicht.onCRUDItemRetrieved() replacing index "+i+" with existing item from CRUDItem.artikelLieferarten: ", CRUDItem.artikelLieferarten[idx]);
      
          //       this.artikelLieferartenItems.splice(i, 1, CRUDItem.artikelLieferarten[idx])
          //     }
          //     i++;
          //   });
        
          // }
          console.log("ArtikelUebersicht.onCRUDItemRetrieved() after: this.artikelLieferartenItems:", this.artikelLieferartenItems);
        }
        this.artikelLieferartenSelected = CRUDItem.artikelLieferarten; // Parent+Child
        this.artikelLieferartenOnSelected();

      }

      artikelLieferartenOnSelected() {
        this.artikelLieferartenSelectedAsString = "";
        this.artikelLieferartenSelected.forEach(artikelLieferart => {
          if(this.artikelLieferartenSelectedAsString.length > 0) this.artikelLieferartenSelectedAsString += ', ';
          this.artikelLieferartenSelectedAsString += artikelLieferart.summary;
        });
        this.artikelLieferartenDisplayAuswahlPopUp = false;
    
        // this.displayMessageForm = {}; // validity für Artikelgruppe neu prüfen
        // this.CRUDForm.updateValueAndValidity(); // validity für Artikelgruppe neu prüfen
      }

      ngOnInit_ArtikelUebersichtComponent_pt2(thisInstance : ArtikelUebersichtComponent) {
        console.log("ArtikelUebersicht.ngOnInit_ArtikelUebersichtComponent_pt2() ...");
        thisInstance.lieferartService.getLieferartenCollection(1, 0, '').subscribe(function (response) {
          thisInstance.artikelLieferartenItems = [];
          response.lieferarten.forEach(lieferart => {
            let artikelForArtikelLieferart = thisInstance._crudItemService.initializeArtikel();
            //artikelForArtikelLieferart.id = thisInstance.dataId;
            let artikelLieferart:IArtikelLieferart = thisInstance.artikelLieferartService.initializeArtikelLieferart();
            artikelLieferart.artikel = artikelForArtikelLieferart;
            //artikelLieferart.artikelId = thisInstance.dataId;
            artikelLieferart.lieferart = lieferart;
            artikelLieferart.lieferartId = lieferart.id;
            artikelLieferart.summary = lieferart.summary;
            thisInstance.artikelLieferartenItems.push(artikelLieferart);
          });
          console.log("ArtikelUebersicht.ngOnInit_ArtikelUebersichtComponent_pt2() artikelLieferartenItems:", thisInstance.artikelLieferartenItems);
          thisInstance.artikelLieferartenSelected = [];
    
          thisInstance.ngOnInit_ArtikelUebersichtComponent_pt3(thisInstance);
        }, function (error) { 
          console.log("ArtikelUebersicht.ngOnInit_ArtikelUebersichtComponent_pt2() error:", error);
          //return thisInstance.handleError(error); 
        });
      }

      ngOnInit_ArtikelUebersichtComponent_pt3(thisInstance : ArtikelUebersichtComponent) {
        /*if (navigator.geolocation) {
          let thisInstance = this;
          navigator.geolocation.getCurrentPosition(position => {

            console.log("ArtikelUebersicht.ngOnInit() position.coords.latitude/position.coords.longitude:", position.coords.latitude, position.coords.longitude);
            this.geodatenService.getGeodatenForLatLong(position.coords.latitude, position.coords.longitude).subscribe(function (response) {
              thisInstance.geodaten = response;
              //thisInstance.umkreisKm = 100;
              console.log("ArtikelUebersicht.ngOnInit() geodaten (from Browser):", thisInstance.geodaten);

              //thisInstance.fillLastSearchFilters();  // erst jetzt wo die Location klar ist, anfangen zu laden
              thisInstance.dataviewFill = true; // erst jetzt wo die Location klar ist, anfangen zu laden
              thisInstance.refresh(); // erst jetzt wo die Location klar ist, anfangen zu laden
            }, function (error) { return this.handleError(error); });
          });
        }
        else {*/
          if(this.app != null && this.app.benutzer != null && this.app.benutzer.unternehmen != null && this.app.benutzer.unternehmen.geodatenId != null && this.app.benutzer.unternehmen.geodatenId != 0) {
            this.geodatenService.getGeodaten(this.app.benutzer.unternehmen.geodatenId).subscribe(function (response) { // app.benutzer.unternehmen hat nur die geodatenId, nicht die ricklichen geodaten -> nachladen!
              thisInstance.geodaten = response;
              console.log("ArtikelUebersicht.ngOnInit() geodaten (from Unternehmen):", thisInstance.geodaten);

              //thisInstance.fillLastSearchFilters();  // erst jetzt wo die Location klar ist, anfangen zu laden
              thisInstance.dataviewFill = true; // erst jetzt wo die Location klar ist, anfangen zu laden
              thisInstance.refresh(); // erst jetzt wo die Location klar ist, anfangen zu laden
              }, function (error) { return this.handleError(error); });
          }
          else {
            // keine geodaten einstellen
            //this.fillLastSearchFilters();  // erst jetzt wo die Location klar ist, anfangen zu laden
            this.dataviewFill = true; // erst jetzt wo die Location klar ist, anfangen zu laden
            this.refresh(); // erst jetzt wo die Location klar ist, anfangen zu laden
          }
        /*}*/
        }  

      //Filter zurücksetzen
      clearAll() {
        this.artikelGruppeBreadCrumbItems = null;
        this.artikelnummerNg = '';
        this.hersteller = null;
        //this.onlyMyUser = null; // ausgeblendete filter / filter aus queryParms nicht zurücksetzen
        //this.onlyAktive = null; // ausgeblendete filter / filter aus queryParms nicht zurücksetzen
        //this.onlyLagerbestand = null; // ausgeblendete filter / filter aus queryParms nicht zurücksetzen
        //this.onlyFavoriten = null; // ausgeblendete filter / filter aus queryParms nicht zurücksetzen
        //this.suchanfragen = null; // ausgeblendete filter / filter aus queryParms nicht zurücksetzen
        this.auchLagerbestand = null; 
        if(this.onlyMyUser == null || this.onlyMyUser == false) this.unternehmen = null; // unternehmen nur rausnehmen, wenn nicht onlyMyUser!
        this.artikelLieferartenSelectedAsString = '';
        this.lager = null;
        this.clearOrt = '';
        this.geodaten = null;
        this.umkreisKm = 0;
        this.clearPlz = '';
        this.sortOptions = null;
        this.volltextSuche = '';
      }

      public refresh() { // wird auch von appMenu aufgerufen
        console.log("ArtikelUebersicht.refresh() geodaten:", this.geodaten);
        console.log("ArtikelUebersicht.refresh() umkreisKm:", this.umkreisKm);

        console.log("ArtikelUebersicht.refresh() artikelGruppeSelected/lastSearch_artikelgruppeId:", this.artikelGruppeSelected, this.lastSearch_artikelgruppeId);


        this.fillLastSearchFilters();
/*
        this.artikelService.getArtikelCollectionForThisUserAndLocation(this.geodaten != null ? this.geodaten.id : 0, this.umkreisKm, 1, 0, "") 
          .subscribe(
            //response => this.onCRUDItemsRetrieved(response.titel, response.pagination),
            response => {
              this.artikel = response.artikel;
              console.log("ArtikelUebersichtComponent.refresh() itemsRetrieved() response.artikel:", response.artikel);
              //this.totalRecords = pagination.totalCount;
              //this.sizeTable();
              this.dataView.first = 0;
            },
            error => {
                console.log("ArtikelUebersichtComponent.refresh() error: ", error);
            }
          );
*/

        // alles auf 0 - und neu suchen!
        this.artikel = [];
        this.totalRecords = null;
        this.paginatorPosition = "";
        this.paginationText = "";
        let loadFirstPageEvent: LazyLoadEvent = {
          first: 0,
          rows: this.rows,
          sortField: null,
          sortOrder: null,
          multiSortMeta: null,
          filters: null,
          globalFilter: null
        }
        this.loadDataVirtualScroller(loadFirstPageEvent);
        if(this.dataView != null) {
          this.dataView.first = 0;           
        }
        else console.log("ArtikelUebersichtComponent.refresh() warning: this.dataView == null!");
      }
      
      fillLastSearchFilters() {
        // letzte Suchkriterien - zum Zeitpunkt von "Aktualisieren"
        // die müssten gepuffert werden, und beim lazyloading genommen werden - statt der aktuellen Werte!
        // Weil: Blättern soll er ja mit den zuletzt abgerufenen Werten!
        this.lastSearch_geodatenId = this.geodaten != null ? this.geodaten.id : 0;
        this.lastSearch_umkreisKm = this.umkreisKm;
        this.lastSearch_warMitUmkreis = this.geodaten != null && this.umkreisKm > 0;
        this.lastSearch_artikelgruppeId = this.artikelGruppeSelected != null ? this.artikelGruppeSelected.data.id : 0;
        console.log("ArtikelUebersichtComponent.fillLastSearchFilters() artikelGruppeSelected/lastSearch_artikelgruppeId:", this.artikelGruppeSelected, this.lastSearch_artikelgruppeId);
        this.lastSearch_unternehmenId = this.unternehmen != null ? this.unternehmen.id : 0;
        this.lastSearch_onlyMyUser = this.onlyMyUser;
        this.lastSearch_onlyAktive = this.onlyAktive;
        this.lastSearch_onlyLagerbestand = this.onlyLagerbestand;
        this.lastSearch_auchLagerbestand = this.auchLagerbestand;
        //this.lastSearch_suchanfragen = this.suchanfragen;
        this.lastSearch_typ = this.typ;
        this.lastSearch_onlyFavoriten = this.onlyFavoriten;
        this.lastSearch_artikelnummer = this.artikelnummerNg;
        this.lastSearch_herstellerId = this.hersteller != null ? this.hersteller.id : 0;
        this.lastSearch_lagerId = this.lager != null ? this.lager.id : 0;
        // Lieferarten: Das Array hier ist nicht vom Typ Lieferart, sondern vom Typ ArtikelLieferart, daher umformatieren!
        this.lastSearch_lieferarten = [];
        if(this.artikelLieferartenSelected != null) {
          this.artikelLieferartenSelected.forEach(artikelLieferart => {
            this.lastSearch_lieferarten.push(cloneDeep(artikelLieferart.lieferart));
          });
        }
        this.lastSearch_volltextSuche = this.volltextSuche;

        this.lastSearch_sortKey = this.sortKey;
      }
      
      loadDataVirtualScroller(event: LazyLoadEvent) {
        if(this.debugMode==true) console.log("ArtikelUebersicht.loadDataVirtualScroller() event:", event);

        if(this.dataviewFill != true) return; // solange der init (Geolocation voreinstellen) nicht passiert ist -> nicht suchen!

        this.loading = true;
        //  wenn sich mehrere LazyLoads ergeben (schnell scrollen), dass dann der letzte (aktuelle) zuerst ausgeführen!
        //this.pendingLazyLoadEvents.push(event);
        //this.lastLazyLoadEvent_first = event.first;
        let thisInstance = this;
        //thisInstance.loadDataVirtualScroller_handleNextPending(thisInstance); // TODO
        thisInstance.loadDataVirtualScroller_afterTimeout(event, thisInstance);
      }

      loadDataVirtualScroller_afterTimeout(event: LazyLoadEvent, thisInstance: any) {
        if(thisInstance.debugMode==true) console.log("ArtikelUebersicht.loadDataVirtualScroller() event:", event);
    
        thisInstance.currentPage = Math.floor(event.first / thisInstance.rows) + 1;
        if(thisInstance.debugMode==true) console.log("ArtikelUebersicht.loadDataVirtualScroller() currentPage:", thisInstance.currentPage);
        if(thisInstance.debugMode==true) console.log("ArtikelUebersicht.loadDataVirtualScroller() artikelService:", thisInstance.artikelService);
        
        let searchQuery=thisInstance.search;
        let lagerbestandsFlag = (this.lastSearch_onlyLagerbestand == true ? "ONLYLAG" : (this.lastSearch_auchLagerbestand == true || (this.lastSearch_volltextSuche != null && this.lastSearch_volltextSuche.length > 0) ? "" : "NOLAG")); // bei Volltextsuche prinzipiell NOLAG ignorieren!
        //if(thisInstance.debugMode==true) console.log("ArtikelUebersicht.loadDataVirtualScroller() lagerbestandsFlag:", lagerbestandsFlag);
        thisInstance.artikelService.getArtikelCollectionFiltered(this.lastSearch_typ, this.lastSearch_onlyMyUser, this.lastSearch_geodatenId, this.lastSearch_umkreisKm, 
          this.lastSearch_artikelgruppeId, this.lastSearch_unternehmenId, this.lastSearch_artikelnummer, this.lastSearch_lieferarten, this.lastSearch_herstellerId, this.lastSearch_lagerId, this.lastSearch_onlyFavoriten == true ? "ONLYFAV" : null /*favoritenFlag*/,
          this.lastSearch_onlyAktive, lagerbestandsFlag,
          thisInstance.currentPage, thisInstance.rows, thisInstance.lastSearch_volltextSuche, thisInstance.lastSearch_sortKey)
        .subscribe(
          response => {
            if(thisInstance.debugMode==true) console.log("ArtikelUebersicht.loadDataVirtualScroller() response:", response);
            if(thisInstance.debugMode==true) console.log("ArtikelUebersicht.loadDataVirtualScroller() searchQuery:", searchQuery);
  
            // wenn das searchQuery aus dem get...() nicht zu dem aktuellen search passt, dann ist das ein response, der viel zu spät ankommt!
            // in dem Fall ignorieren!
            // man könnte das alternativ machen, indem man wieder unsubscribed: https://stackoverflow.com/questions/52096111/how-to-cancel-http-request-in-angular-6
            //if(searchQuery != this.search) {
            //  if(thisInstance.debugMode==true) console.log("ArtikelUebersicht.loadDataVirtualScroller() searchQuery passt nicht zu aktuellem search. (vermutlich aus vorherigem search). Ignoriere!");
            //}
            //else {
              if(thisInstance.totalRecords == null) {
                thisInstance.totalRecords = response.pagination.totalCount;
                thisInstance.paginationText = thisInstance.totalRecords + " Artikel gefunden.";
              }
              if(thisInstance.totalRecords <= 12){
                this.paginatorPosition = 'top';
              }else{
                this.paginatorPosition = 'both';
              }

              // hier nicht die Logik wie bei Virtualscroller (ein riesengrosses Array - teilweise befüllt ... (*))
              /*
              if(thisInstance.artikel == null || thisInstance.artikel.length == 0) thisInstance.artikel = Array.from({ length: thisInstance.totalRecords });
              let arraySizeBeforeSplice = thisInstance.artikel.length; // Sicherheitsprüfung, s.u.
              //populate page of virtual cars
              Array.prototype.splice.apply(thisInstance.artikel, [
                ...[event.first, event.rows],
                ...response.artikel
              ]);
              if(arraySizeBeforeSplice != 0 && thisInstance.artikel.length != arraySizeBeforeSplice) {
                console.error("ArtikelUebersicht.loadDataVirtualScroller() Warning(Error?): Array-Grösse hat sich durch das nachladen verändert!");
                debugger;
              }
            
              //trigger change detection
              setTimeout(function(){ 
                thisInstance.artikel = [...thisInstance.artikel];
                if(thisInstance.debugMode==true) console.log("ArtikelUebersicht.loadDataVirtualScroller() artikel:", thisInstance.artikel);
              }, 200);
              */
              
              // (*) ... sondern die Ligik für Dataview: das Array enthält immer genau die aktuell dargestellten Items:
              setTimeout(function() { 
                //if(thisInstance.artikel == null || thisInstance.artikel.length == 0) thisInstance.artikel = [];
                //thisInstance.artikel.push(...response.artikel);
                thisInstance.artikel = response.artikel;

                thisInstance.updateFavoriten(thisInstance);

                thisInstance.loading = false;
                if(thisInstance.debugMode==true) console.log("ArtikelUebersicht.loadDataVirtualScroller() artikel:", thisInstance.artikel);
              }, 200);
          
            //}
          },
          error => thisInstance.handleError(error)
        );
      }

      onPage(event: any) {
        /*if(this.debugMode==true)*/ console.log("ArtikelUebersicht.onPage() event:", event);
      }

      public openFilter() { // wird aus AppMenu aufgerufen.
        this.filterOpen = true;
      }

      ngOnDestroy() {
        this.app.setArtikelUebersicht(null);
      }
      onImgError(event) {
        event.target.src ='assets/layout/images/imageNotFound.png';
      }
      //Favorit



      favBtn(artikel: IArtikel) {
        console.log("DashboardComponent.favBtn() artikel: ", artikel);
        //artikel.favorit = !artikel.favorit;
        if (this.app.benutzer == null) {
          alert("Bitte zuerst anmelden!");
        }
        else {
          // toDo: Disable Icon!
          if (artikel['_meinFavorit'] == null) artikel['_meinFavorit'] = false;
          artikel['_meinFavorit'] = !artikel['_meinFavorit'];
          if (artikel['_meinFavorit'] == true) {
            let neuerFavorit = this.favoritService.initializeFavorit();
            neuerFavorit.artikelId = artikel.id;
            neuerFavorit.benutzerId = this.app.benutzer.id;
            this.favoritService.createFavorit(neuerFavorit).subscribe(function (response) {
              console.log("DashboardComponent.favBtn() ... artikel/response:", artikel, response);
              artikel['_meinFavoritId'] = response.id;
              // toDo: Enable Icon!
            }, function (error) {
              console.log("Giuseppe Balsamo HALLO");
              console.log("DashboardComponent.favBtn() error:", error);
              //return thisInstance.handleError(error); 
            });
          }
          else {
            let favoritId = artikel['_meinFavoritId'];
            this.favoritService.deleteFavorit(favoritId).subscribe(
              () => {
                delete artikel['_meinFavorit'];
                delete artikel['_meinFavoritId'];
                // toDo: Enable Icon!
              },
              (error: any) => {
                console.log("DashboardComponent.favBtn() error:", error);
              }
            );
          }
        }
      }

      refreshRow(id) {
        console.log("refresh() id=" + id);
        if (id != 0) {
          let idStillExists: boolean = false; // erkennen, ob es die konfig nicht mehr gibt (gelöscht)
          let foundInExistingItems: boolean = false; // erkennen, ob es die id überhaupt schon gab, oder ob die neu ist
    
          for(let i: number = 0; i<this.artikel.length; i++) {
            let crudItem = this.artikel[i];
            if (crudItem.id == id) {
              foundInExistingItems = true;
              //console.log("refresh() refreshing - before:", crudItem);
    
              this.artikelService.getArtikel(id).subscribe(response => {
                //console.log("refresh() response=", response);
                idStillExists = true;
                //console.log("refresh() idStillExists!");
    
                // alle felder aus response nach konfiguration mappen:
                //let saveSelected = crudItem._selected;
                for (let i in response) {
                  if (crudItem.hasOwnProperty(i)) {
                    crudItem[i] = response[i];
                  }
                }

              }, error => {
                console.log('Error getting (refresh()).');

                // die konfig gibt es nicht mehr ? (gelöscht)
                // die konfig aus dem array löschen
                this.artikel = this.artikel.filter((val, i) => val.id != id)
                //console.log("refresh() deleted ", crudItem);
                //console.log("refresh() after delete", this.crudItems);
    
              });
            }
          }//);
          // ... oder ist das item neu ?
          if (!foundInExistingItems) { // item ist neu!
            this.artikelService.getArtikel(id).subscribe(response => {
              //console.log("refresh() response=", response);
              let crudItem: /*ITitel*/any = response;
              //this.afterRefreshRow(crudItem); // evtl. Entity-individuelle Nachbearbeitung (muss dann in der entspr. Klasse überschreiben werden)
              this.artikel.push(crudItem);
            }, error => {
              console.log('Error getting (refresh()).');
            });
          }
        }
        else {
          console.log("refresh() skipped, since id=0");
        }
      }

  updateFavoriten(thisInstance: ArtikelUebersichtComponent) {
    if(thisInstance._app.benutzer != 0) {
      thisInstance.artikel.forEach(item => {
        try {
          thisInstance.favoritService.getFavoritForArtikelAndBenutzer(item.id, thisInstance._app.benutzer.id ).subscribe(function (response) {
            console.log("ArtikelUebersicht.updateFavoriten() ... response:", response);
            item['_meinFavorit'] = true;
            item['_meinFavoritId'] = response.id;
          }, function (error) {
            console.log("ArtikelUebersicht.updateFavoriten() error:", error);
            //return thisInstance.handleError(error); 
          });  
        }
        catch(e) {
          console.log("ArtikelUebersicht getFavoritForArtikelAndBenutzer() exception:", e);
        }
      });
    }
  }

  breadBrumbClicked(event:any) {
    console.log("ArtikelUebersicht.breadBrumbClicked() event:", event);

    this.artikelGruppeSelected = this.artikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds(this.artikelGruppeTreeNodes, event.item.id);
    this.artikelGruppeBuildBreadCrumb();
  }
 
}

