<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
        <div #divCardToolbar class="card">
          <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
          [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
          [class.card-toolbar-slim-active]="app.isSlim()"-->
          <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <span>
            <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
            <button style="width: auto;" pButton type="submit" [icon]="CRUDButtonSaveIcon" [label]="translate.instant(CRUDButtonSaveLabel,true)"></button>
          </span>
          <span class="floatRight">
            <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
            <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
              (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
          </span>
        </div>

        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card">
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
      </form>
    </div>
  </div>
</div>
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>

<!-- ^ hierhin das gesamte CRUDBasicDetail kopieren ! ^ -->
<ng-template #CRUDChildTemplate>
            <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <crud-basic-autocomplete #benutzer formControlName="benutzer" [type]="'benutzer'" 
                [required]="false"></crud-basic-autocomplete>
                <label>{{'Benutzer'|translate:true}}</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.benutzer}}
              </div>
            </div>-->

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input style="width:90%" type="text" pInputText formControlName="vorname">
                  <label>{{'Vorname'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.vorname}}
                </div>
              </div>
  
              <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                  <span class="md-inputfield">
                    <input style="width:90%" type="text" pInputText formControlName="nachname">
                    <label>{{'Nachname'|translate:true}} *</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-6">
                  <div class="errorMessage">
                    {{displayMessage.nachname}}
                  </div>
                </div>

                <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                    <span class="md-inputfield">
                      <input style="width:90%" type="text" pInputText formControlName="telefon">
                      <label>{{'Telefon'|translate:true}}</label>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-6">
                    <div class="errorMessage">
                      {{displayMessage.telefon}}
                    </div>
                  </div>

                  <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                      <span class="md-inputfield">
                        <input style="width:90%" type="text" pInputText formControlName="mobil">
                        <label>{{'Mobil'|translate:true}}</label>
                      </span>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      <div class="errorMessage">
                        {{displayMessage.mobil}}
                      </div>
                    </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="email">
                <label>{{'E-Mail'|translate:true}}</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.email}}
              </div>
            </div>

            <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <crud-basic-autocomplete #unternehmen formControlName="unternehmen" [type]="'unternehmen'" 
                [required]="false"></crud-basic-autocomplete>
                <label>{{'Unternehmen'|translate:true}}</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.unternehmen}}
              </div>
            </div>-->

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" style="margin-top: 10px; text-align: right;">
              <div style="width: 90%;">
                <span id="emailAdresseAnzeigen">Ansprechpartner ist auch Benutzer von Plattgruen</span>
                <p-inputSwitch formControlName="_ansprechpartner_ist_auch_benutzer" 
                [readonly]="CRUDForm.value.nachname == null || CRUDForm.value.nachname.length == 0"
                (onChange)="ansprechpartner_als_benutzer_changed()"></p-inputSwitch><!--style="margin-left: 10px;"-->
              </div>
          </div>
          <div class="ui-g-12 ui-md-6">
            <div class="errorMessage">
            </div>
          </div>

          <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" [ngStyle]="{'display':CRUDForm.value._ansprechpartner_ist_auch_benutzer == null || CRUDForm.value._ansprechpartner_ist_auch_benutzer == false ? 'none' : 'unset'}">
            <div style="width: 90%;">
            <span class="md-inputfield">
              <crud-basic-autocomplete *ngIf="benutzerShowAutoComplete == true" #benutzer [options]="benutzerForAutoComplete" formControlName="benutzer" [type]="'benutzer'" 
              [required]="false" ></crud-basic-autocomplete><!--[options]="parentCrudItem.benutzer"-->
              <label>{{'Benutzer'|translate:true}}</label>
            </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-6">
            <!--<div class="errorMessage" *ngIf="CRUDForm.errors && CRUDForm.errors.Benutzer_ist_bereits_bei_anderem_Ansprechpartner_hinterlegt">
              {{'Benutzer_ist_bereits_bei_anderem_Ansprechpartner_hinterlegt'|translate:true}}
            </div>-->
            <div class="errorMessage">
              {{displayMessageForm.Benutzer_ist_bereits_bei_anderem_Ansprechpartner_hinterlegt}}
            </div>
          </div>




</ng-template>
    
