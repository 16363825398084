import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { NavigationEnd } from '@angular/router';

import { Message } from 'primeng/primeng';
import { MessageService} from 'primeng/api';
import { MessageWrapperService } from './_services/message-wrapper.service';
import { IBenutzer } from './_interfaces/benutzer';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { AppMenuComponent } from './app.menu.component';
import { ArtikelUebersichtComponent } from './artikel-uebersicht/artikel-uebersicht.component';
import { DeviceDetectorService } from 'ngx-device-detector';

//import { MessageService } from './_services/message.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    //providers: [MessageService] // darf nicht doppelt enthalten sein (in app.module und nochmal hier!)
})
export class AppComponent implements OnInit {
    // Zeiger auf Components, die untereinander kommunizieren
    private appMenu: AppMenuComponent;
    private artikelUebersichtComponent: ArtikelUebersichtComponent;

    private pageTitle = "";
    benutzer: IBenutzer = null;

    public inAppMode = false;
    public isMobileOrTabletBrowser = false;
    //private deviceInfo = null;

    //msgsStatic: Message[] = [];
    //msgsTimed: Message[] = [];

    constructor(
        //private globalService: GlobalService,
        private messageService: MessageService,
        private messageWrapperService: MessageWrapperService,
        private authService: AuthService,
        private router: Router,
        private deviceService: DeviceDetectorService
      ) {
        //console.log("AppComponent.constructor() initializing messageWrapperService/_app=this:", messageWrapperService, this);
        messageWrapperService._app = this;
        authService._app = this;

        console.log("App.constructor: userAgent: ", window.navigator.userAgent);
        let deviceInfo = this.deviceService.getDeviceInfo();
        let isMobile = this.deviceService.isMobile();
        let isTablet = this.deviceService.isTablet();
        let isDesktopDevice = this.deviceService.isDesktop();
        //console.log("app.constructor() deviceInfo:", this.deviceInfo);
        //console.log("app.constructor() isMobile:", isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        //console.log("app.constructor() isTablet:", isTablet);  // returns if the device us a tablet (iPad etc)
        //console.log("app.constructor() isDesktopDevice:", isDesktopDevice); // returns if the app is running on a Desktop browser.
  
        if(window.navigator.userAgent.indexOf('PlattGruenApp_IOS') >= 0 || window.navigator.userAgent.indexOf('PlattGruenApp_Android') >= 0) {
          console.log("AppMain: frameType=N since of userAgent! -> no titleBar etc...");
          this.inAppMode = true;
        }
        else {
          if(isMobile==true || isTablet==true) {
            this.isMobileOrTabletBrowser = true;
          }
        }
        if(this.isMobileOrTabletBrowser == false) this.restoreLoginDataAtStartup();
    }
    
    messageServiceAdd(message: Message) {
      this.messageService.add(message);      
    }

    setPageTitle(title) {
      this.pageTitle = title;
      let eventData = {
        eventType: "title",
        data: title
      };
      this.invokeXamarinEvent(eventData);
    }
    getPageTitle() {
      return this.pageTitle;
    }

    invokeXamarinEvent(data:any){
      let dataAsString = JSON.stringify(data);
      console.log('App.invokeXamarinEvent() trying to send: ', dataAsString);
      if (window['invokeCSharpAction'])
        window['invokeCSharpAction'](dataAsString);
      else {
        console.log('App.invokeXamarinEvent() invokeCSharpAction not found');
      }
    }

    ngOnInit() {  
      if(this.isMobileOrTabletBrowser) {
        this.router.navigate(['/getmobileapp']);
      }

      if(true/* || this.inAppMode == true*/) {
        // auf reuse (RouteReuseStrategy) reagieren: https://stackoverflow.com/questions/50392691/angular-5-how-to-reload-current-data-when-i-use-routereusestrategy
        this.router.onSameUrlNavigation = 'reload';
        this.router.events.subscribe(event => {
          if ((event instanceof NavigationEnd)) {
            let navigationEnd: NavigationEnd = <NavigationEnd>event;
            if (navigationEnd.url != null) {
              console.log("App event NavigationEnd.url:", navigationEnd.url);
    
              let url: string = navigationEnd.url;
              let eventData = {
                eventType: "url",
                data: url
              };
              this.invokeXamarinEvent(eventData);
            }
          }
        });
      }

        /*
        this.messageService.messageUpdateStatic.subscribe(
          (message: Message) => {
            if (message === null) {
              this.msgsStatic = [];
            }
            else {
              this.msgsStatic.push(message);
            }
          }
        );
    
        this.messageService.messageUpdateTimed.subscribe(
          (message: Message) => {
            let oldMessages = this.msgsTimed;
            this.msgsTimed = [];
            this.msgsTimed.push(...oldMessages);
            this.msgsTimed.push(message);
          }
        );
        */

      }

      ngAfterViewInit() {
        //if(window.navigator.userAgent != 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.72 Safari/537.36') {
        //  this.messageWrapperService.postStaticMessage({ severity: 'info', summary: 'Your UserAgent (for debugging)', detail: window.navigator.userAgent });
        //}
      }

      public restoreLoginDataAtStartup() {
        let thisInstance = this;
        this.authService.checkAuthTestController().subscribe(function (response) {
          console.log("App.restoreLoginData() response.authTest:", response.authTest['authTest']);
          let authTestResult = response.authTest['authTest'];
          if(authTestResult == "success") {
            thisInstance.benutzer = thisInstance.authService.getBenutzer();
          }
          else {
            //thisInstance.benutzer = null; // macht bereits der TokenInterceptor bei failed RefreshToken()
            //thisInstance.authService.removeTokens(); // macht bereits der TokenInterceptor bei failed RefreshToken()
            console.log("App.restoreLoginData() failed -> route to login (!=success)");
            thisInstance.router.navigate(['/login']);
          }
        }, function (error) { 
          console.log("App.restoreLoginData() failed -> route to login (error)");

          let beginOfRelativePath = window.location.href.indexOf("#");
          let returnUrl = window.location.href.substring(beginOfRelativePath + ("#/").length);
          console.log("App.restoreLoginDataAtStartup() function error: route to /login wirh returnUrl:", returnUrl);

          // warum auch immer kommt er bei Einstieg über   http://localhost:4600/#/artikel-ansicht/2   2mal hier rein! (Test aus Inaktiv-Mail in Edge, wo noch nicht angemeldet), daher
          // wenn bereits ein "login?returnUrl=" enthalten, das rausscheiden
          if(returnUrl.indexOf("returnUrl=login%3FreturnUrl%3D")) {
            console.log("App.restoreLoginDataAtStartup cutting/replacing returnUrl from: ", returnUrl);
            returnUrl = returnUrl.replace("login?returnUrl=", "");
            returnUrl = returnUrl.replace("%2F", "/");
            console.log("App.restoreLoginDataAtStartup cutted/replaced returnUrl to   : ", returnUrl);
          }
debugger;
          thisInstance.router.navigate(['/login'], {queryParams: { returnUrl: returnUrl }} );
          if(error.status != 401 && error.status != 403) {
            return thisInstance.handleError(error); 
          }
          else {
            //
          }
        });
      }
   
    public getAppMenu() {
      console.log("App.getAppMenu()");  
      return this.appMenu;
    }
    public setAppMenu(newComp: AppMenuComponent) {
      console.log("App.setAppMenu()"); 
      this.appMenu = newComp;
    }
    public getArtikelUebersicht() {
      console.log("App.getArtikelUebersicht()");  
      return this.artikelUebersichtComponent;
    }
    public setArtikelUebersicht(newComp: ArtikelUebersichtComponent) {
      console.log("App.setArtikelUebersicht()");  
      this.artikelUebersichtComponent = newComp;
    }

  /*private*/protected handleError(error) {
    console.log("App.handleError error: ", error);
    let errorMessage: string = null;
    if(error.status == 401 || error.status == 403) errorMessage = "Nicht authorisiert." + (error.error != null ? " " + error.error : "");
    else {
      if(error.message != null) errorMessage = error.message;
      //else if(error.error != null && error.error typeof String) errorMessage = error.error;
      else errorMessage = "unbekannter Fehler";
    }
    this.messageWrapperService.postStaticMessage({severity: 'error', summary: 'App: Fehler: ', detail: errorMessage}); 
  }

  public isAdmin() {
    if(this.benutzer != null && this.benutzer.unternehmen.id == 1) return true;
    else return false;
  }
      
}
