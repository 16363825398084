<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
        <!-- button bar: bei Plattgruen UNTEN !-->
        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card">
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
        <div #divCardToolbar class="card">
            <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
            [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
            [class.card-toolbar-slim-active]="app.isSlim()"-->
            <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
            <span>
              <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
              <button style="width: auto;" pButton type="submit" [icon]="CRUDButtonSaveIcon" [label]="translate.instant(CRUDButtonSaveLabel,true)"></button>
            </span>
            <span class="floatRight">
              <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
              <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
                (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
            </span>
          </div>
  
      </form>
    </div>
  </div>
</div>
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>

<!-- ^ hierhin das gesamte CRUDBasicDetail kopieren ! ^ -->
<ng-template #CRUDChildTemplate>
            <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="anzahl_Benutzer">
                <label>{{'Anzahl  Benutzer'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.anzahl_Benutzer}}
              </div>
            </div>-->

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="bezeichnung">
                <label>{{'Firmenname'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.bezeichnung}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input style="width:90%" type="text" pInputText formControlName="rechtsform">
                  <label>{{'Rechtsform'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.rechtsform}}
                </div>
              </div>
  
              <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input style="width:90%" type="text" pInputText formControlName="steuernummer">
                  <!--<app-select-unternehmen (unternehmenSelected)="unternehmenDubletteSelected($event)"></app-select-unternehmen>-->
                  <label>{{'Steuernummer'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.steuernummer}}
                </div>
              </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input style="width:90%" type="text" pInputText formControlName="strasse">
                  <label>{{'Strasse'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.strasse}}
                </div>
              </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <crud-basic-autocomplete #geodaten formControlName="geodaten" [type]="'geodaten_postleitzahl'" 
                  [required]="true"></crud-basic-autocomplete>
                  <label>{{'Postleitzahl Ort'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.geodaten}}
                </div>
              </div>

              <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                  <span class="md-inputfield">
                    <input style="width:90%" type="text" pInputText formControlName="telefon">
                    <label>{{'Telefon'|translate:true}}</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-6">
                  <div class="errorMessage">
                    {{displayMessage.telefon}}
                  </div>
                </div>
    
                <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                  <span class="md-inputfield">
                    <input style="width:90%" type="text" pInputText formControlName="website">
                    <label>{{'Website'|translate:true}}</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-6">
                  <div class="errorMessage">
                    {{displayMessage.website}}
                  </div>
                </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="email">
                <label>{{'E-Mail'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.email}}
              </div>
            </div>

          <div style="display: none">
            <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <p-calendar dateFormat="dd.mm.yy" [showTime]="true" [stepMinute]="01" [showSeconds]="true" [defaultDate]="dateNow" formControlName="gueltig_bis"></p-calendar>
                <label>{{'Gültig bis'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.gueltig_bis}}
              </div>
            </div>-->

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <crud-basic-autocomplete [disabled]=true #lizenzart formControlName="lizenzart" [type]="'lizenzart'" 
                [required]="false"></crud-basic-autocomplete>
                <label>{{'Lizenzart'|translate:true}}</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.lizenzart}}
              </div>
            </div>

            <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input disabled style="width:90%" type="text" pInputText formControlName="postleitzahl">
                  <label>{{'Postleitzahl'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.postleitzahl}}
                </div>
              </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input disabled style="width:90%" type="text" pInputText formControlName="ort">
                <label>{{'Ort'|translate:true}}</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.ort}}
              </div>
            </div>-->

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input disabled style="width:90%" type="text" pInputText formControlName="datenherkunft">
                  <label>{{'Datenherkunft'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.datenherkunft}}
                </div>
              </div>
  
              <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input disabled ="width:90%" type="text" pInputText formControlName="accuracy">
                  <label>{{'accuracy'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.accuracy}}
                </div>
              </div>
  
              <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input disabled style="width:90%" type="text" pInputText formControlName="latitude">
                  <label>{{'latitude'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.latitude}}
                </div>
              </div>
  
              <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input disabled style="width:90%" type="text" pInputText formControlName="longitude">
                  <label>{{'longitude'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.longitude}}
                </div>
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <label>{{'Gewerbeschein'|translate:true}}</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
              </div>
            </div>
            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <!--<label>{{'Gewerbeschein'|translate:true}}</label>-->
                <div class="gewerbescheinDiv">
                  <app-unternehmen-datei-upload #_datei_Gewerbeschein formControlName="_datei_Gewerbeschein"
                  [unternehmenId]="dataId"
                  [labelNoImage]="translate.instant('Es ist kein Gewerbeschein hinterlegt',true)"></app-unternehmen-datei-upload>
                </div>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <button style="width: 90%;" pButton type="button" icon="fa fa-upload"
                  (click)="_datei_Gewerbeschein.upload()"
                  [label]="CRUDForm.value._datei_Gewerbeschein!=null ? translate.instant('Gewerbeschein ersetzen',true) : translate.instant('Gewerbeschein hinterlegen',true)"></button>
                  <!--                [label]="CRUDForm.value._datei_Gewerbeschein!=null && ''+CRUDForm.value._datei_Gewerbeschein.length > 0 ? translate.instant('Gewerbeschein ersetzen',true) : translate.instant('Gewerbeschein hinterlegen',true)"-->
              <div class="errorMessage">{{displayMessageForm.Bitte_Gewerbeschein_hinterlegen}}</div>
            </div>

            
            <p-accordion [multiple]="true">
                <p-accordionTab *ngIf="eigenerBenutzerShow==true" header="Mein Benutzer" [selected]="true">
                    <div class="p-grid">

                        <div class="p-col-12"></div>

                        <div [formGroup]="CRUDForm" class="p-col-6 inputTextForm">
                            <span class="md-inputfield">
                              <input style="width:90%" type="text" pInputText formControlName="_benutzer_username"
                              [readonly]="dataId != 0 || urlParmUserName != null"><!--US 23997 [formGroup]="CRUDForm" auf DIV-Ebene, nicht beim <input>, sonst bekommt das Feld immer class ng-invalid, auch wenn es valid ist !!!-->
                              <label>{{'Benutzername'|translate:true}} *</label>
                            </span>
                          </div>
                          <div class="p-col-6">
                            <div class="errorMessage">
                              {{displayMessage._benutzer_username}}<!-- funktioniert bei async validator so nicht, weil displayMessage vermutlich erst nach verlassen des Feldes aktualisiert wird, daher:-->
                              {{CRUDForm != null && (CRUDForm.controls._benutzer_username.dirty || CRUDForm.controls._benutzer_username.touched) && CRUDForm.controls._benutzer_username.errors != null && CRUDForm.controls._benutzer_username.errors.Benutzername_exists != null && displayMessage._benutzer_username == "" ? translate.instant('Benutzername_exists',true) : null}}

                              {{displayMessageForm.Benutzername_ist_erforderlich}}
                              {{displayMessageForm.Benutzername_enthaelt_ungueltige_Zeichen}}
                              {{displayMessageForm.Benutzername_enthaelt_ungueltige_Zeichen != null ? ": "+benutzerService.validateUserName(CRUDForm.value._benutzer_username) : null}}
                              <!--// async validator-->
                              <!--<div *ngIf="!CRUDForm.controls._benutzer_username.valid && (CRUDForm.controls._benutzer_username.dirty || CRUDForm.controls._benutzer_username.touched)">
                                  <div *ngIf="CRUDForm.controls._benutzer_username.errors.Benutzername_exists">
                                    {{'Benutzername_exists'|translate:true}}
                                  </div>
                                </div>-->
                            </div>
                          </div>
                          <div class="p-col-12"></div>
        
                    <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                        <span class="md-inputfield">
                          <input style="width:90%" type="text" pInputText formControlName="_benutzer_vorname"><!--US 23997 [formGroup]="CRUDForm" auf DIV-Ebene, nicht beim <input>, sonst bekommt das Feld immer class ng-invalid, auch wenn es valid ist !!!-->
                          <label>{{'Vorname'|translate:true}} *</label>
                        </span>
                      </div>
                      <div class="ui-g-12 ui-md-6">
                        <div class="errorMessage">
                          {{displayMessage._benutzer_vorname}}
                        </div>
                      </div>
                      <div class="p-col-12"></div>
        
                    <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                      <span class="md-inputfield">
                        <input style="width:90%" type="text" pInputText formControlName="_benutzer_nachname"><!--US 23997 [formGroup]="CRUDForm" auf DIV-Ebene, nicht beim <input>, sonst bekommt das Feld immer class ng-invalid, auch wenn es valid ist !!!-->
                        <label>{{'Nachname'|translate:true}} *</label>
                      </span>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      <div class="errorMessage">
                        {{displayMessage._benutzer_nachname}}
                      </div>
                    </div>
                    <div class="p-col-12"></div>

                    <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                      <span class="md-inputfield">
                        <input style="width:90%" type="text" pInputText formControlName="_benutzer_telefon">
                        <label>{{'Telefon'|translate:true}}</label>
                      </span>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      <div class="errorMessage">
                        {{displayMessage._benutzer_telefon}}
                      </div>
                    </div>

                    <div class="p-col-12"></div>
        
                    <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                        <span class="md-inputfield">
                          <input style="width:90%" type="text" pInputText formControlName="_benutzer_mobil">
                          <label>{{'Mobil'|translate:true}}</label>
                        </span>
                      </div>
                      <div class="ui-g-12 ui-md-6">
                        <div class="errorMessage">
                          {{displayMessage._benutzer_mobil}}
                        </div>
                      </div>

                      <div class="p-col-12"></div>
        
                    <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                        <span class="md-inputfield">
                            <input style="width:90%" type="text" pInputText formControlName="_benutzer_email"><!--US 23997 [formGroup]="CRUDForm" auf DIV-Ebene, nicht beim <input>, sonst bekommt das Feld immer class ng-invalid, auch wenn es valid ist !!!-->
                            <label>{{'E-Mail'|translate:true}} *</label>
                        </span>
                      </div>
                      <div class="ui-g-12 ui-md-6">
                        <div class="errorMessage">
                          {{displayMessage._benutzer_email}}<!-- funktioniert bei async validator so nicht, weil displayMessage vermutlich erst nach verlassen des Feldes aktualisiert wird, daher:-->
                          {{CRUDForm != null && (CRUDForm.controls._benutzer_email.dirty || CRUDForm.controls._benutzer_email.touched) && CRUDForm.controls._benutzer_email.errors != null && CRUDForm.controls._benutzer_email.errors.eMail_exists != null && displayMessage._benutzer_email == "" ? translate.instant('eMail_exists',true) : null}}
                        </div>
                      </div>
                      <div class="p-col-12"></div>

                      <!--<button style="width: auto; margin-left: 5px;" pButton type="button" icon="fas fa-user-check" [label]="translate.instant('als Ansprechpartner übernehmen',false)"
                        (click)="benutzer_als_ansprechpartner()"></button>--><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->

                        <!--<div [formGroup]="CRUDForm" style="margin-top: 10px; margin-left: 8px;">
                            <span id="emailAdresseAnzeigen">Benutzer ist auch Ansprechpartner für die Artikel</span>
                            <p-inputSwitch formControlName="_benutzer_ist_auch_ansprechpartner" 
                            [readonly]="CRUDForm.value._benutzer_nachname == null || CRUDForm.value._benutzer_nachname.length == 0"
                            (onChange)="benutzer_als_ansprechpartner_changed()"></p-inputSwitch>
                        </div>-->

                        <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                          <span class="md-inputfield">
                            <span id="">Benutzer mit Login-Berechtigung&nbsp;</span>
                            <p-inputSwitch formControlName="_benutzer_istBenutzer" 
                            [readonly]="true"></p-inputSwitch><!--eigener Benutzer muss ja immer Login-Berechtigung haben!-->
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-6">
                          <div class="errorMessage">
                            {{displayMessage._benutzer_istBenutzer}}
                          </div>
                        </div>
          
                        <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                          <span class="md-inputfield">
                            <span id="">Ansprechpartner für die Artikel&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <p-inputSwitch formControlName="_benutzer_istAnsprechpartner" 
                            ></p-inputSwitch><!--style="margin-left: 10px; (onChange)="istBenutzer_changed()""-->
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-6">
                          <div class="errorMessage">
                            {{displayMessage._benutzer_istAnsprechpartner}}
                          </div>
                        </div>

                        
                        <div [formGroup]="CRUDForm" *ngIf="eigenerBenutzer != null && eigenerBenutzer.id >= 0" class="ui-g-12 ui-md-6 inputTextForm">
                            <span class="md-inputfield">
                              <button pButton type="button" icon="ui-icon-lock" class="ui-button" style="width: 33%;" [label]="translate.instant('Passwort ändern',true)"
                                (click)="eigenerBenutzerPasswortAendern()"></button>
                            </span>
                          </div>
                          <div *ngIf="eigenerBenutzer != null && eigenerBenutzer.id >= 0" class="ui-g-12 ui-md-6">
                            <div class="errorMessage">
                            </div>
                          </div>

                        <div [formGroup]="CRUDForm" style="margin-top: 15px;" class="ui-g-12 ui-md-6 inputTextForm" [style.display]="dataId == 0 ? 'unset' : 'none'">
                          <span class="md-inputfield">
                              <input formControlName="_benutzer_password" autocomplete="new-password" [type]="passwordVisible ? 'text' : 'password'" class="inputFields"
                              promptLabel="Bitte tragen Sie ein Passwort ein" weakLabel="schwach" mediumLabel="mittel"
                              strongLabel="stark" pPassword style="width: 92% !important" />
                            <!-- width 92%, damit noch das auge dahinterpasst -->&nbsp;<i class="fa fa-fw"
                              [ngClass]="{'fa-eye-slash': passwordVisible, 'fa-eye': !passwordVisible}" (click)="passwordVisible = !passwordVisible"
                              title="{{ passwordVisible ? translate.instant('verstecken',true) : translate.instant('anzeigen',true)}}"
                              style="cursor: pointer;"></i>
                              <label>{{'Passwort'|translate:true}} *</label>
                          </span>
                        </div>
                        <div class="ui-g-12 ui-md-6" [style.display]="dataId == 0 ? 'unset' : 'none'">
                          <div class="errorMessage">
                            {{displayMessage._benutzer_password}}
                            {{displayMessageForm.Passwort_ist_erforderlich}}
                          </div>
                        </div>
        
                        <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-12 inputTextForm" [style.display]="dataId == 0 ? 'unset' : 'none'">
                          <br>
                        </div> weil sonst kein Platz für die weaklabel -->

                        <div [formGroup]="CRUDForm" style="margin-top: 10px;" class="ui-g-12 ui-md-6 inputTextForm" [style.display]="dataId == 0 ? 'unset' : 'none'">
                            <span class="md-inputfield">
                                <input formControlName="_benutzer_passwordBestaetigen" [type]="passwordVisible ? 'text' : 'password'"
                                autocomplete="new-password" [feedback]="false" class="inputFields"
                                pPassword style="width: 92% !important" />
                                <label>{{'Passwort best&auml;tigen'|translate:true}} *</label>
                            </span>
                          </div>
                          <div class="ui-g-12 ui-md-6" [style.display]="dataId == 0 ? 'unset' : 'none'">
                            <div class="errorMessage">
                              {{displayMessage._benutzer_passwordBestaetigen}}
                              {{displayMessageForm.Die_Passwoerter_stimmen_nicht_ueberein}}
                            </div>
                          </div>

                    </div>
        
                </p-accordionTab>
                <p-accordionTab [header]="eigenerBenutzerShow==true? 'Weitere Benutzer / Ansprechpartner' : 'Benutzer / Ansprechpartner'" [selected]="dataId != 0">
                    <app-benutzer-list *ngIf="CRUDItem != null" [parentCrudItem]="CRUDItem" [parentComponent]="this"
                    [crudItems]="CRUDItem.benutzer"
                    (crudItemsValueChange)="onCrudItemsValueChangeBenutzer($event)"></app-benutzer-list>
    
                </p-accordionTab>
                <!--<p-accordionTab header="Ansprechpartner" [selected]="true">
                    <app-ansprechpartner-list *ngIf="CRUDItem != null" [parentCrudItem]="CRUDItem" [parentCrudForm]="CRUDForm" [parentComponent]="this"
                    [crudItems]="CRUDItem.ansprechpartner"
                    (crudItemsValueChange)="onCrudItemsValueChangeAnsprechpartner($event)"></app-ansprechpartner-list>
   
                </p-accordionTab>-->

                <p-accordionTab header="Lagerort" [selected]="true">
                    <app-lager-list *ngIf="CRUDItem != null" [parentCrudItem]="CRUDItem" 
                    [crudItems]="CRUDItem.lager"
                    (crudItemsValueChange)="onCrudItemsValueChangeLager($event)"></app-lager-list>
                </p-accordionTab>
                <div class="ui-g-12 ui-md-12">
                  <div class="errorMessage">
                    {{displayMessageForm.Es_muss_mindestens_ein_Lager_vorhanden_sein}}
                  </div>
                </div>

            </p-accordion>

            <crud-basic-select *ngIf="dublettenShowDialog==true" [visible]="dublettenShowDialogVisible==true" [options]="dubletten" 
            [search]="''" [title]="'Unternehmen mit dieser Steuernummer bereits vorhanden!<br>Wählen Sie das Unternehmen, um Ihren Benutzer dort hinzuzufügen.'" [label]="'bezeichnung'" [label2]="'ort'" 
            (onSelect)="dubletteSelected($event)" (onClose)="dubletteCancel($event)"></crud-basic-select>
            <!--*ngIf="dublettenShowDialog==true"-->
            <!-- visible reicht nicht! *ngIf muss auch, sonst macht er für alle autocompletes einen unsichtbaren select-dialog -->

<!--<p-blockUI id="benutzerDetailBlock" *ngIf="showEditDialog" [blocked]="true" [style]="{'opacity':'0.99'}"><!-- alles innerhalb blockUI = Ersatz für nicht funktionierendes modal (- falls es nicht funktioniert)-->
  <div style="position: relative">
      <p-dialog *ngIf="eigenerBenutzerPasswordResetShow" modal="true" id="editDialog" styleClass="editDialog" closeOnEscape="true" [style]="{'width': '44%'}" [contentStyle]="{'overflow':'auto'}" [responsive]="true" [resizable]="true" [closable]="false" [visible]="true">
      <!--height="auto" 'margin-right': '15px', 'height':'66%',  -->
          <p-header>
          </p-header>
          <app-passwort-reset [benutzerId]="eigenerBenutzer.id" [askForOldPassword]="true"
          (zurueckClicked)="eigenerBenutzerPasswordZuruecksetzenZurueckClicked()" (saveClicked)="eigenerBenutzerPasswordZuruecksetzenSaveClicked()"></app-passwort-reset>
  </p-dialog>
  </div>
  <!--</p-blockUI>-->

</ng-template>
    
