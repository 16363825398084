import { CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component';
//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
//import { LagerListComponent_Template } from './lager-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector } from '@angular/core';
import { AppComponent } from '../app.component';

//import { ILager } from '../_interfaces/lager';
import { LagerService } from '../_services/lager.service';
import { CRUDBasicListForChildComponent } from '../crud-basic-list-for-child/crud-basic-list-for-child.component';

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

//@Pipe({ name: 'baustelleAsIcon', pure: false })
export class LagerList_BaustelleAsIconPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, args?: any): any {
    let HTML = "";
    if(value == true) {
      HTML = '<i class="fa fa-construction"></i>';
    } 
    return this.sanitizer.bypassSecurityTrustHtml(HTML);
  }

}


@Component({
  selector: 'app-lager-list',
  templateUrl: './lager-list.component.html',
  //templateUrl: './lager-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${LagerListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class LagerListComponent extends CRUDBasicListForChildComponent implements OnInit {
  pageTitle: string = this.translate.instant("Lager", true);
  CRUDItemKurzform: string = "Lager";
  CRUDItemButtonTitleNeu: string = this.translate.instant("Neues Lager", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Lager";
  CRUDItemBezeichnungPluralCapitalized: string = "Lager";
  CRUDItemBezeichnungSingular: string = "lager";
  CRUDItemBezeichnungPlural: string = "lager";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "lager";
  CRUDItemRoutePlural: string = "lager";

  CRUDItemParentName : string = "unternehmen"; // wie heisst die Navigation zum Parent innerhalb des CRUDItem ? // AM Parent-ChildTab-CRUD
  CRUDItemChildName : string = "Lager"; // wie heisst (das Array) der Childs innerhalb dem Parent ? // AM Parent-ChildTab-CRUD

  CRUDDisableLoeschen: boolean = true;

  // customPipe !!!
  //baustelleAsiconPipe : LagerList_BaustelleAsIconPipe = new LagerList_BaustelleAsIconPipe(this.sanitizer);
  
  CRUDItemAvailableCols: any[] = [
          /*{
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 1,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 2,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 3,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 4,
            showByDefault: false
          },*/
          {
            field: 'bezeichnung',
            header: this.translate.instant('Bezeichnung', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 5,
            showByDefault: true
          },
          {
            field: 'strasse',
            header: this.translate.instant('Strasse', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 6,
            showByDefault: true
          },
          {
            field: 'geodaten.summary',
            header: this.translate.instant('Postleitzahl', true) + " " + this.translate.instant('Ort', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 7,
            showByDefault: true
          },
          /*{
            field: 'postleitzahl',
            header: this.translate.instant('Postleitzahl', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 7,
            showByDefault: true
          },
          {
            field: 'ort',
            header: this.translate.instant('Ort', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 8,
            showByDefault: true
          },
          {
            field: 'latitude',
            header: this.translate.instant('latitude', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 9,
            showByDefault: true
          },
          {
            field: 'longitude',
            header: this.translate.instant('longitude', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: true
          },
          {
            field: 'datenherkunft',
            header: this.translate.instant('Datenherkunft', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 11,
            showByDefault: true
          },
          {
            field: 'accuracy',
            header: this.translate.instant('accuracy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 12,
            showByDefault: true
          }*/,
          {
            field: 'baustelle',
            header: this.translate.instant('Baustelle', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 13,
            showByDefault: true,
            //customPipe: this.baustelleAsiconPipe, // customPipe !!!
            //spezialContentType: 'HTML'
          }/*,
          {
            field: 'baustelle_Gueltigkeit',
            header: this.translate.instant('Baustelle  Gültigkeit', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 15,
            showByDefault: true
          },
          {
            field: 'unternehmen.summary',
            header: this.translate.instant('Unternehmen', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 16,
            showByDefault: true
          }*/
    ];
    
    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: LagerService,
      private _injector: Injector,
      private sanitizer: DomSanitizer
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;
    }

    //ngOnInit() { // HTML Template Plugins
    //  super.ngOnInit();
    //  this.app.pageTitle = this['pageTitle'];
    //}

}


