<!--<div">-->
    <div #uppermostDiv
        
        [style.backgroundImage]="'url('+artikelTitelBildURL(CRUDItem)+')'"
        [style.backgroundSize]="'cover'"
        [style.backgroundPositionX]="'center'"
        [style.backgroundPositionY]="'center'"
        [style.lineHeight]="'0.5em'"

        [style.textAlign]="'justify'"

        [style.height]="'250px'"
        [style.maxHeight]="'250px'"
        [style.color]="'white'"
        >
        <!-- [style.color]="'white'" muss so sein, weil man im Quill standardmässig mit weiss schreibt -> das soll er 1:1 wieder so darstellen -->

        <div style="position: relative; top: -2px; left: 2px; color: black; background-color: white; width: fit-content;">
            &nbsp;Anzeige&nbsp;<br>&nbsp;
        </div>

        <div [style.padding]="'1em'" [innerHTML]="CRUDItem.beschreibung | keepHtml"></div>

    </div>
<!--</div>-->