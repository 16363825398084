import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IUnternehmenDatei } from '../_interfaces/unternehmen-datei';
import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class UnternehmenDateiService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'typ', type: 'string'},
    {name: 'guid', type: 'Guid'},
    {name: 'sortierung', type: 'int'},
    {name: 'dateiname', type: 'string'},
    {name: 'dateiendung', type: 'string'},
    {name: 'unternehmenId', type: 'int'},
    {name: 'unternehmen', type: 'object'},
    {name: 'summary', type: 'string'}
  ];
    
  getUnternehmenDateienCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ unternehmenDateien: IUnternehmenDatei[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'unternehmendateien?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { unternehmenDateien: <IUnternehmenDatei[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getUnternehmenDatei(id: number): Observable<IUnternehmenDatei> {

    if (id === 0) {
      return Observable.create((observer: any) => {
        observer.next(this.initializeUnternehmenDatei());
        observer.complete();
      })
    }

    return this.httpClient.get<IUnternehmenDatei>(this.apiBaseUrl + 'unternehmendateien/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  saveUnternehmenDatei(unternehmenDatei: IUnternehmenDatei): Observable<IUnternehmenDatei> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(unternehmenDatei); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("UnternehmenDateiService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("UnternehmenDateiService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (unternehmenDatei.id === 0) {
      return this.createUnternehmenDatei(/*unternehmenDatei*/itemToSave);
    }

    return this.updateUnternehmenDatei(/*unternehmenDatei*/itemToSave)

  }

  updateUnternehmenDatei(unternehmenDatei: IUnternehmenDatei): Observable<IUnternehmenDatei> {

    return this.httpClient.put<IUnternehmenDatei>(this.apiBaseUrl + 'unternehmendateien/' + unternehmenDatei.id, unternehmenDatei, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteUnternehmenDatei(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'unternehmendateien/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createUnternehmenDatei(unternehmenDatei: IUnternehmenDatei): Observable<IUnternehmenDatei> {
    return this.httpClient.post<IUnternehmenDatei>(this.apiBaseUrl + 'unternehmendateien', unternehmenDatei, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeUnternehmenDatei(): IUnternehmenDatei {
    return {
      id: 0,
      createdBy: '',
      created: '',
      modifiedBy: '',
      modified: '',
      rowVersion: '',
      typ: '',
      typ_ChoiceListEntry: null,
      guid: "00000000-0000-0000-0000-000000000000",
      sortierung: 0,
      dateiname: '',
      dateiendung: '',
      unternehmenId: null,
      unternehmen: null,
      summary: '',
      _file: null
    };
  }

getValidChoiceList_Typ(): Observable<{ choiceListEntries: IChoiceList[] }> {
  return this.httpClient.get(this.apiBaseUrl + 'unternehmendateien/GetValidChoiceList_Typ', { headers: httpOptions, observe: 'response', withCredentials: true })
    .pipe(
      map((response) => {
        //console.log("UnternehmenDateiService.getValidChoiceList_Typ() response.body:", response.body);
        return { 
          choiceListEntries: <IChoiceList[]>response.body
        };
      }), catchError(this.handleError))
}

}
