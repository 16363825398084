<p-blockUI [blocked]="true">
        <div style="position:absolute;top:25%;left:40%">
        
        <form [formGroup]="loginForm" #form autocomplete="off" novalidate (ngSubmit)="login(password.value, username.value)">
          <!--[formGroup]="loginForm"-->
        
            <!--<fieldset [disabled]="(disableFields ? 'disabled':null)">-->
                  <div class="card login-panel ui-fluid" style="position: relative; margin-top: 0px !important;">
                    <div class="loginHeader"><img src="assets/layout/images/plattGruenZweiLogo.png" width="176px"></div>
                    <div class="ui-g-12">
                    </div>
                    <div class="ui-g-12">Neuen Benutzer registrieren:
                        </div>
                        <div class="ui-g-12">
                            </div>
                    <div class="ui-g-12">
                      <span class="md-inputfield loginInputStyles">
                        <input type="text" #username pInputText loginFormControl formControlName="username" style="font-size: 18px"
                          autocomplete="off" class="ui-inputtext ui-corner-all ui-state-default ui-widget" required placeholder="&#xF007; Benutzername" style="font-family:Arial, FontAwesome">
                      </span>
                    </div>
                    <div class="ui-g-12">
                      <span class="md-inputfield loginInputStyles">
                        <input loginFormControl  type="text" #password formControlName="password" style="font-size: 18px"
                          autocomplete="off" class="ui-inputtext ui-corner-all ui-state-default ui-widget" required placeholder="&#xF0E0; E-Mail" style="font-family:Arial, FontAwesome" >
                      </span>
                    </div>
                    <div class="ui-g-12">
                      <div>
                        <!--<button [disabled]='!loginForm.valid' pButton type="submit" id="loginBtn"
                          style="padding-left:0 !important;" class="ui-state-default ui-corner-all ui-button-text-icon-left">
                          <span class="ui-button-icon-left ui-c fa fa-sign-in"></span>
        
                          Login
                        </button>-->
                      <button id="loginButton" styleClass="ui-button-warning" pButton type="submit"
                      [icon]="'pi pi-sign-in'"
                      [label]="'Registrieren'"></button>
              
                      </div>
                    </div>
                  </div>
                  <!--<div  *ngIf="!aktiveUserVisible" class="ui-widget" style="text-align: center">
                    <img id="imkeLogo" src="assets\layout\images\settings300.png">
                  </div>-->
            <!--</fieldset>-->
          </form>
        
        </div>
        </p-blockUI>