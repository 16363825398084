import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import {MenuItem, TreeNode, MessageService} from 'primeng/api';
import {SelectItem} from 'primeng/api';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
import { AutoComplete, FileUpload, Galleria } from 'primeng/primeng';
import { Router } from '@angular/router';

//import { IArtikel } from '../_interfaces/artikel';
import { ArtikelService } from '../_services/artikel.service';

// Lieferat(en)
import { ILieferart } from '../_interfaces/lieferart';
import { LieferartService } from '../_services/lieferart.service';
import { IArtikelLieferart } from '../_interfaces/artikel-lieferart';
import { ArtikelLieferartService } from '../_services/artikel-lieferart.service';

// Artikelgruppe
import { IArtikelgruppe } from '../_interfaces/artikelgruppe';
import { ArtikelgruppeService } from '../_services/artikelgruppe.service';

// Artikelbild
import { IArtikelbild } from '../_interfaces/artikelbild';
import { ArtikelbildService } from '../_services/artikelbild.service';

// Unternehmen
import { IUnternehmen } from '../_interfaces/unternehmen';
import { UnternehmenService } from '../_services/unternehmen.service';

// Favorit
import { IFavorit } from '../_interfaces/favorit';
import { FavoritService } from '../_services/favorit.service';

// upload
import { Image } from '../demo/domain/image';

// Galleria
import { PhotoService } from '../demo/service/photoservice.service';
//import { GalleriaFixer } from '../_helpers/galleria-fixer';

// ...
import { MessageWrapperService } from '../_services/message-wrapper.service';

import { empty, from } from 'rxjs';

// Euro
import { CurrencyPipe } from '@angular/common';
import { CustomCurrencyPipe } from '../_services/custom.currencypipe';

// Moment für zB Gültigkeit
import * as moment from 'moment';
import { LagerService } from '../_services/lager.service';

declare var jquery: any;
declare var $: any;

//import { WerbeanzeigeDetailAnsichtComponent_Template } from './artikel-detail.component.include_template';

@Component({
  selector: 'app-werbeanzeige-detail-ansicht',
  templateUrl: './werbeanzeige-detail-ansicht.component.html',
  styleUrls: ['./werbeanzeige-detail-ansicht.component.css']
})
/* zunächst ist WerbeanzeigeDetailAnsichtComponent eine 1:1 Kopie von Artikel-Ansicht - aber eben mit Template
   für Werbung - wird auch nur im preview-Modus aufgerufen
   ... muss noch ausgemistet werden */
export class WerbeanzeigeDetailAnsichtComponent extends CRUDBasicDetailComponent implements OnInit {
  @Input('CRUDItemAsParameter') CRUDItemAsParameter: any; // Alternativ kann man das CRUDItem auch per Parameter mitgeben
                          // Das wird verwendet, um den Artikel als Vorschau anzuzeigen
  @Input('mode') mode: string; // "import" zB ohne Save button
  @Output() zurueckClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveClicked: EventEmitter<any> = new EventEmitter<any>();

  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Werbeanzeige";
  CRUDPageTitleNeu: string = this.translate.instant("Neue Werbeanzeige", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Werbeanzeige anzeigen", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Artikel";
  CRUDItemBezeichnungPluralCapitalized: string = "Artikel";
  CRUDItemBezeichnungSingular: string = "artikel";
  CRUDItemBezeichnungPlural: string = "artikel";
  CRUDItemRouteSingular: string = "artikel";
  CRUDItemRoutePlural: string = "artikel-uebersicht";
  CRUDItemHelpTopic: string = "Artikel";
  CRUDMethodNameGet: string = "getArtikelAndIncreaseAufrufe"; // Die Aufrufe hochzählen

  //CRUDMethodGetAfterViewInit: boolean = true; // get"CrudItem"() nicht schon im ngOnInit machen, sondern erst im ngAfterViewInit!

  //debugMode: boolean = true;

  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START

  // pseudoCarousel
  pseudoArr = [this.CRUDItem, this.CRUDItem, this.CRUDItem, this.CRUDItem, this.CRUDItem, this.CRUDItem,];







  urlParmOverviewMode: string; // wenn man von artikel-uebersicht?overviewmode=myItems kommt, dann auch wieder dahin zurück!

  // Favorit
  isActive = false;
  isActiveFavoritId: number = 0;
  
  
  unternehmen: IUnternehmen = null;



  // aus Edit-Mode - kann vermutlich gelöscht werden


  // Lieferart(en)
  artikelLieferartenItems: IArtikelLieferart[];
  artikelLieferartenSelected: IArtikelLieferart[] = []; 
  artikelLieferartenSelectedAsString: string = "";
  artikelLieferartenDisplayAuswahlPopUp: boolean;
  
  // Artikelgruppe
 artikelGruppeItems: IArtikelgruppe[];
 artikelGruppeTreeNodes: TreeNode[];
 artikelGruppeSelected: TreeNode; 
 artikelGruppeDisplayAuswahlPopUp: boolean;
 artikelGruppeBreadCrumbItems: MenuItem[];
 artikelGruppeBreadCrumbHome = {icon: 'pi pi-home'/*, routerLink: '/'*/};

 // Artikelbilder
 // WICHTIG:
 // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
 //@ViewChild('artikelbilderFileUpload') public artikelbilderFileUpload: FileUpload;
 @ViewChild('artikelBilderGalleria') public artikelBilderGalleria: Galleria;
 //artikelbilderFileUpload.files : File[] = [];
 artikelBilderGalleryImages: Image[] = [];
 artikelBilderDisplayGalleryOrUpload: string = "U";
 artikelBilderTitlePicGuid: string = "00000000"; // guid des Titelbildes
 artikelBilderAlreadySwitchedToGallery: boolean = false; // schon einmal autom. auf Gallery geswitched (das machen wir nur 1mal, danach soll der User manuell switchen)

 // ???
 userform: FormGroup;
 submitted: boolean;
 genders: SelectItem[];
 description: string;
 showWarningMsg: boolean = false;
 // showVermerk: boolean = true;
 disabledVermerk: boolean = true;
 // Hersteller
 //hersteller: IHersteller = null;

 // Lager
 //lager: ILager = null;

 // Mengeneinheit
 //mengeneinheit: IMengeneinheit = null;

 // Preisvorstellung
 preisvorstellungPro: any;
 preisvorstellungProItems: SelectItem[] = [
  {label:'Pro Stück', value: 'S'},
  {label:'Pauschal', value: 'P'},
]; 
 // Artikelzustand
 //artikelzustand: IArtikelzustand = null;

 //galleriaFixer: GalleriaFixer = new GalleriaFixer();
// Galleria
galleriaFullscreen: boolean = false;

responsiveOptions:any[] = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];
items: MenuItem[];
//Galleria endet hier

//Euro 
emitEvent: boolean;
// Labels 
gueltig: string = "Gültig bis:";
verlademoeglichkeitLbl: string = "Verlademöglichkeit vorhanden:";
vermerkLbl: string = "Vermerk:";
eMailAdresse: string = "E-Mail Adresse anzeigen";
telNummer: string = "Telefonnummer anzeigen";
aktiveArtikel: string = "Artikel aktiv";
handyNummer: string ="Mobilnummer anzeigen";
lagerBestand: string ="Lagerbestand anzeigen";
agbEins: string = "Mit der Speicherung stimme ich den Nutzungsbedingungen zu, die unter";
agbZwei: string = "eingesehen werden können.";


  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: ArtikelService,

    // CHILD-spezifisch: zusätzliche Services - START
    private lieferartService: LieferartService,
    private artikelLieferartService: ArtikelLieferartService,
    private artikelgruppeService: ArtikelgruppeService,
    public messageService: MessageService,
    public messageWrapperService: MessageWrapperService,
    public photoService: PhotoService,
    private currencyPipe: CurrencyPipe ,
    private customCurrencyPipe: CustomCurrencyPipe,
    private artikelbildService: ArtikelbildService,
    private lagerService: LagerService,
    private unternehmenService: UnternehmenService,
    private _router: Router,
    private favoritService: FavoritService,
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      bezeichnung: {
        required: this._translate.instant('Bezeichnung', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Bezeichnung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      hersteller: {
        required: this._translate.instant('Hersteller', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      //lieferart: {
      //  required: this._translate.instant('Lieferart', true) + ' ' + this._translate.instant('ist erforderlich', true)
      //},

      beschreibung: {
        maxlength: this._translate.instant('Beschreibung', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      },

      //lager: {
      //  required: this.translate.instant('Lager', true) + ': ' + this._translate.instant('ist erforderlich', true)
      //},

      artikelnummer: {
        
        required: this.translate.instant('Artikelnummer', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Artikelnummer', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      },

      //baustelle: {
      //  maxlength: this._translate.instant('Baustelle', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      //},

      //strasse: {
      //  maxlength: this._translate.instant('Strasse', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      //},

      //postleitzahl: {
      //  maxlength: this._translate.instant('Postleitzahl', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      //},

      //ort: {
      //  maxlength: this._translate.instant('Ort', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      //},

      preis: {
        required: this._translate.instant('Preis', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      menge: {
        required: this._translate.instant('Menge', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      zustand: {
        required: this._translate.instant('Artikelzustand', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      verlademoeglichkeit: {
        required: this._translate.instant('Verlademöglichkeit', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      verlademoeglichkeitVermerk: {
        required: this._translate.instant('Vermerk', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Artikelnummer', true) + ' ' + this._translate.instant('darf 1000 Zeichen nicht überschreiten', true)
      },

      aktiv: {
        required: this._translate.instant('Aktiv', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      email_anzeigen: {
        required: this._translate.instant('Email anzeigen', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      lagerbestand: {
        required: this._translate.instant('Lagerbestand', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      telefon_anzeigen: {
        required: this._translate.instant('Telefon anzeigen', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      mobilnummer_anzeigen: {
        required: this._translate.instant('Mobilnummer anzeigen', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      pauschal: {
        required: this._translate.instant('Pauschal', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      mengeneinheit: {
        required: this._translate.instant('Mengeneinheit', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      ansprechpartner: {
        required: this._translate.instant('Ansprechpartner', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      _AGBAkzeptiert: {
        required: this._translate.instant('Nutzungsbedingungen', true) + ': ' + this._translate.instant('müssen akzeptiert werden', true),
      },
    };
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      bezeichnung: ['', [Validators.required, Validators.maxLength(255)]],
      beschreibung: ['', [Validators.maxLength(255)]],
      artikelnummer: ['', [Validators.required, Validators.maxLength(255)]],
      baustelle: [null, [Validators.maxLength(255)]],
      baustelle_Gueltigkeit: [null],
      postleitzahl: [null, [Validators.maxLength(255)]],
      strasse: [null, [Validators.maxLength(255)]],
      ort: [null, [Validators.maxLength(255)]],
      preis: [0, [Validators.required]],
      menge: [0, [Validators.required]],
      verlademoeglichkeit: [false, [Validators.required]],
      verlademoeglichkeitVermerk: ['', [Validators.maxLength(1000)]],
      gueltigkeit: [null],
      aktiv: [false, [Validators.required]],
      email_anzeigen: [false, [Validators.required]],
      lagerbestand: [false, [Validators.required]],
      telefon_anzeigen: [false, [Validators.required]],
      mobilnummer_anzeigen: [false, [Validators.required]],
      ansprechpartner: [null],
      zustand: [null],
      hersteller: ['', [Validators.required]],
      lager: [null/*, [Validators.required]*/],
      //lieferart: [null],
      mengeneinheit: [null],
      pauschal: [false, [Validators.required]],
      verfuegbarkeit: [null],
      verkaufseinheit: [null],
      verpackung: [null],

      artikelbilder: [null], // Parent+Child

      _AGBAkzeptiert: [null, [Validators.required]],
      _baustelleAnzeigen: [null, [Validators.required]],

      dummy: [''] // TO DO
    },{
      //validator: this.formValidator // auf FormEbene!
    });
    
    this.CRUDForm['___component'] = this; // trick, um im formValidator wieder an die component zu kommen.
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;
    

    // Falls CRUDItem per Parameter kam, diesen Wert nach getCRUDItem übernehmen - und dabei statt getArtikel() nur ein Dummy holen (siehe auch ...)
    if(this.CRUDItemAsParameter != null) {
      this.CRUDMethodNameGet = "getArtikelDummy";
      //this.CRUDPageTitleNeu = this.translate.instant("Vorschau Artikel", true);
      //this.CRUDPageTitleBearbeiten = this.translate.instant("Vorschau Artikel", true);
      this.CRUDDisablePageTitleUpdates = true;
    }

    this.route.queryParams.subscribe(params => {
      this.urlParmOverviewMode = params['overviewmode'];
    });

    /////////////////smooth fade in/out button 
    $(window).scroll(function(){
      if($(this).scrollTop()>800){
        $(".btnArtikelToTop").css({"opacity": "1"})
      }
      else {
        $(".btnArtikelToTop").css({"opacity": "0"})
      }
    });

  
  

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    
    // ArtikelGruppen
    // es ist sinnfrei, nur die Parent zu laden - und dann nachzuladen, weil!
    // dann funktioniert die Suche nicht!
    /*
    let thisInstance = this;
    artikelgruppeService.getArtikelgruppenChildrenForParent(0).subscribe(function (response) {
      let allFetchedArtikelGruppen:IArtikelgruppe[] = response.artikelgruppen;
      console.log("WerbeanzeigeDetailAnsichtComponent.constructor() getArtikelgruppenChildrenForParent reponse:", response);
      //_this.onItemsRetrieved(response.mandantenBankkonten);
      thisInstance.artikelGruppeTreeNodes = [];
      response.artikelgruppen.forEach(artikelGruppe => {
        let children:TreeNode[] = [];
        artikelGruppe.inverseArtikelgruppe.forEach(inverseArtikelgruppe => {
          let newChildTreeNode:TreeNode = {
            children: null,
            collapsedIcon: "ui-icon-folder",
            data: inverseArtikelgruppe.bezeichnung,
            expandedIcon: "ui-icon-folder-open",
            label: inverseArtikelgruppe.bezeichnung
          }            
          children.push(newChildTreeNode);
        });

        let newTreeNode:TreeNode = {
          children: children,
          collapsedIcon: "ui-icon-folder",
          data: artikelGruppe.bezeichnung,
          expandedIcon: "ui-icon-folder-open",
          label: artikelGruppe.bezeichnung
        }            
        thisInstance.artikelGruppeTreeNodes.push(newTreeNode);
      });
      console.log("WerbeanzeigeDetailAnsichtComponent.constructor() getArtikelgruppenChildrenForParent artikelGruppeTreeNodes:", this.artikelGruppeTreeNodes);
    }, function (error) { return this.handleError(error); });
    */

    // alle Artikelgruppen auf einmal laden!
    /*
    let thisInstance = this;
    this.artikelgruppeService.getArtikelgruppenChildrenForTree().subscribe(function (response) {
      thisInstance.artikelGruppeItems = response.artikelgruppen;
      console.log("WerbeanzeigeDetailAnsichtComponent.ngOnInit() getArtikelgruppenCollection thisInstance.artikelGruppeItems:", thisInstance.artikelGruppeItems);
      thisInstance.artikelGruppeTreeNodes = [];
      thisInstance.artikelGruppeItems.filter(f => f.artikelgruppeId == null).forEach(artikelGruppe => {
        //thisInstance.artikelGruppeTreeNodes.push(newTreeNode);
        thisInstance.artikelGruppeTreeNodes.push(thisInstance.artikelGruppePrepareTreeNode(thisInstance, 0, artikelGruppe));
      });
      console.log("WerbeanzeigeDetailAnsichtComponent.ngOnInit() getArtikelgruppenChildrenForParent thisInstance.artikelGruppeTreeNodes:", thisInstance.artikelGruppeTreeNodes);
      thisInstance.ngOnInit_WerbeanzeigeDetailAnsichtComponent_pt2(thisInstance); // erst NACHDEM die Artieklgruppen gelesen sind mit getCRUDitem() etc. weitermachen!
    }, function (error) { return this.handleError(error); });
    */
    this.ngOnInit_WerbeanzeigeDetailAnsichtComponent_pt2(this);
    
    this.items = [
     {label: 'Angular.io', icon: 'pi pi-external-link', url: 'http://angular.io'},
     {label: 'Theming', icon: 'pi pi-palette'}
    ];

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    //super.___ngOnInit(); // siehe oben -> passiert aus dem subscribe heraus - NACHDEM die Artieklgruppen gelesen sind!
  }



  ngOnInit_WerbeanzeigeDetailAnsichtComponent_pt2(thisInstance : WerbeanzeigeDetailAnsichtComponent) {
    console.log("WerbeanzeigeDetailAnsichtComponent.ngOnInit_WerbeanzeigeDetailAnsichtComponent_pt2() ...");
    /*
    thisInstance.lieferartService.getLieferartenCollection(1, 0, '').subscribe(function (response) {
      thisInstance.artikelLieferartenItems = [];
      response.lieferarten.forEach(lieferart => {
        let artikelForArtikelLieferart = thisInstance._crudItemService.initializeArtikel();
        artikelForArtikelLieferart.id = thisInstance.dataId;
        let artikelLieferart:IArtikelLieferart = thisInstance.artikelLieferartService.initializeArtikelLieferart();
        artikelLieferart.artikel = artikelForArtikelLieferart;
        artikelLieferart.artikelId = thisInstance.dataId;
        artikelLieferart.lieferart = lieferart;
        artikelLieferart.lieferartId = lieferart.id;
        artikelLieferart.summary = lieferart.summary;
        thisInstance.artikelLieferartenItems.push(artikelLieferart);
      });
      console.log("WerbeanzeigeDetailAnsichtComponent.ngOnInit_WerbeanzeigeDetailAnsichtComponent_pt2() artikelLieferartenItems:", thisInstance.artikelLieferartenItems);
      thisInstance.artikelLieferartenSelected = [];

      thisInstance.ngOnInit_WerbeanzeigeDetailAnsichtComponent_pt3(thisInstance); // erst NACHDEM die Artieklgruppen gelesen sind mit getCRUDitem() etc. weitermachen!
    }, function (error) { return thisInstance.handleError(error); });
    */
   thisInstance.ngOnInit_WerbeanzeigeDetailAnsichtComponent_pt3(thisInstance); 
  }
  ngOnInit_WerbeanzeigeDetailAnsichtComponent_pt3(thisInstance : WerbeanzeigeDetailAnsichtComponent) {
    console.log("WerbeanzeigeDetailAnsichtComponent.ngOnInit_WerbeanzeigeDetailAnsichtComponent_pt3() calling ... super.ngOnInit()");
    super.ngOnInit();
  }

  onCRUDItemRetrieved(CRUDItem: any): void {
    // Falls CRUDItem per Parameter kam, diesen Wert nach getCRUDItem übernehmen - und dabei statt getArtikel() nur ein Dummy holen (siehe auch ...)
    if(this.CRUDItemAsParameter != null) {
      CRUDItem = this.CRUDItemAsParameter;
      console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() CRUDItem.artikelbilder: ", CRUDItem.artikelbilder);
    }

        // Bei Suchanfrage: Kennzeichen, andere Überschrift, Prüfungen deaktivieren
        if(this.urlParmOverviewMode!=null) {
          if(this.urlParmOverviewMode == "myAdverts") {
            this.CRUDItemKurzform = "Werbeanzeige";
            this.CRUDPageTitleNeu = this.translate.instant("Neue Werbeanzeige", true);
            this.CRUDPageTitleBearbeiten = this.translate.instant("Werbeanzeige anzeigen", true);
            this.CRUDItemBezeichnungSingularCapitalized = "Werbeanzeige";
            this.CRUDItemBezeichnungPluralCapitalized = "Werbeanzeigen";
            this.CRUDConfirmMessageDelete = "Wollen Sie diese Werbeanzeige wirklich löschen?"; // Alternativen Text zum Standard 
              
            // MethodNames heissen aber torzdem "...Artikel..."
            this.CRUDMethodNameGet = "getArtikel";
            this.CRUDMethodNameSave = "saveArtikel";
            this.CRUDMethodNameDelete = "deleteArtikel";
          }
        }

    super.onCRUDItemRetrieved(CRUDItem);

    console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() CRUDItem.artikelbilder: ", CRUDItem.artikelbilder);

    let thisInstance = this;
    thisInstance.lieferartService.getLieferartenCollection(1, 0, '').subscribe(function (response) {
      thisInstance.artikelLieferartenItems = [];
      response.lieferarten.forEach(lieferart => {
        let artikelForArtikelLieferart = thisInstance._crudItemService.initializeArtikel();
        artikelForArtikelLieferart.id = thisInstance.dataId;
        let artikelLieferart:IArtikelLieferart = thisInstance.artikelLieferartService.initializeArtikelLieferart();
        artikelLieferart.artikel = artikelForArtikelLieferart;
        artikelLieferart.artikelId = thisInstance.dataId;
        artikelLieferart.lieferart = lieferart;
        artikelLieferart.lieferartId = lieferart.id;
        artikelLieferart.summary = lieferart.summary;
        thisInstance.artikelLieferartenItems.push(artikelLieferart);
      });
      console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() artikelLieferartenItems:", thisInstance.artikelLieferartenItems);
      thisInstance.artikelLieferartenSelected = [];

      // die virtuell erstellten ArtikelLieferarten (zur Auswahl) durch die echten - bereits vorhandenen - ersetzen.
      console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() CRUDItem.artikelLieferarten:", CRUDItem.artikelLieferarten);
      console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() before: thisInstance.artikelLieferartenItems:", thisInstance.artikelLieferartenItems);
      if(thisInstance.artikelLieferartenItems != null && thisInstance.artikelLieferartenItems.length > 0) {
        if(CRUDItem.artikelLieferarten != null && CRUDItem.artikelLieferarten.length > 0) {
          let i = 0;
          thisInstance.artikelLieferartenItems.forEach(artikelLieferartItem => {
            console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() checking artikelLieferartItem:", artikelLieferartItem);
            let idx = CRUDItem.artikelLieferarten.findIndex(f => f.lieferartId == artikelLieferartItem.lieferartId);
            if(idx >= 0) {
              console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() replacing index "+i+" with existing item from CRUDItem.artikelLieferarten: ", CRUDItem.artikelLieferarten[idx]);
  
              thisInstance.artikelLieferartenItems.splice(i, 1, CRUDItem.artikelLieferarten[idx])
            }
            i++;
          });
    
        }
        console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() after: thisInstance.artikelLieferartenItems:", thisInstance.artikelLieferartenItems);
      }

      thisInstance.artikelLieferartenSelected = CRUDItem.artikelLieferarten; // Parent+Child
      thisInstance.artikelLieferartenOnSelected();
      console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() artikelLieferartenSelected:", thisInstance.artikelLieferartenSelected);

    }, function (error) { return thisInstance.handleError(error); });

        /*// Galleria
        this.artikelBilderGalleryImages = [];
        let indexOfArtikelbild = -1;
        console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() building artikelBilderGalleryImages: CRUDItem.artikelbilder:", CRUDItem.artikelbilder);
        CRUDItem.artikelbilder.forEach(artikelbild => {
          indexOfArtikelbild ++;
          let newImage : Image = new Image();
          newImage.title = artikelbild.bezeichnung;
          newImage.thumbnailImageSrc = this.artikelbildService.getThumbDownloadUrl(artikelbild.guid, artikelbild.dateiendung);
          newImage.previewImageSrc = this.artikelbildService.getDownloadUrl(artikelbild.guid, artikelbild.dateiendung);
          console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() adding image to artikelBilderGalleryImages:", newImage);
          this.artikelBilderGalleryImages.push(newImage);
        
          // wenn das das Titelbild ist, dann das in der Galleria voreinstellen
          if(artikelbild.guid == CRUDItem.titelbild.guid) {
            //this.artikelBilderGalleria.activeIndex = indexOfArtikelbild;
            let thisInstance = this;
            setTimeout(() => {
              thisInstance.artikelBilderGalleria.activeIndex = indexOfArtikelbild;
            }, 100);
          }
        });*/

    //let thisInstance = this;
    this.artikelgruppeService.getArtikelgruppenChildrenForTree().subscribe(function (response) {
      thisInstance.artikelGruppeItems = response.artikelgruppen;
      console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() getArtikelgruppenCollection thisInstance.artikelGruppeItems:", thisInstance.artikelGruppeItems);
      thisInstance.artikelGruppeTreeNodes = [];
      thisInstance.artikelGruppeItems.filter(f => f.artikelgruppeId == null).forEach(artikelGruppe => {
        //thisInstance.artikelGruppeTreeNodes.push(newTreeNode);
        thisInstance.artikelGruppeTreeNodes.push(thisInstance.artikelGruppePrepareTreeNode(thisInstance, 0, artikelGruppe));
      });
      console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() getArtikelgruppenChildrenForParent thisInstance.artikelGruppeTreeNodes:", thisInstance.artikelGruppeTreeNodes);
    }, function (error) { return this.handleError(error); });

    // Favorit
    if(this.CRUDItemAsParameter == null && this.dataId > 0 && this.app.benutzer != null) {
      try {
        this.favoritService.getFavoritForArtikelAndBenutzer(this.CRUDItem.id, this.app.benutzer.id).subscribe(
          (response: any) => {
            console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() getFavoritForArtikelAndBenutzer() response:", response);
            this.isActive = true;
            this.isActiveFavoritId = response.id;
          },
          (error: any) => {
            // ignore! Der Artikel ist kein Favorit!
            //alert("error: "+error);
          }
        );
      }
      catch(e) {
        console.log("ArtikelDetail getFavoritForArtikelAndBenutzer() exception:", e);
      }
    }

    // unternehmen NACHladen
    if(/*CRUDItem.ansprechpartner != null*/true) {
      this.unternehmenService.getUnternehmen(CRUDItem/*.ansprechpartner*/.unternehmenId).subscribe(response => {
        this.unternehmen = response;
        console.log("WerbeanzeigeDetailAnsichtComponent.onCRUDItemRetrieved() loading unternehmen: this/unternehmen", this, this.unternehmen);
      }, error => { return this.handleError(error); });
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    // das galleriaitem finden + höhe setzen: p-galleria > p-galleriacontent > div > div > p-galleriaitem
    let thisInstance = this;
    //setTimeout(() => {
    //  thisInstance.alignGalleriaAtBottom();
    //}, 1000);


    
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE
  }
  onSubmit(value: string) {
    this.submitted = true;
    this.messageService.add({severity: 'info', summary: 'Success', detail: 'Form Submitted'});

    
  }
  get diagnostic() {return JSON.stringify(this.userform.value);}
 

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    a.ansprechpartnerId = a.ansprechpartner != null ? a.ansprechpartner.id : null;
    a.zustandId = a.zustand != null ? a.zustand.id : null;
    a.herstellerId = a.hersteller != null ? a.hersteller.id : null;
    //a.lieferartId = a.lieferart != null ? a.lieferart.id : null;
    a.mengeneinheitId = a.mengeneinheit != null ? a.mengeneinheit.id : null;
    a.verfuegbarkeitId = a.verfuegbarkeit != null ? a.verfuegbarkeit.id : null;
    a.verkaufseinheitId = a.verkaufseinheit != null ? a.verkaufseinheit.id : null;
    a.verpackungId = a.verpackung != null ? a.verpackung.id : null;
    a.ansprechpartnerId = a.ansprechpartner != null ? a.ansprechpartner.id : null;

    // specials:
    a.artikelLieferarten = this.artikelLieferartenSelected != null ? this.artikelLieferartenSelected : 0;
    a.artikelgruppeId = this.artikelGruppeSelected != null ? this.artikelGruppeSelected.data.id : 0;
    a.preis = parseFloat(a.preis); // numerisch mit Dezimal

    // Hersteller: wenn manuell eingegeben, dann Unternehmen nachtragen
    if(a.hersteller != null && (a.hersteller.id == null || a.hersteller.id == 0)) {
      a.hersteller.unternehmenId = this.app.benutzer.unternehmenId;
    }

    // Lager: Wenn neue baustelle eingegeben ...
    if(this.CRUDForm.value._baustelleAnzeigen == true) {
      let newLager = this.lagerService.initializeLager();
      newLager.baustelle = true;
      
      newLager.bezeichnung = a.baustelle;
      //newLager.ort = a.ort;
      //newLager.postleitzahl = a.postleitzahl;
      newLager.geodatenId = a.geodaten.id;
      newLager.strasse = a.strasse;
      newLager.unternehmenId = this.app.benutzer.unternehmenId;

      a.lager = newLager;
      console.log("WerbeanzeigeDetailAnsichtComponent.getValuesFromForm() this.CRUDForm.value.gueltigkeit_Baustelle:", this.CRUDForm.value.gueltigkeit_Baustelle);
      if(a.baustelle_Gueltigkeit != null) {
        a.lager.baustelle_Gueltigkeit = moment.utc(this.getUTCDateFromForm(this.CRUDForm.value.baustelle_Gueltigkeit)); // kommt von p-calendar schon mit Zeit 0 - aber mit Zeitzone (und die wiederum in die Studnen eingerechnet) -> das anpassen, es soll 0h ohne Zeitzone ankommen!
        console.log("WerbeanzeigeDetailAnsichtComponent.getValuesFromForm() a.lager.baustelle_Gueltigkeit:", a.lager.baustelle_Gueltigkeit);
      }
      a.lagerId = 0;
    }
    else {
      a.lagerId = a.lager != null ? a.lager.id : null;
    }

    // pauschal soll binary, nicht alpha sein!
    a.pauschal = a.pauschal == "true" ? true : false;

    console.log("WerbeanzeigeDetailAnsichtComponent.getValuesFromForm() this.CRUDForm.value.gueltigkeit:", this.CRUDForm.value.gueltigkeit);
    if(a.gueltigkeit != null) {
      a.gueltigkeit = moment.utc(this.getUTCDateFromForm(this.CRUDForm.value.gueltigkeit)); // kommt von p-calendar schon mit Zeit 0 - aber mit Zeitzone (und die wiederum in die Studnen eingerechnet) -> das anpassen, es soll 0h ohne Zeitzone ankommen!
      console.log("WerbeanzeigeDetailAnsichtComponent.getValuesFromForm() a.gueltigkeit:", a.gueltigkeit);
    }

    // Artikelbilder aus der fileUpload-Liste aktualisieren
    // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
    //console.log("WerbeanzeigeDetailAnsichtComponent.getValuesFromForm() artikelbilderFileUpload.files:", this.artikelbilderFileUpload.files);
    /*
    a.artikelbilder = [];
    let bestehendeSortierung : number = 0;
    this.artikelbilderFileUpload.files.forEach(file => {
      if(file['_artikelbild'] != undefined) { // das Artikelbild gab es schon (Update-Modus, per GetArtikel bekommen)
        let existingArtikelbild : IArtikelbild = file['_artikelbild']
        console.log("WerbeanzeigeDetailAnsichtComponent.getValuesFromForm() adding existing existingArtikelbild:", existingArtikelbild);
        a.artikelbilder.push(existingArtikelbild);
        if(file['_artikelbild'].sortierung > bestehendeSortierung) bestehendeSortierung = file['_artikelbild'].sortierung;
      }
      else { // ein neues Artikelbild
        let dateiendung = file.name.substr(file.name.lastIndexOf('.') + 1);
        let dateiname = file.name.substr(0, file.name.length - (dateiendung.length + 1));
        let newArtikelbild : IArtikelbild = this.artikelbildService.initializeArtikelbild();
        delete newArtikelbild._bild; // sonst kommen die Parameter beim Controller nicht an!
        newArtikelbild.dateiname = dateiname;
        newArtikelbild.dateiendung = dateiendung;
        newArtikelbild.bezeichnung = file.name;
        newArtikelbild.guid = file['_guid'];
        newArtikelbild.sortierung = bestehendeSortierung + 1;
        bestehendeSortierung ++;
        newArtikelbild.id = 0;
        newArtikelbild.artikelId = this.CRUDItem.id;
        console.log("WerbeanzeigeDetailAnsichtComponent.getValuesFromForm() adding new newArtikelbild:", newArtikelbild);
        a.artikelbilder.push(newArtikelbild);
      }
    });
    */

    // Titelbild
    if(this.artikelBilderTitlePicGuid != null && this.artikelBilderTitlePicGuid != "00000000") {
      console.log("WerbeanzeigeDetailAnsichtComponent.getValuesFromForm() searching titelbild in a.artikelbilder:", a.artikelbilder);
      let foundTitelbild = a.artikelbilder.find(f => f['guid'] == this.artikelBilderTitlePicGuid);
      a.titelbild = foundTitelbild;
      a.titelbildId = a.titelbild.id;
    }
    else {
      a.titelbild = null;
      a.titelbildId = 0;
    }
    
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    console.log("WerbeanzeigeDetailAnsichtComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    //console.log("WerbeanzeigeDetailAnsichtComponent.sendValuesToForm()");
    //console.log("WerbeanzeigeDetailAnsichtComponent.sendValuesToForm() artikelbilderFileUpload.files:", this.artikelbilderFileUpload.files);
    

    if (this.dataId != null && this.dataId == '0' && this.CRUDItemAsParameter == null /* nicht Vorschau! */) { // create mode
      console.log("WerbeanzeigeDetailAnsichtComponent.sendValuesToForm() create-mode");
      // anhängende Entities:
      // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
      this.CRUDItem.artikelbilder = []; // Parent+Child
      //this.artikelbilderFileUpload.files = [];
      this.artikelBilderGalleryImages = [];
    }
    else {
      console.log("WerbeanzeigeDetailAnsichtComponent.sendValuesToForm() upd-mode: this.CRUDItem.artikelbilder:", this.CRUDItem.artikelbilder);
      if(this.artikelBilderGalleryImages == null) {
        // Artikelbilder in gallery und die fileUpload-Liste eintragen
        // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
        /*
        this.artikelbilderFileUpload.files = [];
        this.artikelBilderGalleryImages = [];
        this.CRUDItem.artikelbilder.forEach(artikelbild => {
          let newImage : Image = new Image();
          newImage.title = artikelbild.bezeichnung;
          newImage.thumbnailImageSrc = this.artikelbildService.getThumbDownloadUrl(artikelbild.guid, artikelbild.dateiendung);
          newImage.previewImageSrc = this.artikelbildService.getDownloadUrl(artikelbild.guid, artikelbild.dateiendung);
          console.log("WerbeanzeigeDetailAnsichtComponent.sendValuesToForm() adding image to artikelBilderGalleryImages:", newImage);
          this.artikelBilderGalleryImages.push(newImage);

          let newFile = new File([], artikelbild.dateiname+"."+artikelbild.dateiendung, {
            type: "image/"+(artikelbild.dateiendung),
          });
          newFile['objectURL']=this.artikelbildService.getThumbDownloadUrl(artikelbild.guid, artikelbild.dateiendung);
          newFile['_alreadyUploaded']=true;
          newFile['_percentUploaded']=100;
          newFile['_guid']=artikelbild.guid;
          newFile['_artikelbild']=artikelbild; // gleich die entity mit ranhängen, erleichtert das Aktualisieren des CRUDItems bei Save
          console.log("WerbeanzeigeDetailAnsichtComponent.sendValuesToForm() adding file to artikelbilderFileUpload.files:", newFile);
          this.artikelbilderFileUpload.files.push(newFile);
        });
        */

        // Titelbild GUID ermitteln und in Galleria das richtig Bild voreinstellen
        if(this.artikelBilderTitlePicGuid == null || this.artikelBilderTitlePicGuid == "00000000") {
          if(this.CRUDItem.titelbild != null) {
            this.artikelBilderTitlePicGuid = this.CRUDItem.titelbild.guid; 
            
            // Galleria
            //let indexOfTitelbild = this.artikelbilderFileUpload.files.findIndex(f => f['_guid'] == this.CRUDItem.titelbild.guid);
            //if(indexOfTitelbild >= 0) this.artikelBilderGalleria.activeIndex = indexOfTitelbild;
          }
        }
      }

      if(this.artikelBilderGalleryImages != null && this.artikelBilderGalleryImages.length > 0) {
        if(this.artikelBilderAlreadySwitchedToGallery == false) {
          this.artikelBilderDisplayGalleryOrUpload = 'G';
          this.artikelBilderAlreadySwitchedToGallery = true;
        }
        //this.artikelBilderTitlePicGuid = this.artikelbilderFileUpload.files[0]['_guid'];
        //console.log("WerbeanzeigeDetailAnsichtComponent.sendValuesToForm() artikelBilderTitlePicGuid:", this.artikelBilderTitlePicGuid);
      }
    }

    try {
      this.CRUDForm.patchValue({
        bezeichnung: this.CRUDItem.bezeichnung,
        beschreibung: this.CRUDItem.beschreibung,
        artikelnummer: this.CRUDItem.artikelnummer,
        baustelle: this.CRUDItem.baustelle,
        strasse: this.CRUDItem.strasse,
        postleitzahl: this.CRUDItem.postleitzahl,
        ort: this.CRUDItem.ort,
        preis: this.CRUDItem.preis,
        menge: this.CRUDItem.menge,
        verlademoeglichkeit: this.CRUDItem.verlademoeglichkeit,
        verlademoeglichkeitVermerk: this.CRUDItem.verlademoeglichkeitVermerk,
        gueltigkeit: this.CRUDItem.gueltigkeit != null ? new Date(this.CRUDItem.gueltigkeit) : null, // AM Modi: bei SQL "datetimeoffset"
        baustelle_Gueltigkeit: this.CRUDItem.baustelle_Gueltigkeit != null ? new Date(this.CRUDItem.baustelle_Gueltigkeit) : null, // AM Modi: bei SQL "datetimeoffset"
        aktiv: this.CRUDItem.aktiv,
        email_anzeigen: this.CRUDItem.email_anzeigen,
        lagerbestand: this.CRUDItem.lagerbestand,
        telefon_anzeigen: this.CRUDItem.telefon_anzeigen,
        mobilnummer_anzeigen: this.CRUDItem.mobilnummer_anzeigen,
        ansprechpartner: this.CRUDItem.ansprechpartner,
        zustand: this.CRUDItem.zustand,
        hersteller: this.CRUDItem.hersteller,
        lager: this.CRUDItem.lager,
        //lieferart: this.CRUDItem.lieferart,
        mengeneinheit: this.CRUDItem.mengeneinheit,
        pauschal: this.CRUDItem.pauschal ? "true" : "false", // pauschal soll binary, nicht alpha sein!
        verfuegbarkeit: this.CRUDItem.verfuegbarkeit,
        verkaufseinheit: this.CRUDItem.verkaufseinheit,
        verpackung: this.CRUDItem.verpackung,
        vermerk: this.CRUDItem.vermerk,
  
        artikelbilder: this.CRUDItem.artikelbilder, // Parent+Child

        //_AGBAkzeptiert: null // gar nicht bestücken! Ist nur ich der Form, damit validated werden kann
    });
    if(this.CRUDForm.value._baustelleAnzeigen == null) this.CRUDForm.patchValue({
      _baustelleAnzeigen: false // nur mit false vorbestücken, wenn noch gar nicht bestückt
    });  
  
    }
    catch (e) {
      console.error("WerbeanzeigeDetailAnsichtComponent.sendValuesToForm() ERROR patchValue():", e);
    }

    this.artikelGruppeSelected = this.artikelGruppeFindTreeNodeInChilds(this.CRUDItem.artikelgruppeId);
    this.artikelGruppeBuildBreadCrumb();

    console.log("WerbeanzeigeDetailAnsichtComponent.sendValuesToForm() end ... calling super.sendValuesToForm()");

    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("WerbeanzeigeDetailAnsichtComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  // ArtikelLieferart(en)
  artikelLieferartShowAuswahlPopUp() {
    this.artikelLieferartenDisplayAuswahlPopUp = true;
  }

  artikelLieferartenOnSelected() {
    this.artikelLieferartenSelectedAsString = "";
    this.artikelLieferartenSelected.forEach(artikelLieferart => {
      if(this.artikelLieferartenSelectedAsString.length > 0) this.artikelLieferartenSelectedAsString += ', ';
      this.artikelLieferartenSelectedAsString += artikelLieferart.summary;
    });
    this.artikelLieferartenDisplayAuswahlPopUp = false;

    this.displayMessageForm = {}; // validity für Artikelgruppe neu prüfen
    this.CRUDForm.updateValueAndValidity(); // validity für Artikelgruppe neu prüfen
  }

  // Artikelgruppe
  artikelGruppeShowAuswahlPopUp() {
    this.artikelGruppeDisplayAuswahlPopUp = true;
  }
  artikelGruppePrepareTreeNode(instance: any, level:number, artikelGruppe:IArtikelgruppe) {
    //console.log("WerbeanzeigeDetailAnsichtComponent.prepareArtikelGruppeTreeNode() level/artikelGruppe", level, artikelGruppe.bezeichnung);
    let children:TreeNode[] = [];
    artikelGruppe.inverseArtikelgruppe.forEach(child => {
      children.push(this.artikelGruppePrepareTreeNode(this, level+1, child));
    });
    let newTreeNode:TreeNode = {
      children: children,
      collapsedIcon: "ui-icon-folder",
      data: { // data enthält id und parentId der artikelGruppe
        id: artikelGruppe.id, 
        artikelgruppeId: artikelGruppe.artikelgruppeId
      },
      expandedIcon: "ui-icon-folder-open",
      label: artikelGruppe.bezeichnung /*+ " (id: "+artikelGruppe.id+")"*/,
      key: ""+artikelGruppe.id // ohne den "key" funktioniert die Children-Auswahl NACH FILTER nicht! https://forum.primefaces.org/viewtopic.php?t=58524
    };
    return newTreeNode;
  }

  artikelGruppeFindTreeNodeInChilds(artikelGruppeId:number) { // treeNode finden, egal wie tief verschachtelt (childs)
    //console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeFindTreeNodeInChilds() artikelGruppeId/artikelGruppeTreeNodes:", artikelGruppeId, this.artikelGruppeTreeNodes);
    if(this.artikelGruppeTreeNodes == null) return null;
    let foundTree = this.artikelGruppeTreeNodes.find(f => f.data.id == artikelGruppeId);
    if(foundTree != null) {
      //console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeFindTreeNodeInChilds() found:", foundTree);
      return foundTree; // gefunden !
    }
    else { // nicht gefunden -> childs durchsuchen
      let treesWithChilds = this.artikelGruppeTreeNodes.filter(f => f.children != null && f.children.length > 0);
      for(let i:number = 0; i < treesWithChilds.length; i++ ) { // forEach "not breakable / return"
        let child = treesWithChilds[i];
        let foundTreeInChild:TreeNode = this.artikelGruppeFindTreeNodeInChilds_pt2(1, child, artikelGruppeId);
        if(foundTreeInChild != null) {
          //console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeFindTreeNodeInChilds() found (in children):", foundTreeInChild);
          return foundTreeInChild;
        }
      }
    }
    //console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeFindTreeNodeInChilds() didn't found tree!");
    return null; // nicht gefunden!
  }
  artikelGruppeFindTreeNodeInChilds_pt2(level:number, treeNode:TreeNode, artikelGruppeId:number) { 
    //console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeFindTreeNodeInChilds_pt2() level/treeNode/artikelGruppeId:", level, treeNode, artikelGruppeId);
    let foundTree = treeNode.children.find(f => f.data.id == artikelGruppeId);
    if(foundTree != null) {
      //console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeFindTreeNodeInChilds_pt2() found (in children) level/tree:", level, foundTree);
      return foundTree; // gefunden !
    }
    else { // nicht gefunden -> childs durchsuchen
      let treesWithChilds = treeNode.children.filter(f => f.children != null && f.children.length > 0);
      for(let i:number = 0; i < treesWithChilds.length; i++) { // forEach "not breakable / return"
      let child = treesWithChilds[i];
        let foundTreeInChild:TreeNode = this.artikelGruppeFindTreeNodeInChilds_pt2(level+1, child, artikelGruppeId);
        if(foundTreeInChild != null) {
          //console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeFindTreeNodeInChilds_pt2() found (in children) level/tree:", level, foundTreeInChild);
          return foundTreeInChild;
        }
      }
    }
    //console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeFindTreeNodeInChilds_pt2() didn't found tree!");
    return null; // nicht gefunden!
  }

  artikelGruppeOnSelected(event, thisInstance:any) {
    console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeOnSelected() event/thisInstance", event, thisInstance);
    this.artikelGruppeSelected = event.node;
    this.artikelGruppeDisplayAuswahlPopUp = false;
    this.artikelGruppeBuildBreadCrumb();

    thisInstance.displayMessageForm = {}; // validity für Artikelgruppe neu prüfen
    thisInstance.CRUDForm.updateValueAndValidity(); // validity für Artikelgruppe neu prüfen
  }

  artikelGruppeBuildBreadCrumb() {
    console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeBuildBreadCrumb()");
    this.artikelGruppeBreadCrumbItems = [];
    let item = this.artikelGruppeSelected;
    if(item == null) return;
    this.artikelGruppeBreadCrumbItems.push({label: item.label});
    console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeBuildBreadCrumb() item:", item);
    while(item.data.artikelgruppeId != null) {
      console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeBuildBreadCrumb() searching for id="+item.data.artikelgruppeId+" in this.artikelGruppeItems:", this.artikelGruppeItems);
      let artikelGruppeParent = this.artikelGruppeItems.find(f => f.id == item.data.artikelgruppeId);
      if(artikelGruppeParent != null) {
        item = {
          data: { // data enthält id und parentId der artikelGruppe
            id: artikelGruppeParent.id, 
            artikelgruppeId: artikelGruppeParent.artikelgruppeId
          },
          label: artikelGruppeParent.bezeichnung
        }
        this.artikelGruppeBreadCrumbItems.unshift({label: item.label}); // unshift statt push -> an ANFANG des Arrays!
      }
      else { // parent (warum auch immer) nicht gefunden -> break
        break;
      }
    }
    console.log("WerbeanzeigeDetailAnsichtComponent.artikelGruppeBuildBreadCrumb() artikelGruppeBreadCrumbItems:", this.artikelGruppeBreadCrumbItems);
  }


  formatInputCurrency(inputId:string) {
    $('#' + inputId).val(this.customCurrencyPipe.transform(Number($('#'+ inputId).val()), 'money'));
  }
  formatInputFloat(inputId:string) {
    let formatedValue = this.customCurrencyPipe.retransform($('#'+ inputId).val())
    $('#' + inputId).val(formatedValue);
  }
  validateAndSaveCRUDItem(close: boolean): void {
    //this.messageWrapperService.postTimedMessage({severity:'success', summary: 'Summary Text Timed', detail: 'Detail Text'});
    //this.messageWrapperService.postStaticMessage({severity:'success', summary: 'Summary Text Static', detail: 'Detail Text'});
    this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
    super.validateAndSaveCRUDItem(close);
  }
  debug(obj) {
    //console.log("WerbeanzeigeDetailAnsichtComponent.debug() unternehmen:", this.unternehmen);
    console.log("WerbeanzeigeDetailAnsichtComponent.debug() this._app.benutzer:", this._app.benutzer);
    console.log("WerbeanzeigeDetailAnsichtComponent.debug() this.artikelBilderGalleria:", this.artikelBilderGalleria);
    console.log("WerbeanzeigeDetailAnsichtComponent.debug() this.artikelBilderGalleryImages:", this.artikelBilderGalleryImages);

    super.debug(null);
  }


  saveArtikel() { // NOT IN USE // TO DO
    console.log("WerbeanzeigeDetailAnsichtComponent.saveArtikel() CRUDForm:", this.CRUDForm);
  }


  //alignGalleriaAtBottom() {
    //if(this._app !=null && this._app.inAppMode != true)
    //    this.galleriaFixer.alignGalleriaAtBottom('galleriaFloat', this.artikelBilderGalleryImages, false);
  //}

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  editArtikel() {
    if(this._app !=null) {

    }
    if(this._app.inAppMode == false) {
      if(this.CRUDItem.typ == 'S') {
        this._router.navigate(['/suche', this.dataId], { queryParamsHandling: "merge" });
      }
      else if(this.CRUDItem.typ == 'W') {
        this._router.navigate(['/werbeanzeige', this.dataId], { queryParamsHandling: "merge" });
      }
      else {
        this._router.navigate(['/artikel', this.dataId], { queryParamsHandling: "merge" });
      }
      console.log("Hallo Test");
    } 
    else {
      let eventData = {
        eventType: "artikel-edit",
        data: ""+this.dataId
      };
      this._app.invokeXamarinEvent(eventData);
    }
  }
  telnummerAndEmail(telemailCall:string){
    if(this._app != null && this._app.inAppMode == true){
    if(telemailCall == 'ansprechpartnerTelefon' && this.CRUDItem.ansprechpartner.telefon != null ){
      let telNummerAndEmail = {
        eventType: "call-telnummer",
        data: "" + this.CRUDItem.ansprechpartner.telefon
      } 
      
      //   {
      //     eventType: "send-email",
      //     data: "" + this.CRUDItem.ansprechpartner.email
      //   }
      this._app.invokeXamarinEvent(telNummerAndEmail);
    } else if(telemailCall == 'unternehmenTelefon' && this.unternehmen.telefon != null){
      let telNummerAndEmail = {
        eventType: "call-telnummer",
        data: "" + this.unternehmen.telefon
      }
      this._app.invokeXamarinEvent(telNummerAndEmail);
    }
    else if(telemailCall == 'ansprechpartnerMobil' && this.CRUDItem.ansprechpartner.mobil != null){
      let telNummerAndEmail = {
        eventType: "call-telnummer",
        data: "" + this.CRUDItem.ansprechpartner.mobil
      }
      this._app.invokeXamarinEvent(telNummerAndEmail);
    } 
    else if(telemailCall == 'unternehmenEmail' && this.unternehmen.email != null){
      let telNummerAndEmail = {
        eventType: "send-email",
        data: "" + this.unternehmen.email
      }
      this._app.invokeXamarinEvent(telNummerAndEmail);
    } else if(telemailCall == 'ansprechpartnerEmail' && this.CRUDItem.ansprechpartner.email != null){
      let telNummerAndEmail = {
        eventType: "send-email",
        data: "" + this.CRUDItem.ansprechpartner.email
      }
      this._app.invokeXamarinEvent(telNummerAndEmail);
    }
   
    
    }
  }

  vorschauZurueckClicked() {
    this.zurueckClicked.emit(null);
  }
  vorschauSpeichernClicked() {
    this.saveClicked.emit(null);
  }

  galleriaClickFullscreen() {
    console.log("ArtikelDetailComponent.galleriaClickFullscreen()");
    //this.galleriaFullscreen = true;
  }

  // ----------------------
  changeActivity() {
    if(this.CRUDItemAsParameter != null) {
      // im Vorschaumodus ignorieren!
    }
    else {
      console.log("ArtikelDetailComponent.changeActivity()");
      if(this.app.benutzer == null) {
        alert("Bitte zuerst anmelden!");
        this.isActive = false;
      }
      else {
        // toDo: Disable Icon!
        this.isActive = !this.isActive;
        if(this.isActive == true) {
          let neuerFavorit = this.favoritService.initializeFavorit();
          neuerFavorit.artikelId = this.CRUDItem.id;
          neuerFavorit.benutzerId = this.app.benutzer.id;
          this.favoritService.createFavorit(neuerFavorit).subscribe(function (response) {
            console.log("ArtikelDetailComponent.changeActivity() ... response:", response);
            this.isActiveFavoritId = response.id;
            // toDo: Enable Icon!
          }, function (error) {
            console.log("ArtikelDetailComponent.changeActivity() error:", error);
            //return thisInstance.handleError(error); 
          });
        }
        else {
          let favoritId = this.isActiveFavoritId;
          this.favoritService.deleteFavorit(favoritId).subscribe(
            () => {
              this.isActive = false;
              this.isActiveFavoritId = 0;
              // toDo: Enable Icon!
            },
            (error: any) => {
              console.log("ArtikelDetailComponent.changeActivity() error:", error);
            }
          );
        }
      }
    }
  }
}


