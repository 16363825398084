<div class="p-col-12" style="padding: 0px;">
    <p-carousel [value]="artikel" [numVisible]="6" [numScroll]="3" *ngIf="!hideCarouselImg" [circular]="false" [autoplayInterval]="0"
        [responsiveOptions]="responsiveOptions" class="dashboardContainer">
        <ng-template let-artikel pTemplate="item">

            <!-- Darstellung für Artikel -->
            <div class="p-grid p-nogutter" *ngIf="artikel.typ != 'W'">
                <!--<div *ngIf="debugMode == true && artikel != null" style="color: green;">
                    <small>{{getSortInfo(artikel)}}</small>
                </div>-->
                <div class="p-col-12" id="dashboardImages" 
                    [class.dashboardImages_app]="_app !=null && _app.inAppMode == true"
                    [ngStyle]="_app != null && _app.inAppMode == true && mobileAnsicht ? {'height': '148px'} : '' ">

                    <div class="iconBtn" style="z-index: 1; text-align: right;" [style.visibility]="artikel.typ == 'W' ? 'hidden' : 'unset'">
                        <button (click)="favBtn(artikel, 'carousel')" pButton type="button" class="ui-button-secondary"
                            [style]="{'text-align':'center', 'display': 'contents'}">
                            <i class="fa-star fa-lg"
                                [class.far]="artikel._meinFavorit == null || artikel._meinFavorit == false"
                                [class.fas]="artikel._meinFavorit != null && artikel._meinFavorit == true"
                                style="margin-top: 8px; margin-right: 10px;" id="btnStar"></i></button>
                    </div>
                    <div class="carouselImageDiv"
                    [ngStyle]="_app != null && _app.inAppMode == true && mobileAnsicht
                    ? {'top': '-30px'} : ''"><img id="carouselImage_{{artikel.artikelnummer}}"
                            [class.carouselImageDiv_app]="_app !=null && _app.inAppMode == true"
                            class="carouselImageImg" [ngStyle]="{'bottom': moveImage(artikel.artikelnummer)}"
                            [src]="artikelTitelBildURL(artikel)" (click)="selectArtikel($event,artikel)"
                            (error)="onImgError($event)"></div>
                    <div *ngIf="artikel.typ != 'W'" class="p-col-12" id="dashboardInfo" (click)="selectArtikel($event,artikel)"
                        [class.dashboardInfo_app]="_app !=null && _app.inAppMode == true"
                        [class.dashboardInfoAdvert]="artikel.typ == 'W'"
                        [ngStyle]="_app != null && _app.inAppMode == true && mobileAnsicht ? {'height': 'calc(100% - 15px)'} : ''">
                        <div class="car-title">{{artikel.summary}}</div>
                        <div class="distanceOverImage" style="text-align: left; z-index: 1;" [style.visibility]="artikel.typ == 'W' ? 'hidden' : 'unset'">
                            <span
                                *ngIf="artikel.distance != 0">{{artikel.distance | crudBasic:null:(artikelService.propertyTypes!=null&&artikelService.propertyTypeOf!=null?artikelService.propertyTypeOf('distance'):null)}}
                                km</span>
                            <span *ngIf="artikel.distance == 0">0 km</span> |
                            <span *ngIf="artikel != null && artikel.lager != null && artikel.lager.geodaten != null"
                                style="display: inline-flex;"> {{artikel.lager.geodaten.ort}}</span>
                        </div>
                        <div class="car-subtitle">{{artikel.artikelnummer}}
                        </div>
                        <div class="lieferung" [class.lieferung_App]="_app !=null && _app.inAppMode == true">
                            <i *ngIf="isAbholung(artikel)" class="fa fa-warehouse"
                                style="float: right; margin-top: 3px;" pTooltip="Abholung" tooltipPosition="bottom"></i>
                            <i *ngIf="isLieferung(artikel)" class="fa fa-truck"
                                style="float: right;margin-right: 5px; margin-top: 3px;" pTooltip="Lieferung"
                                tooltipPosition="bottom"></i>
                        </div>
                        {{artikelLieferartenSelectedAsString}}
                    </div>
                    <div *ngIf="artikel.typ == 'W'" class="p-col-12" id="dashboardInfo_Werbung" (click)="selectArtikel($event,artikel)"
                    [innerHTML]="getArtikelbeschreibungForInnerHtml(artikel) | keepHtml"></div>
                </div>
            </div>
            <!-- ENDE Darstellung für Artikel -->

            <!-- Darstellung für Werbeanzeigen -->
            <div class="p-grid p-nogutter" *ngIf="artikel.typ == 'W'" (click)="selectArtikel($event,artikel)">
                <app-werbeanzeige-darstellung-carousel [CRUDItem]="artikel">
                </app-werbeanzeige-darstellung-carousel>
            </div>
            <!-- ENDE Darstellung für Werbeanzeigen -->

        </ng-template>
    </p-carousel>
    <div class="openCloseCarousel" style="width: 100%;" *ngIf="_app !=null && _app.inAppMode == true && mobileAnsicht">
        <div (click)="hideCarousel()"style="margin-left: 50%; width: 100%; font-size: 21px; color: #99cc33">
            <i class="far fa-chevron-up" style="font-weight: bold;" *ngIf="!hideCarouselImg"></i>
            <i class="far fa-chevron-down" style="font-weight: bold;" *ngIf="hideCarouselImg"></i>
        </div>

    </div>

    <div id="virtualscrollerDiv" *ngIf="_app !=null && _app.inAppMode == true"
        style="height: auto; width: 100vw; overscroll-behavior: none; display: contents !important;">
        <p-virtualScroller *ngIf="_app !=null && _app.inAppMode == true && scrollHeight != null"
            [style]="{'overscroll-behavior': 'none', 'width': '100%'}" [value]="virtualScrollerItems"
            [scrollHeight]="scrollHeight" [itemSize]="250" [rows]="rows" [lazy]="true"
            (onLazyLoad)="loadDataVirtualScroller($event)">
            <ng-template let-artikel pTemplate="item">
              <div
                [style.height]="'250px'"
                [style.maxHeight]="'250px'"
                [style.borderBottom]="'1px solid lightgrey'"
                [style.background]="debugMode == true ? 'yellow' : null"
                ><!-- ridge / solid -->               

                <!-- Darstellung Artikel -->  
                <div class="p-grid containerDashboardTablet" *ngIf="artikel.typ != 'W'"
                [style.height]="'230px'"
                [style.maxHeight]="'230px'"
                [style.margin]="'10px'"
                [style.background]="debugMode == true ? 'lightgreen' : null">
                    <div class="ui-md-6 " *ngIf="!mobileAnsicht">

                        &nbsp;<br>
                        <div class="artikelUebersichtImageVirtualscrollerDiv"><img
                                (click)="selectArtikel($event, artikel)" class="artikelUebersichtImageImgDashboard"
                                [src]="artikelTitelBildURL(artikel)" (error)="onImgError($event)">

                            <div class="iconBtn" style="z-index: 1; text-align: right;">
                                <button (click)="favBtn(artikel, 'scroller')" pButton type="button"
                                    class="ui-button-secondary"
                                    [style]="{'text-align':'center', 'display': 'contents'}">
                                    <i class="fa-star fa-lg"
                                        [class.far]="artikel._meinFavorit == null || artikel._meinFavorit == false"
                                        [class.fas]="artikel._meinFavorit != null && artikel._meinFavorit == true"
                                        style="margin-top: 8px; margin-right: 10px;" id="btnStar"></i></button>
                            </div>

                            <div class="" class="uebersichtInfo" (click)="selectArtikel($event, artikel)">
                            </div>
                        </div>

                    </div>

                    <div class="ui-g-12 ui-md-6" *ngIf="!mobileAnsicht">
                        &nbsp;<br>
                        {{artikel != null ? artikel.bezeichnung: ''}}

                        <div>
                            <p *ngIf="artikel != null && artikel.pauschal == false"
                            style="font-size: 1em; color: green; display: table-cell">
                            {{(artikel != null ? artikel.preis : '') | crudBasic:null:(artikelService.propertyTypes!=null&&artikelService.propertyTypeOf!=null?artikelService.propertyTypeOf('preis'):null)}}
                            pro
                            {{artikel != null && artikel.mengeneinheit != null ? artikel.mengeneinheit.summary : ''}},
                            {{artikel != null ? artikel.menge : ''}}
                            {{artikel != null && artikel.mengeneinheit != null ? artikel.mengeneinheit.summary : ''}}
                            verfügbar</p>
                        <p *ngIf="artikel != null && artikel.pauschal == true"
                            style="font-size: 1em; color: green; display: table-cell">
                            {{(artikel != null ? artikel.preis : '') | crudBasic:null:(artikelService.propertyTypes!=null&&artikelService.propertyTypeOf!=null?artikelService.propertyTypeOf('preis'):null)}}
                            für {{artikel != null ? artikel.menge : ''}}
                            {{artikel != null && artikel.mengeneinheit != null ? artikel.mengeneinheit.summary : ''}}
                        </p>

                        <div *ngIf="artikel != null" class="p-grid" style="font-size: 1em;">
                            <div class="ui-g-12 ui-md-6" style="padding-bottom: 0">Hersteller:</div>
                            <div class="ui-g-12 ui-md-6" style="padding-bottom: 0">
                                {{artikel != null && artikel.hersteller != null ? artikel.hersteller.summary : ''}}
                            </div>
                            <div class="ui-g-12 ui-md-6" style="padding-bottom: 0; padding-top: 0">Produktzustand:</div>
                            <div class="ui-g-12 ui-md-6" style="padding-bottom: 0; padding-top: 0">
                                {{artikel != null && artikel.zustand != null ? artikel.zustand.summary : ''}}</div>
                            <div class="ui-g-12 ui-md-6" style="padding-bottom: 0; padding-top: 0">Artikelnummer:</div>
                            <div class="ui-g-12 ui-md-6" style="padding-bottom: 0; padding-top: 0;">
                                {{artikel != null ? artikel.artikelnummer : ''}}</div>
                            <div class="ui-g-12 ui-md-6" style="padding-bottom: 0; padding-top: 0">Lieferung:</div>
                            <div class="ui-g-12 ui-md-6" style=" padding-bottom: 0; padding-top: 0">
                                <i *ngIf="isLieferung(artikel)" class="fa fa-truck" style="color: #808080; transform: scaleX(-1); margin-right: 5px;" pTooltip="Lieferung"
                                    tooltipPosition="bottom"></i>
                                    <i *ngIf="isAbholung(artikel)" class="fa fa-warehouse" pTooltip="Abholung"
                                    tooltipPosition="bottom" style="color: #808080;"></i>
                            </div>
                            <div class="ui-g-12 ui-md-6" style="padding-bottom: 0; padding-top: 0">Ort:</div>
                            <div class="ui-g-12 ui-md-6" style="padding-bottom: 0; padding-top: 0;">
                                <div *ngIf="artikel != null && artikel.distance != 0" style="overflow: hidden; text-overflow: ellipsis; -o-text-overflow: ellipsis; white-space: nowrap; width: 100%">
                                    {{artikel.distance | crudBasic:null:(artikelService.propertyTypes!=null&&artikelService.propertyTypeOf!=null?artikelService.propertyTypeOf('distance'):null)}}
                                    km | {{artikel.lager != null ? artikel.lager.geodaten.ort : ''}}</div>
                                <span *ngIf="artikel != null && artikel.distance == 0" >0 km | {{artikel.lager != null ? artikel.lager.geodaten.ort : ''}}</span> 
                                <!-- <span *ngIf="artikel != null && artikel.lager != null && artikel.lager.geodaten != null"></span> -->
                            </div>
                        </div>

                        <div>
                            <p *ngIf="artikel.beschreibung != null && artikel.beschreibung.length > 0" class="textRight artikelBeschreibung" style="display: table-cell;"
                                [innerHTML]="artikel.beschreibung"></p>
                        </div>

                    </div>
                </div>

                </div>

                <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!Für die Handyansicht!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->

                <div class="p-grid containerDashboardHandyAnsicht" *ngIf="mobileAnsicht">

                    <div class="p-col-12" *ngIf="mobileAnsicht">


                        <!--, 'background-color': 'yellow'-->
                        <div class="artikelUebersichtImageVirtualscrollerDivHandy"><img
                                (click)="selectArtikel($event, artikel)" class="artikelUebersichtImageImgDashboardHandy"
                                [src]="artikelTitelBildURL(artikel)" (error)="onImgError($event)">

                            <div class="iconBtn" style="z-index: 1; text-align: right;">
                                <button (click)="favBtn(artikel, 'scroller')" pButton type="button"
                                    class="ui-button-secondary"
                                    [style]="{'text-align':'center', 'display': 'contents'}">
                                    <i class="fa-star fa-lg"
                                        [class.far]="artikel._meinFavorit == null || artikel._meinFavorit == false"
                                        [class.fas]="artikel._meinFavorit != null && artikel._meinFavorit == true"
                                        style="margin-top: 40px; margin-right: 10px;" id="btnStar"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="p-col-12 contentContainerDashboardHandyAnsicht" *ngIf="mobileAnsicht" style="overflow: hidden; text-overflow: ellipsis; -o-text-overflow: ellipsis; white-space: nowrap; width: 95%;">
                        {{artikel != null ? artikel.bezeichnung: ''}}

                        <p *ngIf="artikel != null && artikel.pauschal == false"
                            style="font-size: 1em; color: green; display: table-cell">
                            {{(artikel != null ? artikel.preis : '') | crudBasic:null:(artikelService.propertyTypes!=null&&artikelService.propertyTypeOf!=null?artikelService.propertyTypeOf('preis'):null)}}
                            pro
                            {{artikel != null && artikel.mengeneinheit != null ? artikel.mengeneinheit.summary : ''}},
                            {{artikel != null ? artikel.menge : ''}}
                            {{artikel != null && artikel.mengeneinheit != null ? artikel.mengeneinheit.summary : ''}}
                            verfügbar</p>
                        <p *ngIf="artikel != null && artikel.pauschal == true"
                            style="font-size: 1em; color: green; display: table-cell">
                            {{(artikel != null ? artikel.preis : '') | crudBasic:null:(artikelService.propertyTypes!=null&&artikelService.propertyTypeOf!=null?artikelService.propertyTypeOf('preis'):null)}}
                            für {{artikel != null ? artikel.menge : ''}}
                            {{artikel != null && artikel.mengeneinheit != null ? artikel.mengeneinheit.summary : ''}}
                        </p>

                        <div *ngIf="artikel != null" class="p-grid" style="font-size: 1em;">
                            <div class="ui-g-12 p-col-6" style="padding-bottom: 0">Hersteller:</div>
                            <div class="ui-g-12 p-col-6 p-col-6" style="padding-bottom: 0;overflow: hidden; text-overflow: ellipsis; -o-text-overflow: ellipsis; white-space: nowrap; width: 50%">
                                {{artikel != null && artikel.hersteller != null ? artikel.hersteller.summary : ''}}
                            </div>
                            <div class="ui-g-12 p-col-6" style="padding-bottom: 0; padding-top: 0">Produktzustand:</div>
                            <div class="ui-g-12 p-col-6" style="padding-bottom: 0; padding-top: 0">
                                {{artikel != null && artikel.zustand != null ? artikel.zustand.summary : ''}}</div>
                            <div class="ui-g-12 p-col-6" style="padding-bottom: 0; padding-top: 0;">Artikelnummer:</div>
                            <div class="ui-g-12 p-col-6" style="padding-bottom: 0; padding-top: 0;overflow: hidden; text-overflow: ellipsis; -o-text-overflow: ellipsis; white-space: nowrap; width: 50%;">
                                {{artikel != null ? artikel.artikelnummer : ''}}</div>
                            <div class="ui-g-12 p-col-6" style="padding-bottom: 0; padding-top: 0">Lieferung:</div>
                            <div class="ui-g-12 p-col-6" style=" padding-bottom: 0; padding-top: 0">
                                <i *ngIf="isLieferung(artikel)" class="fa fa-truck" style="color: #808080; transform: scaleX(-1); margin-right: 5px;" pTooltip="Lieferung"
                                    tooltipPosition="bottom"></i>
                                    <i *ngIf="isAbholung(artikel)" class="fa fa-warehouse" pTooltip="Abholung"
                                    tooltipPosition="bottom" style="color: #808080;"></i>
                            </div>
                            <div class="ui-g-12 p-col-6" style="padding-bottom: 0; padding-top: 0">Ort:</div>
                            <div class="ui-g-12 p-col-6" style="padding-bottom: 0; padding-top: 0">
                                <div *ngIf="artikel != null && artikel.distance != 0" style="overflow: hidden; text-overflow: ellipsis; -o-text-overflow: ellipsis; white-space: nowrap; width: 95%;">
                                    {{artikel.distance | crudBasic:null:(artikelService.propertyTypes!=null&&artikelService.propertyTypeOf!=null?artikelService.propertyTypeOf('distance'):null)}}
                                    km | {{artikel.lager.geodaten.ort}}</div>
                                <span *ngIf="artikel != null && artikel.distance == 0">0 km | {{artikel.lager.geodaten.ort}} </span> 
                                
                            </div>


                        </div>


                    </div>

                </div>
              <!-- Ende Darstellung Artikel -->

              <!-- Darstellung Werbeanzeige -->  
              <div *ngIf="artikel.typ == 'W'"
              [style.height]="'250px'"
              [style.maxHeight]="'250px'"
              [style.marginLeft]="'10px'"
              [style.marginRight]="'10px'"
              [style.background]="debugMode == true ? 'green' : null"
              >
                    <app-werbeanzeige-darstellung-scroller [CRUDItem]="artikel">
                    </app-werbeanzeige-darstellung-scroller>
              </div>
              <!-- Ende Darstellung Artikel -->                                         

              <!--<hr style="color:#eeeeee; margin-top: -7px;">-->
              </div>
            </ng-template>
            <ng-template let-artikel pTemplate="loadingItem">
                <p-progressSpinner [style]="{width: '2em', height: '2em'}" strokeWidth="2"></p-progressSpinner>
            </ng-template>
        </p-virtualScroller>
    </div>
</div>
<div class="p-grid" *ngIf="_app !=null && _app.inAppMode == false">
    <div class="p-lg-6 p-md-6">
        <div class="timeLine">
            <!--<div class="timeline">
                <div class="timelineBody">
                    <div *ngFor="let element of timelineElements" class="timeline-item">
                        <p class="time">jetzt</p>
                        <div class="timelineContent">
                            <h3 class="title" style="color: black;">{{ element.ueberschrift }}</h3>
                            <p style="margin-bottom: 0px;" class="timelineTxt"><span
                                    *ngIf="element.showFull == false">{{ element.shortText }}</span><span
                                    *ngIf="element.showFull == true">{{ element.fullText }}</span></p>

                            <a (click)="element.showFull = !element.showFull"
                                style="text-decoration: underline; color: #99cc33; cursor: pointer;">{{ element.showFull ? "Weniger Anzeigen" : "Mehr Anzeigen" }}</a>
                        </div>
                    </div>
                </div>
            </div>-->
            <div style="margin: 10px; margin-top: 15px;">
            <app-artikel-scroller></app-artikel-scroller>
        </div>
        </div>

    </div>
    <div class="p-col-12 p-lg-6 p-md-6">
        <div class="p-lg-12 p-md-12" style="padding: 0px;">
            <!-- hier kommt p-md-6 -->
            <div class="FirmenEingenschaftenContainer">
                <div class="p-field p-col">
                    <p style="text-align: center;">Hier finden Sie eine Übersicht über Ihren Lagerbestand, Ihre
                        eingestellten Artikel, wie auch über Ihre
                        Favoriten.</p>
                    <br>
                    <p id="AktiveAngeboteFirmenEigenschaften" style="font-weight: bold;">Aktive Artikel:<span
                            style="width: 6.3em; line-height: 30px; padding-left: 1px; text-align: center;" id="spanFavoritenFirmenEigenschaften"
                            (click)="routeToMyActive()">{{counters!=null?counters.aktive:''}}
                            <i class="pi pi-chevron-right btnChevronRightDashboard" style="font-size: 2em"></i></span>
                    </p>
                    <p id="FavoritenFirmenEigenschaften" style="font-weight: bold;">Lagerbestand:<span
                            style="width: 6.3em; line-height: 30px; padding-left: 1px; text-align: center;" id="spanFavoritenFirmenEigenschaften"
                            (click)="routeToMyStock()">{{counters!=null?counters.lagerbestand:''}}
                            <i class="pi pi-chevron-right btnChevronRightDashboard" style="font-size: 2em"></i></span>
                    </p>
                    <p id="FavoritenFirmenEigenschaften" style="font-weight: bold;">Meine Artikel:<span
                            style="width: 6.3em; line-height: 30px; padding-left: 1px; text-align: center;" id="spanFavoritenFirmenEigenschaften"
                            (click)="routeToMyItems()">{{counters!=null?counters.gesamt:''}}
                            <i class="pi pi-chevron-right btnChevronRightDashboard" style="font-size: 2em"></i></span>
                    </p>
                    <p id="FavoritenFirmenEigenschaften" style="font-weight: bold;">Favoriten:<span
                            style="width: 6.3em; line-height: 30px; padding-left: 1px; text-align: center;" id="spanFavoritenFirmenEigenschaften"
                            (click)="routeToMyFavorites()">{{counters!=null?counters.favoriten:''}}
                            <i class="pi pi-chevron-right btnChevronRightDashboard" style="font-size: 2em"></i></span>
                    </p>
                </div>
            </div>

        </div>
        <div class="InputContainerNeuenArtikelEinstellen">
            <form [formGroup]="newArtikelForm" #form (ngSubmit)="newArtikelCreate()" novalidate>
                <h3 id="neuenArtikelEinstellen" style="color: #000000;">Neuen Artikel einstellen</h3><br><br>
                <label for="InputFieldArtikelbezeichung" id="InputFieldArtikelbezeichnung"
                    style="font-weight: bold;">Artikelbezeichung:</label><br>
                <input id="inputArtikelbezeichnung" type="text" style="width: 100%; margin-bottom: 10px;"
                    formControlName="newArtikelBezeichnung" pInputText>
                <br>
                <label for="firstname3" id="InputFieldArtikelnummer"
                    style="font-weight: bold;">Artikelnummer:</label>
                <input id="inputArtikelnummer"  type="text" style="width: 100%; margin-bottom: 10px;"
                    formControlName="newArtikelArtikelnummer" pInputText>
                <br>
                <!--<label for="hersteller4" id="HerstellerLabelNeuenArtikelEinstellen"
                style="font-weight: bold;">Hersteller:</label>
            <p-dropdown [options]="cities1" id="dashboardDropdown" [(ngModel)]="cities1" [style]="{'width': '100%'}">
            </p-dropdown>-->
            
                
                    <label for="hersteller" id="herstellerLabel"><b>{{'Hersteller'|translate:true}}:
                        </b></label><br>
                    <crud-basic-autocomplete [formGroup]="newArtikelForm" style="width: 100%;"
                        formControlName="newArtikelHersteller" id="hersteller" [required]="true" [type]="'hersteller'"
                        [allowUnvalidatedInput]="true">
                    </crud-basic-autocomplete>
                
                <br><br>
                <button type="submit" style="color: #000000;" class="ArtikelAnlegenBtn" pButton
                    label="Artikel anlegen"></button>
            </form>
        </div>
    </div>
</div>

<p-carousel *ngIf="_app != null && _app.inAppMode == false" [value]="rss" [autoplayInterval]="10000" [circular]="true" [responsiveOptions]="responsiveOptions" class="rssCarousel" 
showIndicators="false">
    <ng-template let-rss pTemplate="item">

                <b>{{rss.title}}</b> ({{rss.publishDate | datumOhneZeit}})<br>
                <p style="word-wrap: break-word;">{{rss.summary}}</p>
                <a href="{{rss.link}}" target="_blank">weiter lesen ...</a>

    </ng-template>
</p-carousel>