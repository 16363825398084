import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable'; //TODO

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from './translate.service'; //TODO

export const httpOptions =
  new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });

  @Injectable(/*{
    providedIn: 'root'
  }*/)
  export class CRUDBasicService {

  apiBaseUrl: string;

  constructor(
    /*private*/protected httpClient: HttpClient,
    protected translate: TranslateService,
    private config: AppconfigService) {
    this.apiBaseUrl = this.config.get('apiBaseUrl');
    //this.apiBaseUrl = "https://localhost:44354/api/"
  }
  
  propertyTypeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property;
    else return null;    
  }
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    //{name: 'myField', type: 'decimal', format: '$'},
  ];


  /*private*/protected handleError(error) {
    console.log("CRUDBasicService.handleError error: ", error);
    return observableThrowError(error);
    /*
    if(error.status === 422 && error.error.DbUpdateException) { // Modi: ohne das: webpack-error!
      return observableThrowError(error);
    }
    else if (error.status === 422 || error.status === 401) {
      if (error.status === 422) {
        //console.log("CRUDBasicService.handleError error: ", error);
      }
      return Observable.throw(error.Concurrency || error.DbUpdateException || error.Error || 'Server Error');
    }
    return Observable.throw(error.error || 'Server error')
    */
  }
}
