import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChange, SimpleChanges, Inject, forwardRef, Injector, ElementRef } from '@angular/core';
import { Checkbox } from 'primeng/checkbox';
import { TranslateService } from '../_services/translate.service';
import { AppComponent } from '../app.component';

import { MessageWrapperService } from '../_services/message-wrapper.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TriStateCheckbox } from 'primeng/tristatecheckbox';

@Component({
  selector: 'crud-basic-checkbox',
  templateUrl: './crud-basic-checkbox.component.html',
  styleUrls: ['./crud-basic-checkbox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CRUDBasicCheckboxComponent),
      multi: true
    }
  ]
})
export class CRUDBasicCheckboxComponent implements OnInit, ControlValueAccessor  {
  // die Komponente kann direkt formControlName arbeiten:
  //       https://alligator.io/angular/custom-form-control/
  //       https://netbasal.com/angular-custom-form-controls-made-easy-4f963341c8e2
  @Input('nullable') nullable: boolean; 
  @Input('label') label: string; 
  @Input('disabled') disabled: boolean; 
  @Input('ngModel') ngModel: boolean; // das ist keine vollständige ngModel Implementation! Aber ausreichend für diesen Zweck: in CRUD List wird das so (nur zur Anzeige) genutzt.
  //@Output('change') change = new EventEmitter();

  value?: boolean;

  onChange_callbackFunction : any; // bekommt die function von Angular forms - wenn formControlName - die bei Änderung gecallt werden muss.
  onChange_lastValue : any; // enthält den jeweils zuletzt rückgemeldeten Wert - als Vergleich/Abfangmechanismus, um nicht unnötig oft zu callen
  //onTouch_callbackFunction : any; // bekommt die function von Angular forms - wenn formControlName - die bei Touch gecallt werden muss.

  @ViewChild('tricheckbox', { static: true }) public tricheckboxControl: TriStateCheckbox;
  @ViewChild('checkbox', { static: true }) public checkboxControl: Checkbox;

  constructor(
    @Inject( forwardRef(() => AppComponent)) public app: AppComponent,
    private injector: Injector,
    private translate: TranslateService,
    private messageWrapperService: MessageWrapperService
  ) { 
  }

  ngOnInit() {
    this.nullable = false; // TJ sagt wir brauchen keine nullable ...
 
    if(this.ngModel != null) {  
      this.value = this.ngModel;
    }

  }

  // aus ControlValueAccessor Interface: patchValue/setValue/new FormControl soll Wert im HTML aktualisieren
  writeValue( obj : any ) : void {
    //console.log("CRUDBasicCheckboxComponent.writeValue() checkboxControl/obj:", this.checkboxControl, obj);
    // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
    
    // .writeValue führt zumindest bei checkBox in kaufvertrag-kaufpreisrate-detail.component.html zu einem primeNg internen Fehler!
    /*
    if(this.nullable) this.tricheckboxControl.writeValue(obj);
    else this.checkboxControl.writeValue(obj);
    */
    this.value = obj;
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onChange" aufrufen sollen.
  // die zunächst NUR MERKEN, ggf. rufen wir die (siehe onChange()) auf
  registerOnChange(fn: (rating: number) => void): void {
    //console.log("CRUDBasicCheckboxComponent.registerOnChange() fn:", fn);
    this.onChange_callbackFunction = fn;
  }

  onChange() {
    //console.log("CRUDBasicCheckboxComponent.onChange() event:", event);
    //if(this.value != this.onChange_lastValue) { // nur wenn der Wert != dem zuletzt gemeldeten Wert ist (doppel-Rückmeldungen vermeiden! Performance!)
      //console.log("CRUDBasicCheckboxComponent.onChange() calling callback!");
      this.onChange_callbackFunction(this.value);
      this.onChange_lastValue = this.value;
    //}
    //else {
      //console.log("CRUDBasicCheckboxComponent.onChange() skip, since it's the same option as last time!");
    //}    
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onTouch" aufrufen sollen. 
  registerOnTouched(fn: () => void): void {
    //console.log("CRUDBasicCheckboxComponent.registerOnTouched() fn:", fn);
    // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
    if(this.nullable) this.tricheckboxControl.registerOnTouched(fn);
    else this.checkboxControl.registerOnTouched(fn);
    //this.onTouch_callbackFunction = fn;
  }

  // Angular forms ruft diese Funktion, wenn sich der disabled-Status ändert.
  setDisabledState(isDisabled: boolean): void {
    //console.log("CRUDBasicCheckboxComponent.setDisabledState() isDisabled:", isDisabled);
    // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
    if(this.nullable) this.tricheckboxControl.setDisabledState(isDisabled);
    else this.checkboxControl.setDisabledState(isDisabled);
  }


  handleCRUDItemChange(event) {
    //console.log("CRUDBasicCheckboxComponent.handleCRUDItemChange() event:", event);
    this.onChange(); // Veränderung an parent-Comp. melden!
    return;
  }

  handleError(error: any) {
    //this.loading = false;
    //this.blockedDocument = false;
    let errorMessage: string = "";

    let summary = this.translate.instant('Fehler', true);

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      errorMessage = "Unauthorized";
      //this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      errorMessage = error.message;
    }

    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: errorMessage });
  }
}
