import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, SimpleChange, SimpleChanges, Inject, forwardRef, Injector, ViewChildren } from '@angular/core';
import { AutoComplete, LazyLoadEvent } from 'primeng/primeng';
//import { TranslateService } from '../_services/translate.service'; //TODO
import { AppComponent } from '../app.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MessageWrapperService } from '../_services/message-wrapper.service';
import { HerstellerService } from '../_services/hersteller.service';
import { OberflaecheService } from '../_services/oberflaeche.service';
import { FarbeService } from '../_services/farbe.service';
import { KanteService } from '../_services/kante.service';
import { LagerService } from '../_services/lager.service';
import { MengeneinheitService } from '../_services/mengeneinheit.service';
import { ArtikelzustandService } from '../_services/artikelzustand.service';
import { LieferartService } from '../_services/lieferart.service';
import { AnsprechpartnerService } from '../_services/ansprechpartner.service';
import { VerkaufseinheitService } from '../_services/verkaufseinheit.service';
import { VerpackungService } from '../_services/verpackung.service';
import { UnternehmenService } from '../_services/unternehmen.service';
import { GeodatenService } from '../_services/geodaten.service';
import { LizenzartService } from '../_services/lizenzart.service';
import { BenutzerService } from '../_services/benutzer.service';

@Component({
  //selector: 'div[crud-basic-autocomplete]',
  selector: 'crud-basic-autocomplete',
  templateUrl: './crud-basic-autocomplete.component.html',
  styleUrls: ['./crud-basic-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CRUDBasicAutocompleteComponent),
      multi: true
    }
  ]
})
export class CRUDBasicAutocompleteComponent implements OnInit, ControlValueAccessor {
  // die Komponente kann direkt formControlName arbeiten:
  //       https://alligator.io/angular/custom-form-control/
  //       https://netbasal.com/angular-custom-form-controls-made-easy-4f963341c8e2
  @Input('type') type: string;
  @Input('choiceListServiceInstance') choiceListServiceInstance: any; // falls type = "_CHOICELIST": Werte direkt über call auf choiceListMethod holen
  @Input('choiceListMethod') choiceListMethod: any; // falls type = "_CHOICELIST": Werte direkt über call auf choiceListMethod holen
  @Input('required') required: boolean;
  @Input('placeholder') placeholder: string;
  @Input('inputStyle') inputStyle: string;
  //@Input('disabled') disabled: boolean; 
  //@Output('change') change = new EventEmitter();
  @Input('disabled') disabled: boolean;
  @Input('debug') debug: boolean = false; // debug ?
  //Optionaler Parameter. Hier kann die Liste von Optionen vordefiniert werden, wenn man nicht alle Datensätze eines Typs zur Auswahl
  @Input('options') options: any[];
  //Optionaler Parameter. Hier kann eine callback-Funktion hinterlegt werden, die die options nach dem retrieved nochmal filtern
  // Callback-Funktion als Parameter an Component übergeben: https://stackoverflow.com/questions/35328652/angular-pass-callback-function-to-child-component-as-input-similar-to-angularjs
  @Input('filterFunction') filterFunction: (CRUDItems: any[]) => any[];
  @Input('allowUnvalidatedInput') allowUnvalidatedInput: boolean;
  @Input('size') size: number;
  

  debugMode: boolean = false || this.debug;

  onChange_callbackFunction: any; // bekommt die function von Angular forms - wenn formControlName - die bei Änderung gecallt werden muss.
  onChange_lastOption: any; // enthält den jeweils zuletzt rückgemeldeten Wert - als Vergleich/Abfangmechanismus, um nicht unnötig oft zu callen
  //onTouch_callbackFunction : any; // bekommt die function von Angular forms - wenn formControlName - die bei Touch gecallt werden muss.


  crudItemService: any;
  crudItemMethodGetAll: string;
  crudItemMethodInitialize: string; // wird nur benötigt, wenn "allowUnvalidatedInput"
  crudItemResultName: string;
  crudItemMethodSignature: string = 'pN_pS_sQ'; // pageNumber, pageSize, searchQuery
  crudItemResultLabelFeld: string = "bezeichnung";
  crudItemResultFilter: string = null; // spezielle Filter - nur bei bestimmten Typen verwendet, z.B. aspnetuser_imkeonly
  crudItemResultProperty: string = null; // z.B. benutzer simuliert 'benutzer', liest aber aus aspnetusers. Dann muss als Result aber der Benutzer = apsnetuser.imkeBenutzer zurückgegeben werden!
  crudItemSearchDialog: boolean = false; // statt dropdown (wie in AutoComplete üblich) -> Suchdialog!
  crudItemSearchDialogTitle: string = ''; // Titel im Suchdialog!
  crudItemSearchDialogLabel: string = 'summary'; // Label (und Suche) für den Dialog
  crudItemSearchDialogLabel2: string = null; // Label2 für den Dialog
  crudItemMinLength: number = 1; // ab wieviel Zeichen Eingabe soll die autocomplete aufklappen ?

  inputOptionAlreadyWritten: boolean = true;
  inputOptionValue: /*string*/any = null;

  @ViewChild('autoComplete', { static: true }) public autoCompleteControl: AutoComplete; // #WAITFORVIEWCHILD NICHT MEHR VERWENDET, weil über static: true haben wir das "frühere" Verhalten: ViewChild schon während ngOnInit initialisieren: https://www.thecodecampus.de/blog/angular-viewchild-static-property-in-ng8/
  @ViewChild('divProgressSpinner') public divProgressSpinnerControl: any;
  @ViewChild('eraseButton') public eraseButtonControl: any;
  // alle Aktionen auf das ViewChild "autoComplete" erst machen, wenn das tatsächlich verfügbar ist! // #WAITFORVIEWCHILD
  //catchUpRegisterOnTouched: any // #WAITFORVIEWCHILD

  option: any;
  optionOnFocus: any;
  filteredOptions: any[];

  inputHasFocus: boolean = false; // unser AutoComplete hat aktuell focus ?
  nativeInputElement: any = null; // das native <Input> Element (wird bei handleCRUDItemFocus() bestückt)

  showProgressSpinner: boolean = false; // 

  //dropdownIcon_default = "pi pi-chevron-down";
  dropdownIcon_default = "pi pi-caret-down";
  dropdownIcon_loading = "pi pi-spinner";
  dropdownIcon = this.dropdownIcon_loading; //#WAITFORVIEWCHILD dropdownIcon = wait als Ersatz für früheren loading ...

  dialog: boolean = false; // Suchdialog (statt Dropdown) ist in Verwendung (wurde schonmal geöffnet)
  dialogVisible: boolean = false; // Suchdialog (statt Dropdown) ist sichtbar
  dialogSearchValue: string = null;
  dialogOptionOnFocusSaveBeforeOpeningDialog: any;

  constructor(
    @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
    private injector: Injector,
    //private translate: TranslateService,
    private messageWrapperService: MessageWrapperService
  ) {
  }

  ngOnInit() {
    //console.log(this.required)
    // grosse Teile der Logik nach ngAfterViewInit() verlagert, weil sonst der @ViewChild('autoComplete') noch undefined ist! // #WAITFORVIEWCHILD
        // console.log("CRUDBasicAutoComplete.ngOnInit() type:", this.type);
        switch (this.type) {
          case '_CHOICELIST': {
            this.crudItemService = this.choiceListServiceInstance;
            this.crudItemMethodGetAll = this.choiceListMethod;
            this.crudItemResultName = "choiceListEntries";
            this.crudItemMethodSignature = 'none'; // no parameter!
            //this.debugMode = true;
            break;
          }
    
          case 'hersteller': {
            this.crudItemService = this.injector.get(HerstellerService);
            this.crudItemMethodGetAll = 'getHerstellerCollectionForThisUser';
            this.crudItemMethodInitialize = 'initializeHersteller'; // wird nur benötigt, wenn "allowUnvalidatedInput"
            this.crudItemResultName = "hersteller";

            //this.debug = true;
            
            break;
          }

          case 'oberflaeche': {
            this.crudItemService = this.injector.get(OberflaecheService);
            this.crudItemMethodGetAll = 'getOberflaechenCollectionForThisUser';
            this.crudItemMethodInitialize = 'initializeOberflaeche'; // wird nur benötigt, wenn "allowUnvalidatedInput"
            this.crudItemResultName = "oberflaechen";

            //this.debug = true;
            
            break;
          }
          case 'farbe': {
            this.crudItemService = this.injector.get(FarbeService);
            this.crudItemMethodGetAll = 'getFarbenCollectionForThisUser';
            this.crudItemMethodInitialize = 'initializeFarbe'; // wird nur benötigt, wenn "allowUnvalidatedInput"
            this.crudItemResultName = "farben";

            //this.debug = true;
            
            break;
          }
          case 'kante': {
            this.crudItemService = this.injector.get(KanteService);
            this.crudItemMethodGetAll = 'getKantenCollectionForThisUser';
            this.crudItemMethodInitialize = 'initializeKante'; // wird nur benötigt, wenn "allowUnvalidatedInput"
            this.crudItemResultName = "kanten";

            //this.debug = true;
            
            break;
          }

          case 'lager': {
            this.crudItemService = this.injector.get(LagerService);
            this.crudItemMethodGetAll = 'getLagerCollectionForThisUser';
            this.crudItemResultName = "lager";
            break;
          }

          case 'lieferart': {
            this.crudItemService = this.injector.get(LieferartService);
            this.crudItemMethodGetAll = 'getLieferartenCollectionForThisUser';
            this.crudItemResultName = "lieferarten";
            break;
          }

          case 'mengeneinheit': {
            this.crudItemService = this.injector.get(MengeneinheitService);
            this.crudItemMethodGetAll = 'getMengeneinheitenCollection';
            this.crudItemResultName = "mengeneinheiten";
            break;
          }

          case 'artikelzustand': {
            this.crudItemService = this.injector.get(ArtikelzustandService);
            this.crudItemMethodGetAll = 'getArtikelzustaendeCollection';
            this.crudItemResultName = "artikelzustaende";
            break;
          }

          case 'verkaufseinheit': {
            this.crudItemService = this.injector.get(VerkaufseinheitService);
            this.crudItemMethodGetAll = 'getVerkaufseinheitenCollection';
            this.crudItemResultName = "verkaufseinheiten";
            break;
          }

          case 'verpackung': {
            this.crudItemService = this.injector.get(VerpackungService);
            this.crudItemMethodGetAll = 'getVerpackungenCollection';
            this.crudItemResultName = "verpackungen";
            break;
          }

          case 'ansprechpartner': {
            this.crudItemService = this.injector.get(AnsprechpartnerService);
            this.crudItemMethodGetAll = 'getAnsprechpartnerCollectionForThisUser';
            this.crudItemResultName = "ansprechpartner";
            this.crudItemResultLabelFeld = "summary";
            break;
          }

          case 'unternehmen': {
            this.crudItemService = this.injector.get(UnternehmenService);
            this.crudItemMethodGetAll = 'getUnternehmenCollection';
            this.crudItemResultName = "unternehmen";
            break;
          }
          
          case 'geodaten_postleitzahl': {
            this.crudItemService = this.injector.get(GeodatenService);
            this.crudItemMethodGetAll = 'getGeodatenCollection';
            this.crudItemResultName = "geodaten";


            this.crudItemResultLabelFeld = "summary";
            this.crudItemSearchDialog = true;
            this.crudItemSearchDialogTitle = 'Postleitzahl Ort';
            this.crudItemSearchDialogLabel = 'summary'; 

            this.crudItemMinLength = 3; // bei Postleitzahlen erst nach Eingabe von 3 Zeichen aufklappen
          
            break;
          }

          case 'lizenzart': {
            this.crudItemService = this.injector.get(LizenzartService);
            this.crudItemMethodGetAll = 'getLizenzartCollection';
            this.crudItemResultName = "lizenzart";
            break;
          }

          case 'benutzer': { 
            this.crudItemService = this.injector.get(BenutzerService);
            this.crudItemMethodGetAll = 'getBenutzerCollection';
            this.crudItemResultName = "benutzer";

            this.crudItemResultLabelFeld = "summary";
            break;
          }


          case 'default': {
            console.log("CRUDBasicAutoComplete: Error! type='" + this.type + "' ist bisher nicht implementiert!");
            alert("CRUDBasicAutoComplete: Error! type='" + this.type + "' ist bisher nicht implementiert!");
            break;
          }
        }
    
        // wenn Dialog statt DropDown, dann verhindern, dass primeNG von sich aus den Dropdown öffnet, weil das dauert bei grossen Datenmengen ...
        if (this.crudItemSearchDialog == true) {
          if (this.debugMode == true) console.log("CRUDBasicAutoComplete.ngOnInit() setting dropdownMode to blank... old value:", this.autoCompleteControl.dropdownMode);
    
          // die primeNg Doku ist an der Stelle wohl falsch ?
          // default ist    blank    - man muss es auf '' setzen (nicht auf 'blank') wenn man verhindern will, dass der mit den eingegebenen Werten sucht
          this.autoCompleteControl.dropdownMode = '';
        }
    
        //console.log("CRUDBasicAutoComplete.ngOnInit() type:", this.type);
        //console.log("CRUDBasicAutoComplete.ngOnInit() crudItemService:", this.crudItemService);
        //console.log("CRUDBasicAutoComplete.ngOnInit() crudItemMethodGetAll:", this.crudItemMethodGetAll);
        //console.log("CRUDBasicAutoComplete.ngOnInit() crudItemMethodSignature:", this.crudItemMethodSignature);
  }
  ngAfterViewInit() { //#WAITFORVIEWCHILD
    //this.autoCompleteControl.loading = true; //#WAITFORVIEWCHILD: wenn ich das setze: ExpressionChangedAfterItHasBeenCheckedError in primeng-autocomplete.ts - wenn er irgendwas mit seinem "ctx.loading" macht

    // dafür sorgen, dass die p-autocomplete nicht in ein <span ng_content_xxx> verpackt wird, sonst
    // funktionieren die ganzen Styles nicht! Gelöst durch replace "span" by "child"
    // saubere Lösung könnte sein:
    // "containerless components" - dazu gibt es jede Menge feature-requests bei angular (ging wohl mit AngularJS "replace")
    // :host { display: contents; }   -> funktioniert aber (noch???) nicht, ist ein experimental feature in chrome
    let thisAutoCompleteControl = this.autoCompleteControl.el.nativeElement;
    let thisEraseButtonControl = this.eraseButtonControl.nativeElement;
    //console.log("CRUDBasicAutoComplete.ngOnInit() divProgressSpinnerControl:", this.divProgressSpinnerControl);
    let thisDivProgressSpinnerControl = this.divProgressSpinnerControl.nativeElement;
    let parentNode = thisAutoCompleteControl.parentNode;
    let parentparentNode = parentNode.parentNode;
    console.log("CRUDBasicAutoComplete.ngOnInit() thisAutoCompleteControl:", thisAutoCompleteControl);
    console.log("CRUDBasicAutoComplete.ngOnInit() thisDivProgressSpinnerControl:", thisDivProgressSpinnerControl);
    //console.log("CRUDBasicAutoComplete.ngOnInit() thisEraseButtonControl:", thisEraseButtonControl);
    //console.log("CRUDBasicAutoComplete.ngOnInit() parentNode:", parentNode);
    //console.log("CRUDBasicAutoComplete.ngOnInit() parentparentNode:", parentparentNode);
    //parentparentNode.replaceChild(parentNode, thisAutoCompleteControl);
    let isIE = false || !!document['documentMode'];
    if (isIE == true) {
      parentNode.replaceNode(thisAutoCompleteControl); // IE!
      //this.htmlInsertAfter(thisEraseButtonControl, thisAutoCompleteControl); // IE: ungetestet! TODO!
      this.htmlInsertAfter(thisDivProgressSpinnerControl, thisAutoCompleteControl); // IE: ungetestet! TODO!
      this.htmlInsertAfter(thisEraseButtonControl, /*thisAutoCompleteControl*/thisDivProgressSpinnerControl); // IE: ungetestet! TODO!
    }
    else {
      parentNode.replaceWith(thisAutoCompleteControl); // nicht IE!
      //this.htmlInsertAfter(thisEraseButtonControl, thisAutoCompleteControl);
      this.htmlInsertAfter(thisDivProgressSpinnerControl, thisAutoCompleteControl); 
      this.htmlInsertAfter(thisEraseButtonControl, /*thisAutoCompleteControl*/thisDivProgressSpinnerControl);
    }

    if (this.crudItemMethodGetAll != null && ("" + this.crudItemMethodGetAll).length > 0) {
      if (this.options == null) {
        this.getCRUDItems();
      }
      else {
        this.onCRUDItemsRetrieved(this.options);
      }
    }
  }

  htmlInsertAfter(newNode, existingNode) { // https://www.javascripttutorial.net/javascript-dom/javascript-insertafter/
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
  }

  //https://ngdev.space/angular-2-input-property-changes-detection-3ccbf7e366d2
  //ngOnChanges(changes: SimpleChanges) { // monitoring Input-Changes - nur monitoring/debug - ohne weitere Funktion!!! (kann man weglassen!)
  //  console.log("CRUDBasicAutoComplete.ngOnChanges():", changes);
  //}

  // aus ControlValueAccessor Interface: patchValue/setValue/new FormControl soll Wert im HTML aktualisieren
  writeValue(obj: any): void {
    //if(this.debugMode == true) console.log("CRUDBasicAutoComplete.writeValue() start typeof obj/obj: ", typeof obj, obj); 
    // alle Aktionen auf das ViewChild "autoComplete" erst machen, wenn das tatsächlich verfügbar ist! // #WAITFORVIEWCHILD nicht aktiviert, siehe oben Bemerkung bei static true
    let thisInstance = this;
    //if(this.autoCompleteControl == undefined) {
    //  if( obj != undefined) { // passiert z.B. bei Gartenbau ArtikelDetail Mengeneinheit ganz zu Beginn -> wenn wir das per setTimeout verzögert schreiben, passiert dass NACHDEM inzw. der echte Wert angekommen ist! - daher: nur machen, wenn es auch ein Wert gibt!
    //    if(thisInstance.debugMode == true) console.log("CRUDBasicAutoComplete.writeValue() autoCompleteControl is still undefined. retry after timeout ...");
    //    setTimeout(() => {
    //      if(thisInstance.debugMode == true) console.log("CRUDBasicAutoComplete.writeValue() after timeout: thisInstance: ", thisInstance);
    //      thisInstance.writeValue(obj);
    //    }, 250);
    //  }
    //  return;
    //}
    //else {
      if(thisInstance.debugMode == true) console.log("CRUDBasicAutocomplete.writeValue() typeof obj/obj: ", typeof obj, obj);
      if (typeof obj == "string" || typeof obj == "number" ) {
        //console.log("CRUDBasicAutoComplete.writeValue() obj is a string:", obj);
        this.inputOptionValue = obj;
        this.inputOptionAlreadyWritten = false;
        this.writeChoiceListAsObject("writeValue(string/number)");
      }
      else {
        //console.log("CRUDBasicAutoComplete.writeValue() obj:", obj);
        // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt

        this.autoCompleteControl.writeValue(obj); // AM 26.06.2019: funktioniert bei AufgabenlistePositionDetailComponent nicht mehr !
        // vielleicht timing, weil einige autocompletes ?
        // verzögerts Schreiben (timeout) würde funktionieren, aber wenn dann elegant:
        // auch "normale" Objekte ähnlich wie Choicelisten behandeln = nach dem "items retrieved" writeValue() WIEDERHOLEN!
        // in dem Fall WIEDERHOLEN, trotzdem weiterhin gleich zu Beginn auch schon schreiben, oftmals funktioniert's ja - und ist damit flotter.
        this.inputOptionValue = obj;
        this.inputOptionAlreadyWritten = false;
        this.writeChoiceListAsObject("writeValue(NOT string/number)");
      }
    //}
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onChange" aufrufen sollen.
  // die zunächst NUR MERKEN, ggf. rufen wir die (siehe onChange()) auf
  registerOnChange(fn: (rating: number) => void): void {
    //console.log("CRUDBasicAutoComplete.registerOnChange() fn:", fn);
    this.onChange_callbackFunction = fn;
  }

  onChange() {
    //console.log("CRUDBasicAutoComplete.onChange() event:", event);
    if (this.option != this.onChange_lastOption) { // nur wenn der Wert != dem zuletzt gemeldeten Wert ist (doppel-Rückmeldungen vermeiden! Performance!)

      let callBackValue: any = null;
      if (this.type == '_CHOICELIST') { // bei CHOICELIST nicht das Objekt zurückgeben, sondern den Wert!
        callBackValue = this.option.value;
      }
      else { // z.B. benutzer simuliert 'benutzer', liest aber aus aspnetusers. Dann muss als Result aber der Benutzer = apsnetuser.imkeBenutzer zurückgegeben werden!
        if (this.crudItemResultProperty != null) {
          callBackValue = this.option[this.crudItemResultProperty];
          if(this.debugMode == true) console.log("CRUDBasicAutoComplete.onChange() returning option." + this.crudItemResultProperty + ":", callBackValue);
          if(this.debugMode == true) console.log("CRUDBasicAutoComplete.onChange() ... instead of option:", this.option);
        }
        else { // Standard-Variante
          callBackValue = this.option;
        }
      }

      // console.log("CRUDBasicAutoComplete.onChange() calling callback - value:", callBackValue);
      this.onChange_callbackFunction(callBackValue);
      this.onChange_lastOption = this.option;
    }
    else {
      //console.log("CRUDBasicAutoComplete.onChange() skip, since it's the same option as last time!");
    }
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onTouch" aufrufen sollen. 
  registerOnTouched(fn: () => void): void {
    // console.log("CRUDBasicAutoComplete.registerOnTouched() fn:", fn);
    // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
    
    // alle Aktionen auf das ViewChild "autoComplete" erst machen, wenn das tatsächlich verfügbar ist! // #WAITFORVIEWCHILD nicht aktiviert, siehe oben Bemerkung bei static true
    //let thisInstance = this;
    //if(this.autoCompleteControl == undefined) {
    //  setTimeout(() => {
    //    if(this.debugMode == true) console.log("CRUDBasicAutoComplete.registerOnTouched() after timeout: thisInstance: ", thisInstance);
    //    thisInstance.registerOnTouched(fn);
    //  }, 250);
    //  return;
    //}
    //else {
    //  // just continue
    //}

    this.autoCompleteControl.registerOnTouched(fn);

    //this.onTouch_callbackFunction = fn;
  }

  // Angular forms ruft diese Funktion, wenn sich der disabled-Status ändert.
  setDisabledState(isDisabled: boolean): void {
    // console.log("CRUDBasicAutoComplete.setDisabledState() isDisabled:", isDisabled);
    // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt

    // alle Aktionen auf das ViewChild "autoComplete" erst machen, wenn das tatsächlich verfügbar ist! // #WAITFORVIEWCHILD nicht aktiviert, siehe oben Bemerkung bei static true
    //let thisInstance = this;
    //if(this.autoCompleteControl == undefined) {
    //  setTimeout(() => {
    //    if(thisInstance.debugMode == true) console.log("CRUDBasicAutoComplete.registerOnTouched() after timeout: thisInstance: ", thisInstance);
    //    thisInstance.setDisabledState(isDisabled);
    //  }, 250);
    //  return;
    //}
    //else {
    //  // just continue
    //}

    this.autoCompleteControl.setDisabledState(isDisabled);
  }

  // child-class specifisches:
  getCRUDItems() {
    //console.log("CRUDBasicAutoComplete.getCRUDItems() type:", this.type);
    //console.log("CRUDBasicAutoComplete.getCRUDItems() crudItemService:", this.crudItemService);
    //console.log("CRUDBasicAutoComplete.getCRUDItems() crudItemMethodGetAll:", this.crudItemMethodGetAll);
    //console.log("CRUDBasicAutoComplete.getCRUDItems() crudItemMethodSignature:", this.crudItemMethodSignature);
    let crudItemResultName = this.crudItemResultName;
    switch (this.crudItemMethodSignature) {
      case 'pN_pS_sQ': {
        this.crudItemService[this.crudItemMethodGetAll](1, 0, "").subscribe(
          (response: any) => {
            //console.log("CRUDBasicAutoComplete.getCRUDItems() response:", response);
            this.onCRUDItemsRetrieved(response[crudItemResultName]/*response.firmaRollen*/);
          },
          (error: any) => {
            if(this.debugMode == true) console.log("CRUDBasicAutoComplete.getCRUDItems() error:", error);
            this.handleError(error);
          }
        );
        break;
      }

      /*case 'pN_pS_sQ_currentAspNetUserId': {
        // zuerst mal den currentAspNetUser ermitteln
        let globalService = this.injector.get(GlobalService);
        let currentUser: IUser = globalService.getUser();
        this.crudItemService[this.crudItemMethodGetAll](1, 0, "", currentUser.id).subscribe(
          (response: any) => {
            // console.log("CRUDBasicAutoComplete.getCRUDItems() response:", response);
            this.onCRUDItemsRetrieved(response[crudItemResultName]);
          },
          (error: any) => {
            this.handleError(error);
          }
        );
        break;
      }*/

      case 'none': {
        this.crudItemService[this.crudItemMethodGetAll]().subscribe(
          (response: any) => {
            //console.log("CRUDBasicAutoComplete.getCRUDItems() response:", response);
            if (crudItemResultName == null) this.onCRUDItemsRetrieved(response);
            else this.onCRUDItemsRetrieved(response[crudItemResultName]/*response.firmaRollen*/);
          },
          (error: any) => {
            this.handleError(error);
          }
        );
        break;
      }

      case 'default': {
        // console.log("CRUDBasicAutoComplete: Error! signature='" + this.crudItemMethodSignature + "' ist bisher nicht implementiert!");
        alert("CRUDBasicAutoComplete: Error! signature='" + this.crudItemMethodSignature + "' ist bisher nicht implementiert!");
      } break;
    }
  }
  onCRUDItemsRetrieved(CRUDItems: any[]): void {
    if (this.debugMode == true) console.log("CRUDBasicAutoComplete.onCRUDItemsRetrieved() CRUDItems:", CRUDItems);

    // spezielle Filter ?
    switch (this.crudItemResultFilter) {
      //case 'aspnetuser_imkeonly': {
      //console.log("CRUDBasicAutoComplete.onCRUDItemsRetrieved() crudItemResultFilter="+this.crudItemResultFilter+ "... filtering ...");
      //CRUDItems = CRUDItems.filter(u => u.imkeBenutzer != null); // nur die imke User
      //console.log("CRUDBasicAutoComplete.onCRUDItemsRetrieved() filtered result=", CRUDItems);
      //break;
      //}

      case 'default': {
        // nichts! - kein Filter!
      } break;
    }

    // ggf. callback-Funktion aufrufen, die die options nach dem retrieved nochmal filtert
    if (this.debugMode == true) console.log("CRUDBasicAutoComplete.onCRUDItemsRetrieved() filterFunction:", this.filterFunction);
    if (this.filterFunction != null) {
      if (this.debugMode == true) console.log("CRUDBasicAutoComplete.onCRUDItemsRetrieved() calling filterFunction ...");
      this.options = this.filterFunction(CRUDItems);
      if (this.debugMode == true) console.log("CRUDBasicAutoComplete.onCRUDItemsRetrieved() options after filterFunction: options:", this.options);
    }
    else {
      this.options = CRUDItems;
    }
    this.filteredOptions = CRUDItems; // modi
    //if(this.crudItemResultLabelFeld != "bezeichnung") { // falls es in dem Datentyp keine Bezeichnung gibt, dann entspr. Feld nach Bezeichnung kopieren
    //  for(let i = 0; i<this.filteredOptions.length; i++) {
    //    this.filteredOptions[i]['bezeichnung'] = this.filteredOptions[i][this.crudItemResultLabelFeld];
    //  }
    //}
    //console.log("CRUDBasicAutoComplete.onCRUDItemsRetrieved() filteredOptions for '"+this.type+"':", this.filteredOptions);
    //console.log("CRUDBasicAutoComplete.onCRUDItemsRetrieved() autoCompleteControl.value:", this.autoCompleteControl.value);
    //console.log("CRUDBasicAutoComplete.onCRUDItemsRetrieved() inputOptionValue:", this.inputOptionValue);

    this.writeChoiceListAsObject("onCRUDItemsRetrieved()");

    // this.autoCompleteControl.loading = false; //#WAITFORVIEWCHILD dropdownIcon als Ersatz für .loading, was zur ExpressionChangedAfterItHasBeenCheckedError führt
    this.dropdownIcon = this.dropdownIcon_default; 
   
  }

  // im ChoiceList Mode, kommt als Eingangsparamter (formControlName) kein Object, sondern nur der Wert als String
  // das - SOBALD DIE OPTIONS VERFÜGBAR SIND - in ein Object wandeln - und writen!
  writeChoiceListAsObject(calledByJustForDebug:string) {
    if (!this.inputOptionAlreadyWritten) {
      let optionsAlreadyLoaded = this.options != null;
      if(this.debugMode == true) console.log("CRUDBasicAutoComplete.writeChoiceListAsObject() (calledBy:"+calledByJustForDebug+") ... options are already loaded:", optionsAlreadyLoaded);
      if (optionsAlreadyLoaded) {
        if(this.debugMode == true) console.log("CRUDBasicAutoComplete.writeChoiceListAsObject() (calledBy:"+calledByJustForDebug+") ... setting option with value:", this.inputOptionValue);

        if (typeof this.inputOptionValue == "string" || typeof this.inputOptionValue == "number") {
          let option = this.options.filter(o => o.value == this.inputOptionValue);
          if (option != null && option.length > 0) {
            let newOption = { value: this.inputOptionValue, [this.crudItemResultLabelFeld]: option[0][this.crudItemResultLabelFeld] };
            // console.log("CRUDBasicAutoComplete.fixSelectedOption() new option:", newOption);
            this.autoCompleteControl.writeValue(newOption);
            this.inputOptionAlreadyWritten = true;
          }
        }
        else {
          this.autoCompleteControl.writeValue(this.inputOptionValue);
          this.inputOptionAlreadyWritten = true;
        }
      }
    }
  }

  searchCRUDItem(event) {
    //this.filteredOptions = this.options.filter(item => item[this.crudItemResultLabelFeld].toUpperCase().indexOf(event.query.toUpperCase()) > -1);
    if (this.options == null) {
      this.filteredOptions = null;
      console.log("CRUDBasicAutoComplete.searchCRUDItem() no options available (yet?)");
      console.log("CRUDBasicAutoComplete.searchCRUDItem() type:", this.type);
      console.log("CRUDBasicAutoComplete.searchCRUDItem() crudItemService:", this.crudItemService);
      console.log("CRUDBasicAutoComplete.searchCRUDItem() crudItemMethodGetAll:", this.crudItemMethodGetAll);
      console.log("CRUDBasicAutoComplete.searchCRUDItem() crudItemMethodSignature:", this.crudItemMethodSignature);
    }
    else {
      console.log("CRUDBasicAutoComplete.searchCRUDItem() options: ", this.options);
      this.filteredOptions = this.options.filter(item => this.getProperty(item, this.crudItemResultLabelFeld).toUpperCase().indexOf(event.query.toUpperCase()) > -1);
    }
  }

  getProperty(obj: any, propertyName: string) { // macht im Prinzip   einfach obj[propertyName] - aber funktioniert auch, wenn propertyName mit Punkt, also z.B. objekt.projekt.bezeichnung
    //console.log("CRUDBasicAutoComplete.getProperty() propertyName: "+propertyName+" obj:", obj);

    // bei z.B. aspnetuser ist crudItemResultName NICHT VORHANDEN, weil das result aus dem Service.get() nicht ein Objekt.Array ist, sondern einfach direkt nur ein Array
    // in dem Fall also einfachere Logik:
    if (this.crudItemResultName == null) {
      let ret = obj[propertyName];
      return ret != null ? ret : ''; // Sicherstellen, dass kein null zurückkommt, weil sonst Fehler in .filter()
    }
    else { // Array, das in einem Objekt verpackt ist, z.B.   laender {[], [], []}
      let idx = propertyName.indexOf(".");
      if (idx < 0) { // nicht mehr weitersuchen
        let ret = obj[propertyName];
        return ret != null ? ret : ''; // Sicherstellen, dass kein null zurückkommt, weil sonst Fehler in .filter()
      }
      else { // weitersuchen
        let nextProperty = propertyName.substr(0, idx);
        let subObj = obj[nextProperty];
        let restlicheProperties = propertyName.substr(idx + 1);
        let ret = this.getProperty(subObj, restlicheProperties);
        return ret != null ? ret : ''; // Sicherstellen, dass kein null zurückkommt, weil sonst Fehler in .filter()
      }
    }
  }

  getIcon(obj: any) { // gibt z.B. bei Lager = Baustelle ein Icon aus
    //console.log("CRUDBasicAutoComplete.getIcon() obj: ", obj);
    if(this.type == "lager") {
      //console.log("CRUDBasicAutoComplete.getIcon() type == lager!");
      if(obj.baustelle == true) {
        //console.log("CRUDBasicAutoComplete.getIcon() Baustelle!");
        return '<i class="fa fa-construction"></i>';
      }
    }
    return null;
  }

  handleCRUDItemDropdown(event) {
  }

  handleCRUDItemFocus(event) {
    // console.log("CRUDBasicAutoComplete.handleCRUDItemFocus() event:", event);
    // console.log("CRUDBasicAutoComplete.handleCRUDItemFocus() option:", this.option);
    this.inputHasFocus = true;
    this.nativeInputElement = document.activeElement;
    //console.log("CRUDBasicAutoComplete.handleCRUDItemFocus() nativeInputElement:", this.nativeInputElement);
    this.optionOnFocus = this.option;
    //this.onTouch_callbackFunction(event);
  }

  handleCRUDItemBlur(event) {
    //console.log("CRUDBasicAutoComplete.handleCRUDItemBlur() event:", event);

    // Im Dialog: wenn blur, weil man auf dropDown geklickt hat, dann ignorieren!
    // der blur feuert da sogar 2mal: 1mal wegen dropdown, dann nochmal, wenn Dialog visible wird
    // 
    // Wir können hier aber noch nicht erkennen, ob dropdown geklickt wurde, weil zuerst läuft dieser blur, dann der dropDown-Event!
    // Daher Trick:
    // blur-event nicht sofort ausführen, sondern 0,75 sekunden warten, dann prüfen, ob dialogVisible (vom dropdown gesetzt) ...
    // nur wenn nicht dialogVisible, dann ausführen
    if (this.crudItemSearchDialog == true) {
      setTimeout(() => {
        if (this.dialogVisible == false) this.handleCRUDItemBlur_pt2(event);
      }, 500);
      return;
    }
    else {
      this.handleCRUDItemBlur_pt2(event);
    }
  }
  handleCRUDItemBlur_pt2(event) {
    //console.log("CRUDBasicAutoComplete.handleCRUDItemBlur() option:", this.option);
    //Überprüfen, ob bereits gültige option eingestellt, wenn ja ok!
    if (this.option != undefined && this.option.id !== undefined) {
      if(this.debugMode == true) console.log("CRUDBasicAutoComplete.handleCRUDItemBlur() bereits gültige option eingestellt. return!");
      setTimeout(() => { this.checkIfStillFocused(); }, 500); // Zeitverzögert, dass ein evtl. Click auf X noch ausgeführt werden kann
      return;
    }
    //ansonsten: Eingabe nicht leer, dann nach einem passenden item suchen:
    else {
      if(this.debugMode == true) console.log("CRUDBasicAutoComplete.handleCRUDItemBlur() noch keine gültige option eingestellt.");
      
      if (event.target != undefined && event.target.value != undefined && event.target.value != "") {
        if(this.debugMode == true) console.log("CRUDBasicAutoComplete.handleCRUDItemBlur() suche nach passendem Wert.");
        let _options = this.options != null ? this.options.filter(option => this.getProperty(option, this.crudItemResultLabelFeld).toUpperCase().indexOf(event.target.value.toUpperCase()) > -1) : null;
        //Wenn genau ein Item auf die Eingabe passt, dann kann der Wert eingestellt werden
        if (_options != null && _options.length == 1) {
          if(this.debugMode == true) console.log("CRUDBasicAutoComplete.handleCRUDItemBlur() nur ein passender Wert gefunden -> einstellen und return!");
          this.autoCompleteControl.selectItem(_options[0], false);
          this.onChange(); // Veränderung an parent-Comp. melden!
          setTimeout(() => { this.checkIfStillFocused(); }, 500); // Zeitverzögert, dass ein evtl. Click auf X noch ausgeführt werden kann
          return;
        }
        else {
          if(this.debugMode == true) console.log("CRUDBasicAutoComplete.handleCRUDItemBlur() - don't return!");
        }
      }
      else {
        // zumindest, wenn nicht required -> sicherstellen, dass man das Feld verlassen kann (return;)
        //console.log(this.required)
        if (!this.required) {
          if(this.debugMode == true) console.log("CRUDBasicAutoComplete.handleCRUDItemBlur() - not required - return!");
          this.option = null;
          this.autoCompleteControl.selectItem(null, false);
          this.onChange(); // Veränderung an parent-Comp. melden!
          setTimeout(() => { this.checkIfStillFocused(); }, 500); // Zeitverzögert, dass ein evtl. Click auf X noch ausgeführt werden kann
          return;
        }
      }
    }

    // Ansonsten: unvalidatedInput
    // - wenn EIGENE Eingabe erlaubt sind:
    // - ansonsten: vorherigen Wert wieder selecten! 
    if(this.allowUnvalidatedInput) {
      if(this.debugMode == true) console.log("CRUDBasicAutoComplete.handleCRUDItemBlur() unvalidatedInput!");
      // neues Item einstellen mit genau den Eingaben - aber id = null - woran man dann ein neues erkennt
      /*let newItem={
        id: null
      }*/
      let newItem = this.crudItemService[this.crudItemMethodInitialize]();
      //newItem.id = null;
      newItem[this.crudItemResultLabelFeld] = event.target.value;

      // ggf. vorherige neu hinzugefügte wieder löschen - und diesen neuen Eintrag hinzufügen
      this.filteredOptions = this.filteredOptions.filter(f => f.id != null && f.id != 0);
      this.filteredOptions.push(newItem);

      this.option = newItem;
      this.optionOnFocus = this.option;
      let thisInstance = this;
      //setTimeout(() => {
        thisInstance.autoCompleteControl.selectItem(this.optionOnFocus, false);
      //}, 250);
      this.onChange(); // Veränderung an parent-Comp. melden!
      setTimeout(() => { this.checkIfStillFocused(); }, 500); // Zeitverzögert, dass ein evtl. Click auf X noch ausgeführt werden kann
      return;
    }
    else {
      if(this.debugMode == true) console.log("CRUDBasicAutoComplete.handleCRUDItemBlur() vorherigen Wert wieder wählen und return!");
      this.autoCompleteControl.selectItem(this.optionOnFocus);
      this.onChange(); // Veränderung an parent-Comp. melden!
      setTimeout(() => { this.checkIfStillFocused(); }, 500); // Zeitverzögert, dass ein evtl. Click auf X noch ausgeführt werden kann
      return;
    }
  }

  checkIfStillFocused() {
    if(this.nativeInputElement == null || this.nativeInputElement != document.activeElement) {
      this.inputHasFocus = false; 
    }
    else {
      // skip! <Input> is still focused! (oder wieder: nach Klick auf den removeButton!)
    }
    console.log("CRUDBasicAutoComplete.checkIfStillFocused(): inputHasFocus:", this.inputHasFocus);
  }

  handleCRUDItemSelect(item: any) {
    //console.log("CRUDBasicAutoComplete.handleCRUDItemSelect()");
    this.onChange(); // Veränderung an parent-Comp. melden!
  }

  onDropdownClick() {
    //console.log("CRUDBasicAutoComplete.onDropdownClick() autoCompleteControl:", this.autoCompleteControl);
    if (this.crudItemSearchDialog == true) {
      //this.autoCompleteControl.dropdown = false; // In Plattgruen führt das .dropdown = false und wieder = true zu einem Optik-Problem, daher: einfach an lassen.

      // Demo-Daten... for debug:
      /*if(this.options!=null && this.options.length<100) {
        console.log("CRUDBasicAutoComplete.onDropdownClick() creating demodata ...");
        for(let i:number = 9000; i<39999; i++) {
          let o = this.crudItemService.initializeObjekte();
          o.id = i;
          o.objektnummer = i;
          o.bezeichnung = "objekt Nr. "+i;
          this.options.push(o);
        }
      }*/

      this.dialogOptionOnFocusSaveBeforeOpeningDialog = this.optionOnFocus;
      this.dialogSearchValue = this.autoCompleteControl./*inputFieldValue*/inputEL.nativeElement.value; // Wichtig! dialogSearchValue füllen, bevor dialog Sichtbar wird, sonst funktioniert die Initialisierung des Suchfelds im Dialog nicht!
      //console.log("CRUDBasicAutoComplete.onDialogSelected() set dialogSearchValue/autoCompleteControl:", this.dialogSearchValue, this.autoCompleteControl);
      this.dialog = true;
      //Create und Anzeigen des Dialogs darf nicht gleichzeit ausgeführt werden
      //Damit der Dialog zentriert aufgerufen wird.
      setTimeout(() => {
        this.dialogVisible = true;
      },1)
    }
    else {
      if(this.autoCompleteControl.value == null || this.autoCompleteControl.value.length < 3) {
        console.log("CRUDBasicAutoComplete.onDropdownClick() waiting ... divProgressSpinnerControl:", this.divProgressSpinnerControl);
        //setTimeout(() => {
          //this.showProgressSpinner = true; // wird leider nicht SOFORT dargestellt...
          //this.divProgressSpinnerControl.nativeElement.style.display = "unset"; // wird leider nicht SOFORT dargestellt...
        //},1)
      }
    }
  }

  onShowOverlay($event) {
    console.log("CRUDBasicAutoComplete.onShowOverlay() $event:", $event);
    setTimeout(() => {
      //this.showProgressSpinner = false; 
      //this.divProgressSpinnerControl.nativeElement.style.display = "none";
    },1)
  }

  onDialogSelected($event) {
    //console.log("CRUDBasicAutoComplete.onDialogSelected() $event:", $event),
    debugger;
    this.option = $event;
    this.onChange();
    this.dialogVisible = false;
    //this.dialog = false; // eigentlich wäre es sinnvoll, den Dialog offen zu lassen, aber warum auch immer dauert da WIEDER-anzeigen bei grossen Daten teils ewig. 
    //                     // Daher: Dialog verwerfen und nä. mal neu aufmachen
    this.autoCompleteControl.focusInput();
    //this.autoCompleteControl.dropdown = true; // In Plattgruen führt das .dropdown = false und wieder = true zu einem Optik-Problem, daher: einfach an lassen.
  }

  onDialogClose($event) {
    //console.log("CRUDBasicAutoComplete.onDialogClose() $event:", $event),
    this.dialogVisible = false;
    // Der gleich folgende focus() sichert wiederum   optionOnFocus = option, daher Folgendes sicherstellen:
    // Wenn man mit Pfeil den Dialog öffnet, den aber ohne Auswahl wieder schliesst - und dann wieder auf dem 
    // Eingabefeld ist, dass dann der "Event bei focus" die optionOnFocus wieder mit genau dem Wert bestückt, wie ursprünglich beim betreten.
    this.option = this.dialogOptionOnFocusSaveBeforeOpeningDialog;
    this.autoCompleteControl.focusInput();
    //this.autoCompleteControl.dropdown = true; // In Plattgruen führt das .dropdown = false und wieder = true zu einem Optik-Problem, daher: einfach an lassen.
  }

  clear($event) {
    console.log("CRUDBasicAutoComplete.clear() $event:", $event),
    //console.log("CRUDBasicAutoComplete.clear() autoCompleteControl.inputEL.nativeElement.value:", this.autoCompleteControl.inputEL.nativeElement.value);
    this.option = null;
  }

  handleError(error: any) {
    //this.loading = false;
    //this.blockedDocument = false;

    console.log("CRUDBasicAutoComplete.handleError() type:", this.type);
    console.log("CRUDBasicAutoComplete.handleError() crudItemService:", this.crudItemService);
    console.log("CRUDBasicAutoComplete.handleError() crudItemMethodGetAll:", this.crudItemMethodGetAll);
    console.log("CRUDBasicAutoComplete.handleError() crudItemMethodSignature:", this.crudItemMethodSignature);

    let errorMessage: string = "";
    //let summary = this.translate.instant('Fehler', true);
    let summary = 'Fehler'; //TODO

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      errorMessage = "Unauthorized";
      //this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      errorMessage = error.message;
    }

    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: errorMessage });

  }
}
