import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IArtikelgruppe } from '../_interfaces/artikelgruppe';
import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

//import { AppconfigService } from '../_services/appconfig.service'; //TODO
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class ArtikelgruppeService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'artikelgruppeId', type: 'int'},
    {name: 'artikelgruppe', type: 'object'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'}
  ];
    
  getArtikelgruppenCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ artikelgruppen: IArtikelgruppe[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'artikelgruppen?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { artikelgruppen: <IArtikelgruppe[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getArtikelgruppenChildrenForParent(parentId: number): Observable<{ artikelgruppen: IArtikelgruppe[]}> {
    return this.httpClient.get(this.apiBaseUrl + 'artikelgruppen/getArtikelgruppenChildrenForParent/' + parentId, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          let artikelGruppen = <IArtikelgruppe[]>response.body;
          //console.log("ArtikelgruppeService.getArtikelgruppenChildrenForParent() artikelGruppen (not as tree)")
          return { artikelgruppen: artikelGruppen };
        }), catchError(this.handleError))
  }

  getArtikelgruppenChildrenForTree(): Observable<{ artikelgruppen: IArtikelgruppe[]}> {
    return this.httpClient.get(this.apiBaseUrl + 'artikelgruppen/getArtikelgruppenForTree', { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          let artikelGruppen = <IArtikelgruppe[]>response.body;
          //console.log("ArtikelgruppeService.getArtikelgruppenChildrenForTree() artikelGruppen")
          return { artikelgruppen: artikelGruppen };
        }), catchError(this.handleError))
  }

  getArtikelgruppe(id: number): Observable<IArtikelgruppe> {

    if (id === 0) {
      return Observable.create((observer: any) => {
        observer.next(this.initializeArtikelgruppe());
        observer.complete();
      })
    }

    return this.httpClient.get<IArtikelgruppe>(this.apiBaseUrl + 'artikelgruppen/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  saveArtikelgruppe(artikelgruppe: IArtikelgruppe): Observable<IArtikelgruppe> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(artikelgruppe); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("ArtikelgruppeService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("ArtikelgruppeService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (artikelgruppe.id === 0) {
      return this.createArtikelgruppe(/*artikelgruppe*/itemToSave);
    }

    return this.updateArtikelgruppe(/*artikelgruppe*/itemToSave)

  }

  updateArtikelgruppe(artikelgruppe: IArtikelgruppe): Observable<IArtikelgruppe> {

    return this.httpClient.put<IArtikelgruppe>(this.apiBaseUrl + 'artikelgruppen/' + artikelgruppe.id, artikelgruppe, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteArtikelgruppe(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'artikelgruppen/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createArtikelgruppe(artikelgruppe: IArtikelgruppe): Observable<IArtikelgruppe> {
    return this.httpClient.post<IArtikelgruppe>(this.apiBaseUrl + 'artikelgruppen', artikelgruppe, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeArtikelgruppe(): IArtikelgruppe {
    return {
      id: 0,
      bezeichnung: '',
      artikelgruppeId: null,
      artikelgruppe: null,
      createdBy: '',
      created: '',
      modifiedBy: '',
      modified: '',
      rowVersion: ''
    };
  }


}
