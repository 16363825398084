<!-- Desktop Layout F=Full -->
<div *ngIf="frameType=='F'" class="layout-wrapper" [ngClass]="{'layout-compact':layoutCompact}" (click)="onLayoutClick()">

    <div #layoutContainer class="layout-container"
         [ngClass]="{'menu-layout-static': !isOverlay(),
            'menu-layout-overlay': isOverlay(),
            'layout-menu-overlay-active': overlayMenuActive,
            'menu-layout-horizontal': isHorizontal(),
            'menu-layout-slim': isSlim(),
            'layout-menu-static-inactive': staticMenuDesktopInactive,
            'layout-menu-static-active': staticMenuMobileActive}">

        <app-topbar></app-topbar>

        <div class="layout-menu" [ngClass]="{'layout-menu-dark':darkMenu}" (click)="onMenuClick($event)">
            <app-inline-profile *ngIf="profileMode=='inline'&&!isHorizontal()"></app-inline-profile>
            <app-menu></app-menu>
        </div>

        <div class="layout-main">
            <app-breadcrumb></app-breadcrumb>

            <div class="layout-content">
                <router-outlet></router-outlet>
                
                <app-footer></app-footer>
            </div>
        </div>

        <app-rightpanel></app-rightpanel>
        <!-- <app-config></app-config> -->

        <div class="layout-mask"></div>
    </div>

</div>

<!-- in-app Layout N=NoHeader -->
<div *ngIf="frameType=='N'" class="layout-content">
        <router-outlet></router-outlet>
        
        <!--<app-footer></app-footer>-->
</div>

