// basis aus:
// https://medium.com/@juliapassynkova/angular-2-component-reuse-strategy-9f3ddfab23f5
// erweitert um Schalter "shouldReuse", der pro Route einzeln steuert, ob sie reused werden soll
// https://stackoverflow.com/questions/49155895/how-to-activate-routereusestrategy-only-for-specific-routes

// API documentation: 
// https://raymondhlee.wordpress.com/2017/12/30/routereusestrategy-for-route-caching-in-angular/

// wie macht man in der reused Component dann einen Refresh ???:
// siehe konfiguration-list-component.ts: "onSameUrlNavigation"

import {RouteReuseStrategy, DefaultUrlSerializer, ActivatedRouteSnapshot, DetachedRouteHandle} from "@angular/router";
import {ComponentRef} from "@angular/core";
//import { KonfigurationListComponent } from "../konfiguration/konfiguration-list.component";

// This impl. bases upon one that can be found in the router's test cases.
export class CustomReuseStrategy implements RouteReuseStrategy {
    debugMode: boolean = false;

    //handlers: {[key: string]: DetachedRouteHandle} = {};
    handlers: {[key: string]: {detachedRouteHandle: DetachedRouteHandle, data: any}} = {}; // data = data aus AppModule Routes
    
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if(this.debugMode == true) console.log('CustomReuseStrategy.shouldDetach() route/route.component', route, route.component);
        //return true;

        //try {
        //    debugger;
        //    let shouldReuse = route.component['shouldReuse']();
        //    return shouldReuse;
        //}
        //catch (e) {
            return route.data.shouldReuse || false;
        //}
    }
    
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        if(this.debugMode == true) console.log('CustomReuseStrategy:store', route, handle);
        //this.handlers[route.routeConfig.path] = handle;
        if (route.data.shouldReuse) {
            //this.handlers[route.routeConfig.path] = handle;
            this.handlers[route.routeConfig.path] = {detachedRouteHandle: handle, data: route.data};    // data = data aus AppModule Routes
        }
        if(this.debugMode == true) console.log('CustomReuseStrategy.store() after storing: handlers:', this.handlers);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if(this.debugMode == true) console.log('CustomReuseStrategy:shouldAttach', route);

        // alle gespeicherten Handler clearen, die nicht mehr benötigt werden
        /*if(route.routeConfig.path != "")*/ this.cleanUp(route.routeConfig.path); // MODI im Vergleich zu IMKE: Er durchläuft das auch für die AppMainComponent - und die hat kein Pfad!

        //return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
        return !!route.routeConfig && !!(this.handlers[route.routeConfig.path] != null ? this.handlers[route.routeConfig.path].detachedRouteHandle : null);
    }

    cleanUp(navigatingToPath: string) {
        if(this.debugMode==true) console.log("CustomReuseStrategy.cleanUp() navigating to:", navigatingToPath);

        let gespeicherteHandlerVorhanden = false;
        // jeden einzelnen gespeicherten Handler/component prüfen:
        // hat er in data ein 'shouldReuseWhenPreviousPath' ? Dann prüfen, ob da wo wir aktuell hin-navigieren ein Pfad ist,
        // von dem wir evtl. wieder zu diesem Handler/component zurück wollen (sprich zu 'shouldReuseWhenPreviousPath' passt).
        for (let key in this.handlers) {
            gespeicherteHandlerVorhanden = true;
            if(key != navigatingToPath) { // nur die gespeicherten Handler prüfen, zu denen ich nicht aktuell unterwegs bin. Wenn man z.B. von 'hobby/' zu 'hobbies' zurückkommt, nicht in dem Moment handler 'hobbies' löschen, weil im 'shouldReuseWhenPreviousPath' 'hobby/*' steht, aber nicht 'hobbies'
                let handler = this.handlers[key];
                let keep : boolean = this.checkPreviousPath(handler.data, navigatingToPath, '/'+navigatingToPath, false); // false = deleteIfNotOK = das ist noch Logik aus vor cleanup() Zeiten, vorsichtshalber drin gelassen, ist aber möglicherweise seit cleanup() überflüssig ?
                if(keep) {
                    // zu diesem Handler/component wollen wir evtl. zurück - also KEEP!
                    if(this.debugMode==true) console.log('CustomReuseStrategy.cleanUp() KEEP handler ('+key+'):', handler);
                }
                else {
                    // zu diesem Handler/component wollen wir sicht zurück, weil wir aktuell wo ganz anders hin-navigieren - also DROP!
                    if(this.debugMode==true) console.log('CustomReuseStrategy.cleanUp() DROP handler ('+key+'):', handler);
                    this.deactivateOutlet(this.handlers[key]) // Bereinigen aller gespeicherter handles: https://github.com/angular/angular/issues/16713
                    delete this.handlers[key];
                }
            }
        }
        //if(gespeicherteHandlerVorhanden == false) console.log('CustomReuseStrategy.cleanUp() there wasn\'t any stored handlers to check/clean.');
        if(this.debugMode==true) console.log('CustomReuseStrategy.cleanUp() after storing: handlers:', this.handlers);
    }

    // https://github.com/angular/angular/issues/16713
    // Todo: we manually destroy the component view here. Since RouteReuseStrategy is experimental, it
    // could break anytime the protocol change. We should alter this once the protocol change.
    private deactivateOutlet(handle: DetachedRouteHandle): void {
        let componentRef: ComponentRef<any> = handle['detachedRouteHandle']['componentRef'] // MODI
        if (componentRef) {
            if(this.debugMode == true) console.log('CustomReuseStrategy.deactivateOutlet() (cleanup) destroy handle:', handle);
            componentRef.destroy()
        }
        else {
            if(this.debugMode == true) console.log('CustomReuseStrategy.deactivateOutlet() (cleanup) FAILED TO destroy handle, since componentRef not known: handle:', handle);
        }
    }
    
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        if(this.debugMode == true) console.log('CustomReuseStrategy:retrieve', route);
        if (!route.routeConfig) return null;
        //return this.handlers[route.routeConfig.path];
        return this.handlers[route.routeConfig.path] != null ? this.handlers[route.routeConfig.path].detachedRouteHandle : null;
    }
    
    /*shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        console.log('CustomReuseStrategy:shouldReuseRoute', future, curr);
        //return future.data.shouldReuse || false;
        if(future != null && future.data != null && future.data.shouldReuse) {
            return future.routeConfig === curr.routeConfig;
        }
        else {
            return false;
        }
    }*/

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if(this.debugMode == true) console.log('CustomReuseStrategy:shouldReuseRoute', future, curr);
        if(this.debugMode == true) console.log('CustomReuseStrategy:shouldReuseRoute handlers=', this.handlers);
        // future.data enthält NICHT die data aus AppModule Routes !!! (die logik war wohl aufgrund dieser Annahme schon immer falsch!) - stattdessen aus den zwischengespeicherten handlers ermitteln!
        let futureUrl = future['_routerState'].url;
        if(futureUrl.startsWith("/")) futureUrl = futureUrl.substr(1,futureUrl.length-1);
        //console.log('CustomReuseStrategy:shouldReuseRoute futureUrl=', futureUrl);
        let handlerFutureUrl = this.handlers[futureUrl];
        //console.log('CustomReuseStrategy:shouldReuseRoute handlerFutureUrl=', handlerFutureUrl);
        let futureData = handlerFutureUrl != null ? handlerFutureUrl.data : null
        //console.log('CustomReuseStrategy:shouldReuseRoute futureData=', futureData);
        //return futureData.shouldReuse || false;
        if(future != null && futureData != null && futureData.shouldReuse) {
            //console.log('CustomReuseStrategy:shouldReuseRoute future != null && futureData.shouldReuse');
            // gibt es filter ? nur reUsen, wenn von xxx kommend ?
            let previousOK : boolean = this.checkPreviousPath(futureData, futureUrl, curr != null ? curr['_routerState']['url'] : null, true); // true = deleteIfNotOK = das ist noch Logik aus vor cleanup() Zeiten, vorsichtshalber drin gelassen, ist aber möglicherweise seit cleanup() überflüssig ?

            if(previousOK) {
                if(this.debugMode == true) console.log('CustomReuseStrategy:shouldReuseRoute RETURN routeConfig');
                return future.routeConfig === curr.routeConfig;
            }
            else {
                if(this.debugMode == true) console.log('CustomReuseStrategy:shouldReuseRoute RETURN false');
                return false;
            }
        }
        else {
            if(this.debugMode == true) console.log('CustomReuseStrategy:shouldReuseRoute future == null || !futureData.shouldReuse');
            if(this.debugMode == true) console.log('CustomReuseStrategy:shouldReuseRoute RETURN false');
            return false;
        }
    }

    // den 'shouldReuseWhenPreviousPath' prüfen
    checkPreviousPath(data: any, futureUrl: any, url: string, deleteIfNotOK: boolean) { // data z.B. = futureData // url = z.B. curr['_routerState']['url'] // deleteIfNotOK = das ist noch Logik aus vor cleanup() Zeiten, vorsichtshalber drin gelassen, ist aber möglicherweise seit cleanup() überflüssig ?
        let previousOK : boolean = false;
        if(data.shouldReuseWhenPreviousPath == null) { // es gibt kein "shouldReuseWhenPreviousPath" - Filter!
            if(this.debugMode==true) console.log('CustomReuseStrategy:shouldReuseRoute no filter to check ... previousOK!');
            previousOK = true;
        }
        else {
            if(url != null) {
                for(let i:number = 0; i<data.shouldReuseWhenPreviousPath.length; i++) {
                    let filter:string = data.shouldReuseWhenPreviousPath[i];
                    if(this.debugMode==true) console.log('CustomReuseStrategy:shouldReuseRoute checking filter:', filter);
                    if(filter.endsWith("*")) {
                        let filterHelper:string = filter.substr(0,filter.length-1);
                        if(url.startsWith('/'+filterHelper)) { // die filter in AppModule sind wie die Pfade auch - ohen führendes slash geschrieben
                            previousOK = true;
                            if(this.debugMode==true) console.log('CustomReuseStrategy:shouldReuseRoute filter match ... previousOK!', filter);
                            break;
                        }
                    }
                }
                // alle filter geprüft, aber kein Treffer: dann müssen wir den gespeicherten handler löschen, da
                // angular trotz dem return false shouldAttach() aufruft!
                // Wir müssen also dafür sorgen, dass shouldAttach kein gespeicherten handler mehr hat, den es zurückgeben kann.

                // seit US 13411 sollte das eigentlich überflüssig sein, da die "nicht mehr benötigten handler" schon 
                // zu einem früheren Zeitpunkt von cleanup() bereinigt werden, aber vorsichtshalber drin lassen.
                // dürfte aber eigentlich nie vorkommen:
                if(deleteIfNotOK) {
                    if(!previousOK) {
                        delete this.handlers[futureUrl];
                        if(this.debugMode == true) console.log('CustomReuseStrategy:shouldReuseRoute no filter match! deleted stored handler for '+futureUrl);
                    }
                }
            }
            else {
                if(this.debugMode == true) console.log('CustomReuseStrategy:shouldReuseRoute skipping filter-check, since url == null');
            }
        }
        return previousOK;
    }
}
