import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IArtikel } from '../_interfaces/artikel';
import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';
import { ILieferart } from '../_interfaces/lieferart';

@Injectable()
export class _ArtikelImportService extends CRUDBasicService {

  downloadVorlage()/*: Observable<>*/ { // https://www.illucit.com/angular/angular-5-httpclient-file-download-with-authentication/
    return this.httpClient.get<IArtikel>(this.apiBaseUrl + 'artikelimport/downloadtemplate', { headers: httpOptions, observe: 'body', withCredentials: false/* TODO */, responseType: 'blob' as 'json' }) 
      .pipe(map((response) => response), catchError(this.handleError));
      //.map(res => res);
  }

  uploadArtikelImport(fileToUpload: File, artikelbildForCreate: /*IArtikelbild*/any, callbackInstance:any, callbackProgress:any, callbackSuccess:any, callbackError:any, hersteller: boolean, artikel: boolean, vorschau: boolean, unternehmen: number) {
    //https://code-maze.com/upload-files-dot-net-core-angular/
    //let fileToUpload : File = event.files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    
    // nicht nur das file uploaden, sondern auch die Entity 
    formData.append('artikelImport.dateiname', artikelbildForCreate.dateiname);
    formData.append('artikelImport.dateiendung', artikelbildForCreate.dateiendung);
    formData.append('artikelImport.bezeichnung', artikelbildForCreate.bezeichnung);
    formData.append('artikelImport.unternehmen', artikelbildForCreate.unternehmen);

    let thisInstance = this;
    this.httpClient.post(this.apiBaseUrl + 'artikelimport/upload/' + hersteller + '/' + artikel + '/' + vorschau + '/' + unternehmen, formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        console.log("ArtikelImportService.upload() event:", event);
        if (event.type === HttpEventType.UploadProgress) {
          let progress = Math.round(100 * event.loaded / event.total);
          console.log("ArtikelImportService.upload() progress:", progress);
          callbackProgress(callbackInstance, progress);
        }
        else if (event.type === HttpEventType.Response) {
          console.log("ArtikelbildService.upload() success!");
          callbackSuccess(callbackInstance, event.body);
        }
      }
      
      , function (error) { 
        //return thisInstance.handleError(error); 
        callbackError(callbackInstance, error);
      }
            
      );
    }

    initializeArtikelImport(): /*IArtikelImport*/any {
      return {
        bezeichnung: '',
        dateiname: '',
        dateiendung: '',
        unternehmen: 0
      };
    }
}
