import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { UnternehmenDetailComponent } from './unternehmen-detail.component';
//import { GlobalService } from '../_services/global.service';
import { TranslateService } from '../_services/translate.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class UnternehmenDetailGuard implements CanActivate {

  constructor(//private globalService: GlobalService,
    public translate: TranslateService,
    private authService: AuthService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if(this.authService.isAdmin() == true) return true; 
    return this.authService.isMyUnternehmen(next.params.id);
  }

  canDeactivate(component: UnternehmenDetailComponent): boolean {
    if (component.CRUDForm.dirty && !component.deleted && !component.errorMessage) {
      //let bezeichnung = component.CRUDForm.get('bezeichnung').value || "#pageTitleAdd#";
      //return false;
      return confirm(this.translate.instant('ERROR_UNSAVED_CHANGES'));
    }

    return true;
  }
}
