import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ViewChild, ElementRef, HostListener, Inject, forwardRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MenuItem, MessageService} from 'primeng/api';
import {SelectItem} from 'primeng/api';
import { Image } from '../../domain/image';
import {SelectItemGroup} from 'primeng/api';
import { CountryService } from '../../service/countryservice';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {FileUploadModule} from 'primeng/fileupload';
import { PhotoService } from '../../service/photoservice.service';
import {GalleriaModule} from 'primeng/galleria';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {EditorModule} from 'primeng/editor';
import {MultiSelectModule} from 'primeng/multiselect';
import {NodeService} from '../../service/nodeservice';
import {TreeNode} from 'primeng/api';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FieldsetModule} from 'primeng/fieldset';
import {CalendarModule} from 'primeng/calendar';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';

import { IArtikelgruppe } from '../../../_interfaces/artikelgruppe';
import { ArtikelgruppeService } from '../../../_services/artikelgruppe.service';
import { IHersteller } from 'src/app/_interfaces/hersteller';
//import { HerstellerService } from 'src/app/_services/hersteller.service';
import { ILager } from 'src/app/_interfaces/lager';
import { IMengeneinheit } from 'src/app/_interfaces/mengeneinheit';
import { IArtikelzustand } from 'src/app/_interfaces/artikelzustand';
import { IBenutzer } from 'src/app/_interfaces/benutzer';
import { CRUDBasicDetailComponent } from 'src/app/crud-basic-detail/crud-basic-detail.component';

declare var jquery: any;
declare var $: any;

interface City {
  name: string;
  code: string;
}
@Component({
  selector: 'app-produkt-details',
  templateUrl: './produkt-details.component.html',
  //providers: [MessageService] // darf nicht doppelt enthalten sein (in app.module und nochmal hier!)
  styles: [`
  :host ::ng-deep button {
      margin-right: .25em;
  }

  :host ::ng-deep .ui-splitbutton {
      margin-left: .25em;
  }

  :host ::ng-deep .ui-splitbutton button {
      margin-right: 0;
  }
`]

  
})
export class ProduktDetailsComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  artikelForm: FormGroup;

//Galleria
  images: Image[];

    responsiveOptions:any[] = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];
  //Galleria endet hier
 //Kalender
  date9: Date;
  date0: Date[];
  minDate: Date;
  maxDate: Date;
  de: any;
  invalidDates: Array<Date>
 //Kalender endet hier

 // Checkbox
  selectedValues: string[] = [];
  value: boolean;
 // Checkbox eindet hier
 items: MenuItem[];

  cities2: City[];

 selectedCity1: City;
 
 text: string;

 name: string;
 code: string;

 uploadedFiles: any[] = [];

 checkedone: boolean = false;
 uncheckedone: boolean = false;
 checkedtwo: boolean = false;
 uncheckedtwo: boolean = false;
uncheckedfive: boolean = false;
 //Lieferart Multiselect
 cars : SelectItem[];
 selectedCars1: string[] = [];
 selectedCars2: string[] = [];
 multiSelect: SelectItem[];
 item: string;
 // Lieferart Multiselect endet hier



 

 // CRUDItem
 dataId: /*number*/any;
 CRUDItem: /*IAnrede*/any;
 pageTitle: string;

 // Artikelgruppe
 artikelGruppeItems: IArtikelgruppe[];
 artikelGruppeTreeNodes: TreeNode[];
 artikelGruppeSelected: TreeNode;
 artikelGruppeDisplayAuswahlPopUp: boolean;
 artikelGruppeBreadCrumbItems: MenuItem[];
 artikelGruppeBreadCrumbHome = {icon: 'pi pi-home'/*, routerLink: '/'*/};
 
 // Hersteller
 hersteller: IHersteller = null;

 // Lager
 lager: ILager = null;

 // Mengeneinheit
 mengeneinheit: IMengeneinheit = null;

 // Preisvorstellung
 preisvorstellungPro: any;
 preisvorstellungProItems: SelectItem[];

 // Artikelzustand
 artikelzustand: IArtikelzustand = null;

 constructor (private fb: FormBuilder,
              private countryService: CountryService, 
              private messageService: MessageService, 
              private photoService: PhotoService, 
              private nodeService: NodeService,
              private route: ActivatedRoute,
              private artikelgruppeService: ArtikelgruppeService,
              //private herstellerService: HerstellerService

              ) {  

                this.route.params.subscribe(
                  params => {
                    this.dataId = +params['id'];
                    if (this.dataId == 0) {
                      this.pageTitle = "Artikel erstellen"
                    }
                    else {
                      this.pageTitle = "Artikel bearbeiten";
                    }
                  });
                
        this.preisvorstellungProItems = [
            {label:'Pro Stück', value: 'S'},
            {label:'Pauschal', value: 'P'},
           
        ]; 
        
        this.nodeService.getFiles().then(files => {
          //this.artikelGruppeTreeNodes = files;
          //console.log("ProduktDetailsComponent.constructor() getArtikelgruppenChildrenForParent (1) artikelGruppeTreeNodes:", this.artikelGruppeTreeNodes);
          //let artikelGruppeTreeNodesdbg = files;
          //console.log("ProduktDetailsComponent.constructor() getArtikelgruppenChildrenForParent (1) artikelGruppeTreeNodesdbg:", artikelGruppeTreeNodesdbg);
        });
        
        // es ist sinnfrei, nur die Parent zu laden - und dann nachzuladen, weil!
        // dann funktioniert die Suche nicht!
        /*
        let thisInstance = this;
        artikelgruppeService.getArtikelgruppenChildrenForParent(0).subscribe(function (response) {
          let allFetchedArtikelGruppen:IArtikelgruppe[] = response.artikelgruppen;
          console.log("ProduktDetailsComponent.constructor() getArtikelgruppenChildrenForParent reponse:", response);
          //_this.onItemsRetrieved(response.mandantenBankkonten);
          thisInstance.artikelGruppeTreeNodes = [];
          response.artikelgruppen.forEach(artikelGruppe => {
            let children:TreeNode[] = [];
            artikelGruppe.inverseArtikelgruppe.forEach(inverseArtikelgruppe => {
              let newChildTreeNode:TreeNode = {
                children: null,
                collapsedIcon: "ui-icon-folder",
                data: inverseArtikelgruppe.bezeichnung,
                expandedIcon: "ui-icon-folder-open",
                label: inverseArtikelgruppe.bezeichnung
              }            
              children.push(newChildTreeNode);
            });

            let newTreeNode:TreeNode = {
              children: children,
              collapsedIcon: "ui-icon-folder",
              data: artikelGruppe.bezeichnung,
              expandedIcon: "ui-icon-folder-open",
              label: artikelGruppe.bezeichnung
            }            
            thisInstance.artikelGruppeTreeNodes.push(newTreeNode);
          });
          console.log("ProduktDetailsComponent.constructor() getArtikelgruppenChildrenForParent artikelGruppeTreeNodes:", this.artikelGruppeTreeNodes);
        }, function (error) { return this.handleError(error); });
        */

       // alle Artikelgruppen auf einmal laden!
       let thisInstance = this;
       artikelgruppeService.getArtikelgruppenChildrenForTree().subscribe(function (response) {
         thisInstance.artikelGruppeItems = response.artikelgruppen;
         console.log("ProduktDetailsComponent.constructor() getArtikelgruppenCollection thisInstance.artikelGruppeItems:", thisInstance.artikelGruppeItems);
         thisInstance.artikelGruppeTreeNodes = [];
         thisInstance.artikelGruppeItems.filter(f => f.artikelgruppeId == null).forEach(artikelGruppe => {
           //thisInstance.artikelGruppeTreeNodes.push(newTreeNode);
           thisInstance.artikelGruppeTreeNodes.push(thisInstance.artikelGruppePrepareTreeNode(thisInstance, 0, artikelGruppe));
         });
         console.log("ProduktDetailsComponent.constructor() getArtikelgruppenChildrenForParent thisInstance.artikelGruppeTreeNodes:", thisInstance.artikelGruppeTreeNodes);
       }, function (error) { return this.handleError(error); });

        this.cars = [
          {label: 'Audi', value: 'Audi'},
          {label: 'BMW', value: 'BMW'},
          {label: 'Fiat', value: 'Fiat'},
          {label: 'Ford', value: 'Ford'},
          {label: 'Honda', value: 'Honda'},
          {label: 'Jaguar', value: 'Jaguar'},
          {label: 'Mercedes', value: 'Mercedes'},
          {label: 'Renault', value: 'Renault'},
          {label: 'VW', value: 'VW'},
          {label: 'Volvo', value: 'Volvo'},
        ];
        
        this.multiSelect = [];
        for(let i = 0; i < 10000; i++) {
          this.multiSelect.push({label: 'Item' + i, value: 'multiSelect ' + i});
        }
      
     }
     artikelGruppeShowAuswahlPopUp() {
      this.artikelGruppeDisplayAuswahlPopUp = true;
  }

  saveArtikel() {
    console.log("ProduktDetailsComponent.saveArtikel() artikelForm:", this.artikelForm);
  }

  artikelGruppePrepareTreeNode(instance: any, level:number, artikelGruppe:IArtikelgruppe) {
    //console.log("ProduktDetailsComponent.prepareArtikelGruppeTreeNode() level/artikelGruppe", level, artikelGruppe.bezeichnung);
    let children:TreeNode[] = [];
    artikelGruppe.inverseArtikelgruppe.forEach(child => {
      children.push(this.artikelGruppePrepareTreeNode(this, level+1, child));
    });
    let newTreeNode:TreeNode = {
      children: children,
      collapsedIcon: "ui-icon-folder",
      data: { // data enthält id und parentId der artikelGruppe
        id: artikelGruppe.id, 
        artikelgruppeId: artikelGruppe.artikelgruppeId
      },
      expandedIcon: "ui-icon-folder-open",
      label: artikelGruppe.bezeichnung,
      key: ""+artikelGruppe.id // ohne den "key" funktioniert die Children-Auswahl NACH FILTER nicht! https://forum.primefaces.org/viewtopic.php?t=58524
    };
    return newTreeNode;
  }

  artikelGruppeOnSelected(event, thisInstance:any) {
    console.log("ProduktDetailsComponent.artikelGruppeOnSelected() event/thisInstance", event, thisInstance);
    this.artikelGruppeSelected = event.node;
    this.artikelGruppeDisplayAuswahlPopUp = false;
    this.artikelGruppeBuildBreadCrumb();
  }

  artikelGruppeBuildBreadCrumb() {
    console.log("ProduktDetailsComponent.artikelGruppeBuildBreadCrumb()");
    this.artikelGruppeBreadCrumbItems = [];
    let item = this.artikelGruppeSelected;
    this.artikelGruppeBreadCrumbItems.push({label: item.label});
    console.log("ProduktDetailsComponent.artikelGruppeBuildBreadCrumb() item:", item);
    while(item.data.artikelgruppeId != null) {
      console.log("ProduktDetailsComponent.artikelGruppeBuildBreadCrumb() searching for id="+item.data.artikelgruppeId+" in this.artikelGruppeItems:", this.artikelGruppeItems);
      let artikelGruppeParent = this.artikelGruppeItems.find(f => f.id == item.data.artikelgruppeId);
      if(artikelGruppeParent != null) {
        item = {
          data: { // data enthält id und parentId der artikelGruppe
            id: artikelGruppeParent.id, 
            artikelgruppeId: artikelGruppeParent.artikelgruppeId
          },
          label: artikelGruppeParent.bezeichnung
        }
        this.artikelGruppeBreadCrumbItems.unshift({label: item.label}); // unshift statt push -> an ANFANG des Arrays!
      }
      else { // parent (warum auch immer) nicht gefunden -> break
        break;
      }
    }
    console.log("ProduktDetailsComponent.artikelGruppeBuildBreadCrumb() artikelGruppeBreadCrumbItems:", this.artikelGruppeBreadCrumbItems);
  }

  onUpload(event) {
        for(let file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    }
  ngOnInit(): void {
    this.artikelForm = this.fb.group({
      hersteller: ['', Validators.required],
      lager: ['', Validators.required],
      mengeneinheit: ['', Validators.required],
      preisvorstellungPro: ['', Validators.required],
      artikelzustand: ['', Validators.required],

      dummy: ['', Validators.required],
    });



    this.de = {
      firstDayOfWeek: 0,
      dayNames: [ "Sonntag","Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag"],
      dayNamesShort: [ "Son","Mon","Die","Mit","Don","Fre","Sam"],
      dayNamesMin: [ "So","Mo","Di","Mi","Do","Fr","Sa"],
      monthNames: [ "Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"],
      monthNamesShort: [ "Jan","Feb","Mär","Apr","Mai","Jun","Jul","Aug","Sep","Okt","Nov","Dez"],
      today: 'Heute',
      clear: 'Löschen',
    };
    
    this.photoService.getImages().then(images => this.images = images)
    this.items = [
      {label: 'Angular.io', icon: 'pi pi-external-link', url: 'http://angular.io'},
      {label: 'Theming', icon: 'pi pi-palette'}
    ];
   
   }
  
  ngAfterViewInit(): void {
  }
  country: any;
  countries: any[];
  filteredCountriesSingle: any[];
  filteredCountriesMultiple: any[];
  brands: string[] = ['Audi','BMW','Fiat','Ford','Honda','Jaguar','Mercedes','Renault','Volvo','VW'];
  filteredBrands: any[];
  brand: string;
  

  filterCountrySingle(event) {
    let query = event.query;        
    this.countryService.getCountries().then(countries => {
        this.filteredCountriesSingle = this.filterCountry(query, countries);
    });
}
filterCountry(query, countries: any[]):any[] {
  //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
  let filtered : any[] = [];
  for(let i = 0; i < countries.length; i++) {
      let country = countries[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }
  return filtered;
}
filterBrands(event) {
  this.filteredBrands = [];
  for(let i = 0; i < this.brands.length; i++) {
      let brand = this.brands[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
          this.filteredBrands.push(brand);
      }
  }
}
}


  