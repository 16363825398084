export class GuessMIMEType {

  guessMIMEType(/*bytes: any[]*/base64: string) {
    // idee basiert auf: https://stackoverflow.com/questions/49664561/determine-if-a-base64-string-or-a-buffer-contains-jpeg-or-png-without-metadata
    //console.log("MandantenDetailComponent.guessMIMEType() byte 0: ", bytes[0]);
    //console.log("MandantenDetailComponent.guessMIMEType() byte 1: ", bytes[1]);
    //if(bytes.length>=8 && bytes[0] == 137 && bytes[1] == 80 && bytes[2] == 78 && bytes[3] == 71 && bytes[4] == 13 
    //  && bytes[5] == 10 && bytes[6] == 26 && bytes[7] == 10) { // PNG
    if(base64.length>=8 && base64.substring(0,11)=="iVBORw0KGgo") { // PNG
        return "png";
      }
    //else if(bytes.length>=2 && bytes[0] == 255 && bytes[1] == 216) { // JPEG
    else if(base64.length>=2 && base64.substring(0,2)=="/9") { // JPEG
        return "jpeg";
      }
    else return "*"; // unbekannt
  }
}
