import { Component, OnInit } from '@angular/core';
import {GalleriaModule} from 'primeng/galleria';
import { PhotoService } from '../../service/photoservice.service';
import { Image } from '../../domain/image';
import {MenuItem, MessageService } from 'primeng/api';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {DialogModule} from 'primeng/dialog';
import { ColorPicker } from 'primeng';
import { ArtikelbildService } from 'src/app/_services/artikelbild.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { IArtikel } from '../../../_interfaces/artikel';




@Component({
  selector: 'app-detail-artikel-ansicht',
  templateUrl: './detail-artikel-ansicht.component.html',
  styleUrls: ['./detail-artikel-ansicht.component.css'],
  //providers: [MessageService] // darf nicht doppelt enthalten sein (in app.module und nochmal hier!)
  styles: [`
  :host ::ng-deep button {
      margin-right: .25em;
  }

  :host ::ng-deep .ui-splitbutton {
      margin-left: .25em;
  }

  :host ::ng-deep .ui-splitbutton button {
      margin-right: 0;
  }
`]
})

export class DetailArtikelAnsichtComponent implements OnInit {
  
isActive = false;
  images: Image[];

    responsiveOptions:any[] = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];
    displayModal: boolean;
    displayPosition: boolean;
    position: string;

  items: MenuItem[];

  items2: MenuItem[];
  uploadedFiles: any[] = [];
  
  constructor(private photoService: PhotoService, private messageService: MessageService, private breadcrumbService: BreadcrumbService,
  private artikelbildService: ArtikelbildService) {
    this.breadcrumbService.setItems([
      { label: 'Components' },
      { label: 'Artikelansicht', routerLink: ['/artikel-ansicht'] }
      
  ]);
   }

   
    
  
  onUpload(event) {
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }

    this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
}

  ngOnInit(): void {
    var bla = this.isActive;
    this.photoService.getImages().then(images => this.images = images)
    this.items = [
      {label: 'Angular.io', icon: 'pi pi-external-link', url: 'http://angular.io'},
      {label: 'Theming', icon: 'pi pi-palette'}
  ];
  this.items2 = [
    {label: 'Home', icon: 'pi pi-fw pi-home'},
    {label: 'Calendar', icon: 'pi pi-fw pi-calendar'},
    {label: 'Edit', icon: 'pi pi-fw pi-pencil'},
    {label: 'Documentation', icon: 'pi pi-fw pi-file'},
    {label: 'Settings', icon: 'pi pi-fw pi-cog'}
];
  }
  changeActivity() {
    this.isActive = !this.isActive;
  }
  showModalDialog() {
    this.displayModal = true;
    
}
}

