import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IArtikelbild } from '../_interfaces/artikelbild';
import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class ArtikelbildService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'guid', type: 'Guid'},
    {name: 'sortierung', type: 'int'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'beschreibung', type: 'string'},
    {name: 'artikelId', type: 'int'},
    {name: 'artikel', type: 'object'},
    {name: 'summary', type: 'string'},
    {name: 'bild', type: 'byte[]'},
  ];
    
  getArtikelbilderCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ artikelbilder: IArtikelbild[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'artikelbilder?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { artikelbilder: <IArtikelbild[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getArtikelbild(id: number): Observable<IArtikelbild> {

    if (id === 0) {
      return Observable.create((observer: any) => {
        observer.next(this.initializeArtikelbild());
        observer.complete();
      })
    }

    return this.httpClient.get<IArtikelbild>(this.apiBaseUrl + 'artikelbilder/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  saveArtikelbild(artikelbild: IArtikelbild): Observable<IArtikelbild> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(artikelbild); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("ArtikelbildService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("ArtikelbildService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (artikelbild.id === 0) {
      return this.createArtikelbild(/*artikelbild*/itemToSave);
    }

    return this.updateArtikelbild(/*artikelbild*/itemToSave)

  }

  updateArtikelbild(artikelbild: IArtikelbild): Observable<IArtikelbild> {

    return this.httpClient.put<IArtikelbild>(this.apiBaseUrl + 'artikelbilder/' + artikelbild.id, artikelbild, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteArtikelbild(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'artikelbilder/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createArtikelbild(artikelbild: IArtikelbild): Observable<IArtikelbild> {
    return this.httpClient.post<IArtikelbild>(this.apiBaseUrl + 'artikelbilder', artikelbild, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  createArtikelbildTemp(artikelbild: IArtikelbild): Observable<IArtikelbild> {
    return this.httpClient.post<IArtikelbild>(this.apiBaseUrl + 'artikelbilder/CreateArtikelbildTemp', artikelbild, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  uploadArtikelbildToTemp(fileToUpload: File, artikelbildForCreate: IArtikelbild, callbackInstance:any, callbackProgress:any, callbackSuccess:any) {
      //https://code-maze.com/upload-files-dot-net-core-angular/
      //let fileToUpload : File = event.files[0];
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      
      // nicht nur das file uploaden, sondern auch die Entity "Artikelbild"
      formData.append('artikelbild.dateiname', artikelbildForCreate.dateiname);
      formData.append('artikelbild.dateiendung', artikelbildForCreate.dateiendung);
      formData.append('artikelbild.bezeichnung', artikelbildForCreate.bezeichnung);
      formData.append('artikelbild.sortierung', ""+artikelbildForCreate.sortierung);

      this.httpClient.post(this.apiBaseUrl + 'artikelbilder/uploadtotemp', formData, {reportProgress: true, observe: 'events'})
        .subscribe(event => {
          console.log("ArtikelbildService.uploadArtikelbild() event:", event);
          if (event.type === HttpEventType.UploadProgress) {
            let progress = Math.round(100 * event.loaded / event.total);
            console.log("ArtikelbildService.uploadArtikelbild() progress:", progress);
            callbackProgress(callbackInstance, progress);
          }
          else if (event.type === HttpEventType.Response) {
            console.log("ArtikelbildService.uploadArtikelbild() success!");
            callbackSuccess(callbackInstance, event.body);
          }
        });
  }

  //getHTTPClient() {
  //  return this.httpClient;
  //}
  //getApiBaseUrl() {
  //  return this.apiBaseUrl;
  //}

  getDownloadUrl(guid : string, dateiendung : string) {
    //console.log("ArtikelbildService.getDownloadUrl() guid:", guid);
    return this.apiBaseUrl + 'artikelbilder/downloaddirect/' + guid +"/"+dateiendung;
  }
  getThumbDownloadUrl(guid : string, dateiendung : string) {
    //console.log("ArtikelbildService.getDownloadUrl() guid:", guid);
    return this.apiBaseUrl + 'artikelbilder/downloadthumbdirect/' + guid +"/"+dateiendung;
  }

  getThumbDownloadUrlForTitelbildArtikel(id: number, rowVersion: string) {
    //console.log("ArtikelbildService.getThumbDownloadUrlForTitelbildArtikel() id:", id);
    return this.apiBaseUrl + 'artikelbilder/downloadthumbdirecttitelbildartikel/' + id + '?rowVersion='+rowVersion;
  }

  initializeArtikelbild(): IArtikelbild {
    return {
      id: 0,
      createdBy: '',
      created: '',
      modifiedBy: '',
      modified: '',
      rowVersion: '',
      guid: null,
      sortierung: 0,
      bezeichnung: '',
      beschreibung: '',
      artikelId: null,
      artikel: null,
      summary: '',
      dateiname: '',
      dateiendung: '',
      _bild: null
    };
  }


}
