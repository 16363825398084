<ul class="ultima-menu ultima-main-menu clearfix" style="padding-bottom: 0px">
    <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>

    <!--<li>
        <a routerlinkactive="active-menuitem-routerlink" class="ripplelink ng-star-inserted" ng-reflect-router-link-active="active-menuitem-routerlink" 
        ng-reflect-router-link="/artikel-uebersicht?type=myItems" 
        (click)="routeToMyItems()"><i class="material-icons ng-tns-c207-30 ng-star-inserted">apps</i><span>Meine Artikel</span></a>
    </li>--><!--href="#/artikel-uebersicht?type=myItems"-->

    <!-- das folgende bitte nicht LÖSCHEN! sondern ggf. nur auskommentieren! (AM braucht das zum Test) -->
    <!--<li>
        <a routerlinkactive="active-menuitem-routerlink" class="ripplelink ng-star-inserted" ng-reflect-router-link-active="active-menuitem-routerlink" 
        ng-reflect-router-link="/unternehmen/0?email=neuer@user.de&username=neuerUserName" 
        href="#/unternehmen/0?email=neuer@user.de&username=neuerUserName"><i class="material-icons ng-tns-c207-30 ng-star-inserted">apps</i><span>Neuer Benutzer (Teil 2)</span></a>
    </li>-->

    <!--<li>
    <a routerlinkactive="active-menuitem-routerlink" class="ripplelink ng-star-inserted" ng-reflect-router-link-active="active-menuitem-routerlink" 
    ng-reflect-router-link="{{urlAPIDownloadArtikelImportTemplate}}" 
    href="{{urlAPIDownloadArtikelImportTemplate}}"><i class="material-icons ng-tns-c207-30 ng-star-inserted">apps</i><span>Import-Vorlage</span></a>
    </li>-->

</ul>

<p-tree id="appMenuArtikelgruppenTree" [style]="{'width': '100%'}" [value]="artikelGruppeTreeNodes" [filter]="true"
selectionMode="single" [propagateSelectionUp]="false" [propagateSelectionDown]="false"
(onNodeSelect)="artikelGruppeOnSelected($event, this)"></p-tree>
