
    &nbsp;<b style="font-size: large; color: red;">Gesuchte Artikel</b>

    

<!--&nbsp;&nbsp;&nbsp;debugMode&nbsp;<p-inputSwitch [(ngModel)]="debugMode"></p-inputSwitch>-->
<br>

<div id="virtualscrollerDiv" style="height: auto; width: 100vw; overscroll-behavior: none; display: contents !important;">
    <p-virtualScroller [style]="{'overscroll-behavior': 'none', 'width': '100%', 'margin-top': '10px'}" [value]="virtualScrollerItems" [scrollHeight]="scrollHeight"
    
    [itemSize]="150" [rows]="rows"
    [lazy]="true" (onLazyLoad)="loadDataVirtualScroller($event)">
    <ng-template let-artikel pTemplate="item">
        <div style="border-style: dotted; border-color: lightgrey; border-width: 0.25px; height: 100%;">
            <div class="p-grid" style="margin: 0.375em;">
                <!-- wenn keine Bilder, dann grid ui-12, ansonsten ui-12 weil dahinter noch ein Bild kommt -->
                <div [ngClass]="{'ui-g-12 ui-md-12': (artikel != null && artikel.artikelbilder != null && artikel.artikelbilder.length > 0) == false, 'ui-g-8 ui-md-8': artikel != null && artikel.artikelbilder != null && artikel.artikelbilder.length > 0}" 
                (click)="selectArtikel($event, artikel)">
                    <b>{{artikel != null ? artikel.bezeichnung: ''}}</b><br>
                    <span *ngIf="artikel.distance != 0">{{artikel.distance | crudBasic:null:(crudItemService.propertyTypes!=null&&crudItemService.propertyTypeOf!=null?crudItemService.propertyTypeOf('distance'):null)}} km</span>
                    <span *ngIf="artikel.distance == 0">0 km</span> |
                    <span *ngIf="artikel != null && artikel.lager != null && artikel.lager.geodaten != null" style="display: inline-flex;"> {{artikel.lager.geodaten.ort}}</span>
                    <span *ngIf="artikel != null && artikel.lager == null && artikel.unternehmen != null && artikel.unternehmen.geodaten != null" style="display: inline-flex;"> {{artikel.unternehmen.geodaten.ort}}</span>
                    <br>
                    <span *ngIf="artikel != null"><small>{{artikel.modified | datumOhneZeit}}</small></span>
                    <br>
                    <span *ngIf="debugMode == true && artikel != null"><small>{{getSortInfo(artikel)}}</small></span>
                    <br>
                    <p *ngIf="artikel != null && artikel.beschreibung != null && artikel.beschreibung.length > 0" class="textRight artikelBeschreibung" style="display: table-cell;"
                        [innerHTML]="artikel.beschreibung"></p>
                </div>
                <div *ngIf="artikel != null && artikel.artikelbilder != null && artikel.artikelbilder.length > 0"
                        class="ui-g-4 ui-md-4"><!---->

                    <div class="artikelScrollerSucheImageDiv"><img (click)="selectArtikel($event, artikel)"
                        class="artikelScrollerSucheImageImg" [src]="artikelTitelBildURL(artikel)"
                        (error)="onImgError($event)">
                    </div>
    
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template let-artikel pTemplate="loadingItem">
        <p-progressSpinner [style]="{width: '2em', height: '2em'}" strokeWidth="2"></p-progressSpinner>
    </ng-template>
    </p-virtualScroller>
</div>
