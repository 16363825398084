<div class="ui-g">
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>DataTable</h1>
            <p-table #dt [columns]="cols" styleClass="ui-table-cars" [value]="cars1" [paginator]="true" [rows]="10" dataKey="vin" selectionMode="single" [(selection)]="selectedCar">
                <ng-template pTemplate="caption">
                    DataTable
                    <div class="ui-table-globalfilter-container">
                        <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:250px;">
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngSwitch]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th style="width: 8em; text-align: center;">
                            <button pButton type="button" icon="ui-icon-settings"></button>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field" style="padding: .5em;">
                            <input *ngSwitchCase="'vin'" pInputText type="text" placeholder="Starts with" class="ui-column-filter" style="width: 100%; margin-top: 0;" (input)="dt.filter($event.target.value, col.field, 'startsWith')">
                            <input *ngSwitchCase="'year'" pInputText type="text" placeholder="Contains" class="ui-column-filter" style="width: 100%; margin-top: 0;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                            <p-dropdown *ngSwitchCase="'brand'" appendTo="body" [options]="brands" styleClass="ui-column-filter" [showClear]="true" [style]="{'min-width':'1em', 'width':'100%', 'margin-top':'0'}" placeholder="Select a Brand" (onChange)="dt.filter($event.value, col.field, 'equals')">
                                <ng-template let-item pTemplate="selectedItem">
                                    <img src="assets/demo/images/car/{{item.label}}.gif" style="width:16px;vertical-align:middle" />
                                    <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
                                </ng-template>
                                <ng-template let-car pTemplate="item">
                                    <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                        <img src="assets/demo/images/car/{{car.label}}.gif" style="width:24px;position:absolute;top:1px;left:5px"/>
                                        <div style="font-size:14px;float:right;margin-top:4px">{{car.label}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <p-multiSelect *ngSwitchCase="'color'" [options]="colors" [filter]="false"  styleClass="ui-column-filter" [style]="{'width':'100%', 'margin-top':'0'}"  defaultLabel="Select a color" (onChange)="dt.filter($event.value, col.field, 'in')"></p-multiSelect>
                        </th>
                        <th style="width: 8em;">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                        <td>{{rowData.vin}}</td>
                        <td>{{rowData.year}}</td>
                        <td>
                            <img src="assets/demo/images/car/{{rowData.brand}}.gif" style="width: 50px; margin-right: 1em; vertical-align: middle;">
                            {{rowData.brand}}
                        </td>
                        <td>{{rowData.color}}</td>
                        <td>
                            <button pButton type="button" class="ui-button-success" icon="ui-icon-search" style="margin-right: .5em"></button>
                            <button pButton type="button" class="orange-btn" icon="ui-icon-edit"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>DataView</h1>
            <p-dataView #dv [value]="cars2" [paginator]="true" [rows]="10" paginatorPosition="both" filterBy="brand"
                        [sortField]="sortField" [sortOrder]="sortOrder">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="ui-g">
                            <div class="ui-g-12 ui-md-4">
                                <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By" (onChange)="onSortChange($event)" [style]="{'min-width':'140px'}"></p-dropdown>
                            </div>
                            <div class="ui-g-6 ui-md-4 filter-container">
                                <div style="position:relative">
                                    <input type="search" pInputText placeholder="Search by brand" (keyup)="dv.filter($event.target.value)">
                                </div>
                            </div>
                            <div class="ui-g-6 ui-md-4" style="text-align:right">
                                <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                            </div>
                        </div>
                    </div>
                </p-header>
                <ng-template let-car pTemplate="listItem">
                    <div class="ui-g-12">
                        <div class="car-details-list">
                            <div>
                                <img src="assets/demo/images/car/{{car.brand}}.gif">
                                <div class="ui-g">
                                    <div class="ui-g-12">Vin: <b>{{car.vin}}</b></div>
                                    <div class="ui-g-12">Year: <b>{{car.year}}</b></div>
                                    <div class="ui-g-12">Brand: <b>{{car.brand}}</b></div>
                                    <div class="ui-g-12">Color: <b>{{car.color}}</b></div>
                                </div>
                            </div>
                            <button pButton type="button" icon="ui-icon-search"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template let-car pTemplate="gridItem">
                    <div style="padding:.5em" class="ui-g-12 ui-md-3">
                        <p-panel [header]="car.vin" [style]="{'text-align':'center'}">
                            <img src="assets/demo/images/car/{{car.brand}}.gif" width="60">
                            <div class="car-detail">{{car.year}} - {{car.color}}</div>
                            <button pButton type="button" icon="ui-icon-search" style="margin-top:0"></button>
                        </p-panel>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
    </div>
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>VirtualScroller</h1>
            <p-virtualScroller [value]="cars3" scrollHeight="500px" [itemSize]="150" [rows]="40" [cache]="false"
                               [lazy]="true" (onLazyLoad)="loadCarsLazy($event)" [totalRecords]="200">
                <p-header>
                    List of Cars
                </p-header>
                <ng-template let-car pTemplate="item" let-i="index">
                    <div class="ui-g car-item">
                        <div class="ui-g-12 ui-md-2 index-col" style="text-align:center">
                            <div style="font-size: 24px; text-align: center; padding-top: 48px">{{i}}</div>
                        </div>
                        <div class="ui-g-12 ui-md-2 image-col" style="text-align:center">
                            <img style="padding-top: 23px" width="72" src="assets/demo/images/car/{{car?.brand}}-big.gif">
                        </div>
                        <div class="ui-g-12 ui-md-8">
                            <div class="ui-g">
                                <div class="ui-g-2 ui-sm-6">Vin: </div>
                                <div class="ui-g-10 ui-sm-6">{{car?.vin}}</div>

                                <div class="ui-g-2 ui-sm-6">Year: </div>
                                <div class="ui-g-10 ui-sm-6">{{car?.year}}</div>

                                <div class="ui-g-2 ui-sm-6">Brand: </div>
                                <div class="ui-g-10 ui-sm-6">{{car?.brand}}</div>

                                <div class="ui-g-2 ui-sm-6">Color: </div>
                                <div class="ui-g-10 ui-sm-6">{{car?.color}}</div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template let-car pTemplate="loadingItem">
                    <div class="ui-g car-item empty-car-item">
                        <div class="ui-g-12 ui-md-2 index-col">
                            <div class="empty-car-item-index"></div>
                        </div>
                        <div class="ui-g-12 ui-md-2 image-col">
                            <div class="empty-car-item-image"></div>
                        </div>
                        <div class="ui-g-12 ui-md-8">
                            <div class="ui-g">
                                <div class="ui-g-12"><div class="empty-car-item-text"></div></div>
                                <div class="ui-g-12"><div class="empty-car-item-text"></div></div>
                                <div class="ui-g-12"><div class="empty-car-item-text"></div></div>
                                <div class="ui-g-12"><div class="empty-car-item-text"></div></div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-virtualScroller>
        </div>
    </div>
    <div class="ui-g-12 ui-md-8">
        <div class="card card-w-title">
            <h1>PickList</h1>
            <p-pickList [source]="sourceCars" [target]="targetCars" sourceHeader="Available" targetHeader="Selected" [responsive]="true"
                        [sourceStyle]="{'height':'250px'}" [targetStyle]="{'height':'250px'}">
                <ng-template let-car pTemplate="item">
                    <div class="ui-helper-clearfix">
                        <img src="assets/demo/images/car/{{car.brand}}.gif" style="display:inline-block;margin:2px 0 2px 2px"/>
                        <div style="font-size:16px;float:right;margin:15px 5px 0 0">{{car.brand}}</div>
                    </div>
                </ng-template>
            </p-pickList>
        </div>
    </div>

    <div class="ui-g-12 ui-md-4">
        <div class="card card-w-title">
            <h1>OrderList</h1>
            <p-orderList [value]="orderListCars" [listStyle]="{'height':'250px'}" header="OrderList">
                <ng-template let-car pTemplate="item">
                    <div class="ui-helper-clearfix">
                        <img src="assets/demo/images/car/{{car.brand}}.gif" style="display:inline-block;margin:2px 0 2px 2px" />
                        <div style="font-size:16px;float:right;margin:15px 5px 0 0">{{car.brand}}</div>
                    </div>
                </ng-template>
            </p-orderList>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card">
            <div class="ui-g">
                <div class="ui-g-12 ui-md-6">
                    <h1>Tree</h1>
                    <p-tree [value]="files1" selectionMode="single" [(selection)]="selectedNode1" [style]="{width: '100%'}"></p-tree>
                </div>
                <div class="ui-g-12 ui-md-6">
                    <h1>Checkbox Tree</h1>
                    <p-tree [value]="files2" selectionMode="checkbox" [(selection)]="selectedNodes" [style]="{width: '100%'}"></p-tree>
                </div>
            </div>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card">
            <h1>Horizontal Tree</h1>
            <p-tree [value]="files3" selectionMode="single" [(selection)]="selectedNode2" layout="horizontal"></p-tree>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>TreeTable</h1>
            <p-treeTable [value]="files4" [columns]="cols2" selectionMode="checkbox" [(selection)]="selectedNode3">
                <ng-template pTemplate="caption">
                    FileSystem
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Size</th>
                        <th>Type</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns; let i = index">
                            <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                            <p-treeTableCheckbox [value]="rowNode" *ngIf="i == 0"></p-treeTableCheckbox>
                            <span style="vertical-align: middle">{{rowData[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-treeTable>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card card-w-title" style="overflow: auto">
            <h1>Organization Chart</h1>
            <p-organizationChart [value]="data" selectionMode="single" [(selection)]="selectedNodeOrg"></p-organizationChart>
        </div>
    </div>

    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>Carousel</h1>
            <p-carousel [value]="carouselCars" [numVisible]="4" [numScroll]="3" [circular]="false" [responsiveOptions]="responsiveOptions">
                <ng-template let-car pTemplate="item">
                    <div class="car-details">
                        <div class="p-grid p-nogutter">
                            <div class="p-col-12">
                                <img src="assets/demo/images/car/{{car.brand}}-big.gif" />
                            </div>
                            <div class="p-col-12 car-data">
                                <div class="car-title">{{car.brand}}</div>
                                <div class="car-subtitle">{{car.year}} | {{car.color}}</div>

                                <div class="car-buttons">
                                    <button pButton type="button" class="ui-button-secondary" icon="ui-icon-search"></button>
                                    <button pButton type="button" class="ui-button-secondary" icon="ui-icon-star"></button>
                                    <button pButton type="button" class="ui-button-secondary" icon="ui-icon-settings"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>Schedule</h1>
            <p-fullCalendar [events]="events" [options]="fullcalendarOptions"></p-fullCalendar>
        </div>
    </div>
</div>
