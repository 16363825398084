import { CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component';
//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
//import { AnsprechpartnerListComponent_Template } from './ansprechpartner-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector, Input } from '@angular/core';
import { AppComponent } from '../app.component';

import { UnternehmenDetailComponent } from '../unternehmen-detail/unternehmen-detail.component';

//import { IBenutzer } from '../_interfaces/benutzer';
import { BenutzerService } from '../_services/benutzer.service';
import { CRUDBasicListForChildComponent } from '../crud-basic-list-for-child/crud-basic-list-for-child.component';
import { IBenutzer } from '../_interfaces/benutzer';

@Component({
  selector: 'app-benutzer-list',
  //templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  templateUrl: './benutzer-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${BenutzerListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class BenutzerListComponent extends CRUDBasicListForChildComponent implements OnInit {
  @Input('parentComponent') parentComponent: UnternehmenDetailComponent; 

  pageTitle: string = this.translate.instant("Benutzer", true);
  CRUDItemKurzform: string = "Benutzer";
  CRUDItemButtonTitleNeu: string = this.translate.instant("Neuer Benutzer", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Benutzer";
  CRUDItemBezeichnungPluralCapitalized: string = "Benutzer";
  CRUDItemBezeichnungSingular: string = "benutzer";
  CRUDItemBezeichnungPlural: string = "benutzer";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "benutzer";
  CRUDItemRoutePlural: string = "benutzer";

  //CRUDItemParentName : string = "benutzerUnternehmen"; // wie heisst die Navigation zum Parent innerhalb des CRUDItem ? // AM Parent-ChildTab-CRUD
  CRUDItemParentName : string = "unternehmen"; // wie heisst die Navigation zum Parent innerhalb des CRUDItem ? // AM Parent-ChildTab-CRUD
  CRUDItemChildName : string = "Benutzer"; // wie heisst (das Array) der Childs innerhalb dem Parent ? // AM Parent-ChildTab-CRUD

  CRUDDisableLoeschen: boolean = true;

  passwordResetShow: boolean = false; // dialog

  CRUDItemAvailableCols: any[] = [
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 1,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 2,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 3,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 4,
            showByDefault: false
          },
          {
            field: 'aspNetUserId',
            header: this.translate.instant('Asp Net User Id', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 5,
            showByDefault: false
          },
          {
            field: 'vorname',
            header: this.translate.instant('Vorname', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 6,
            showByDefault: true
          },
          {
            field: 'nachname',
            header: this.translate.instant('Nachname', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 7,
            showByDefault: true
          },
          {
            field: 'unternehmen.summary',
            header: this.translate.instant('Unternehmen', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 8,
            showByDefault: false
          }
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: BenutzerService,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;
    }

    addCRUDItem() {  // Overwrite zum CRUDBasicForChild Standard // AM Parent-ChildTab-CRUD
      super.addCRUDItem();
      this.selectedCrudItem.id = this.parentComponent.negativeId; // Alle neuen Benutzer/Ansprechpartner bekommen temporär eine negative Id, damit sie untereinander verknüpft werdeen können. Die API löst das auf. Siehe API - siehe API "*NEGATIVE_ID"
      this.parentComponent.negativeId--;
    }

    ngOnInit() { 
      super.ngOnInit();
      
      // weitere SplitButton-Funktion
      this.buttonItems.push(
        {
          id: 'changePW', label: this.translate.instant(/*this.CRUDItemKurzform+' löschen'*/'Passwort zurücksetzen', true), icon: 'fa fa-lock', automationId: 20, command: (res) => {
            this.passwordZuruecksetzen(this.selectedCrudItem);
          }
        }
      );
    }

    onCRUDItemsRetrieved(CRUDItems: /*ITitel*/any[], pagination: any) {
      console.log("BenutzerList.onCRUDItemsRetrieved() CRUDItems:", CRUDItems);
      // den eigenen (angemeldeten) User in der Liste NICHT anzeigen
      if(CRUDItems != null && this._app != null && this._app.benutzer != null) {
        console.log("BenutzerList.onCRUDItemsRetrieved() filter own Benutzer:", this._app.benutzer);
        CRUDItems = CRUDItems.filter(f => f.id != this._app.benutzer.id);
      }
      else {
        console.log("BenutzerList.onCRUDItemsRetrieved() skip filter own Benutzer!");
      }
      super.onCRUDItemsRetrieved(CRUDItems, pagination);
    }

    passwordZuruecksetzen(benutzer: IBenutzer) {
      if(benutzer.id >= 0 && benutzer.istBenutzer == true) {
        this.passwordResetShow = true;
      }
      else {
        this.messageWrapperService.postStaticMessage({ severity: 'error', summary: "Passwort", detail: "Benutzer hat keine Login-Berechtigung oder ist nicht nicht gespeichert." }); 
      }
    }
    passwordZuruecksetzenZurueckClicked() {
      this.passwordResetShow = false;
    }
    passwordZuruecksetzenSaveClicked() {
      this.passwordResetShow = false;
    }

}
