import { CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component';
//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
//import { AnsprechpartnerListComponent_Template } from './ansprechpartner-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector, Input } from '@angular/core';
import { AppComponent } from '../app.component';

//import { IAnsprechpartner } from '../_interfaces/ansprechpartner';
import { AnsprechpartnerService } from '../_services/ansprechpartner.service';
import { CRUDBasicListForChildComponent } from '../crud-basic-list-for-child/crud-basic-list-for-child.component';
import { UnternehmenDetailComponent } from '../unternehmen-detail/unternehmen-detail.component';
import { IBenutzer } from '../_interfaces/benutzer';
import * as cloneDeep from 'lodash/cloneDeep'; 

@Component({
  selector: 'app-ansprechpartner-list',
  templateUrl: './ansprechpartner-list.component.html',
  //templateUrl: './ansprechpartner-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${AnsprechpartnerListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class AnsprechpartnerListComponent extends CRUDBasicListForChildComponent implements OnInit {
  @Input('parentCrudForm') parentCrudForm: any; // AM Parent-ChildTab-CRUD
  @Input('parentComponent') parentComponent: UnternehmenDetailComponent; 

  pageTitle: string = this.translate.instant("Ansprechpartner", true);
  CRUDItemKurzform: string = "Ansprechpartner";
  CRUDItemButtonTitleNeu: string = this.translate.instant("Neuer Ansprechpartner", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Ansprechpartner";
  CRUDItemBezeichnungPluralCapitalized: string = "Ansprechpartner";
  CRUDItemBezeichnungSingular: string = "ansprechpartner";
  CRUDItemBezeichnungPlural: string = "ansprechpartner";
  CRUDItemHelpTopic: string = null;
  CRUDItemRouteSingular: string = "ansprechpartner";
  CRUDItemRoutePlural: string = "ansprechpartner";

  //CRUDItemParentName : string = "ansprechpartnerUnternehmen"; // wie heisst die Navigation zum Parent innerhalb des CRUDItem ? // AM Parent-ChildTab-CRUD
  CRUDItemParentName : string = "unternehmen"; // wie heisst die Navigation zum Parent innerhalb des CRUDItem ? // AM Parent-ChildTab-CRUD
  CRUDItemChildName : string = "Ansprechpartner"; // wie heisst (das Array) der Childs innerhalb dem Parent ? // AM Parent-ChildTab-CRUD
 
  //debugMode: boolean = true;

  CRUDItemAvailableCols: any[] = [
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 1,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 2,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 3,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 4,
            showByDefault: false
          },
          {
            field: 'vorname',
            header: this.translate.instant('Vorname', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 5,
            showByDefault: true
          },
          {
            field: 'nachname',
            header: this.translate.instant('Nachname', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 6,
            showByDefault: true
          },
          {
            field: 'telefon',
            header: this.translate.instant('Telefon', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 7,
            showByDefault: false
          },
          {
            field: 'mobil',
            header: this.translate.instant('Mobil', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 8,
            showByDefault: false
          },
          {
            field: 'email',
            header: this.translate.instant('Email', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 9,
            showByDefault: false
          },
          {
            field: 'benutzer.summary',
            header: this.translate.instant('Benutzer', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: false
          },
          {
            field: 'unternehmen.summary',
            header: this.translate.instant('Unternehmen', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 11,
            showByDefault: false
          },
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: AnsprechpartnerService,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;
    }

    addCRUDItem() {  // Overwrite zum CRUDBasicForChild Standard // AM Parent-ChildTab-CRUD
      super.addCRUDItem();
      this.selectedCrudItem.id = this.parentComponent.negativeId; // Alle neuen Benutzer/Ansprechpartner bekommen temporär eine negative Id, damit sie untereinander verknüpft werdeen können. Die API löst das auf. Siehe API - siehe API "*NEGATIVE_ID"
      this.parentComponent.negativeId--;
    }

    //ngOnInit() { // HTML Template Plugins
    //  super.ngOnInit();
    //  this.app.pageTitle = this['pageTitle'];
    //}
}
