import { Component, OnInit, Inject, forwardRef, ViewChild } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppComponent } from './app.component';
import {MenuItem} from 'primeng/api';
import {MenuModule, Menu} from 'primeng/menu';
import {AuthService} from './auth/auth.service';
import { Router } from '@angular/router';
import { IBenutzer } from './_interfaces/benutzer';




@Component({
    selector: 'app-topbar',
    template: `
        <div class="topbar clearfix">
            <div class="topbar-left" routerLink="/">
                <div class="logo"></div>
            </div>

            <div class="topbar-right">
                <a id="menu-button" href="#" (click)="appMain.onMenuButtonClick($event)">
                    <i></i>
                </a>

                <a id="topbar-menu-button" href="#" (click)="appMain.onTopbarMenuButtonClick($event)">
                    <i class="material-icons">menu</i>
                </a>

                <span style="font-size: 1.4em; vertical-align: bottom; vertical-align: -webkit-baseline-middle;">
                {{ app != null && app.getPageTitle() != null ? app.getPageTitle() : "null" }}
                </span>

                <ul class="topbar-items animated fadeInDown" [ngClass]="{'topbar-items-visible': appMain.topbarMenuActive}">
                    <li #profile class="profile-item" *ngIf="appMain.profileMode==='top'||appMain.isHorizontal()"
                        [ngClass]="{'active-top-menu':appMain.activeTopbarItem === profile}">

                        <ul class="ultima-menu animated fadeInDown">
                            
                            <li role="menuitem">
                                <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                                    <i class="material-icons">security</i>
                                    <span>Privacy</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                                    <i class="material-icons">settings_applications</i>
                                    <span>Settings</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="appMain.onTopbarSubItemClick($event)">
                                    <i class="material-icons">power_settings_new</i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div (click)="menu.toggle($event)">
                            <i class="fa fa-user-circle fa-3x cursorTopBar"></i>
                            <span class="positionBenutzer">
                                {{app != null && app.benutzer != null ? app.benutzer.nachname : "..."}}<!--nicht angemeldet-->
                            </span>
                        </div>
                    </li>
                    
                </ul>
            </div>
        </div>
        <p-menu #menu [popup]="true" [model]="items"></p-menu>
    `
})
export class AppTopbarComponent implements OnInit  {
    @ViewChild("menu") menu: Menu;
    constructor(
        @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
        public appMain: AppMainComponent, public authService: AuthService, private router: Router) {
    }
    items: MenuItem[];
    benutzer: IBenutzer = null;
    ngOnInit() {
        // console.log('TEST angekommen');
       
       
        // {{this.app != null && this.app.benutzer != null ? this.app.benutzer.nachname : "nicht angemeldet"}}
       
        this.items = [
           
            {
              
            label: "Mein Benutzer", icon: 'fa fa-user-circle', command: () => 
                {
                    this.router.navigate(['/unternehmen', this.app.benutzer.unternehmenId]);
                } 
            },
            {label: 'Einstellungen ', icon: 'fa fa-cog'},
            {label: 'Hilfe ', icon: 'fa fa-question-circle'},
            {label: 'Abmelden ', icon: 'fa fa-sign-out', command: () => {this.logOut();} }

        ];
    }
    logOut() {
        this.authService.logout()
        .subscribe(success => {
          if (success) {
            this.router.navigate(['/login']);
          }
        });
    }

}
