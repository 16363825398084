//import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ViewChild, ElementRef, HostListener, Inject, forwardRef, Injector, ComponentFactoryResolver } from '@angular/core';
//import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
//import { ActivatedRoute, Router, ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy } from '@angular/router';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { AppComponent } from '../app.component';

//import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from './auth.service';
import { TranslateService } from '../_services/translate.service';
import { MessageWrapperService } from '../_services/message-wrapper.service'; 

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  disableFields: boolean = false;

  displayMessage: { [key: string]: string } = {};

  returnUrl: string;

  constructor(@Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private authService: AuthService, private formBuilder: FormBuilder, private router: Router, 
    public translate: TranslateService, public messageWrapperService: MessageWrapperService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl']/* || '/'*/;

    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    });
  }

  ngAfterViewInit() {
    if(this._app.inAppMode == true) {
      let eventData = {
        eventType: "pageloaded",
        data: "/login"
      };
      this._app.invokeXamarinEvent(eventData);
    }
  }

  get f() { return this.loginForm.controls; }

  login(username, password) {
    console.log("Login.login() username: ", username);
    //console.log("Login.login() f: ", this.f);
    delete this.displayMessage._lastLoginErrorMessage;

    this.authService.login(
      {
        username: /*this.f.username.value*/username,
        password: /*this.f.password.value*/password
      }
    )
    .subscribe(success => {
      if (success) {
        if(this.returnUrl != null) {
          console.log("Login.login() route to returnUrl:", this.returnUrl);
          this.router.navigateByUrl('/'+this.returnUrl);
          //this.router.navigate([this.returnUrl]);
        }
        else {
          console.log("Login.login() route to /home!");
          this.router.navigate(['/home']);
        }
      }
    },
    (error: any) => {
      //this.handleError(error);
      //console.log("error: ", error);
      let additionalInfo: string = null;
      try {
        additionalInfo = error.error;
      }
      catch(e) {}

      let errorMessage: string = null;
      if(additionalInfo == "Login failed (0x06)") {
        errorMessage = "Fehler bei der Anmeldung. Der Benutzer ist gesperrt.";
      }
      else {
        errorMessage = "Fehler bei der Anmeldung. Bitte prüfen sie Benutzername und Passwort."+"\n"+additionalInfo;
      }

      this.displayMessage._lastLoginErrorMessage = errorMessage;
    });
  }

  getUrl() {
    return window.location.href;
  }

  /*handleError(error: any) {
    console.log("Login.handleError error", error);
    //this.globalService.addFeedbackByClone("error " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.handleError()", error); // feedback

    //this.loading = false;
    //this.blockedDocument = false;
    let summary = this.translate.instant('Fehler', true);
    let errorMessage:string = null;

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      errorMessage = "Unauthorized";
      //this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      errorMessage = error.message;
    }

    //if(this.errorMessage != null) this.errorMessage = this.errorMessage.replace(/\n/g, "<br>");
    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: errorMessage }); // TO DO
  }*/

}
