import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { ILager } from '../_interfaces/lager';
import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

//import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class LagerService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'strasse', type: 'string'},
    //{name: 'postleitzahl', type: 'string'},
    //{name: 'ort', type: 'string'},
    {name: 'baustelle', type: 'boolean'},
    {name: 'unternehmenId', type: 'int'},
    {name: 'unternehmen', type: 'object'},
    {name: 'baustelle_Gueltigkeit', type: 'DateTimeOffset'},
    {name: 'geodatenId', type: 'int'},
    {name: 'geodaten', type: 'object'},
    {name: 'latitude', type: 'decimal'},
    {name: 'longitude', type: 'decimal'},
    {name: 'datenherkunft', type: 'string'},
    {name: 'accuracy', type: 'string'},
    {name: 'summary', type: 'string'}
  ];
    
  getLagerCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ lager: ILager[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'lager?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { lager: <ILager[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }
  getLagerCollectionForThisUser(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ lager: ILager[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'lager/getLagerCollectionForThisUser?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { lager: <ILager[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getLager(id: number): Observable<ILager> {

    if (id === 0) {
      return Observable.create((observer: any) => {
        observer.next(this.initializeLager());
        observer.complete();
      })
    }

    return this.httpClient.get<ILager>(this.apiBaseUrl + 'lager/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  saveLager(lager: ILager): Observable<ILager> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(lager); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("LagerService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("LagerService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (lager.id === 0) {
      return this.createLager(/*lager*/itemToSave);
    }

    return this.updateLager(/*lager*/itemToSave)

  }

  updateLager(lager: ILager): Observable<ILager> {

    return this.httpClient.put<ILager>(this.apiBaseUrl + 'lager/' + lager.id, lager, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteLager(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'lager/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createLager(lager: ILager): Observable<ILager> {
    return this.httpClient.post<ILager>(this.apiBaseUrl + 'lager', lager, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeLager(): ILager {
    return {
      id: 0,
      createdBy: '',
      created: '',
      modifiedBy: '',
      modified: '',
      rowVersion: '',
      bezeichnung: '',
      strasse: '',
      //postleitzahl: '',
      //ort: '',
      baustelle: false,
      unternehmenId: null,
      unternehmen: null,
      baustelle_Gueltigkeit: null,
      geodatenId: null,
      geodaten: null,
      latitude: 0,
      longitude: 0,
      datenherkunft: '',
      accuracy: '',
      summary: ''
    };
  }


}
