    <!--entspricht CRUDBasicSelect aber ohne Dialog -->
    <!--Ausser die Labels, crudItemSize -->
    <!--und die Fusszeilen (keine Elemente ...) sind abweichend -->
        
    <i class="fa fa-search"></i>&nbsp;<input style="width: 95%" type="search" pInputText [value]="search" [formControl]="searchControl" [placeholder]="translateService.instant('Suche',true)">
    <br><br>
    
    <p-virtualScroller *ngIf="killScroller != true" #ds [value]="items" (onLazyLoad)="loadDataVirtualScroller($event)" [lazy]="true"
      scrollHeight="400px" [itemSize]="crudItemSize" [rows]="rows">
      <ng-template let-rowData pTemplate="item" let-i="index">
        <div class="ui-g" style="border-bottom: 1px solid #D5D5D5">
          <div class="ui-g-12 ui-md-3">
            <button pButton type="button" icon="pi pi-external-link" (click)="selectItem(rowData)"></button>
          </div>
          <div class="ui-g-12 ui-md-9">
            <div class="ui-g">
              <div class="ui-g-12 ui-sm-12">
                <b>{{rowData[crudItemLabel]}}</b>
              </div>
              <div class="ui-g-12 ui-sm-12">
                {{rowData[crudItemLabel2]}}
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template let-product pTemplate="loadingItem">
        <div class="ui-g" style="border-bottom: 1px solid #D5D5D5">
          <div class="ui-g-12 ui-md-3">
            &nbsp;
          </div>
          <div class="ui-g-12 ui-md-9">
            <div class="ui-g">
              <div class="ui-g-12 ui-sm-12">
                &nbsp;
              </div>
              <div class="ui-g-12 ui-sm-12">
                <p-progressSpinner [style]="{width: '2em', height: '2em'}" strokeWidth="2"></p-progressSpinner>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-virtualScroller>
    <div *ngIf="totalRecords == null" style="text-align: center;"><p-progressSpinner [style]="{width: '2em', height: '2em'}" strokeWidth="2"></p-progressSpinner></div>
    <div *ngIf="totalRecords != null && (!items || items.length == 0)">{{'Keine Elemente'|translate:true}}</div>
   