import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutes} from './app.routes';

// PrimeNG Components for demos
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {EditorModule} from 'primeng/editor';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SpinnerModule} from 'primeng/spinner';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {BlockUIModule} from 'primeng/blockui';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

// Application Components
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AppHelpComponent} from './pages/app.help.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppWizardComponent} from './pages/app.wizard.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppInlineProfileComponent} from './app.profile.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightpanelComponent} from './app.rightpanel.component';
import {AppTopbarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';

// Demo pages
import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';
import {SampleDemoComponent} from './demo/view/sampledemo.component';
import {FormsDemoComponent} from './demo/view/formsdemo.component';
import {DataDemoComponent} from './demo/view/datademo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {UtilsDemoComponent} from './demo/view/utilsdemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';

// Demo services
import {CarService} from './demo/service/carservice';
import {CountryService} from './demo/service/countryservice';
import {EventService} from './demo/service/eventservice';
import {NodeService} from './demo/service/nodeservice';

// Application services
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {BreadcrumbService} from './breadcrumb.service';
import {MenuService} from './app.menu.service';
import { ArtikelUebersichtComponent } from './artikel-uebersicht/artikel-uebersicht.component';
import { ProduktDetailsComponent } from './demo/view/produkt-details/produkt-details.component';
import { DetailArtikelAnsichtComponent } from './demo/view/detail-artikel-ansicht/detail-artikel-ansicht.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeviceDetectorService } from 'ngx-device-detector';

// Plattgruen Funktionen
import {
  ConfirmationService,
} from 'primeng/api';
import { APP_INITIALIZER } from '@angular/core';

import { RouteReuseStrategy } from "@angular/router";
import { CustomReuseStrategy } from "./_routing/custom-reuse-strategy";
import { AppconfigService } from './_services/appconfig.service';

//import { MessageService } from './_services/message.service'; // TO DO
import {MessageService} from 'primeng/api';
import { MessageWrapperService } from './_services/message-wrapper.service';

import { TranslateService, TRANSLATION_PROVIDERS } from './_services/translate.service';
import { CRUDBasicDetailComponent } from './crud-basic-detail/crud-basic-detail.component';
import { CRUDBasicDetailForChildComponent } from './crud-basic-detail-for-child/crud-basic-detail-for-child.component';
import { CRUDBasicListComponent } from './crud-basic-list/crud-basic-list.component';
import { CRUDBasicListForChildComponent } from './crud-basic-list-for-child/crud-basic-list-for-child.component';
import { CRUDBasicAutocompleteComponent } from './crud-basic-autocomplete/crud-basic-autocomplete.component';
import { CRUDBasicInputComponent } from './crud-basic-input/crud-basic-input.component';
import { CRUDBasicPipe } from './_pipes/crud-basic.pipe';
import { CRUDBasicCheckboxComponent } from './crud-basic-checkbox/crud-basic-checkbox.component';
import { CRUDBasicSelectComponent } from './crud-basic-select/crud-basic-select.component';
import { CRUDBasicSelectLazyComponent } from './crud-basic-select-lazy/crud-basic-select-lazy.component';
import { CRUDBasicLogoComponent } from './crud-basic-logo/crud-basic-logo.component';
import { EscapeHtmlPipe } from './_pipes/keep-html.pipe';
import { DatumOhneZeitPipe } from './_pipes/datum-ohne-zeit.pipe';
import { ArtikelgruppeService } from './_services/artikelgruppe.service';
import { LizenzartService } from './_services/lizenzart.service';
import { UnternehmenService } from './_services/unternehmen.service';
import { UnternehmenDateiService } from './_services/unternehmen-datei.service';
import { HerstellerService } from './_services/hersteller.service';
import { AnredeService } from './_services/anrede.service';
import { ArtikelzustandService } from './_services/artikelzustand.service';
import { BenutzerService } from './_services/benutzer.service';
import { LagerService } from './_services/lager.service';
import { LieferartService } from './_services/lieferart.service';
import { RSSService } from './_services/rss.service';
import { MengeneinheitService } from './_services/mengeneinheit.service';
import { VerfuegbarkeitService } from './_services/verfuegbarkeit.service';
import { VerpackungService } from './_services/verpackung.service';
import { VerkaufseinheitService } from './_services/verkaufseinheit.service';
import { AnsprechpartnerService } from './_services/ansprechpartner.service';
import { ArtikelbildService } from './_services/artikelbild.service';
import { ArtikelLieferartService } from './_services/artikel-lieferart.service';
import { ArtikelDetailComponent } from './artikel-detail/artikel-detail.component';
import { ArtikelService } from './_services/artikel.service';
import { _ArtikelImportService } from './_services/_artikelimport.service';
import { _ArtikelgruppenImportService } from './_services/_artikelgruppenimport.service';
import { ArtikelDetailGuard } from './artikel-detail/artikel-detail.guard';
import { FavoritService } from './_services/favorit.service';
import { GeodatenService } from './_services/geodaten.service';
import { TranslatePipe } from './_pipes/translate.pipe';
import { CurrencyPipe } from '@angular/common';
 import { CustomCurrencyPipe } from './_services/custom.currencypipe';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login.component';
//import { MessageService } from './_services/message.service';
import { TokenInterceptor } from './auth/token.interceptor';
import { TestObservablesComponent } from './_test/test-observables/test-observables.component';
import { ArtikelDetailAnsichtComponent } from './artikel-detail/artikel-detail-ansicht.component';
import { BenutzerListComponent } from './benutzer-list/benutzer-list.component';
import { BenutzerDetailComponent } from './benutzer-detail/benutzer-detail.component';
import { BenutzerDetailGuard } from './benutzer-detail/benutzer-detail.guard';
import { AnsprechpartnerListComponent } from './ansprechpartner-list/ansprechpartner-list.component';
import { AnsprechpartnerDetailComponent } from './ansprechpartner-detail/ansprechpartner-detail.component';
import { UnternehmenListComponent } from './unternehmen-list/unternehmen-list.component';
import { UnternehmenDetailComponent } from './unternehmen-detail/unternehmen-detail.component';
import { UnternehmenDetailGuard } from './unternehmen-detail/unternehmen-detail.guard';
import { SelectUnternehmenComponent } from './select-unternehmen/select-unternehmen.component';
import { RegisterComponent } from './auth/register.component';
import { ArtikelimportAbrufVorlageComponent } from './artikelimport-abruf-vorlage/artikelimport-abruf-vorlage.component';
import { ArtikelgruppenImportComponent } from './artikelgruppen-import/artikelgruppen-import.component';
import { LagerListComponent } from './lager-list/lager-list.component';
import { LagerDetailComponent } from './lager-detail/lager-detail.component';
import { LagerDetailGuard } from './lager-detail/lager-detail.guard';
import { PasswortResetComponent } from './passwort-reset/passwort-reset.component';
import { UnternehmenDateiUploadComponent } from './unternehmen-datei-upload/unternehmen-datei-upload.component';
import { ArtikelScrollerComponent } from './artikel-scroller/artikel-scroller.component';
import { GetMobileAppComponent } from './get-mobile-app/get-mobile-app.component';
import { OberflaecheService } from './_services/oberflaeche.service';
import { FarbeService } from './_services/farbe.service';
import { KanteService } from './_services/kante.service';
import { WerbeAnzeigeDarstellungCarouselComponent } from './werbeanzeige-darstellung-carousel/werbeanzeige-darstellung-carousel.component';
import { WerbeAnzeigeDarstellungUebersichtComponent } from './werbeanzeige-darstellung-uebersicht/werbeanzeige-darstellung-uebersicht.component';
import { WerbeanzeigeDarstellungScrollerComponent } from './werbeanzeige-darstellung-scroller/werbeanzeige-darstellung-scroller.component';
import { WerbeanzeigeDetailAnsichtComponent } from './werbeanzeige-detail-ansicht/werbeanzeige-detail-ansicht.component';

export function loadConfig(config: AppconfigService) {
    return function internalLoadConfig() {
      return config.load();
    }
  }

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        BlockUIModule,
        TriStateCheckboxModule,
        ProgressSpinnerModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppInlineProfileComponent,
        AppTopbarComponent,
        AppFooterComponent,
        AppRightpanelComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,
        AppHelpComponent,
        AppInvoiceComponent,
        AppWizardComponent,
        DashboardDemoComponent,
        SampleDemoComponent,
        FormsDemoComponent,
        DataDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        UtilsDemoComponent,
        DocumentationComponent,
        ArtikelUebersichtComponent,
        ProduktDetailsComponent,
        DetailArtikelAnsichtComponent,
        DashboardComponent,
        CRUDBasicDetailComponent,
        CRUDBasicDetailForChildComponent,
        CRUDBasicListComponent,
        CRUDBasicListForChildComponent,
        CRUDBasicAutocompleteComponent,
        CRUDBasicInputComponent,
        CRUDBasicPipe,
        CRUDBasicCheckboxComponent,
        CRUDBasicSelectComponent,
        CRUDBasicSelectLazyComponent,
        CRUDBasicLogoComponent,
        EscapeHtmlPipe,
        DatumOhneZeitPipe,
        ArtikelDetailComponent,
        TranslatePipe,
        CustomCurrencyPipe,
        HomeComponent,
        LoginComponent,
        TestObservablesComponent,
        ArtikelDetailAnsichtComponent,
        BenutzerListComponent,
        BenutzerDetailComponent,
        AnsprechpartnerListComponent,
        AnsprechpartnerDetailComponent,
        UnternehmenListComponent,
        UnternehmenDetailComponent,
        SelectUnternehmenComponent,
        RegisterComponent,
        ArtikelimportAbrufVorlageComponent,
        ArtikelgruppenImportComponent,
        LagerListComponent,
        LagerDetailComponent,
        PasswortResetComponent,
        UnternehmenDateiUploadComponent,
        ArtikelScrollerComponent,
        GetMobileAppComponent,
        WerbeAnzeigeDarstellungCarouselComponent,
        WerbeanzeigeDetailAnsichtComponent,
        WerbeAnzeigeDarstellungUebersichtComponent,
        WerbeanzeigeDarstellungScrollerComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        CarService, CountryService, EventService, NodeService, BreadcrumbService, MenuService,
        FormBuilder, CurrencyPipe,CustomCurrencyPipe,

        AppconfigService,
        {
          provide: APP_INITIALIZER,
          useFactory: loadConfig,
          deps: [AppconfigService],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        DeviceDetectorService,
        MessageService,
        { provide: MessageWrapperService, useClass: MessageWrapperService },
        TranslateService, TRANSLATION_PROVIDERS,
        ConfirmationService,
    
        ArtikelgruppeService, LizenzartService, UnternehmenService, UnternehmenDateiService, HerstellerService,
        AnredeService, ArtikelzustandService, BenutzerService, LagerService, LieferartService,
        MengeneinheitService, VerfuegbarkeitService, VerpackungService, AnsprechpartnerService,
        ArtikelbildService, VerkaufseinheitService,
        ArtikelLieferartService, FavoritService, GeodatenService,
        BenutzerService, BenutzerDetailGuard, UnternehmenDetailGuard,
        LagerDetailGuard,
        RSSService,
        OberflaecheService, FarbeService, KanteService,

        ArtikelService, ArtikelDetailGuard,

        _ArtikelImportService,
        _ArtikelgruppenImportService,

        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
