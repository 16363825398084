//import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';
import { CRUDBasicDetailForChildComponent } from '../crud-basic-detail-for-child/crud-basic-detail-for-child.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
import { AutoComplete } from 'primeng/primeng';

import { IBenutzer } from '../_interfaces/benutzer';
import { BenutzerService } from '../_services/benutzer.service';
import { BenutzerDetailGuard } from './benutzer-detail.guard';

import { validateBenutzerAlreadyExists, validateEMailAlreadyExists } from '../_validators/benutzer-validators';

//import { UnternehmenService } from '../_services/unternehmen.service';
//import { IUnternehmen } from '../_interfaces/unternehmen';


//import { BenutzerDetailComponent_Template } from './benutzer-detail.component.include_template';

@Component({
  selector: 'app-benutzer-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${BenutzerDetailComponent_Template}`,
  templateUrl: './benutzer-detail.component.html',
  
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class BenutzerDetailComponent extends CRUDBasicDetailForChildComponent implements OnInit {
  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Benutzer";
  CRUDPageTitleNeu: string = this.translate.instant("Neuer Benutzer", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Benutzer bearbeiten", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Benutzer";
  CRUDItemBezeichnungPluralCapitalized: string = "Benutzer";
  CRUDItemBezeichnungSingular: string = "benutzer";
  CRUDItemBezeichnungPlural: string = "benutzer";
  CRUDItemRouteSingular: string = "benutzer";
  CRUDItemRoutePlural: string = "benutzer";
  CRUDItemHelpTopic: string = null;

  //debugMode: boolean = true;
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START
  passwordVisible: boolean = false;
    // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    public _crudItemService: BenutzerService,
    private _guard: BenutzerDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    public benutzerService: BenutzerService,
    //private unternehmenService: UnternehmenService,
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      //aspNetUserId: {
      //  required: this._translate.instant('Asp Net User Id', true) + ': ' + this._translate.instant('ist erforderlich', true),
      //},

      vorname: {
        required: this._translate.instant('Vorname', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Vorname', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      nachname: {
        required: this._translate.instant('Nachname', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Nachname', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      telefon: {
        maxlength: this._translate.instant('Telefon', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      mobil: {
        maxlength: this._translate.instant('Mobil', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      email: {
        required: this._translate.instant('E-Mail', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('E-Mail', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true),
        //email: this._translate.instant('E-Mail', true) + ': ' + this._translate.instant('ist keine gültige E-Mail-Adresse', true),
        pattern: this._translate.instant('E-Mail', true) + ': ' + this._translate.instant('ist keine gültige E-Mail-Adresse', true),
        eMail_exists: this._translate.instant('eMail_exists', true) // async validator
      },

      _username: {
        //required: this._translate.instant('Benutzername', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Benutzername', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true),
        Benutzername_exists: this._translate.instant('Benutzername_exists', true) // async validator
      },

      _password: {
        //required: this._translate.instant('Passwort', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Passwort', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      _passwordBestaetigen: {
        //required: this._translate.instant('Passwort Bestätigen', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Passwort Bestätigen', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },


};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      aspNetUserId: [null/*, [Validators.required]*/],
      vorname: ['', [Validators.required, Validators.maxLength(255)]],
      nachname: ['', [Validators.required, Validators.maxLength(255)]],
      _username: ['', [/*Validators.required, */Validators.maxLength(255)], [validateBenutzerAlreadyExists(this.benutzerService)]], // async validator https://www.tektutorialshub.com/angular/angular-async-validator-example/
      telefon: ['', [Validators.maxLength(255)]],
      mobil: ['', [Validators.maxLength(255)]],
      email: ['', [Validators.required, Validators.maxLength(255), /*Validators.email,*/Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')], [validateEMailAlreadyExists(this.benutzerService, this, this.benutzerGet)]], // async validator // benutzer.Id über funktion rübergeben, weil zum Zeitpunkt des buidForm noch nicht bekannt!
      //unternehmen: [null],
      _password: ['', [/*Validators.required, */Validators.maxLength(255)]], // im Modify-Mode keine Validators - siehe ngOnInit
      _passwordBestaetigen: ['', [/*Validators.required, */Validators.maxLength(255)]], // im Modify-Mode keine Validators - siehe ngOnInit
      istBenutzer: [null],
      istAnsprechpartner: [null],
    },{
      validator: this.formValidator // auf FormEbene!
    });

    this.CRUDForm['___component'] = this; // trick, um im formValidator wieder an die component zu kommen.
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START

    /*if(this.CRUDItem.id != 0) {
      this.CRUDForm.controls._password.clearValidators(); // im Modify-Mode keine Validators
      this.CRUDForm.controls._passwordBestaetigen.clearValidators();
    }*/
    // wenn NICHT create-Modus, dann Validator für Benutzername_exists + eMail_exists deaktivieren!
     if(this.CRUDItem.id > 0) {
      this.CRUDForm.controls._username.clearAsyncValidators();
      //this.CRUDForm.controls.email.clearAsyncValidators(); // für eMail aktiv lassen, weil die kann man ändern
      console.log("BenutzerDetail.ngOnInit() deactivated validator for 'Benutzername_exists'. controls.this.CRUDForm.controls._username: ", this.CRUDForm.controls._username);
    }

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    //a.unternehmenId = a.unternehmen != null ? a.unternehmen.id : null;

    a.summary = a.vorname + " " + a.nachname; // nur deshalb, weil die Liste der Benutzer als Auswahl bei den Ansprechpartnern kommt (ohne dass die zwischenzeitlich nochmal durch die API laufen)
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("BenutzerDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      aspNetUserId: this.CRUDItem.aspNetUserId,
      vorname: this.CRUDItem.vorname,
      nachname: this.CRUDItem.nachname,
      //unternehmen: this.CRUDItem.unternehmen,
      _username: this.CRUDItem._username,
      _password: this.CRUDItem._password,
      _passwordBestaetigen:  this.CRUDItem._passwordBestaetigen,
      telefon: this.CRUDItem.telefon,
      mobil: this.CRUDItem.mobil,
      email: this.CRUDItem.email,
      istBenutzer: this.CRUDItem.istBenutzer,
      istAnsprechpartner: this.CRUDItem.istAnsprechpartner,
    });
    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("BenutzerDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  /*onCRUDItemRetrieved(CRUDItem: any): void {
    console.log("BenutzerDetail.onCRUDItemRetrieved() CRUDItem:", CRUDItem);

    // wenn NICHT create-Modus, dann Validator für Benutzername_exists + eMail_exists deaktivieren!
    if(this.dataId != 0) {
      this.CRUDForm.controls._username.clearAsyncValidators();
      this.CRUDForm.controls.email.clearAsyncValidators();
      console.log("BenutzerDetail.onCRUDItemRetrieved() deactivated validator for 'Benutzername_exists'. controls.this.CRUDForm.controls._username: ", this.CRUDForm.controls._username);
    }

    super.onCRUDItemRetrieved(CRUDItem);
  }*/

  benutzerGet(thisInstance: BenutzerDetailComponent) {
    return thisInstance.CRUDItem;
  }


  formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors : any[] = [];
    let errorsFound : boolean = false;

    console.log("BenutzerDetailComponent.formValidator() form:", form);

    // dazu erstmal an die in der Form verlinkte Component kommen
    if(form['___component'] != null/* && form['___component'].dataId != null && form['___component'].dataId == 0*/) {
      let thisInstance = form['___component'];
      console.log("BenutzerDetailComponent.formValidator() thisInstance:", thisInstance);

      let createMode = thisInstance.CRUDItem.id != null && thisInstance.CRUDItem.id <= 0;
      let valueIstBenutzer = form.value.istBenutzer;
      let valueUsername = form.value._username;
      let valuePassword = form.value._password;
      let valuePasswordBestaetigen = form.value._passwordBestaetigen;

      if(valueIstBenutzer) {
        console.log("BenutzerDetailComponent.formValidator() valueIstBenutzer:", valueIstBenutzer);
        if(valueUsername == null) {
          errors['Benutzername_ist_erforderlich']=true;
          errorsFound = true;
        }
        else {
          let ungueltigeZeichenGefunden = thisInstance.benutzerService.validateUserName(valueUsername);
          if(ungueltigeZeichenGefunden != null) {
            errors['Benutzername_enthaelt_ungueltige_Zeichen']=true;
            errorsFound = true;
          }
        }

        // wenn Benutzer und es war bisher keiner, dann brauchen wir ein Passwort!
        console.log("BenutzerDetailComponent.formValidator() createMode:", createMode);
        console.log("BenutzerDetailComponent.formValidator() thisInstance.CRUDItem.istBenutzer:", thisInstance.CRUDItem.istBenutzer);
        if(valueIstBenutzer == true && (createMode == true || thisInstance.CRUDItem.istBenutzer == false) ) {
          console.log("BenutzerDetailComponent.formValidator() wir brauchen ein Passwort!");
          if(valuePassword == null) {
            errors['Passwort_ist_erforderlich']=true;
            errorsFound = true;
          }

          if(valuePassword != null /*&& valuePasswordBestaetigen != null*/ && valuePassword != valuePasswordBestaetigen) {
            errors['Die_Passwoerter_stimmen_nicht_ueberein']=true;
            errorsFound = true;
          }
        }
      } 
    }

    //form.setErrors(errorsFound ? errors : null);
    if(errorsFound) return errors;
    else return null;
  }
}
