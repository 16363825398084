<div class="btnArtikelAnlegen"
    *ngIf="CRUDItemAsParameter == null && CRUDItem != null  && CRUDItem.unternehmenId == _app.benutzer.unternehmenId"
    (click)="editArtikel()">
    <i class="fa fa-pencil" style="color: #ffffff;" id="neu" type="button"></i>
</div>
<div class="favouriteBtnStar" style="margin-top: -14px;">
    <!--<span *ngIf="_app !=null && _app.inAppMode == true">
            <i class="material-icons" style="font-size: 32px;
            color: #000000;
            right: 0;
            cursor: pointer;
            padding: 10px 10px 0px 0px;
            display: block;
            top: 0;
            margin-right: 12px;" onclick="history.back();">arrow_back</i>
    </span>-->

    <i class="pi" *ngIf="_app !=null && _app.inAppMode == false && CRUDItem != null && CRUDItem.typ != 'S'" [class.pi-star-o]="!isActive"
        [class.pi-star]="isActive" (click)="changeActivity()" style="font-size: 25px;
                color: #000000;
                right: 0;
                cursor: pointer;
                padding: 15px 10px 0px 0px;
                display: block;
                top: 0;"></i>
    <h2 [class.headerNameApp_app]="_app !=null && _app.inAppMode == true" id="headerName">
        {{CRUDItem != null ? CRUDItem.bezeichnung : ''}}</h2>
</div>
<div class="p-grid">
    <div id="ArtikelAnsichtGridLinks" class="p-col-12 p-md-6 p-lg-6">
        <!-- START LINKS -->


        <div style="margin-top: 5px;"></div>

        <div style="max-height: 200px;" *ngIf="_app !=null && _app.inAppMode != true">
            <p-galleria #artikelBilderGalleria id="galleriaFloat" [fullScreen]="galleriaFullscreen" [changeItemOnIndicatorHover]="true"
                [(value)]="artikelBilderGalleryImages" [thumbnailsPosition]="'bottom'"
                [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '500px', 'background-color': '#FFFFFF'}"
                >
                <!-- Achtung: responsiveOptions sollten zu den Einstellungen im CSS passen (@media screen and (min-width: 768px) #ArtikelAnsichtGridLinks ...) -->
                <ng-template pTemplate="item" let-item class="artikelAnsichtGallery" style="height: 500px !important" [class.portraitMobile]="_app !=null && _app.inAppMode == true" >
                    <img (click)="galleriaClickFullscreen()" [src]="item.previewImageSrc"
                        style="max-height: 50vH; max-width: 100%;" />
                    <!--style="width: 100%; display: block;"-->
                </ng-template>
                <ng-template id="testThumb" pTemplate="thumbnail" let-item>
                    <div id="thumbnailContent">
                        <div id="thumbnail" class="p-grid p-nogutter p-justify-center">
                            <img [src]="item.thumbnailImageSrc" style="display: block; max-height: 160px; max-width: 100%;" />
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="caption" let-item>
                    <h2 id="textCaption">{{item.title}}</h2>
                    <p style="color: #ffffff; font-size: 16px;">{{item.alt}}</p>
                </ng-template>
            </p-galleria>
            

        </div>
        <!-- !!!Handy Ansicht!!! -->
        <div [class.mobileAnsichtCarouselArtikelDetailAnsicht]="_app !=null && _app.inAppMode == true" *ngIf="_app !=null && _app.inAppMode == true">
        <div class="imgMobileView" style="width: 100%; ">
        <p-carousel [(value)]="artikelBilderGalleryImages" [responsiveOptions]="responsiveOptions" orientation="horizontal" [style]="{'max-width':'400px', 'margin-top':'2em'}" verticalViewPortHeight="600px" [numVisible]="1">
            <ng-template pTemplate="item"  let-item class="artikelAnsichtGallery">
                
                    <div class="p-grid p-nogutter">
                        <div class="p-col-12">
                            <img (click)="galleriaClickFullscreen()" [src]="item.previewImageSrc"
                            style="max-height: 30vH; max-width: 100%;"/>
                        </div>
                        
                    </div>
                
            </ng-template>
        </p-carousel>
    </div>
</div>
<!-- !!!Tablet Ansicht!!! -->
<div [class.tabletAnsichtCarouselArtikelDetailAnsicht]="_app !=null && _app.inAppMode == true" *ngIf="_app !=null && _app.inAppMode == true" >
    <p-galleria #artikelBilderGalleria id="galleriaFloat" [fullScreen]="galleriaFullscreen" [changeItemOnIndicatorHover]="true"
                [(value)]="artikelBilderGalleryImages" [thumbnailsPosition]="'bottom'"
                [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '500px'}"
                >
                <!-- Achtung: responsiveOptions sollten zu den Einstellungen im CSS passen (@media screen and (min-width: 768px) #ArtikelAnsichtGridLinks ...) -->
                <ng-template pTemplate="item" let-item class="artikelAnsichtGallery" style="height: 500px !important" [class.portraitMobile]="_app !=null && _app.inAppMode == true" >
                    <img (click)="galleriaClickFullscreen()" [src]="item.previewImageSrc"
                        style="max-height: 25vH; max-width: 100%;" />
                    <!--style="width: 100%; display: block;"-->
                </ng-template>
                <ng-template id="testThumb" pTemplate="thumbnail" let-item>
                    <div id="thumbnailContent">
                        <div id="thumbnail" class="p-grid p-nogutter p-justify-center">
                            <img [src]="item.thumbnailImageSrc" style="display: block; max-height: 160px; max-width: 100%;" />
                        </div>
                    </div>
                </ng-template>
              
            </p-galleria>
</div>

    </div><!-- ENDE LINKS -->
    <div id="ArtikelAnsichtGridRechts" class="p-col-12 p-md-6 p-lg-6">
        <!-- START RECHTS -->
        <div [class.marginTop_app]="_app !=null && _app.inAppMode == true"
            style="margin-top: -22px; margin-left: 16px;">
            <i class="pi" [class.marginStar_app]="_app !=null && _app.inAppMode == true"
                *ngIf="_app !=null && _app.inAppMode == true" [class.pi-star-o]="!isActive" [class.pi-star]="isActive"
                (click)="changeActivity()" style="font-size: 25px;
            color: #000000;
                right: 0;
                float: left;
                padding: 15px 10px 0px 0px;
                display: block;
                top: 0;"></i>
            <p *ngIf="CRUDItem != null" class="textRight" style="font-size: 20px; font-weight: 600;">
                {{CRUDItem.lager == null && unternehmen != null ? unternehmen.postleitzahl : ''}}
                {{CRUDItem.lager == null && unternehmen != null ? unternehmen.ort : ''}}
                {{CRUDItem.lager != null ? CRUDItem.lager.postleitzahl : ''}}
                {{CRUDItem.lager != null ? CRUDItem.lager.ort : ''}}
            </p>
            <p *ngIf="CRUDItem != null && CRUDItem.typ != 'S' && CRUDItem.pauschal == false" class="textRight"
                style="font-size: 18px; color: green">
                {{(CRUDItem != null ? CRUDItem.preis : '') | crudBasic:null:(crudItemService.propertyTypes!=null&&crudItemService.propertyTypeOf!=null?crudItemService.propertyTypeOf('preis'):null)}}
                pro {{CRUDItem != null && CRUDItem.mengeneinheit != null ? CRUDItem.mengeneinheit.summary : ''}},
                {{CRUDItem != null ? CRUDItem.menge : ''}}
                {{CRUDItem != null && CRUDItem.mengeneinheit != null ? CRUDItem.mengeneinheit.summary : ''}} verfügbar
            </p>
            <p *ngIf="CRUDItem != null && CRUDItem.typ != 'S' && CRUDItem.pauschal == true" class="textRight"
                style="font-size: 18px; color: green">
                {{(CRUDItem != null ? CRUDItem.preis : '') | crudBasic:null:(crudItemService.propertyTypes!=null&&crudItemService.propertyTypeOf!=null?crudItemService.propertyTypeOf('preis'):null)}}
                für {{CRUDItem != null ? CRUDItem.menge : ''}}
                {{CRUDItem != null && CRUDItem.mengeneinheit != null ? CRUDItem.mengeneinheit.summary : ''}}</p>
            <br>

            <div *ngIf="CRUDItem != null" class="p-grid" style="font-size: 14px;">
                <div class="p-col-5">Hersteller:</div>
                <div class="p-col-7">
                    <b>{{CRUDItem != null && CRUDItem.hersteller != null ? CRUDItem.hersteller.summary : ''}}</b>
                </div>

                <div class="p-col-5">Artikelgruppe:</div>
                <div class="p-col-7">
                    {{CRUDItem != null && CRUDItem.artikelgruppe != null ? CRUDItem.artikelgruppe.summary : ''}}
                </div>

                <div class="p-col-5" *ngIf="CRUDItem != null && CRUDItem.laenge != null">
                    Länge:
                </div>
                <div class="p-col-7" *ngIf="CRUDItem != null && CRUDItem.laenge != null">
                    {{CRUDItem.laenge | crudBasic:null:(crudItemService.propertyTypes!=null&&crudItemService.propertyTypeOf!=null?crudItemService.propertyTypeOf('laenge'):null)}} cm
                </div>
                <div class="p-col-5" *ngIf="CRUDItem != null && CRUDItem.breite != null">
                    Breite:
                </div>
                <div class="p-col-7" *ngIf="CRUDItem != null && CRUDItem.breite != null">
                    {{CRUDItem.breite | crudBasic:null:(crudItemService.propertyTypes!=null&&crudItemService.propertyTypeOf!=null?crudItemService.propertyTypeOf('breite'):null)}} cm
                </div>
                <div class="p-col-5" *ngIf="CRUDItem != null && CRUDItem.hoehe != null">
                    Höhe:
                </div>
                <div class="p-col-7" *ngIf="CRUDItem != null && CRUDItem.hoehe != null">
                    {{CRUDItem.hoehe | crudBasic:null:(crudItemService.propertyTypes!=null&&crudItemService.propertyTypeOf!=null?crudItemService.propertyTypeOf('hoehe'):null)}} cm
                </div>
                <div class="p-col-5" *ngIf="CRUDItem != null && CRUDItem.dicke != null">
                    Dicke:
                </div>
                <div class="p-col-7" *ngIf="CRUDItem != null && CRUDItem.dicke != null">
                    {{CRUDItem.dicke | crudBasic:null:(crudItemService.propertyTypes!=null&&crudItemService.propertyTypeOf!=null?crudItemService.propertyTypeOf('dicke'):null)}} cm
                </div>
                <div class="p-col-5" *ngIf="CRUDItem != null && CRUDItem.oberflaeche != null">
                    Oberfläche:
                </div>
                <div class="p-col-7" *ngIf="CRUDItem != null && CRUDItem.oberflaeche != null">
                    {{CRUDItem.oberflaeche.summary}}
                </div>
                <div class="p-col-5" *ngIf="CRUDItem != null && CRUDItem.farbe != null">
                    Farbe:
                </div>
                <div class="p-col-7" *ngIf="CRUDItem != null && CRUDItem.farbe != null">
                    {{CRUDItem.farbe.summary}}
                </div>
                <div class="p-col-5" *ngIf="CRUDItem != null && CRUDItem.kante != null">
                    Kante:
                </div>
                <div class="p-col-7" *ngIf="CRUDItem != null && CRUDItem.kante != null">
                    {{CRUDItem.kante.summary}}
                </div>

                <div *ngIf="CRUDItem != null && CRUDItem.typ != 'S'" class="p-col-5">Produktzustand:</div>
                <div *ngIf="CRUDItem != null && CRUDItem.typ != 'S'" class="p-col-7">{{CRUDItem != null && CRUDItem.zustand != null ? CRUDItem.zustand.summary : ''}}
                </div>
                <div *ngIf="CRUDItem != null && CRUDItem.typ != 'S'" class="p-col-5">Verpackung:</div>
                <div *ngIf="CRUDItem != null && CRUDItem.typ != 'S'" class="p-col-7">
                    {{CRUDItem != null && CRUDItem.verpackung != null ? CRUDItem.verpackung.summary : ''}}</div>
                <div *ngIf="CRUDItem != null && CRUDItem.typ != 'S' && CRUDItem.artikelLieferarten != null" class="p-col-5">Lieferart:</div>
                <div *ngIf="CRUDItem != null && CRUDItem.typ != 'S'" class="p-col-7">
                    <span
                        *ngFor="let artikelLieferart of CRUDItem.artikelLieferarten; let i = index">{{i>0 ? ', ' : ''}}{{artikelLieferart.summary}}</span>
                </div>
                <div *ngIf="CRUDItem != null && CRUDItem.typ != 'S'" class="p-col-5">Verlademöglichkeit:</div>
                <div *ngIf="CRUDItem != null && CRUDItem.typ != 'S'" class="p-col-7">{{CRUDItem.verlademoeglichkeit == true ? 'Vorhanden' : 'Nicht vorhanden'}}<span
                        style="margin-left: 5px;"><i class="fa fa-info-circle" id="iCursor" aria-hidden="true"
                            pTooltip="{{CRUDItem.verlademoeglichkeitVermerk}}" tooltipPosition="right"></i>
                    </span></div>
                <!-- <div class="p-col-5" *ngIf="CRUDItem != null && CRUDItem.verlademoeglichkeitVermerk != null">&nbsp;</div> -->
                <!-- <div class="p-col-7" *ngIf="CRUDItem != null && CRUDItem.verlademoeglichkeitVermerk != null"></div> -->
                <div *ngIf="CRUDItem != null && CRUDItem.typ != 'S'" class="p-col-5">&nbsp;</div>
            </div>

            <p-tabView *ngIf="CRUDItem != null" id="tabViewDetailArtikelAnsicht"
                [style]="{'background-color': '#f7f7f7', 'margin-top': '5px', 'font-size': '14px'}">
                <p-tabPanel
                    *ngIf="CRUDItem != null && CRUDItem.beschreibung != null && CRUDItem.beschreibung.length > 0"
                    [headerStyle]="{'background-color': '#f7f7f7'}" header="Beschreibung" [selected]="true">
                    <p class="textRight artikelBeschreibung" style="display: table-cell;"
                        [innerHTML]="CRUDItem.beschreibung | keepHtml"></p>
                        <span *ngIf="debugMode == true">{{CRUDItem.beschreibung}}</span>
                </p-tabPanel>
                <p-tabPanel [headerStyle]="{'background-color': '#f7f7f7'}" header="Kontaktdaten">
                    <div class="textFloat artikelBeschreibung " style="font-weight: 600; font-size: 14px;">
                        <p>{{unternehmen != null ? unternehmen.bezeichnung : ''}}</p>
                        <p>{{unternehmen != null ? unternehmen.website : ''}}</p>
                        <p *ngIf="CRUDItem.ansprechpartner != null">
                            {{CRUDItem.ansprechpartner.summary != null ? CRUDItem.ansprechpartner.summary : ''}}
                        </p>
                        <p
                             *ngIf="CRUDItem.telefon_anzeigen == true && (CRUDItem.ansprechpartner == null || CRUDItem.ansprechpartner.telefon == null)">
                            Telefon: <u><a class="hoverTel" (click)="telnummerAndEmail('unternehmenTelefon')" href="tel:{{unternehmen.telefon}}">{{unternehmen != null ? unternehmen.telefon : ''}}</a></u></p>
                        <p
                            *ngIf="CRUDItem.telefon_anzeigen == true && (CRUDItem.ansprechpartner != null && CRUDItem.ansprechpartner.telefon != null && (CRUDItem.ansprechpartner.mobil == null || CRUDItem.ansprechpartner.mobil != true))">
                            Telefon: <u><a class="hoverTel" (click)="telnummerAndEmail('ansprechpartnerTelefon')" href="tel:{{CRUDItem.ansprechpartner.telefon}}">{{CRUDItem.ansprechpartner.telefon}}</a></u></p>
                        <p
                            *ngIf="CRUDItem.mobilnummer_anzeigen == true && (CRUDItem.ansprechpartner != null && CRUDItem.ansprechpartner.mobil != null && (CRUDItem.ansprechpartner.telefon == null ||CRUDItem.ansprechpartner.telefon != true))">
                            Handy: <u><a class="hoverTel"(click)="telnummerAndEmail('ansprechpartnerMobil')" href="tel:{{CRUDItem.ansprechpartner.mobil}}">{{CRUDItem.ansprechpartner.mobil}}</a></u></p>
                        <p
                            *ngIf="CRUDItem.email_anzeigen == true && (CRUDItem.ansprechpartner == null || CRUDItem.ansprechpartner.email == null)">
                            E-Mail: <u><a class="hoverMail" (click)="telnummerAndEmail('unternehmenEmail')" href="mailto:{{unternehmen.email}}">{{unternehmen != null ? unternehmen.email : ''}}</a></u></p>
                        <p
                            *ngIf="CRUDItem.email_anzeigen == true && (CRUDItem.ansprechpartner != null && CRUDItem.ansprechpartner.email != null)">
                            E-Mail: <u><a class="hoverMail" (click)="telnummerAndEmail('ansprechpartnerEmail')" href="mailto:{{CRUDItem.ansprechpartner.email}}">{{CRUDItem.ansprechpartner.email}}</a></u></p>
                    </div>
                </p-tabPanel>
            </p-tabView>
            
        </div>

    </div><!-- ENDE RECHTS -->
</div>

<div *ngIf="CRUDItemAsParameter != null" class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="box" style="display: flex;">
            <div style="width: 100%">

                <div style="float: right;">
                    <button id="Vorschau" pButton type="button" label="Zurück" class="ui-button-success"
                        style="margin-right: 5px;" (click)="vorschauZurueckClicked()"></button>
                    <button *ngIf="mode == null || mode != 'import'" styleClass="ui-button-warning" pButton type="button" [icon]="'pi pi-check'"
                        [label]="translate.instant('Speichern',true)" (click)="vorschauSpeichernClicked()"></button>
                </div>
            </div>
        </div>
    </div>
</div>

<button *ngIf="debugMode == true" pButton type="button" (click)="debug(null)" icon="fa-bug" title="debug form"></button>
