import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//import { Observable } from 'rxjs/Observable';
import { Observable, of, from } from 'rxjs';
import { map, tap, share, switchMap } from 'rxjs/operators';

import { ArtikelDetailComponent } from './artikel-detail.component';
//import { GlobalService } from '../_services/global.service';
import { TranslateService } from '../_services/translate.service';
import { AuthService } from '../auth/auth.service';
import { ArtikelService } from '../_services/artikel.service';

@Injectable()
export class ArtikelDetailGuard implements CanActivate {

  constructor(/*private globalService: GlobalService,*/
    public translate: TranslateService,
    private authService: AuthService,
    private artikelService: ArtikelService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if(this.authService.isAdmin() == true) return true; 
    return this.checkArtikelIstVonMeinemUnternehmen(next.params.id);
  }

  checkArtikelIstVonMeinemUnternehmen(artikelId: number) {
    if(artikelId == 0) return of(true);

    return this.artikelService.getArtikelBare(artikelId).pipe(
      switchMap(
        data => {
          if(this.authService.isMyUnternehmen(data/*.ansprechpartner*/.unternehmenId)) {
            return of(true);
          }
          else {
            return of(false);
          }
        }
      )
    )
  }

  canDeactivate(component: ArtikelDetailComponent): boolean {
    if (component.CRUDForm.dirty && !component.deleted && !component.errorMessage) {
      //let bezeichnung = component.CRUDForm.get('bezeichnung').value || "#pageTitleAdd#";
      //return false;
      return confirm(this.translate.instant('ERROR_UNSAVED_CHANGES'));
    }

    return true;
  }
}
