import { Pipe, PipeTransform } from '@angular/core';
//import { GlobalService } from '../_services/global.service';
import * as moment from 'moment';
//import * as isDate from 'lodash/isDate';

@Pipe({
  name: 'crudBasic',
  pure: true 
})
export class CRUDBasicPipe implements PipeTransform {
  // DE
  komma: string = ",";
  tausenderTrennzeichen: string = "."; 
  // EN
  //komma: string = "."; 
  //tausenderTrennzeichen: string = ","; 

  constructor(/*private globalService: GlobalService*/) {
    //console.log("CRUDBasicPipe.constructor() globalService.getUser()", this.globalService.getUser());
    try {
      //if(this.globalService.getUser().language=='en') {
      //  this.komma = "."; 
      //  this.tausenderTrennzeichen = ","; 
      //}
    }
    catch(e) {
      //
    }
  }

  // parameter:
  // 1. normales Feld, zB. String: value = der string, args = null
  //    ODER objekt, zB. Sprache: value = das objekt, args[0] = 'bezeichnung'
  // 2. propertyType aus Service (war mal typeOf) - oder null, wenn nicht vorhanden

  transform(value: any, ...args: any[]): string {

    if (!value) return;
    //console.log("CRUDBasicPipe: value: "+value+ " is typeof:", typeof value);
    let propertyType = args != null && args.length>1 ? args[1] : null;
    //console.log("CRUDBasicPipe: value: "+value+ " propertyType (from parameter):", propertyType);
    //let typeOfValue = args != null && args.length>1 ? args[1] : null;
    let typeOfValue = propertyType != null && propertyType.type != null ? propertyType.type : null;
    //console.log("CRUDBasicPipe: value: "+value+ " typeOfValue (from parameter):", typeOfValue);
    let decimalsValue = propertyType != null && propertyType.decimals != null ? propertyType.decimals : null;

    if(typeof value === 'object') {
      if(args.length > 0 && args[0] != null) {
        //console.log("CRUDBasicPipe: value: "+value+ " is an object! and the property is '"+args[0]+"'. returning the value of this property.");

        // Proforma: die meisten Entities liefern noch kein property "summary". - Wenn summary gefragt ist, es das aber nicht gibt, dann
        //           "bezeichnung"
        //if(args[0]=="summary" && value[args[0]]==null) return value["bezeichnung"];
        return this.format(value[args[0]], propertyType);
      }
      else {
        //console.log("CRUDBasicPipe: value: "+value+ " is an object! return .bezeichnung (since propertyname is not given)");
        //return value.bezeichnung;

        // sollte eigentlich nicht vorkommen, daher:
        return "ERROR: CRUDBasicPipe: object without propertyname!"
      }
    }
    //if(typeof value === 'boolean') {
    //}
    else if(typeOfValue!=null && (typeOfValue.toLowerCase()=="decimal" || typeOfValue.toLowerCase()=="int" || typeOfValue.toLowerCase()=="double")) {
      if(this) {
        //return (""+value).replace(".", ",");
        return this.format(this.getFormatedValue(value, this.komma, null, decimalsValue), propertyType);
      }
    }
    else if(this.isValidDate(value, typeOfValue)) {
      if(value.length==19) { // ohne zeitzone
        let datum = moment.utc(value).format('DD.MM.YYYY');
        let zeit = moment.utc(value).format('HH:mm:ss');
        return datum+" "+zeit; // bei Datumsfelder nicht über format() formatieren!
      }
      else { // mit zeitzone
        let datum = moment(value).format('DD.MM.YYYY');
        let zeit = moment(value).format('HH:mm:ss');
        return datum+" "+zeit; // bei Datumsfelder nicht über format() formatieren!
      }
    }
    else {
      //console.log("CRUDBasicPipe: value: "+value+ " is NOT an object! it's typeof='"+(typeof value)+"' return same value", value);
      return this.format(value, propertyType);
    }
  }

  format(value: any, propertyType: any) {
    // bisher unterstützte Formate:
    // "%" - " %" als suffix anhängen
    // "$" - Währung hinten anhängen - konkret " €" (bisher fix €)
    if(propertyType == null || propertyType.format == null) return value;

    if(propertyType.format == "%") return value+/*"&nbsp;%"*/" %";
    if(propertyType.format == "$") return value+/*"&nbsp;€"*/" €";
  }

  //https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date
  isValidDate(date:any, typeOfValue:string) {
    //return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    //return isDate(date); // versuch über lodash/isDate()

    // 1. zuerst prüfen, ob typeOfValue (=service.typeOf()) verfügbar ist (neuere Services): wenn ja ist das entscheidend:
    if(typeOfValue!=null) {
      //console.log("CRUDBasicPipe.isValidDate(): value: "+date+ " typeOf is known: ", typeOfValue);
      if(['datetimeoffset', 'datetime'].indexOf(typeOfValue.toLowerCase()) >= 0) {
        //console.log("CRUDBasicPipe.isValidDate(): value: "+date+ " is a date type!");
        return true;
      }
      //console.log("CRUDBasicPipe.isValidDate(): value: "+date+ " is NOT a date type!");
      return false; // alle anderen typeOfs sind kein Datum!
    }

    //console.log("CRUDBasicPipe.isValidDate(): value: "+date+ " typeOf is NOT known! ");
    // 2. falls typeOfValue (=service.typeOf()) NICHT verfügbar ist (ältere Services): 
    // warum auch immer: aber alle Date (in API DateTime) scheinen als String hier anzukommen - z.B. FiBuBuchungsmonate
    // PROVISORIUM: die einziger Möglichkeit sie zu erkennen: an der Formatierung: YYYY-MM-DDTHH:MM:SS  oder  ...+02:00
    if(date != null && typeof date == 'string' && (date.length==19 || date.length==25)) {
      if(date.substr(4,1)=='-' && date.substr(7,1)=='-' && date.substr(10,1)=='T' && date.substr(13,1)==':' && date.substr(16,1)==':'
         && (date.length==19 || date.substr(19,1)=='+' && date.substr(22,1)==':') ) {
        try {
          let dateAlsDate = new Date(date);
          //console.log("CRUDBasicPipe.isValidDate(): date: "+date+ " dateAlsDate: ", dateAlsDate);
          if(""+dateAlsDate != "Invalid Date") {
            //console.log("CRUDBasicPipe.isValidDate(): date: "+date+ " is a valid date!, return true!  typeof="+typeof date+" dateAlsDate="+dateAlsDate  /*+" instanceof Date:"+(date instanceof Date)*/);
            return true;
          }
          //else return false;
        }
        catch(e) {
          //return false;
        }
      }
    }
    return false;
  }

  public getFormatedValue(value: any, komma: string, quantityUnit: string, decimals?: number, leadingZeros?: boolean) {
    //console.log("CRUDBasicPipe.getFormatedValue() value:"+value+ " komma:"+komma+" quantityUnit:"+quantityUnit+" decimals:"+decimals);
    if(value == null) return null;

    let formatedValue = null;
    
    //https://blog.abelotech.com/posts/number-currency-formatting-javascript/
    if(decimals != null) {
      if(komma == ',') {
        formatedValue = (
          this.fixDecimalsOhneTausenderTrennzeichen(
          new Number(value)
            .toFixed(decimals) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'), komma, decimals) + (quantityUnit != null && quantityUnit.length>0 ? ' ' + quantityUnit : '')
        ) // use . as a separator
      }
      else {
        formatedValue = (
          this.fixDecimalsOhneTausenderTrennzeichen(
          new Number(value)
            .toFixed(decimals) // always two decimal digits
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'), komma, decimals) + (quantityUnit != null && quantityUnit.length>0 ? ' ' + quantityUnit : '')
        ) 
      }
    }
    else { // decimals unbekannt ! (in der Pipe sind sie unbekannt, aus CRUDBasicInput sind sie bekannt)
      if(komma == ',') {
        formatedValue = (
          this.fixDecimalsOhneTausenderTrennzeichen(
          new Number(value).toString()
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'), komma, decimals) + (quantityUnit != null && quantityUnit.length>0 ? ' ' + quantityUnit : '')
        ) // use . as a separator
      }
      else {
        formatedValue = (
          this.fixDecimalsOhneTausenderTrennzeichen(
          new Number(value).toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'), komma, decimals) + (quantityUnit != null && quantityUnit.length>0 ? ' ' + quantityUnit : '')
        ) 
      }
    }

    // durch den new Number() gingen ggf. führende Nullen verloren - ggf. wieder voranstellen
    if(formatedValue != null && leadingZeros != null && leadingZeros == true) {
        if(typeof value === 'string') {
          // führende Nullen zählen
          let leadingZeros = 0;
          for (let i = 0; i < value.length; i ++) {
            if(value.substring(i,i+1) != '0') {
              break;
            }
            leadingZeros ++;
          }
          // führende Nullen wieder voranstellen
          if(leadingZeros > 0) {
            formatedValue = value.substring(0, leadingZeros) + formatedValue;
          }
        }
    }
    return formatedValue;
  }

  // getFormatedValue hat ein Problem: wenn mehr als 3 Nachkommastellen -> dann werden auch hinter dem Komma TausenderPunkte gesetzt!
  // das wird hiermit wieder ausgebügelt
  fixDecimalsOhneTausenderTrennzeichen(formatedValue: string, komma: string, decimals?: number) {
    //console.log("CRUDBasicPipe.fixDecimalsOhneTausenderTrennzeichen() formatedValue:"+formatedValue+ " komma:"+komma+" decimals:"+decimals);
    if(decimals != null && decimals < 4) {
      //console.log("CRUDBasicPipe.fixDecimalsOhneTausenderTrennzeichen()    return 1:1, da keine decimals, oder decimals < 4");
      return formatedValue; // alles ok, muss nicht nachgebessert werden
    }

    // alles nach dem Komma rausfiltern
    let posKomma = formatedValue.indexOf(komma);
    if(posKomma<0) {
      //console.log("CRUDBasicPipe.fixDecimalsOhneTausenderTrennzeichen()    return 1:1, da kein komma");
      return formatedValue; // alles ok, es gibt kein komma
    }
    else {
      let anzahlNachkomma = formatedValue.length - posKomma - 1;
      if(anzahlNachkomma < 4) {
        //console.log("CRUDBasicPipe.fixDecimalsOhneTausenderTrennzeichen()    return 1:1, da nachkommastellen < 4");
        return formatedValue; // alles ok, muss nicht nachgebessert werden
      }
      else { // hinter dem Komma die Tausenderpunkte raus!
        let vorkommaString = formatedValue.substring(0, posKomma);
        let nachkommaString = formatedValue.substring(posKomma + 1);
        if(komma == ',') {
          //nachkommaString = nachkommaString.replace(/./g, ""); // alle , ausfiltern - funktioniert nicht!
          nachkommaString = nachkommaString.replace(".", ""); // filtert eigentlich nur das 1. vorkommen aus, aber das sollte reichen, mehr als 6 Nachkommastellen ist ja unwahrscheinlich
        }
        else {
          //nachkommaString = nachkommaString.replace(/,/g, ""); // alle . ausfiltern - funktioniert nicht!
          nachkommaString = nachkommaString.replace(",", ""); // filtert eigentlich nur das 1. vorkommen aus, aber das sollte reichen, mehr als 6 Nachkommastellen ist ja unwahrscheinlich
        }
        //console.log("CRUDBasicPipe.fixDecimalsOhneTausenderTrennzeichen()    vorkommaString:"+vorkommaString+" nachkommaString:"+nachkommaString+" return:", vorkommaString + komma + nachkommaString);
        return vorkommaString + komma + nachkommaString;
      }
    }

    // kann es diesen Fall überhaupt geben ? Falls ja: unverändert zurück:
    //console.log("CRUDBasicPipe.fixDecimalsOhneTausenderTrennzeichen()    return 1:1, weil Ende der Logik (?)");
    return formatedValue;
  }


}
