import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
import { AutoComplete } from 'primeng/primeng';

import { IUnternehmen } from '../_interfaces/unternehmen';
import { UnternehmenService } from '../_services/unternehmen.service';
import { UnternehmenDetailGuard } from './unternehmen-detail.guard';
import { IBenutzer } from '../_interfaces/benutzer';
import { BenutzerService } from '../_services/benutzer.service';
import { IAnsprechpartner } from '../_interfaces/ansprechpartner';
import { UnternehmenDateiUploadComponent } from 'src/app/unternehmen-datei-upload/unternehmen-datei-upload.component';
import { AnsprechpartnerService } from '../_services/ansprechpartner.service';
import * as cloneDeep from 'lodash/cloneDeep'; 

import { validateBenutzerAlreadyExists, validateEMailAlreadyExists } from '../_validators/benutzer-validators';

//import { UnternehmenDetailComponent_Template } from './unternehmen-detail.component.include_template';

@Component({
  selector: 'app-unternehmen-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${UnternehmenDetailComponent_Template}`,
  templateUrl: './unternehmen-detail.component.html',
  
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class UnternehmenDetailComponent extends CRUDBasicDetailComponent implements OnInit {
  @ViewChild("_datei_Gewerbeschein") _datei_Gewerbeschein: UnternehmenDateiUploadComponent;

  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Unternehm";
  CRUDPageTitleNeu: string = this.translate.instant("Neues Unternehmen", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Unternehmen bearbeiten", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Unternehmen";
  CRUDItemBezeichnungPluralCapitalized: string = "Unternehmen";
  CRUDItemBezeichnungSingular: string = "unternehmen";
  CRUDItemBezeichnungPlural: string = "unternehmen";
  CRUDItemRouteSingular: string = "unternehmen";
  CRUDItemRoutePlural: string = "unternehmen";
  CRUDItemHelpTopic: string = "";

  //debugMode: boolean = true;

  //CRUDMethodGetAfterViewInit: boolean = true; // get"CrudItem"() nicht schon im ngOnInit machen, sondern erst im ngAfterViewInit!
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START
  eigenerBenutzer: IBenutzer = null;
  eigenerBenutzerShow: boolean = true;
  //eigenerBenutzerAnsprechpartner: IAnsprechpartner = null;
  //eigenerBenutzerIstAnsprechpartner: boolean = false;
  eigenerBenutzerPasswordResetShow = false;

  passwordVisible: boolean = false;

  public negativeId: number = -1; // alle neuen Entities (Benutzer/Ansprechpartner bekommen zunächst eine negative Id - damit sie untereinander verknüpft werdem können.
                                  // Die API löst das beim save auf! - siehe API "*NEGATIVE_ID"

  dubletten: IUnternehmen[] = null;
  dublettenShowDialog: boolean = false;
  dublettenShowDialogVisible: boolean = false;

  urlParmEMail: string = null;
  urlParmUserName: string = null;
  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: UnternehmenService,
    private _guard: UnternehmenDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    public benutzerService: BenutzerService,
    private ansprechpartnerService: AnsprechpartnerService,
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      bezeichnung: {
        required: this._translate.instant('Firmenname', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Firmenname', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      strasse: {
        required: this._translate.instant('Strasse', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Strasse', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      /*postleitzahl: {
        required: this._translate.instant('Postleitzahl', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Postleitzahl', true) + ' ' + this._translate.instant('darf 6 Zeichen nicht überschreiten', true)
      },

      ort: {
        maxlength: this._translate.instant('Ort', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      },*/

      geodaten: {
        required: this._translate.instant('Postleitzahl Ort', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      telefon: {
        maxlength: this._translate.instant('Telefon', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      },

      website: {
        maxlength: this._translate.instant('Website', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      },

      email: {
        required: this._translate.instant('E-Mail', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('E-Mail', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true),
        //email: this._translate.instant('E-Mail', true) + ': ' + this._translate.instant('ist keine gültige E-Mail-Adresse', true),
        pattern: this._translate.instant('E-Mail', true) + ': ' + this._translate.instant('ist keine gültige E-Mail-Adresse', true)
      },

      anzahl_Benutzer: {
        required: this._translate.instant('Anzahl  Benutzer', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      gueltig_bis: {
        required: this._translate.instant('Gültig bis', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      rechtsform: {
        required: this._translate.instant('Rechtsform', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Rechtsform', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      steuernummer: {
        required: this._translate.instant('Steuernummer', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Steuernummer', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      //latitude: {
      //  required: this._translate.instant('latitude', true) + ': ' + this._translate.instant('ist erforderlich', true),
      //},

      //longitude: {
      //  required: this._translate.instant('longitude', true) + ': ' + this._translate.instant('ist erforderlich', true),
      //},

      //datenherkunft: {
      //  required: this._translate.instant('Datenherkunft', true) + ': ' + this._translate.instant('ist erforderlich', true),
      //  maxlength: this._translate.instant('Datenherkunft', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      //},

      //accuracy: {
      //  required: this._translate.instant('accuracy', true) + ': ' + this._translate.instant('ist erforderlich', true),
      //  maxlength: this._translate.instant('accuracy', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      //},

      _benutzer_vorname: {
        required: this._translate.instant('Vorname', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Vorname', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      _benutzer_nachname: {
        required: this._translate.instant('Nachname', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Nachname', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      _benutzer_telefon: {
        maxlength: this._translate.instant('Telefon', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      _benutzer_mobil: {
        maxlength: this._translate.instant('Mobil', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      _benutzer_email: {
        required: this._translate.instant('E-Mail', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('E-Mail', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true),
        //email: this._translate.instant('E-Mail', true) + ': ' + this._translate.instant('ist keine gültige E-Mail-Adresse', true),
        pattern: this._translate.instant('E-Mail', true) + ': ' + this._translate.instant('ist keine gültige E-Mail-Adresse', true),
        eMail_exists: this._translate.instant('eMail_exists', true) // async validator
      },

      _benutzer_username: {
        //required: this._translate.instant('Benutzername', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Benutzername', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true),
        Benutzername_exists: this._translate.instant('Benutzername_exists', true) // async validator
      },

      _benutzer_password: {
        //required: this._translate.instant('Passwort', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Passwort', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      _benutzer_passwordBestaetigen: {
        //required: this._translate.instant('Passwort Bestätigen', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Passwort Bestätigen', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },
    };
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      bezeichnung: ['', [Validators.required, Validators.maxLength(255)]],
      strasse: ['', [Validators.required, Validators.maxLength(255)]],
      /*postleitzahl: ['', [Validators.required, Validators.maxLength(6)]],
      ort: ['', [Validators.maxLength(NaN)]],*/
      telefon: ['', [Validators.maxLength(255)]],
      website: ['', [Validators.maxLength(255)]],
      email: ['', [Validators.required, Validators.maxLength(255), /*Validators.email,*/Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]], // https://stackblitz.com/edit/angular-email-validation
      anzahl_Benutzer: [0/*, [Validators.required]*/],
      gueltig_bis: [null/*, [Validators.required]*/],
      lizenzart: [null],
      rechtsform: ['', [Validators.required, Validators.maxLength(255)]],
      //steuernummer: ['', [Validators.required, Validators.maxLength(255)]],
      steuernummer: ['', {updateOn: 'blur', validators:[Validators.required, Validators.maxLength(255)], asyncValidators: []}],
      geodaten: [null, [Validators.required]],
      latitude: [0/*, [Validators.required]*/],
      longitude: [0/*, [Validators.required]*/],
      datenherkunft: [''/*, [Validators.required, Validators.maxLength(255)]*/],
      accuracy: [''/*, [Validators.required, Validators.maxLength(255)]*/],

      _benutzer_vorname: ['', [Validators.required, Validators.maxLength(255)]],
      _benutzer_nachname: ['', [Validators.required, Validators.maxLength(255)]],
      _benutzer_username: ['', [/*Validators.required, */Validators.maxLength(255)], [validateBenutzerAlreadyExists(this.benutzerService)]], // async validator https://www.tektutorialshub.com/angular/angular-async-validator-example/
      _benutzer_telefon: ['', [Validators.maxLength(255)]],
      _benutzer_mobil: ['', [Validators.maxLength(255)]],
      _benutzer_email: ['', [Validators.required, Validators.maxLength(255), /*Validators.email,*/Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')], [validateEMailAlreadyExists(this.benutzerService, this, this.eigenerBenutzerGet)]], // async // eigenerBenutzer.Id über funktion rübergeben, weil zum Zeitpunkt des buidForm noch nicht bekannt!
      _benutzer_istBenutzer: [''],
      _benutzer_istAnsprechpartner: [''],
      _benutzer_password: ['', [/*Validators.required, */Validators.maxLength(255)]], // formvalidator!
      _benutzer_passwordBestaetigen: ['', [/*Validators.required, */Validators.maxLength(255)]], // formvalidator!

      _datei_Gewerbeschein: [null/*, [Validators.maxLength(0)]*/],
    },{
      validator: this.formValidator // auf FormEbene!
    });

    this.CRUDForm['___component'] = this; // trick, um im formValidator wieder an die component zu kommen.

    //https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/#how-to-use-valuechanges
    //der Control (s.o.) ist ausserdem so eingestellt, dass er erste bei 'blur' updated!, siehe dazu https://stackoverflow.com/questions/60423849/how-to-user-updateon-blur-in-formbuilder
    this.CRUDForm.get("steuernummer").valueChanges.subscribe(newValue => {
      console.log("UnternehmenDetail.CRUDForm.valueChanges steuernummer newValue:", newValue);
      if(this.dataId == 0 && newValue != null && newValue.length > 0) {
        this._crudItemService.getUnternehmenCollectionForDublettenCheck(newValue, "NOTINUSE", 1, 0, "")
        .subscribe(
          response => {
            this.dubletten = response.unternehmen;
            if(this.dubletten != null && this.dubletten.length > 0) {
              this.dublettenShowDialog = true;
              this.dublettenShowDialogVisible = true;
            } 
            console.log("UnternehmenDetail.CRUDForm.valueChanges itemsRetrieved() response:", response);
          },
          error => {
              console.log("UnternehmenDetail.CRUDForm.valueChanges error: ", error);
          }
        );
      }

    })

    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    let thisInstance = this;
      this.route.queryParams.subscribe(params => {
        thisInstance.urlParmEMail = params['email'];
        thisInstance.urlParmUserName = params['username'];
      });
  
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    a.geodatenId = a.geodaten != null ? a.geodaten.id : null;
    a.gueltig_bis = this.getUTCDateFromForm(this.CRUDForm.value.gueltig_bis); // bei SQL "datetime"
    a.lizenzartId = a.lizenzart != null ? a.lizenzart.id : null;

    // den ausgefilterten eigenen Benutzer ins array updaten
    if(this.eigenerBenutzerShow == true) {
      if(this.eigenerBenutzer == null) {
        this.eigenerBenutzer = this.benutzerService.initializeBenutzer();
        this.eigenerBenutzer.id = this.negativeId; // Alle neuen Benutzer/Ansprechpartner bekommen temporär eine negative Id, damit sie untereinander verknüpft werdeen können. Die API löst das auf. Siehe API - siehe API "*NEGATIVE_ID"
        this.negativeId--;
      }
      //this.eigenerBenutzer.userName = this.CRUDForm.value._benutzer_username;
      this.eigenerBenutzer._username = this.CRUDForm.value._benutzer_username;
      this.eigenerBenutzer._password = this.CRUDForm.value._benutzer_password;
      this.eigenerBenutzer.vorname = this.CRUDForm.value._benutzer_vorname;
      this.eigenerBenutzer.nachname = this.CRUDForm.value._benutzer_nachname;
      this.eigenerBenutzer.telefon = this.CRUDForm.value._benutzer_telefon;
      this.eigenerBenutzer.mobil = this.CRUDForm.value._benutzer_mobil;
      this.eigenerBenutzer.email = this.CRUDForm.value._benutzer_email;
      this.eigenerBenutzer.istBenutzer = this.CRUDForm.value._benutzer_istBenutzer;
      this.eigenerBenutzer.istAnsprechpartner = this.CRUDForm.value._benutzer_istAnsprechpartner;

      let foundInArray = this.CRUDItem.benutzer.filter(f => f.id == this.eigenerBenutzer.id); // falls noch nicht im Array ...
      if(foundInArray.length == 0) {
        this.CRUDItem.benutzer.push(this.eigenerBenutzer); // ... jetzt einfügen
      }
    }
    
    a.benutzer.forEach(benutzer => {
      this.deleteAllObjectsInObject(benutzer); // rekursivität vermeiden
    });
    

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    console.log("UnternehmenDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      bezeichnung: this.CRUDItem.bezeichnung,
      strasse: this.CRUDItem.strasse,
      /*postleitzahl: this.CRUDItem.postleitzahl,
      ort: this.CRUDItem.ort,*/
      telefon: this.CRUDItem.telefon,
      website: this.CRUDItem.website,
      email: this.CRUDItem.email,
      anzahl_Benutzer: this.CRUDItem.anzahl_Benutzer,
      gueltig_bis: this.CRUDItem.gueltig_bis != null ? new Date(this.CRUDItem.gueltig_bis) : null /* bei SQL "datetime": warum auch immer: wenn nicht null, dann muss das nochmal separat in ein Date gewandelt werden, sonst runtime-error */,
      lizenzart: this.CRUDItem.lizenzart,
      rechtsform: this.CRUDItem.rechtsform,
      steuernummer: this.CRUDItem.steuernummer,
      geodaten: this.CRUDItem.geodaten,
      latitude: this.CRUDItem.latitude,
      longitude: this.CRUDItem.longitude,
      datenherkunft: this.CRUDItem.datenherkunft,
      accuracy: this.CRUDItem.accuracy,

      // eigener Benutzer (der aus Tabelle ausgefiltert wurde)
      _benutzer_vorname: this.eigenerBenutzer != null ? this.eigenerBenutzer.vorname : null,
      _benutzer_nachname: this.eigenerBenutzer != null ? this.eigenerBenutzer.nachname : null,
      _benutzer_telefon: this.eigenerBenutzer != null ? this.eigenerBenutzer.telefon : null,
      _benutzer_mobil: this.eigenerBenutzer != null ? this.eigenerBenutzer.mobil : null,
      _benutzer_username: this.eigenerBenutzer != null ? this.eigenerBenutzer._username : null,
      _benutzer_email: this.eigenerBenutzer != null ? this.eigenerBenutzer.email : null,
      _benutzer_istBenutzer: this.eigenerBenutzer != null ? this.eigenerBenutzer.istBenutzer : null,
      _benutzer_istAnsprechpartner: this.eigenerBenutzer != null ? this.eigenerBenutzer.istAnsprechpartner : null,

      _datei_Gewerbeschein: this.CRUDItem._datei_Gewerbeschein
    });
    //console.log("UnternehmenDetailComponent.sendValuesToForm() eigenerBenutzer.vorname:", this.eigenerBenutzer.vorname);

    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("UnternehmenDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  onCRUDItemRetrieved(CRUDItem: /*IAnrede*/any): void {
    console.log("UnternehmenDetailComponent.onCRUDItemRetrieved() CRUDItem:", CRUDItem);
    console.log("UnternehmenDetailComponent.onCRUDItemRetrieved() _app.benutzer:", this._app.benutzer);
    //console.log("UnternehmenDetailComponent.onCRUDItemRetrieved() _app.benutzer.id:", this._app.benutzer.id);

    // Create-Mode: ggf. Listen leer erstellen
    if(this.dataId == 0) {
      //if(CRUDItem.ansprechpartner == null) CRUDItem.ansprechpartner = [];
      if(CRUDItem.benutzer == null) CRUDItem.benutzer = [];
      if(CRUDItem.lager == null) CRUDItem.lager = [];
    }

    // Den eigenen Benutzer in die Extra-Felder übernehmen (der wird in Tabelle ausgefiltert)
    if(CRUDItem.benutzer != null && this._app != null && this._app.benutzer != null && this._app.benutzer.unternehmenId == this.dataId) {
      this.eigenerBenutzer = CRUDItem.benutzer.filter(f => f.id == this._app.benutzer.id)[0];

      //this.eigenerBenutzerAnsprechpartner = CRUDItem.ansprechpartner.filter(f => f.benutzerId == this.eigenerBenutzer.id)[0];
      //this.eigenerBenutzerIstAnsprechpartner = this.eigenerBenutzerAnsprechpartner != null;

      //this.CRUDForm.controls._benutzer_password.clearValidators(); // im Modify-Mode keine Validators
      //this.CRUDForm.controls._benutzer_passwordBestaetigen.clearValidators();

      // wenn NICHT create-Modus, dann Validator für Benutzername_exists + eMail_exists deaktivieren!
      if(this.dataId != 0) {
        this.CRUDForm.controls._benutzer_username.clearAsyncValidators();
        //this.CRUDForm.controls._benutzer_email.clearAsyncValidators(); // aktiv lassen, weil eMail änderbar!
        console.log("UnternehmenDetail.onCRUDItemRetrieved() deactivated validator for 'Benutzername_exists'. controls.this.CRUDForm.controls._benutzer_username: ", this.CRUDForm.controls._benutzer_username);
      }
    }
    else {
      this.eigenerBenutzer = this.benutzerService.initializeBenutzer();
      this.eigenerBenutzer.id = this.negativeId; // Alle neuen Benutzer/Ansprechpartner bekommen temporär eine negative Id, damit sie untereinander verknüpft werdeen können. Die API löst das auf. Siehe API - siehe API "*NEGATIVE_ID"
      this.negativeId--;
      if(this.dataId != 0) {
        this.eigenerBenutzerShow = false;
        // auch die validators dazu deaktivieren (die max können stehen bleiben)
        this.CRUDForm.controls._benutzer_vorname.clearValidators();
        this.CRUDForm.controls._benutzer_nachname.clearValidators();
        this.CRUDForm.controls._benutzer_email.clearValidators();
        this.CRUDForm.updateValueAndValidity();
      }
      else {
        // Create-Mode: Falls eMail per Parameter bekommen -> übernehmen
        if(this.urlParmEMail != null && this.urlParmUserName != null) {
          this.eigenerBenutzer.email = this.urlParmEMail;
          this.eigenerBenutzer._username = this.urlParmUserName;
        }
      }
    }
      
    console.log("UnternehmenDetailComponent.onCRUDItemRetrieved() eigenerBenutzer:", JSON.stringify(this.eigenerBenutzer));
    //console.log("UnternehmenDetailComponent.onCRUDItemRetrieved() eigenerBenutzer.vorname:", this.eigenerBenutzer.vorname);

    super.onCRUDItemRetrieved(CRUDItem);
  }

  onCrudItemsValueChangeBenutzer($event) { // Parent+Child-CRUD
    console.log("UnternehmenDetailComponent.onCrudItemsValueChangeBenutzer() this:", this);
    console.log("UnternehmenDetailComponent.onCrudItemsValueChangeBenutzer() $event:", $event);
    this.CRUDItem.benutzer = $event;
    this.CRUDForm.markAsDirty();
  }
  /*onCrudItemsValueChangeAnsprechpartner($event) { // Parent+Child-CRUD
    console.log("UnternehmenDetailComponent.onCrudItemsValueChangeAnsprechpartner() this:", this);
    console.log("UnternehmenDetailComponent.onCrudItemsValueChangeAnsprechpartner() $event:", $event);
    this.CRUDItem.ansprechpartner = $event;
    // wurde der Ansprechpartner gelöscht, der = eigner Benutzer ist - UND "Benutzer ist auch Ansprechpartner für die Artikel" ist aktiv ? -> dann rausnehmen
    if(this.eigenerBenutzerIstAnsprechpartner == true) {
      let eigenerAnsprechpartner = this.CRUDItem.ansprechpartner.filter(f => f.benutzer.id == this.eigenerBenutzer.id);
      if(eigenerAnsprechpartner.length == 0) {
        this.eigenerBenutzerAnsprechpartner = null;
        this.eigenerBenutzerIstAnsprechpartner = false;
        let thisInstance = this;
        setTimeout(function(){
          thisInstance.CRUDForm.patchValue({
            _benutzer_ist_auch_ansprechpartner: false
          });
        }, 100);
      }
    }

    this.CRUDForm.markAsDirty();
  }*/
  onCrudItemsValueChangeLager($event) { // Parent+Child-CRUD
    console.log("UnternehmenDetailComponent.onCrudItemsValueChangeLager() this:", this);
    console.log("UnternehmenDetailComponent.onCrudItemsValueChangeLager() $event:", $event);
    this.CRUDItem.lager = $event;
    this.CRUDForm.markAsDirty();

    this.displayMessageForm = {}; // validity für Lager neu prüfen
    this.CRUDForm.updateValueAndValidity(); // validity für Lager neu prüfen
  }
  
  /*benutzer_als_ansprechpartner_changed() {
    console.log("UnternehmenDetailComponent.benutzer_als_ansprechpartner_changed()");
    if(this.CRUDForm.value._benutzer_ist_auch_ansprechpartner == true) { // Schalter wird aktiviert
      let newAnsprechpartner = this.ansprechpartnerService.initializeAnsprechpartner();
      newAnsprechpartner.id = this.negativeId; // Alle neuen Benutzer/Ansprechpartner bekommen temporär eine negative Id, damit sie untereinander verknüpft werdeen können. Die API löst das auf. Siehe API - siehe API "*NEGATIVE_ID"
      this.negativeId--;
      newAnsprechpartner.vorname = this.CRUDForm.value._benutzer_vorname;
      newAnsprechpartner.nachname = this.CRUDForm.value._benutzer_nachname;
      newAnsprechpartner.email = this.CRUDForm.value._benutzer_email;
      newAnsprechpartner.benutzer = this.eigenerBenutzer;
      newAnsprechpartner.benutzerId = this.eigenerBenutzer.id;
      this.CRUDItem.ansprechpartner.push(newAnsprechpartner);

      this.eigenerBenutzerAnsprechpartner = newAnsprechpartner;
      this.eigenerBenutzerIstAnsprechpartner = true;
    }
    else { // Schalter wird deaktiviert
      let allesKlar = false;
      if(this.eigenerBenutzerAnsprechpartner != null) {
        if(this.eigenerBenutzerAnsprechpartner.id > 0) { // Der eigene Ansprechpartner hat eine Id, ist also schon abgespeichert
          this.messageWrapperService.postStaticMessage({ severity: 'error', summary: "Ansprechpartner", detail: "Ansprechpartner kann nicht gelöscht werden, da er möglicherweise bei Artikeln hinterlegt ist." }); 
          let thisInstance = this;
          setTimeout(function(){
            thisInstance.CRUDForm.patchValue({
              _benutzer_ist_auch_ansprechpartner: true
            });
          }, 100);
          allesKlar = true;
        }
        else { // Der eigene Ansprechpartner hat noch keine Id, ist also noch nicht abgespeichert
          let ansprechpartnerVorDelete = this.CRUDItem.ansprechpartner.length;
          this.CRUDItem.ansprechpartner = this.CRUDItem.ansprechpartner.filter(f => f.benutzer.id != this.eigenerBenutzer.id); 
          if(ansprechpartnerVorDelete -1 == this.CRUDItem.ansprechpartner.length ) { // Löschen hat offensichtlich geklappt
            this.eigenerBenutzerAnsprechpartner = null;
            this.eigenerBenutzerIstAnsprechpartner = false;
            allesKlar = true;
          }
        }
      }
      if(allesKlar != true) { // irgendwas unvorhergesehenes ???
        this.messageWrapperService.postStaticMessage({ severity: 'error', summary: "Ansprechpartner", detail: "Interner Fehler beim Löschen des Ansprechpartners." }); 
        let thisInstance = this;
        setTimeout(function(){
          thisInstance.CRUDForm.patchValue({
            _benutzer_ist_auch_ansprechpartner: true
          });
        }, 100);
      }
    }
  }*/

  dubletteSelected($event) {
    console.log("UnternehmenDetailComponent.dubletteSelected() $event:", $event);
    //this.dublettenShowDialog = false; // ist eh schon zu

    this.router.navigateByUrl('unternehmen/2');
  }
  dubletteCancel($event) {
    console.log("UnternehmenDetailComponent.dubletteCancel() $event:", $event);

    let thisInstance = this;
    this.confirmationService.confirm({
      message: "Sind Sie sicher, daß Sie ein neues Unternehmen mit derselben Steuernummer anlegen möchten ?",
      header: this.translate.instant('bereits vorhandenes Unternehmen ignorieren', true) + '?',
      icon: 'fa fa-clone',
      key: 'CRUDBasicDetailConfirmDialog_' + this.CRUDItemBezeichnungPluralCapitalized,
      //acceptLabel: 'Ja, wirklich ein neues Unternehmen anlegen',
      acceptButtonStyleClass: 'redButton',
      rejectLabel: 'Nein, bestehendes Unternehmen wählen',
      rejectButtonStyleClass: 'greenButton',
      accept: () => {
        //thisInstance.dublettenShowDialog = false; // ist eh schon zu!
      },
      reject: () => {
        // Dialog wieder öffnen! - Warum auch immer funktioniert das nicht, wenn man den "ngIF" und den "visible"
        // gleichzeitig aktiviert - Es funktioniert nur, wenn man den visible zeitverzögert aktiviert!
        thisInstance.dublettenShowDialogVisible = false; 
        thisInstance.dublettenShowDialog = true; 
        setTimeout(function(){ 
          thisInstance.dublettenShowDialogVisible = true;
        }, 100);
        
      }
    });
  }

  eigenerBenutzerPasswortAendern() {
    this.eigenerBenutzerPasswordResetShow = true;
  }

  eigenerBenutzerPasswordZuruecksetzenZurueckClicked() {
    this.eigenerBenutzerPasswordResetShow = false;
  }
  eigenerBenutzerPasswordZuruecksetzenSaveClicked() {
    this.eigenerBenutzerPasswordResetShow = false;
  }
  eigenerBenutzerGet(thisInstance: UnternehmenDetailComponent) {
    return thisInstance.eigenerBenutzer;
  }


  /*formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors : any[] = [];
    let errorsFound : boolean = false;

    console.log("UnternehmenDetailComponent.formValidator() form:", form);

    let valuePassword = form.value._benutzer_password;
    let valuePasswordBestaetigen = form.value._benutzer_passwordBestaetigen;
    if(valuePassword != null && valuePasswordBestaetigen != null && valuePassword != valuePasswordBestaetigen) {
        errors['Die_Passwoerter_stimmen_nicht_ueberein']=true;
        errorsFound = true;
    }

    //form.setErrors(errorsFound ? errors : null);
    if(errorsFound) return errors;
    else return null;
  }*/

  formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors : any[] = [];
    let errorsFound : boolean = false;

    console.log("UnternehmenDetailComponent.formValidator() form:", form);

    // dazu erstmal an die in der Form verlinkte Component kommen
    if(form['___component'] != null/* && form['___component'].dataId != null && form['___component'].dataId == 0*/) {
      let thisInstance = form['___component'];

      let createMode = form['___component'].dataId != null && form['___component'].dataId == 0;

      if(thisInstance.eigenerBenutzerShow == true) {
        let valueIstBenutzer = form.value._benutzer_istBenutzer;
        let valueUsername = form.value._benutzer_username;
        let valuePassword = form.value._benutzer_password;
        let valuePasswordBestaetigen = form.value._benutzer_passwordBestaetigen;
    
        if(valueIstBenutzer) {
          if(valueUsername == null) {
            errors['Benutzername_ist_erforderlich']=true;
            errorsFound = true;
          }
          else {
            let ungueltigeZeichenGefunden = thisInstance.benutzerService.validateUserName(valueUsername);
            if(ungueltigeZeichenGefunden != null) {
              errors['Benutzername_enthaelt_ungueltige_Zeichen']=true;
              errorsFound = true;
            }
          }
  
          // wenn Benutzer und es war bisher keiner, dann brauchen wir ein Passwort!
          if(valueIstBenutzer == true && (createMode == true || thisInstance.eigenerBenutzer.istBenutzer == false) ) {
  
            if(valuePassword == null) {
              errors['Passwort_ist_erforderlich']=true;
              errorsFound = true;
            }
  
            if(valuePassword != null /*&& valuePasswordBestaetigen != null*/ && valuePassword != valuePasswordBestaetigen) {
              errors['Die_Passwoerter_stimmen_nicht_ueberein']=true;
              errorsFound = true;
            }
          }
        } 
      }

      if(thisInstance.CRUDItem != null && (thisInstance.CRUDItem.lager == null || thisInstance.CRUDItem.lager.length == 0)) {
        errors['Es_muss_mindestens_ein_Lager_vorhanden_sein']=true;
        errorsFound = true;
      }

      if(thisInstance.CRUDForm.value._datei_Gewerbeschein == null) {
        if(thisInstance.CRUDItem != null && thisInstance.CRUDItem.unternehmenId != 1) {
          errors['Bitte_Gewerbeschein_hinterlegen']=true;
          errorsFound = true;
        }
      }

    }

    //form.setErrors(errorsFound ? errors : null);
    if(errorsFound) return errors;
    else return null;
  }

  debug(obj) {
    console.log("UnternehmenDetailComponent.debug() displayMessage/displayMessageForm:", this. displayMessage, this.displayMessageForm);
    console.log("UnternehmenDetailComponent.debug() obj:", obj);
    console.log("UnternehmenDetailComponent.debug() eigenerBenutzer:", this.eigenerBenutzer);
    //console.log("UnternehmenDetailComponent.debug() eigenerBenutzerShow:", this.eigenerBenutzerShow);
    super.debug(obj);
  }

}
