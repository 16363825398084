<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
        <div #divCardToolbar class="card">
          <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
          [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
          [class.card-toolbar-slim-active]="app.isSlim()"-->
          <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <span>
            <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
            <button style="width: auto;" pButton type="submit" [icon]="CRUDButtonSaveIcon" [label]="translate.instant(CRUDButtonSaveLabel,true)"></button>
          </span>
          <span class="floatRight">
            <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
            <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
              (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
          </span>
        </div>

        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card">
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
      </form>
    </div>
  </div>
</div>
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>

<!-- ^ hierhin das gesamte CRUDBasicDetail kopieren ! ^ -->
<ng-template #CRUDChildTemplate>
            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="bezeichnung">
                <label>{{'Bezeichnung'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.bezeichnung}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="strasse">
                <label>{{'Strasse'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.strasse}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <crud-basic-autocomplete #geodaten formControlName="geodaten" [type]="'geodaten_postleitzahl'" 
                [required]="true"></crud-basic-autocomplete>
                <label>{{'Postleitzahl Ort'|translate:true}} *</label>
            </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.geodaten}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 checkboxes">
              <span class="md-inputfield">
                <crud-basic-checkbox formControlName="baustelle" [nullable]="false" [label]="translate.instant('Baustelle',true)"></crud-basic-checkbox>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.baustelle}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" style="display: none;">
              <span class="md-inputfield">
                <p-calendar [disabled]="false" [inputStyle]="{'width':'100%'}"
                  [style]="{'width':'100%'}" formControlName="baustelle_Gueltigkeit" id="kalenderBaustelleLabel" [locale]="DE"
                  dateFormat="dd.mm.yy">
                </p-calendar>
                <label>Baustelle speichern bis</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessageForm.Baustelle_Gueltigkeit_ist_erforderlich}}
              </div>
            </div>

<!--            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <crud-basic-autocomplete #unternehmen formControlName="unternehmen" [type]="'unternehmen'" 
                [required]="false"></crud-basic-autocomplete>
                <label>{{'Unternehmen'|translate:true}}</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.unternehmen}}
              </div>
            </div>

                        <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="postleitzahl">
                <label>{{'Postleitzahl'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.postleitzahl}}
              </div>
            </div>

                        <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="ort">
                <label>{{'Ort'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.ort}}
              </div>
            </div>

-->

<!--            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="datenherkunft">
                <label>{{'Datenherkunft'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.datenherkunft}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="accuracy">
                <label>{{'accuracy'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.accuracy}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="latitude">
                <label>{{'latitude'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.latitude}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="longitude">
                <label>{{'longitude'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.longitude}}
              </div>
            </div>
-->
</ng-template>
    
