<div class="container">
    <div class="ui-g">
        <div class="ui-g-12">
            <div class="card">
                <h1>Upload</h1>
                <p-toast></p-toast>

                <p-fileUpload name="demo[]" url="./upload.php" (onUpload)="onUpload($event)"
                        multiple="multiple" accept="image/*" maxFileSize="1000000">
                        <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                            </ul>
                        </ng-template>
                </p-fileUpload>
            </div>
        </div>
    </div>
</div>
