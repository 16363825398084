import { CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component';
//import { CRUDBasicListComponent_Template, CRUDBasicListComponent } from '../crud-basic-list/crud-basic-list.component'; // HTML Template Plugins
//import { UnternehmenListComponent_Template } from './unternehmen-list.component.include_template'; // HTML Template Plugins

import { Component, OnInit, Inject, forwardRef, Injector } from '@angular/core';
import { AppComponent } from '../app.component';

//import { IUnternehmen } from '../_interfaces/unternehmen';
import { UnternehmenService } from '../_services/unternehmen.service';

@Component({
  selector: 'app-unternehmen-list',
  templateUrl: '../crud-basic-list/crud-basic-list.component.html',
  //templateUrl: './unternehmen-list.component.html',
  //template: `${CRUDBasicListComponent_Template || ''}${UnternehmenListComponent_Template}`,  // HTML Template Plugins
  styleUrls: ['../crud-basic-list/crud-basic-list.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'sizeTable()'
  }
})
export class UnternehmenListComponent extends CRUDBasicListComponent implements OnInit {
  pageTitle: string = this.translate.instant("Unternehmen", true);
  CRUDItemKurzform: string = "Unternehmen";
  CRUDItemButtonTitleNeu: string = null; //this.translate.instant("Neues Unternehmen", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Unternehmen";
  CRUDItemBezeichnungPluralCapitalized: string = "Unternehmen";
  CRUDItemBezeichnungSingular: string = "unternehmen";
  CRUDItemBezeichnungPlural: string = "unternehmen";
  CRUDItemHelpTopic: string = "Unternehmen";
  CRUDItemRouteSingular: string = "unternehmen";
  CRUDItemRoutePlural: string = "unternehmen";
  
  CRUDItemAvailableCols: any[] = [
          {
            field: 'createdBy',
            header: this.translate.instant('CreatedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 1,
            showByDefault: false
          },
          {
            field: 'created',
            header: this.translate.instant('Created', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 2,
            showByDefault: false
          },
          {
            field: 'modifiedBy',
            header: this.translate.instant('ModifiedBy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 3,
            showByDefault: false
          },
          {
            field: 'modified',
            header: this.translate.instant('Modified', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 4,
            showByDefault: false
          },
          {
            field: 'bezeichnung',
            header: this.translate.instant('Name', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 5,
            showByDefault: true
          },
          {
            field: 'strasse',
            header: this.translate.instant('Strasse', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 6,
            showByDefault: false
          },
          /*{
            field: 'postleitzahl',
            header: this.translate.instant('Postleitzahl', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 7,
            showByDefault: true
          },
          {
            field: 'ort',
            header: this.translate.instant('Ort', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 8,
            showByDefault: true
          },*/
          {
            field: 'geodaten.summary',
            header: this.translate.instant('Postleitzahl', true) + " " + this.translate.instant('Ort', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 8,
            showByDefault: true
          },
          {
            field: 'telefon',
            header: this.translate.instant('Telefon', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 9,
            showByDefault: false
          },
          {
            field: 'website',
            header: this.translate.instant('Website', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 10,
            showByDefault: false
          },
          {
            field: 'email',
            header: this.translate.instant('Email', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 11,
            showByDefault: false
          },
          {
            field: 'anzahl_Benutzer',
            header: this.translate.instant('Anzahl  Benutzer', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 12,
            showByDefault: false
          },
          {
            field: 'gueltig_bis',
            header: this.translate.instant('Gültig bis', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 13,
            showByDefault: false
          },
          {
            field: 'lizenzart.summary',
            header: this.translate.instant('Lizenzart', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 14,
            showByDefault: false
          },
          {
            field: 'rechtsform',
            header: this.translate.instant('Rechtsform', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 1,
            showByDefault: false
          },
          {
            field: 'steuernummer',
            header: this.translate.instant('Steuernummer', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 2,
            showByDefault: false
          },
          {
            field: 'latitude',
            header: this.translate.instant('latitude', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 4,
            showByDefault: false
          },
          {
            field: 'longitude',
            header: this.translate.instant('longitude', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 5,
            showByDefault: false
          },
          {
            field: 'datenherkunft',
            header: this.translate.instant('Datenherkunft', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 6,
            showByDefault: false
          },
          {
            field: 'accuracy',
            header: this.translate.instant('accuracy', true),
            styleClass: 'columnText',
            sortable: true,
            filterMatchMode: "contains",
            order: 7,
            showByDefault: false
          }
    ];

    constructor(
      @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
      private _crudItemService: UnternehmenService,
      private _injector: Injector
    ) {
      super(_app, _injector);
      this.crudItemService = _crudItemService;
    }

    //ngOnInit() { // HTML Template Plugins
    //  super.ngOnInit();
    //  this.app.pageTitle = this['pageTitle'];
    //}
}
