import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { IUnternehmen } from '../_interfaces/unternehmen';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '../_services/translate.service';
import { MessageWrapperService } from '../_services/message-wrapper.service';
import { Router } from '@angular/router';
import { debounceTime } from '../../../node_modules/rxjs/operators';

import { UnternehmenService } from '../_services/unternehmen.service';

//import { GlobalService } from '../_services/global.service'; 
import { LazyLoadEvent } from "primeng/api";
declare var $: any;

import { CRUDBasicSelectLazyComponent } from '../crud-basic-select-lazy/crud-basic-select-lazy.component'; 


@Component({
  selector: 'app-select-unternehmen',
  templateUrl: '../crud-basic-select-lazy/crud-basic-select-lazy.component.html',
  styleUrls: ['../crud-basic-select-lazy/crud-basic-select-lazy.component.css']
})
export class SelectUnternehmenComponent extends CRUDBasicSelectLazyComponent implements OnInit {
  
  @Output()
  unternehmenSelected: EventEmitter<IUnternehmen> = new EventEmitter<IUnternehmen>();

  // folgende Werte müssen in der SubKlasse überschrieben werden - Ausserdem: crudItemService, crudItemOnSelect - siehe constructor
  CRUDItemBezeichnungPluralCapitalized: string = "Unternehmen";
  CRUDItemBezeichnungPlural: string = "unternehmen";

  crudItemLabel: string = "bezeichnung";
  crudItemLabel2: string = "ort";

  CRUDMethodNameGetCollection: string = "getUnternehmen";

    constructor(
      public translateService: TranslateService,
      protected messageWrapperService: MessageWrapperService,
      protected router: Router,
      //protected globalService: GlobalService,
      protected unternehmenService: UnternehmenService
    ) { 
      super(translateService, messageWrapperService, router/*, globalService*/);

      this.crudItemService = this.unternehmenService;
      this.crudItemOnSelect = this.unternehmenSelected;
    }
  
}
