import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, SimpleChange, SimpleChanges, Inject, forwardRef, Injector, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '../_services/translate.service';

import { GuessMIMEType } from '../_helpers/guess-mime-type';

// Logo
import {
  FileUpload
} from 'primeng/fileupload';


@Component({
  selector: 'crud-basic-logo',
  templateUrl: './crud-basic-logo.component.html',
  styleUrls: ['./crud-basic-logo.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CRUDBasicLogoComponent),
      multi: true
    }
  ]
})
export class CRUDBasicLogoComponent implements AfterViewInit, ControlValueAccessor {
  @Input('value') value: string;
  @Input('labelNoImage') labelNoImage: string;

  @ViewChild('logoUpload', { static: true }) public logoUpload: FileUpload;
  @ViewChild('image') public image: ElementRef;

  onChange_callbackFunction: any; // bekommt die function von Angular forms - wenn formControlName - die bei Änderung gecallt werden muss.
  onChange_lastValue: any; // enthält den jeweils zuletzt rückgemeldeten Wert - als Vergleich/Abfangmechanismus, um nicht unnötig oft zu callen
  //onTouch_callbackFunction : any; // bekommt die function von Angular forms - wenn formControlName - die bei Touch gecallt werden muss.

  // logo
  logoFile: any[] = [];
  logoBase64: any;

  dateiendung: string = null;

    constructor(
    public translate: TranslateService
  ) {
  }

  //https://ngdev.space/angular-2-input-property-changes-detection-3ccbf7e366d2
  //ngOnChanges(changes: SimpleChanges) { // monitoring Input-Changes - nur monitoring/debug - ohne weitere Funktion!!! (kann man weglassen!)
  //  console.log("CRUDBasicLogo.ngOnChanges():", changes);
  //}

  ngAfterViewInit(): void {
    
  }

  onChange() {
      console.log("CRUDBasicLogo.onChange() event:", event);
      if (this.value != this.onChange_lastValue) { // nur wenn der Wert != dem zuletzt gemeldeten Wert ist (doppel-Rückmeldungen vermeiden! Performance!)
          let callBackValue: any = null;
          callBackValue = this.value;

          //console.log("CRUDBasicLogo.onChange() calling callback - value (first 100 characters):", callBackValue.substring(0,100));

          this.onChange_callbackFunction(callBackValue);
          this.onChange_lastValue = this.value;
      } else {
          //console.log("CRUDBasicLogo.onChange() skip, since it's the same option as last time!");
      }
  }

  // aus ControlValueAccessor Interface: patchValue/setValue/new FormControl soll Wert im HTML aktualisieren
  writeValue(obj: any): void {
      //console.log("CRUDBasicLogo.writeValue():", obj);
      //this.inputWriteableControl.writeValue(obj);
      this.value = obj;

      this.value = obj;
      if (this.value != null && this.value.length > 0) {
        let guessMIMEType = new GuessMIMEType();
        let logoMIMEType = guessMIMEType.guessMIMEType(this.value);
        guessMIMEType = null;
        console.log("CRUDBasicLogo.writeValue() logoMIMEType:", logoMIMEType);
        this.logoBase64 = 'data:image/'+logoMIMEType+';base64,'+this.value;
      }

      this.onChange_lastValue = this.value;
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onChange" aufrufen sollen.
  // die zunächst NUR MERKEN, ggf. rufen wir die (siehe onChange()) auf
  registerOnChange(fn: (rating: number) => void): void {
      // console.log("CRUDBasicLogo.registerOnChange() fn:", fn);
      this.onChange_callbackFunction = fn;
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onTouch" aufrufen sollen. 
  registerOnTouched(fn: () => void): void {
      // console.log("CRUDBasicLogo.registerOnTouched() fn:", fn);
      // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
      //this.inputWriteableControl.registerOnTouched(fn);
      //this.onTouch_callbackFunction = fn;
  }

  // Angular forms ruft diese Funktion, wenn sich der disabled-Status ändert.
  setDisabledState(isDisabled: boolean): void {
      // console.log("CRUDBasicLogo.setDisabledState() isDisabled:", isDisabled);
      // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
      //this.inputWriteableControl.setDisabledState(isDisabled);
      //this.inputFormattedControl.setDisabledState(isDisabled);
  }

  upload() { // diese Funktion wird von der ParentComp über ViewChild gecallt: https://stackoverflow.com/questions/37587732/how-to-call-another-components-function-in-angular2
    //console.log("CRUDBasicLogo.upload()");
    //console.log("CRUDBasicLogo.upload() this.logoUpload:", this.logoUpload);
    this.logoUpload.basicFileInput.nativeElement.click();
  }


  logoUploader(event) {
      let thisInstance = this;
      let fileReader = new FileReader();
      for (let file of event.files) {
          fileReader.readAsDataURL(file);
          fileReader.onload = function() {
              //console.log("CRUDBasicLogo.logoUploader() reader.result:", fileReader.result);
              //thisInstance.logoBase64 = (""+fileReader.result).split(/,/)[1];
              thisInstance.logoBase64 = "" + fileReader.result
              //console.log("MandantenDetailComponent.logoUploader() thisInstance.logoBase64:", thisInstance.logoBase64);

              thisInstance.value = (""+thisInstance.logoBase64).split(/,/)[1];
              thisInstance.dateiendung = file.name.substr(file.name.lastIndexOf('.') + 1);
              thisInstance.onChange();
              //thisInstance.CRUDForm.markAsDirty();

              thisInstance.logoUpload.clear(); // sonst kann man nicht nochmal den Button klicken
          };
          break; // nur 1. Datei verarbeiten!
      }
  }

  clear() {
    this.logoBase64 = null;
    this.value = null;
    this.onChange();
  }

  getImageNaturalWidth() {
    //console.log("CRUDBasicLogo.getImageNaturalWidth() image:", this.image);
    if(this.image == undefined) return undefined;
    let w = this.image.nativeElement.naturalWidth;
    //console.log("CRUDBasicLogo.getImageNaturalWidth():", w);
    return w;
  }
  getImageNaturalHeight() {
    //console.log("CRUDBasicLogo.getImageNaturalHeight() image:", this.image);
    if(this.image == undefined) return undefined;
    let h = this.image.nativeElement.naturalHeight;
    //console.log("CRUDBasicLogo.getImageNaturalHeight():", h);
    return h;
  }

}