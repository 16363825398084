<i class="fas fa-construction fa-10x"></i><br><br><br><br><br>

<p-checkbox binary="true" [(ngModel)]="vorschau"></p-checkbox><!--(onChange)="onVorschauChange($event)-->
Vorschau-Modus
<br>
<br>
<br>

<div id="upload">
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="fileUpload">
                        <p-fileUpload #artikelImportFileUpload id="dateiHochladen" chooseLabel="Datei auswählen"
                                maxFileSize="999999999" [showUploadButton]="false" [showCancelButton]="false"
                                (onSelect)="onNGFileInputSelect($event)" 
                                multiple="false" accept="application/vnd.oasis.opendocument.spreadsheet/*" maxFileSize="99999999">
                                <ng-template let-file pTemplate="file">
                                    <div class="ui-fileupload-row ng-star-inserted p-grid">
                                        <div class="p-col-3 p-md-3 p-lg-3"></div>
                                        <div class="p-col-6 p-md-6 p-lg-6">{{file.name}}
                                        </div>
                                        <!--<div>
                                            <span
                                                *ngIf="file._alreadyUploaded != null && file._alreadyUploaded == false">{{file._percentUploaded}}
                                                %</span>
                                        </div>-->
                                        <div class="p-col-3 p-md-3 p-lg-3">
                                            
                                            &nbsp;
    
                                        </div>
                                    </div>
                                    <span>
                                        <p-progressBar *ngIf="file._alreadyUploaded != null && file._alreadyUploaded == false && file._percentUploaded>0" [value]="file._percentUploaded"></p-progressBar>
                                    </span>
                                </ng-template>
                            </p-fileUpload>
                        </div></div>
                    </div></div>

                    <p-table *ngIf="imports.length>0" [value]="imports">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Zeilennummer</th>
                                <th>Bezeichnung</th>
                                <th>Status</th>
                                <th>Id (Vorschau: 0)</th>
                                <th>Fehler</th>
                                <th>&nbsp;</th>
                                <th>Kategorie1</th>
                                <th>Kategorie2</th>
                                <th>Kategorie3</th>
                                <th>Kategorie4</th> 
                                <!--<th>&nbsp;</th>
                                <th>Parent</th>-->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-import>
                            <tr>
                                <td>{{import.zeilennummer}}</td>
                                <td>{{import.bezeichnung}}</td>
                                <td>{{import.status}}</td>
                                <td>{{import.id}}</td>
                                <td>{{import.fehler}}</td>
                                <td>&nbsp;</td>
                                <td>{{import.kategorie1}}</td>
                                <td>{{import.kategorie2}}</td>
                                <td>{{import.kategorie3}}</td>
                                <td>{{import.kategorie4}}</td> 
                                <!--<td>&nbsp;</td>
                                <td>{{import.artikelGruppe.artikelgruppe.inverseArtikelgruppe}}</td> -->
                            </tr>
                        </ng-template>
                    </p-table>
    
    
    