import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { Observable, of } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

import { BenutzerService } from '../_services/benutzer.service';
import { IBenutzer } from '../_interfaces/benutzer';

// async validator:
// https://www.tektutorialshub.com/angular/angular-async-validator-example/
// with parameters (benutzerService): 

//export function validateBenutzerAlreadyExists(control: AbstractControl):Observable<ValidationErrors> | null { // ohne Parameter
export function validateBenutzerAlreadyExists(benutzerService: BenutzerService): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    console.log("validateBenutzerAlreadyExists() benutzerService:", BenutzerService);
    let value = control.value;
    console.log("validateBenutzerAlreadyExists() value: ", value);


    // 1. Versuch, noch ohne HTTPGET
    /*
    let errors : any[] = [];
    let errorsFound : boolean = false;

    if(value == 'exists') {
      errors['Benutzername_exists']=true;
      errorsFound = true;
    }      

    if(errorsFound) return of(errors);
    else return of(null);
    */

    // FINALE Lösung - mit HTTPGET
    //
    if(value == null || value.length == 0) return of(null);
    // ACHTUNG! Fehlermeldungen aus async-Validator NICHT per displayMessage darstellen, sondern direkt aus form.controls...error - siehe Unternehmendetail.html _benutzer_username
    return benutzerService.checkBenutzerExists(value);
  }
}

export function validateEMailAlreadyExists(benutzerService: BenutzerService, benutzerGetCallbackInstance: any, benutzerGetCallbackFunction: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    console.log("validateEMailAlreadyExists() benutzerService:", BenutzerService);
    let ungleichBenutzer: IBenutzer = benutzerGetCallbackFunction(benutzerGetCallbackInstance);
    console.log("validateEMailAlreadyExists() ungleichBenutzer:", ungleichBenutzer);
    let value = control.value;
    console.log("validateEMailAlreadyExists() value: ", value);

    // FINALE Lösung - mit HTTPGET
    //
    if(value == null || value.length == 0) return of(null);
    // ACHTUNG! Fehlermeldungen aus async-Validator NICHT per displayMessage darstellen, sondern direkt aus form.controls...error - siehe Unternehmendetail.html _benutzer_username
    return benutzerService.checkEMailExists(value, ungleichBenutzer != null ? ungleichBenutzer.id : 0);
  }
}