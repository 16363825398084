import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { IBenutzer } from '../_interfaces/benutzer';
import { MessageWrapperService } from '../_services/message-wrapper.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  responseOfAuthTestController: string = "";
  //userReturnedFromAPI: string = "";
  //benutzer: IBenutzer = null;

  constructor(
    @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
    public authService: AuthService, private router: Router,
    private messageWrapperService: MessageWrapperService,) { }
  
  ngOnInit(): void {
    //this.benutzer = this.authService.getBenutzer();
  }

  checkAuthTestController() {
    let thisInstance = this;
    this.authService.checkAuthTestController().subscribe(function (response) {
      console.log("Home.ngOnInit() response.authTest:", response.authTest['authTest']);
      thisInstance.responseOfAuthTestController = response.authTest['authTest'];
      //thisInstance.userReturnedFromAPI = response.authTest['username'];
    }, function (error) { /*return*/ thisInstance.handleError(error); });
  }

  getResponseOfAuthTestController() {
    //console.log("Home.getResponseOfAuthTestController() responseOfAuthTestController:" , this.responseOfAuthTestController);
    return this.responseOfAuthTestController;
  }

  logout() {
    this.authService.logout()
    .subscribe(success => {
      if (success) {
        this.router.navigate(['/login']);
      }
    });
  }

    /*private*/protected handleError(error) {
      console.log("Home.handleError error: ", error);
      this.messageWrapperService.postTimedMessage({severity: 'error', summary: 'Home: Fehler: ', detail: error.error}); 
    }

}
