// lang-en.ts

export const LANG_DE_NAME = 'de';

export const LANG_DE_TRANS = {
    'ERROR_UNSAVED_CHANGES': 'Es gibt ungespeicherte Änderungen auf dieser Seite. Trotzdem fortfahren?',
    'CONFIRM_DELETE_RECORD': 'Wollen Sie diesen Datensatz wirklich löschen?',
    'Artikelgruppe_ist_erforderlich': 'Artikelgruppe: ist erforderlich',
    'Lieferart_ist_erforderlich': 'Lieferart: ist erforderlich',
    'Lager_ist_erforderlich': 'Lager: ist erforderlich',
    'Baustelle_ist_erforderlich': 'Baustelle: ist erforderlich',
    'Strasse_ist_erforderlich': 'Strasse: ist erforderlich',
    'Postleitzahl_ist_erforderlich': 'Postleitzahl: ist erforderlich',
    'Ort_ist_erforderlich': 'Ort: ist erforderlich',
    'Baustelle_Gueltigkeit_ist_erforderlich': 'Baustelle speichern bis: ist erforderlich',
    'Die_Passwoerter_stimmen_nicht_ueberein': 'Die Passwörter stimmen nicht überein',
    'Benutzername_ist_erforderlich': 'Benutzername: ist erforderlich',
    'Passwort_ist_erforderlich': 'Passwort: ist erforderlich',
    'Geodaten_sind_erforderlich': 'Postleitzahl Ort: ist erforderlich',
    'Benutzer_ist_bereits_bei_anderem_Ansprechpartner_hinterlegt': 'Benutzer ist bereits bei anderem Ansprechpartner hinterlegt',
    'Es_muss_mindestens_ein_Lager_vorhanden_sein': 'Es muss mindestens ein Lagerort vorhanden sein',
    'Bitte_Gewerbeschein_hinterlegen': 'Bitte Gewerbeschein hinterlegen',
    'Benutzername_enthaelt_ungueltige_Zeichen': 'Benutzername enthält ungültige Zeichen',
    'Benutzername_exists': 'Benutzername ist bereits in Verwendung',
    'eMail_exists': 'E-Mail ist bereits in Verwendung'

};