import { merge as observableMerge, fromEvent as observableFromEvent, Subscription, Observable } from 'rxjs';

import { debounceTime } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ViewChild, ElementRef, HostListener, Inject, forwardRef, Injector, ComponentFactoryResolver } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy } from '@angular/router';

import { MessageWrapperService } from '../_services/message-wrapper.service'; 

//import { GlobalService } from '../_services/global.service'; // feedback
import { NavigationEnd } from '@angular/router';
import { TranslateService } from '../_services/translate.service';
import { AppconfigService } from '../_services/appconfig.service';
//import { CustomizingBasisService } from '../_services/customizing-basis.service'; // help

import { GenericValidator } from '../_helpers/generic-validator';
import { Message, ConfirmationService/*, AutoComplete*/ } from 'primeng/api';
import { AppComponent } from '../app.component';
import * as moment from 'moment';
declare var jquery: any;
declare var $: any;

//export const parentComponentAnnotation = {
//  selector: 'app-crud-basic-detail___NOT_IN_USE',
//  //template: parentComponentTpl,
//  templateUrl: './crud-basic-detail.component.html',
//  styles: ['./crud-basic-detail.component.css']
//}

//import { CRUDBasicDetailComponent_Template } from './crud-basic-detail.component.include_template';
//import { debug } from 'util';
//export { CRUDBasicDetailComponent_Template };

@Component({
  selector: 'app-crud-basic-detail___NOT_IN_USE',
  //templateUrl: './crud-basic-detail.component.html___NOT_IN_USE', 
  //template: `${CRUDBasicDetailComponent_Template || ''}`,
  template: `CRUDBasicDetailComponent_Template NOT IN USE`,
  //providers: [MessageService], 
  styleUrls: ['./crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
//@Component(parentComponentAnnotation)
export class CRUDBasicDetailComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  // Attribute, die von SubClasses überschrieben werden müssen!
  CRUDItemKurzform: string;
  CRUDPageTitleNeu: string;
  CRUDPageTitleBearbeiten: string;
  CRUDItemBezeichnungSingularCapitalized: string;
  CRUDItemBezeichnungPluralCapitalized: string;
  CRUDItemBezeichnungSingular: string;
  CRUDItemBezeichnungPlural: string;
  CRUDItemRouteSingular: string;
  CRUDItemRoutePlural: string;
  CRUDItemHelpTopic: string;
  crudItemService: any;
  guard: any;
  CRUDChildTemplate: any; // ACHTUNG: in der Child class soll das kein property sein, dass aus .TS kommt, sondern
  //          aus .html: <ng-template #CRUDChildTemplate>
  //          muss hier (ParentClass) aber schon bekannt sein, sonst meckert der publish.
  CRUDDisableLoeschen: boolean = false;
  CRUDHideModified: boolean = false;
  debugMode: boolean = false;
  // ENDE Attribute, die von SubClasses überschrieben werden müssen!  

  // Attribute, die von SubClasses wohl eher selten überschrieben werden (nur bei Abweichung von der üblichen CRUD-Logik)
  CRUDButtonSaveIcon = "ui-icon-save";
  CRUDButtonSaveLabel = "Speichern";
  CRUDDisablePageTitleUpdates: boolean = false;
  CRUDMethodGetAfterViewInit: boolean = false; // get"CrudItem"() nicht schon im ngOnInit machen, sondern erst im ngAfterViewInit!
  CRUDMethodNameGet: string = null; // Alternative Methode (im Service), die bei get aufgerufen werden soll
  CRUDMethodNameSave: string = null; // Alternative Methode (im Service), die bei save aufgerufen werden soll
  CRUDMethodNameDelete: string = null; // Alternative Methode (im Service), die bei delete aufgerufen werden soll
  CRUDConfirmMessageDelete: string = null; // Alternativen Text zum Standard 
  CRUDSpecialOnlyOneRecord: boolean = false; // Spezial-Fall: es gibt nur 1 Satz (z.B. Customizing):
                                             // Es gibt also keine Liste zu der man nach Save zurückspringt - und die Nr /1 in der URL ist irrelevant!
  // ENDE Attribute, die von SubClasses wohl eher selten überschrieben werden

  CRUDTemplatePluginTop: any = null; // dummy! ist nur nötig, damit der publish nicht meckert, dass gewisse Child-Klassen das nicht haben (ist ein ng-container im html)

  CRUDForm: FormGroup;

  //dataIdModeAlpha: boolean = false; // NORMALERWEISE ist die dataId = number in Verwendung! Es gibt aber auch Sonderfälle: aspnet_Roles
  dataId: /*number*/any;
  CRUDItem: /*IAnrede*/any;

  blockedDocument: boolean = false;
  disableFields: boolean = false;

  deleted: boolean = false;
  forceValidate: boolean = false;
  showWarningMsg: boolean = false;

  errorMessage: string;
  pageTitle: string;
  private sub: Subscription;

  // so lange nur CRUDBasic den PageTitle setzt: jetzt den alten wieder herstellen:
  //previousPageTitle: string;

  dateNow = new Date(); // wird für p-calendar [defaultDate] benötigt

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.handleUnloadNotification($event); // ausgelagert, damit man es im Child überschreiben kann
  }

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  displayMessageForm: { [key: string]: string } = {}; // wie displayMessage - enthält aber die Meldungen aus formValidators (nicht aus controlValidators)
  /*private*/public validationMessages: { [key: string]: { [key: string]: string } };
  /*private*/public genericValidator: GenericValidator;


  // services, die NICHT (von der child-class) injected werden (sonst müssten die child-classes alle Services kennen)
  //globalService: GlobalService; // feedback
  fb: FormBuilder;
  router: Router;
  route: ActivatedRoute;
  messageWrapperService: MessageWrapperService;
  confirmationService: ConfirmationService;
  translate: TranslateService;
  routeReuseStrategy: RouteReuseStrategy;
  config: AppconfigService;
  CRUDBasicListComponentInstance: any;

  DE: any = { // aktivieren: beim p-calendar einstellen: [locale]="DE"
    firstDayOfWeek: 1,
    dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    dayNamesShort: ["Son", "Mon", "Din", "Mit", "Don", "Fre", "Sam"],
    dayNamesMin: ["So","Mo","Di","Mi","Do","Fr","Sa"],
    monthNames: [ "Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember" ],
    monthNamesShort: [ "Jan", "Feb", "Mär", "Apr", "Mai", "Jun","Jul", "Aug", "Sep", "Okt", "Nov", "Dez" ],
    today: 'Heute',
    clear: 'Clear',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk'
  }

  constructor(
    @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
    private injector: Injector
  ) {
    // services, die NICHT (von der child-class) injected werden (sonst müssten die child-classes alle Services kennen)
    // stattdessen: die child-classes injecten nur den injector selbst - und geben den hierher weiter. Wir injecten dann programmatisch selbst!
    // Alternative wäre: den "injector" global über AppModule bereitzustellen: https://stackoverflow.com/questions/39101865/angular-2-inject-dependency-outside-constructor
    //this.globalService = injector.get(GlobalService); // feedback
    this.fb = injector.get(FormBuilder);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.messageWrapperService = injector.get(MessageWrapperService);
    this.confirmationService = injector.get(ConfirmationService);
    this.translate = injector.get(TranslateService);
    this.routeReuseStrategy = injector.get(RouteReuseStrategy);
    this.config = injector.get(AppconfigService);

    //console.log("CRUDBasicDetailComponent.constructor() routeReuseStrategy=", this.routeReuseStrategy);
    //console.log("CRUDBasicDetailComponent.constructor() CRUDItemBezeichnungSingularCapitalized:", this.CRUDItemBezeichnungSingularCapitalized);
    this.initDataId();

    // Defines all of the validation messages for the form.  -> macht die child-class!
    // These could instead be retrieved from a file or database.
    //this.validationMessages = {
    //  bezeichnung: {
    //    required: this.translate.instant('Bezeichnung', true) + ': ' + this.translate.instant('ist erforderlich', true),
    //    maxlength: this.translate.instant('Bezeichnung', true) + ' ' + this.translate.instant('darf 255 Zeichen nicht überschreiten', true)
    //  },
    //};

    // Define an instance of the validator for use with this form, 
    // passing in this form's set of validation messages.
    //this.genericValidator = new GenericValidator(this.validationMessages);
  }

  initDataId() {
    this.sub = this.route.params.subscribe(
      params => {
        //console.log("CRUDBasicDetailComponent.constructor() reading dataId as number...");
        this.dataId = +params['id'];
        /* Funktioniert an dieser Stelle noch nicht, da CRUDItemBezeichnungSingularCapitalized noch undefined ist! - stattdessen: siehe onInit() 
        if (this.dataId == 0) {
          this.pageTitle = this.translate.instant(this.CRUDItemBezeichnungSingularCapitalized+" anlegen", true);
        }
        else {
          this.pageTitle = this.translate.instant(this.CRUDItemBezeichnungSingularCapitalized+" bearbeiten", true);
        }*/
      }
    )
  }

  ngOnInit() {
    //this.globalService.registerHotKeyHandler(this); // US 14791: registerHotKeyHandler muss bereits im Init passieren! Wenn erst im AfterViewInit kann es passieren, dass die ParentComponent erst nach dem Child CRUDBasicInput registriert, eben weil die ParentComponent später fertig ist mit rendern als das Child!
    //this.blockedDocument = true; // macht die child-class!

    // so lange nur CRUDBasic den PageTitle setzt: jetzt den alten wieder herstellen:
    //this.previousPageTitle = this.app.pageTitle;

    //if (this.dataIdModeAlpha == false && this.dataId == 0 || this.dataIdModeAlpha == true && this.dataId == '' ) {
    if (this.dataId == 0 || this.dataId == '0' ) { // '0' wegen Sonderfall aspnet_Roles, wo id = alpha ist (id = virtuell = eine Kopie von roleId)
      this.pageTitle = /*this.translate.instant(this.CRUDItemBezeichnungSingularCapitalized + " anlegen", true)*/ this.CRUDPageTitleNeu;
    }
    else {
      this.pageTitle = /*this.translate.instant(this.CRUDItemBezeichnungSingularCapitalized + " bearbeiten", true)*/ this.CRUDPageTitleBearbeiten;
    }
    if (this.CRUDDisablePageTitleUpdates == false) this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;

    if(this.CRUDMethodGetAfterViewInit == false) this.getCRUDItem(this.dataId);


    // AM: gepufferte ListComponent über die CustomRouteStrategy finden
    if(this.debugMode==true) console.log("CRUDBasicDetailComponent: use CustomReuseStrategy to send Update to List: searching for " + this.CRUDItemBezeichnungPlural);
    let routeConfig: Route = {
      path: this.CRUDItemRoutePlural
    }
    let routeToListComponent: ActivatedRouteSnapshot = {
      url: null,
      params: null,
      queryParams: null,
      fragment: null,
      data: null,
      outlet: null,
      component: null,
      routeConfig: routeConfig,
      root: null,
      parent: null,
      firstChild: null,
      children: null,
      pathFromRoot: null,
      paramMap: null,
      queryParamMap: null
    };
    //routeToListComponent.routeConfig.path="konfiguration";
    let detachedRouteHandle: DetachedRouteHandle = this.routeReuseStrategy.retrieve(routeToListComponent);
    //console.log("CRUDBasicDetailComponent: use CustomReuseStrategy to send Update to List: detachedRouteHandle=", detachedRouteHandle);

    if (detachedRouteHandle != null) {
      let componentRef: any = detachedRouteHandle['componentRef'];
      //console.log("CRUDBasicDetailComponent: use CustomReuseStrategy to send Update to List: componentRef=", componentRef);

      this.CRUDBasicListComponentInstance = componentRef['instance'];
      //console.log("CRUDBasicDetailComponent: use CustomReuseStrategy to send Update to List: instance=", this.CRUDBasicListComponentInstance);
    }

    // seit ForChildFullscreen werden auch Details reused, daher:
    // auf reuse (RouteReuseStrategy) reagieren: https://stackoverflow.com/questions/50392691/angular-5-how-to-reload-current-data-when-i-use-routereusestrategy
    this.router.onSameUrlNavigation = 'reload';
    this.router.events.subscribe(event => {
      if ((event instanceof NavigationEnd)) {
        let navigationEnd: NavigationEnd = <NavigationEnd>event;
        if (navigationEnd.url != null) {
          //console.log("CRUDBasicDetailComponent event NavigationEnd.url:", navigationEnd.url);
    
          let url: string = navigationEnd.url;
          if (url.startsWith("/" + this.CRUDItemRouteSingular + "/")) {
            // beim reUse wird der pageTitle nicht autom. gesetzt -> übernehmen!
            //console.log("CRUDBasicDetailComponent.NavigationEnd... resetting pageTitle", event);
            if (this.CRUDDisablePageTitleUpdates == false) this.app.setPageTitle(this['pageTitle']); //this.app.pageTitle = this['pageTitle'];
            // workaround (***W1) // UPDATE 25.06.2019: NICHT mehr nötig, seit "key" (p-configDialog key=...)
            //setTimeout(() => { 
            //  this.enableConfirm = true;
            //}, 500);
          }
        }
      }
    });
  }

  ngAfterViewInit(): void {
    //this.globalService.registerHotKeyHandler(this); // US 14791: registerHotKeyHandler muss bereits im Init passieren! Wenn erst im AfterViewInit kann es passieren, dass die ParentComponent erst nach dem Child CRUDBasicInput registriert, eben weil die ParentComponent später fertig ist mit rendern als das Child!
    // Watch for the blur event from any input element on the form.
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => observableFromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    observableMerge(this.CRUDForm.valueChanges, ...controlBlurs).pipe(debounceTime(0)).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.CRUDForm, this.forceValidate);
    });

    if(this.CRUDMethodGetAfterViewInit == true) this.getCRUDItem(this.dataId);
  }

  ngOnDestroy() {
    if (this.debugMode == true) console.log("CRUDBasicDetail.ngOnDestroy()");
    //this.globalService.unRegisterHotKeyHandler(this);

    // so lange nur CRUDBasic den PageTitle setzt: jetzt den alten wieder herstellen:
    //this.app.pageTitle = this.previousPageTitle;
  }

  getCRUDItem(id: /*number*/any) {
    if(this.CRUDSpecialOnlyOneRecord) {
      // wir wissen nicht, welche Id der eine Satz hat, daher über Collection lesen - und den 1. Satz nehmen!
      let methodName = /*this.CRUDMethodNameGetCollection != null ? this.CRUDMethodNameGetCollection :*/ 'get'+this.CRUDItemBezeichnungPluralCapitalized+'Collection';
      this.crudItemService[methodName](1, 0, "")
      .subscribe(
      (CRUDItem: any) => {
        //console.log("CRUDBasicDetailComponent.getCRUDItem() (CRUDSpecialOnlyOneRecord) response:", CRUDItem[this.CRUDItemBezeichnungPlural][0]);

        if(CRUDItem[this.CRUDItemBezeichnungPlural] != null && CRUDItem[this.CRUDItemBezeichnungPlural].length > 0) {
          // mit dem gefundenen 1. Satz, dann die Standard-Routine durchlaufen - also den Satz nochmal explizit mit allen Includes lesen
          this.getCRUDItem_pt2(CRUDItem[this.CRUDItemBezeichnungPlural][0].id);
        }
        else {
          this.dataId = 0;
          this.getCRUDItem_pt2(0);
        }
      },
      (error: any) => {
        this.blockedDocument = false;
        this.handleError(error);
      }
    );
    }
    else { // Default: kein "SpezialFall"
      this.getCRUDItem_pt2(id);
    }
  }   

  getCRUDItem_pt2(id: number) { // diese Methode bitte niemals überschreiben!!!
    //this.anredeService.getAnrede(id)
    //console.log("CRUDBasicDetailComponent.getCRUDItem() crudItemService:", this.crudItemService);
    let methodName = this.CRUDMethodNameGet != null ? this.CRUDMethodNameGet : 'get' + this.CRUDItemBezeichnungSingularCapitalized;
    //console.log("CRUDBasicDetailComponent.getCRUDItem() method to call:crudItemService:", methodName);
    this.crudItemService[methodName](id)
      .subscribe(
        //(anrede: IAnrede) => this.onCRUDItemRetrieved(anrede),
        (CRUDItem: any) => this.onCRUDItemRetrieved(CRUDItem),
        (error: any) => {
          this.blockedDocument = false;
          this.handleError(error);
        }
      );
  }

  onCRUDItemRetrieved(CRUDItem: /*IAnrede*/any): void {
    this.blockedDocument = false;

    if (this.CRUDForm) {
      this.CRUDForm.reset();
    }
    this.CRUDItem = CRUDItem;
    if(this.debugMode) console.log("CRUDBasicDetailComponent.onCRUDItemRetrieved() CRUDItem:", this.CRUDItem);
    //this.globalService.addFeedbackByClone("CRUDItem " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.onCRUDItemRetrieved()", CRUDItem); // feedback

    if (this.CRUDItem.id === 0 || this.CRUDItem.id === '0' || this.CRUDItem.id == null) { // '0' wegen Sonderfall aspnet_Roles, wo id = alpha ist (id = virtuell = eine Kopie von roleId)) 
      this.pageTitle = this.translate.instant(this.CRUDPageTitleNeu, true);
    }
    else {
      this.pageTitle = this.translate.instant(this.CRUDPageTitleBearbeiten, true) + ': ' + /*this.CRUDItem.bezeichnung*/this.getCRUDBezeichnung();
    }
    if (this.CRUDDisablePageTitleUpdates == false) this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;

    //this.globalService.addFeedbackByReference("CRUDForm (cutted)" + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.onCRUDItemRetrieved()", this.cutFormObjectForFeedback(this.CRUDForm)); // feedback // NICHT-cloned - d.h. das zeigt immer den aktuellen Wert an!
    this.sendValuesToForm();
    //this.globalService.addFeedback("CRUDForm "+this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.onCRUDItemRetrieved() nach sendValuesToForm()", true, this.CRUDForm); // feedback
  }

  sendValuesToForm() {
    //this.CRUDForm.patchValue({     // -> macht die child-class
    //  bezeichnung: this.CRUDItem.bezeichnung,
    //});

    //this.globalService.addFeedbackByClone("CRUDForm (cutted)" + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.sendValuesToForm() nach sendValuesToForm()", this.cutFormObjectForFeedback(this.CRUDForm)); // feedback
  }

  validateAndSaveCRUDItem(close: boolean): void {
    console.log("CRUDBasicDetailComponent.validateAndSaveCRUDItem()");
    //this.globalService.addFeedbackByClone("CRUDForm (cutted)" + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.validateAndSaveCRUDItem()", this.cutFormObjectForFeedback(this.CRUDForm)); // feedback // Form nur "gecutted", weil sonst z.B. bei Kaufpreisrate-Kategorie zu gross (weil hier in der Form noch die ___component gepseichert wird)

    Object.keys(this.CRUDForm.controls).forEach(key => {
      this.CRUDForm.get(key).markAsDirty();
    });

    this.forceValidate = true;
    this.displayMessage = this.genericValidator.processMessages(this.CRUDForm, this.forceValidate);
    this.displayMessageForm = {};

    if (this.CRUDForm.valid) {
      this.blockedDocument = true;
     

      if (this.CRUDForm.dirty && this.CRUDForm.valid) {

        //let a = Object.assign({}, this.CRUDItem, this.CRUDForm.value);
        //a.spracheId = a.sprache.id;
        let a = this.getValuesFromForm();

        if (this.debugMode == true) console.log("CRUDBasicDetailComponent.validateAndSaveCRUDItem() getValuesFromForm...a/crudItem:", a);

        this.saveCRUDItem(a, this.CRUDSpecialOnlyOneRecord ? false : close); // SpezialFall "es gibt nur ein Satz (zB Customizing)" ? - dann kein close und kein zurück zur Liste
      }
      else if (!this.CRUDForm.dirty) {
        this.onSaveComplete(this.CRUDSpecialOnlyOneRecord ? false : close); // SpezialFall "es gibt nur ein Satz (zB Customizing)" ? - dann kein close und kein zurück zur Liste
      }
    }
    else {
      let errorMessage: string = "";
  

      for (let key of Object.keys(this.displayMessage)) {

        if (this.displayMessage[key] != null && this.displayMessage[key] != '') {
          errorMessage += '<br>' + this.displayMessage[key];
        }
       

      }
      if (this.CRUDForm.errors != null) {
    
        for (let error of Object.keys(this.CRUDForm.errors)) {

          //if (errorMessage != '') {
          //  errorMessage += '<br>';
          //}

          errorMessage += '<br>' + this.translate.instant(error, true);

          // Fehlermeldungen aus formValidators (nicht controlValidators) festhalten, damit die im HTML dargestellt werden können
          this.displayMessageForm[error] = this.translate.instant(error, true);
        }
      }

      let message: Message = { severity: 'error', summary: this.translate.instant('Validierungsfehler', true), detail: `${errorMessage}` };
      this.messageWrapperService.postTimedMessage(message); 
      //this.globalService.addFeedbackByClone("Message " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.validateAndSaveCRUDItem()", message); // feedback
    }
  }

  saveCRUDItem(a: any, close: boolean) {

    //this.crudItemService.saveCRUDItem(a)
    let methodName = this.CRUDMethodNameSave != null ? this.CRUDMethodNameSave : 'save' + this.CRUDItemBezeichnungSingularCapitalized;
    if(this.debugMode == true) console.log("CRUDBasicDetailComponent.saveCRUDItem() method to call:crudItemService:", methodName);
    if(this.debugMode == true) console.log("CRUDBasicDetailComponent.saveCRUDItem() a:", a);
    this.crudItemService[methodName](a)
      .subscribe(
        (/*titel: ITitel*/CRUDItem: any) => {
          if(this.CRUDSpecialOnlyOneRecord) { // SpezialFall "es gibt nur ein Satz (zB Customizing)" ? - dann kein close und kein zurück zur Liste
            this.onSaveComplete(false); // US 13411 onSaveComplete() muss VOR onCRUDItemRetrieved() laufen, weil onSaveComplete() die Form cleart, und onCRUDItemRetrieved() die Form wieder füllt

            // hier nicht die returnte entity darstellen, sondern nochmal explizit INCLUSIVE INCLUDES lesen
            this.getCRUDItem_pt2(CRUDItem.id);

            //this.onSaveComplete(false); // US 13411 onSaveComplete() muss VOR onCRUDItemRetrieved() laufen, weil onSaveComplete() die Form cleart, und onCRUDItemRetrieved() die Form wieder füllt
            this.afterSaveCRUDItem(); // US 13411 
          }
          else { // Default: kein SpezialFall
            this.onSaveComplete(close); // US 13411 onSaveComplete() muss VOR onCRUDItemRetrieved() laufen, weil onSaveComplete() die Form cleart, und onCRUDItemRetrieved() die Form wieder füllt

            if (this.CRUDBasicListComponentInstance != null) {
              this.CRUDBasicListComponentInstance.refreshRow(CRUDItem.id);
            }
  
            if (!close) {
              this.onCRUDItemRetrieved(/*anrede*/CRUDItem);
            }

            //this.onSaveComplete(close); // US 13411 onSaveComplete() muss VOR onCRUDItemRetrieved() laufen, weil onSaveComplete() die Form cleart, und onCRUDItemRetrieved() die Form wieder füllt
            this.afterSaveCRUDItem(); // US 13411 
          }
        },
        (error: any) => {
          this.blockedDocument = false;
          this.handleError(error);
        }
      );
  }

  afterSaveCRUDItem() { // US 13411 
    // macht Nichts! - kann aber vom Child überschrieben werden, wenn nach dem erfolgreichen Save noch etwas passieren soll
    // z.B. BudgetverschiebungDetail
  }

  getValuesFromForm() {
    let a = Object.assign({}, this.CRUDItem, this.CRUDForm.value);
    return a;
  }

  onSaveComplete(close: boolean): void {
    this.blockedDocument = false;
    let bezeichnung = this.getCRUDBezeichnung();
    this.messageWrapperService.postTimedMessage({ severity: 'success', summary: this.deleted ? this.translate.instant("Löschen erfolgreich", true) : this.translate.instant("Speichern erfolgreich", true), detail: bezeichnung }) // TO DO
    this.CRUDForm.reset();
    // US 14152: Es gibt ein Problem, wenn man ein Feld ändert, NICHT verlässt, per STRG+S zwischenspeichert.
    // in dem Fall bleibt das Feld / die Form dirty. Der dirty muss aber raus, sonst kann man die Form nicht verlassen (guard!)
    if(close == false) {
      let activeElement = $(document.activeElement);
      if(activeElement != null) {
        activeElement.blur();
        activeElement.focus();
      }
    }
    if (close) {
      console.log("CRUDBasicDetailComponent.onSaveComplete() routing to:", this.CRUDItemRoutePlural);
      this.router.navigate(['/' + this.CRUDItemRoutePlural], { queryParamsHandling: "merge" });
    }
  }

  cancelCRUDEdit() {
    this.router.navigate(['/' + this.CRUDItemRoutePlural], { queryParamsHandling: "merge" });
  }

  getCRUDBezeichnung() { // die Bezeichnung ermitteln - um sie z.B. beim Save als "Erfolgreich-Message" auszugeben. - kann von child-Klasse überschrieben werden!
    //if(this.CRUDForm != null && this.CRUDForm.get('bezeichnung') != null) return this.CRUDForm.get('bezeichnung').value;
    return this.CRUDItem.bezeichnung;
  }

  deleteCRUDItem(): void {
    console.log("CRUDBasicDetailComponent.deleteCRUDItem() deleted=", this.deleted);
    
    if (this.CRUDItem.id === 0 || this.CRUDItem.id === '0' ) { // '0' wegen Sonderfall aspnet_Roles, wo id = alpha ist (id = virtuell = eine Kopie von roleId)
      this.deleted = true;
      this.onSaveComplete(true);
    }
    else {
        this.confirmationService.confirm({
        message: this.CRUDConfirmMessageDelete == null ? this.translate.instant('CONFIRM_DELETE_RECORD') : this.CRUDConfirmMessageDelete,
        header: this.translate.instant('Löschen', true) + '?',
        icon: 'fa fa-trash',
        key: 'CRUDBasicDetailConfirmDialog_' + this.CRUDItemBezeichnungPluralCapitalized,
        accept: () => {
      
          this.blockedDocument = true;
          //this.crudItemService.deleteAnrede(this.CRUDItem.id)
          let methodName = this.CRUDMethodNameDelete != null ? this.CRUDMethodNameDelete : 'delete' + this.CRUDItemBezeichnungSingularCapitalized;
          console.log("CRUDBasicDetailComponent.deleteCRUDItem() method to call:crudItemService:", methodName);
          this.crudItemService[methodName](this.CRUDItem.id)
            .subscribe(
              () => {
                // AM Modi: refresh ListComponent:
                if (this.CRUDItem.id != 0 && this.CRUDBasicListComponentInstance != null) {
                  this.CRUDBasicListComponentInstance.refreshRow(this.CRUDItem.id);
                }

                this.deleted = true;
                this.onSaveComplete(true);

              },
              (error: any) => {
                this.deleted = false;
                this.handleError(error);
                this.blockedDocument = false;
              }
            );
            
        },
        reject: () => {
        }
      });
    }

  }

  getUTCDateFromForm(value: Date) {
    if (value == null) {
      return null;
    }
    let year = moment(value).year();
    let month = moment(value).month() + 1;
    let day = moment(value).date(); // day() ist day of week
    let hour = moment(value).hour();
    let minute = moment(value).minute();
    let second = moment(value).second();
    let milli = moment(value).millisecond();
    let dateFromForm = new Date(Date.UTC(year, month - 1, day, hour, minute, second, milli));

    //console.log("getUTCDateFromForm.dateFromForm");
    //console.log(dateFromForm);

    return dateFromForm;
  }

  //onChangeOftriStateCheckbox($event) {
  //  console.log("onChangeOftriStateCheckbox", $event);
  //}

  hotkeys(event) {
    if(event.repeat == true) {
      // // repeated events interessieren uns generell überhaupt nicht!
      // z.B. bei CTRL (keydown) kommt der event endlos oft vor - so lange man auf CTRL bleibt
    }
    else {
      if(this.debugMode == true) console.log("CRUDBasicDetail.hotkeys() event:", event);
      //if(this.globalService != null) this.globalService.handleHotKeys(this, event);
    }
  }

  handleHotkeys(event) {
    //STRG + S // save WITHOUT close
    if (event.keyCode == 83 && event.ctrlKey) {
      this.validateAndSaveCRUDItem(false);
      event.preventDefault();
    }
    //STRG + D // save + close
    else if (event.keyCode == 68 && event.ctrlKey) {
      this.validateAndSaveCRUDItem(true);
      event.preventDefault();
    }
    //STRG + ENTF
    else if (event.keyCode == 46 && event.ctrlKey) {
      this.deleteCRUDItem();
      event.preventDefault();
    }
    //ESC
    else if (event.keyCode == 27) {
      this.router.navigate(['/' + this.CRUDItemRoutePlural], { queryParamsHandling: "merge" })
      event.preventDefault();
    }
  }

  showHelp() {
  }

  debug(obj) {
    console.log("CRUDBasicDetailComponent.debug() obj:", obj);
    console.log("CRUDBasicDetailComponent.debug() CRUDItem:", this.CRUDItem);
    console.log("CRUDBasicDetailComponent.debug() CRUDForm:", this.CRUDForm);
    //console.log("CRUDBasicDetailComponent.debug() CRUDForm.value.firmaRollen:", this.CRUDForm.value.firmaRollen);
    //console.log("CRUDBasicDetailComponent.debug() CRUDForm.controls.grussformel.value:", this.CRUDForm.controls.grussformel.value);
    //this.CRUDForm.controls.forEach(control => {
    Object.keys(this.CRUDForm.controls).forEach(control => {
      //console.log("CRUDBasicDetailComponent.debug() control:", control);
      if (this.CRUDForm.controls[control].status != null && this.CRUDForm.controls[control].status != "VALID") {
        console.log("CRUDBasicDetailComponent.debug() control " + control + " is not valid:", this.CRUDForm.controls[control].status);
      }
    });
  }

  cutFormObjectForFeedback(formObj) {
    let cutted = {
      //controls: formObj.controls,  // dauert zu lange !!!
      dirty: formObj.dirty,
      disabled: formObj.disabled,
      enabled: formObj.enabled,
      errors: formObj.errors,
      invalid: formObj.invalid,
      status: formObj.status,
      touched: formObj.touched,
      value: formObj.value
    };
    return cutted;
  }

  deleteAllObjectsInObject(obj: object) { // innerhalb eines objects alle objekte löschen - z.B. um z.B. vor Zurücksenden an die API alle Rekursivitäten zu vermeiden
    for(var propertyName in obj) {
      if(typeof obj[propertyName] === 'object') {
        delete obj[propertyName];
      }
    }
  }

  confirmDialogAppendTo() { // normaler Detail Dialog soll appendTo=body sein, ein DetailForChild aber nicht!
    return 'body';
  }

  handleUnloadNotification($event: any) {
    if (!this.guard.canDeactivate(this)) {

      //"This message is displayed to the user in IE and Edge when they navigate without using Angular routing (type another URL/close the browser/etc)";
      $event.returnValue = this.translate.instant('ERROR_UNSAVED_CHANGES');
    }
  }  

  handleError(error: any) {
    console.log("CRUDBasicDetailComponent.handleError error", error);
    //this.globalService.addFeedbackByClone("error " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.handleError()", error); // feedback

    //this.loading = false;
    this.blockedDocument = false;
    let summary = this.translate.instant('Fehler', true);

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        this.errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
        if(Array.isArray(this.errorMessage)) this.errorMessage = this.errorMessage[0]; // ist bei DbUpdateException der Fall!
      }
      else {
        this.errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      this.errorMessage = "Unauthorized";
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      this.errorMessage = error.message;
    }

    //if(this.errorMessage != null) this.errorMessage = this.errorMessage.replace(/\n/g, "<br>");
    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: this.errorMessage }); // TO DO
  }
}
