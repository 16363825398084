<!--<div class="p-grid p-nogutter">-->
    <div #uppermostDiv class="p-col-12" id="dashboardImages" 
        [class.dashboardImages_app]="_app !=null && _app.inAppMode == true"
        
        [style.backgroundImage]="'url('+artikelTitelBildURL(CRUDItem)+')'"
        [style.backgroundSize]="'cover'"
        [style.backgroundPositionX]="'center'"
        [style.backgroundPositionY]="'center'"
        [style.lineHeight]="'0.5em'"

        [style.textAlign]="'justify'"

        [style.height]="(_app !=null && _app.inAppMode == true ? 220 : 190) + 55 + 'px'"
        >

        <div style="position: relative; top: -2px; left: 2px; color: black; background-color: white; width: fit-content;">
            &nbsp;Anzeige&nbsp;<br>&nbsp;
        </div>

        <div class="p-col-12" [innerHTML]="CRUDItem.beschreibung | keepHtml"
        [style.color]="'white'"
        >
        <!-- [style.color]="'white'" muss so sein, weil man im Quill standardmässig mit weiss schreibt -> das soll er 1:1 wieder so darstellen -->

        </div>
    </div>
<!--</div>-->