// app/translate/translation.ts
import { Injectable, Inject } from '@angular/core';
import { InjectionToken } from '@angular/core';

// import translations
import { LANG_EN_NAME, LANG_EN_TRANS } from '../_languages/lang-en';
import { LANG_DE_NAME, LANG_DE_TRANS } from '../_languages/lang-de';


// translation token
export const TRANSLATIONS = new InjectionToken('translations');


// all traslations
export const dictionary = {
  'en': LANG_EN_TRANS,
  'de': LANG_DE_TRANS,
};

// providers
export const TRANSLATION_PROVIDERS = [
  { provide: TRANSLATIONS, useValue: dictionary },
];

@Injectable()
export class TranslateService {

  private _currentLang: string = 'de';

  public get currentLang() {
    return this._currentLang;
  }

  // inject our translations
  constructor( @Inject(TRANSLATIONS) private _translations: any) {
  }

  public use(lang: string): void {
    // set current language
    this._currentLang = lang;
  }

  private translate(key: string, capitalized: boolean = false): string {
    // private perform translation
    let translation = key;

    if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {

      translation = this._translations[this.currentLang][key];

    }

    if (capitalized === true && translation != null && translation.length > 0) {
      return translation.charAt(0).toUpperCase() + translation.slice(1);
    }
    return translation;
  }

  public instant(key: string, capitalized: boolean = false) {
    // public perform translation
    return this.translate(key, capitalized);
  }

}


