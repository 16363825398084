import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

import { /*Observable,*/ of } from 'rxjs';
import { debounceTime} from 'rxjs/operators';

import { IPagination } from '../_interfaces/pagination'
import { IBenutzer, IBenutzerForPWChange } from '../_interfaces/benutzer';
import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

//import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class BenutzerService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'aspNetUserId', type: 'Guid'},
    {name: 'vorname', type: 'string'},
    {name: 'nachname', type: 'string'},
    {name: 'unternehmenId', type: 'int'},
    {name: 'unternehmen', type: 'object'},
    {name: 'telefon', type: 'string'},
    {name: 'mobil', type: 'string'},
    {name: 'email', type: 'string'},
    {name: 'userName', type: 'string'},
    {name: '_userName', type: 'string'},
    {name: 'istBenutzer', type: 'bool'},
    {name: 'istAnsprechpartner', type: 'bool'},
  ];
    
  getBenutzerCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ benutzer: IBenutzer[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'benutzer?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { benutzer: <IBenutzer[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getBenutzer(id: number): Observable<IBenutzer> {

    if (id === 0) {
      return Observable.create((observer: any) => {
        observer.next(this.initializeBenutzer());
        observer.complete();
      })
    }

    return this.httpClient.get<IBenutzer>(this.apiBaseUrl + 'benutzer/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  checkBenutzerExists(benutzername: string): Observable<any> {
    return this.httpClient.get<IBenutzer>(this.apiBaseUrl + 'benutzer/CheckBenutzerExists/' + benutzername, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        debounceTime(500),
        map((response) => {
          console.log("BenutzerService.checkBenutzerExists() response:", response);
          let errors : any[] = [];
          let errorsFound : boolean = false;
          if(''+response == 'true') {
            errors['Benutzername_exists']=true;
            errorsFound = true;
          }
          if(errorsFound) {
            // ACHTUNG! hier nicht als observable zurückgeben!
            //let obs = of(errors);
            //console.log("BenutzerService.checkBenutzerExists() return errors: obs:", obs);
            //return obs;
            console.log("BenutzerService.checkBenutzerExists() return errors: ", errors);
            return errors;
          }
          else {
            // ACHTUNG! hier nicht als observable zurückgeben!
            //return of(null);  
            return null;  
          }
        }),
        catchError(this.handleError)
      );
  }

  checkEMailExists(eMail: string, ungleichBenutzerId: number): Observable<any> {
    return this.httpClient.get<IBenutzer>(this.apiBaseUrl + 'benutzer/CheckEMailExists/' + eMail + "/" + ungleichBenutzerId, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        debounceTime(500),
        map((response) => {
          console.log("BenutzerService.checkEMailExists() response:", response);
          let errors : any[] = [];
          let errorsFound : boolean = false;
          if(''+response == 'true') {
            errors['eMail_exists']=true;
            errorsFound = true;
          }
          if(errorsFound) {
            // ACHTUNG! hier nicht als observable zurückgeben!
            //let obs = of(errors);
            //console.log("BenutzerService.checkBenutzerExists() return errors: obs:", obs);
            //return obs;
            console.log("BenutzerService.checkEMailExists() return errors: ", errors);
            return errors;
          }
          else {
            // ACHTUNG! hier nicht als observable zurückgeben!
            //return of(null);  
            return null;  
          }
        }),
        catchError(this.handleError)
      );
  }

  saveBenutzer(benutzer: IBenutzer): Observable<IBenutzer> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(benutzer); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("BenutzerService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("BenutzerService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (benutzer.id === 0) {
      return this.createBenutzer(/*benutzer*/itemToSave);
    }

    return this.updateBenutzer(/*benutzer*/itemToSave)

  }

  updateBenutzer(benutzer: IBenutzer): Observable<IBenutzer> {

    return this.httpClient.put<IBenutzer>(this.apiBaseUrl + 'benutzer/' + benutzer.id, benutzer, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  resetPassword(benutzer: IBenutzerForPWChange): Observable<IBenutzer> {

    return this.httpClient.put<IBenutzer>(this.apiBaseUrl + 'benutzer/ChangePassword/' + benutzer.id, benutzer, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteBenutzer(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'benutzer/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createBenutzer(benutzer: IBenutzer): Observable<IBenutzer> {
    return this.httpClient.post<IBenutzer>(this.apiBaseUrl + 'benutzer', benutzer, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeBenutzer(): IBenutzer {
    return {
      id: 0,
      createdBy: '',
      created: '',
      modifiedBy: '',
      modified: '',
      rowVersion: '',
      aspNetUserId: null,
      vorname: '',
      nachname: '',
      unternehmenId: null,
      unternehmen: null,
      telefon: null,
      mobil: null,
      email: null,
      istBenutzer: true,
      istAnsprechpartner: true,
      userName: null,
      _username: null,
      _password: null,
      summary: ''
    };
  }

  validateUserName(value: string) {
    let ungueltigeZeichenGefunden = null;

    let _allowedChars_lower: string = "abcdefghijklmnopqrstuvwxyz";
    let _allowedChars_upper: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let _allowedChars_num: string = "012345678901234567890123456789";
    let _allowedChars_special: string = " -._@+\\"; // Siehe Startup.cs !!!

    let i = value.length;
    while (i--) {
      let c = value.charAt(i);
      if (!_allowedChars_lower.includes(c) && !_allowedChars_upper.includes(c) && !_allowedChars_num.includes(c) && !_allowedChars_special.includes(c)) {
        //console.log("AspNetUserListComponent.validatePasswordAlleZeichen() INVALID char: ", c);
        if (ungueltigeZeichenGefunden == null) {
          ungueltigeZeichenGefunden = "";
        }
        ungueltigeZeichenGefunden+=c;
      }
    }

    return ungueltigeZeichenGefunden;
  }

}
