<div style="position: relative">
<p-dialog header="{{title}}" [visible]="visible" modal="true" id="dialog"  closeOnEscape="true" [contentStyle]="{'overflow':'auto', 'max-height':'800px', 'max-width':'800px', 'min-width':'600px'}" [responsive]="true" [resizable]="true" [closable]="false" styleClass="editDialog"
appendTo="body">

<!--<button pButton type="button" style="float:right; display: inline-block; width: 0.5em; height: 2.0em;" icon="fa fa-times" class="ui-button-danger editBtns" (click)="dialogClose()"></button>-->
<div #divCardToolbar class="card">
  <span>
    <button style="width: auto;" pButton 
    (click)="selectItem(null)" [icon]="'fas fa-times-circle'" [label]="translateService.instant('Zurücksetzen',true)"></button>
  </span>
  <span class="floatRight">
    <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translateService.instant('Abbrechen',true)"
      (click)="dialogClose()"></button>
  </span>
</div>

<input style="width: 95%" type="search" pInputText [value]="search" [formControl]="searchControl" [placeholder]="translateService.instant('Suche',true)">
&nbsp;<i class="fa fa-search"></i>
<br><br>

<!-- <p-dataScroller #ds [value]="items" [rows]="rows" (onLazyLoad)="loadData($event)" [lazy]="true" [inline]="true" scrollHeight="500px"
    [totalRecords]="totalRecords">
    <ng-template let-rowData pTemplate="item">
        <div class="ui-g" style="border-bottom: 1px solid #D5D5D5">
            <div class="ui-g-12 ui-md-3">
                <button pButton type="button" icon="pi pi-external-link" (click)="selectItem(rowData)"></button>
            </div>
            <div class="ui-g-12 ui-md-9">
                <div class="ui-g">
                    <div class="ui-g-12 ui-sm-12">
                        <b>{{rowData[label]}}</b>
                    </div>

                    <div class="ui-g-12 ui-sm-12">
                        {{rowData[label2]}}
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-dataScroller> -->
<p-virtualScroller #ds [value]="items" (onLazyLoad)="loadDataVirtualScroller($event)" [lazy]="true"
  scrollHeight="400px" [itemSize]="40" [rows]="rows" [style]="{'font-size': '0.9em'}">
  <ng-template let-rowData pTemplate="item" let-i="index">

    <div class="ui-g" style="border-bottom: 1px solid #D5D5D5">
      <div class="ui-g-12 ui-md-3">
        <button pButton type="button" icon="pi pi-external-link" (click)="selectItem(rowData)"></button>
      </div>
      <div class="ui-g-12 ui-md-9" style="align-self: center;">
        <!--<b>-->{{rowData[label]}}<!--</b>-->
        {{rowData[label2]}}
      </div>
    </div>
  </ng-template>
  <ng-template let-product pTemplate="loadingItem">
    <div class="ui-g" style="border-bottom: 1px solid #D5D5D5">
      <div class="ui-g-12 ui-md-3">
        &nbsp;
      </div>
      <div class="ui-g-12 ui-md-9">
        <div class="ui-g">
          <div class="ui-g-12 ui-sm-12">
            &nbsp;
          </div>
          <div class="ui-g-12 ui-sm-12">
            <p-progressSpinner [style]="{width: '2em', height: '2em'}" strokeWidth="2"></p-progressSpinner>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-virtualScroller>
<div *ngIf="!items || items.length == 0">{{'Keine Elemente'|translate:true}}</div>

</p-dialog>
</div>
