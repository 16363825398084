import { LazyLoadEvent } from "primeng/api";
import { Component, OnInit, Injector, Inject, forwardRef, ɵCompiler_compileModuleAndAllComponentsSync__POST_R3__ } from '@angular/core';
import { TranslateService } from '../_services/translate.service';
import { MessageWrapperService } from '../_services/message-wrapper.service'; 
import { Router, NavigationExtras } from '@angular/router';

import { ArtikelService } from '../_services/artikel.service';
import { ArtikelbildService } from '../_services/artikelbild.service';
import { IArtikel, IArtikelForCounting } from '../_interfaces/artikel';
import { AppComponent } from '../app.component';
import * as moment from 'moment';


@Component({
  selector: 'app-artikel-scroller',
  templateUrl: './artikel-scroller.component.html',
  styleUrls: ['./artikel-scroller.component.css']
})
export class ArtikelScrollerComponent implements OnInit {
  messageWrapperService: MessageWrapperService;
  translate: TranslateService;

  debugMode: boolean = false;

  // VirtualScroller 
  virtualScrollerItems: any[];
  pendingLazyLoadEvents: LazyLoadEvent[] = [];
  lastLazyLoadEvent_first: number = null;
  killScroller: boolean = false;
  loading: boolean;
  totalRecords: number = null;
  rows: number = 30;
  currentPage: number;
  scrollHeight: string = "600px";
  lastScrollPosition: number; // bei lazyLoad / virtualScroll: nach dem reuse Component: wieder dort hinscrollen, wo er war

  constructor(@Inject(forwardRef(() => AppComponent))public _app, public app: AppComponent, 
    private artikelbildService: ArtikelbildService,
    public crudItemService: ArtikelService,
    private _router: Router,
    private injector: Injector
  ) {
    this.messageWrapperService = injector.get(MessageWrapperService);
    this.translate = injector.get(TranslateService);
   }

  ngOnInit(): void {
    // virtualScroller
    this.virtualScrollerItems = [];
    this.currentPage = 1;
  }

  loadDataVirtualScroller(event: LazyLoadEvent) {
    if(this.debugMode==true) console.log("ArtikelScroller.loadDataVirtualScroller() event:", event);
    //  wenn sich mehrere LazyLoads ergeben (schnell scrollen), dass dann der letzte (aktuelle) zuerst ausgeführen!
    this.pendingLazyLoadEvents.push(event);
    this.lastLazyLoadEvent_first = event.first;
    let thisInstance = this;
    thisInstance.loadDataVirtualScroller_handleNextPending(thisInstance);
  }

  loadDataVirtualScroller_handleNextPending(thisInstance: any) {
    setTimeout(() => {
      if(thisInstance.pendingLazyLoadEvents.length > 0) {
        let idx = thisInstance.pendingLazyLoadEvents.length -1; // den Event nehmen wir als nächstes! (den letzten)
        let eventToHandle = thisInstance.pendingLazyLoadEvents[idx];
        thisInstance.pendingLazyLoadEvents.splice(idx, 1);
        if(this.debugMode==true) console.log("ArtikelScroller.loadDataVirtualScroller_handleNextPending() lastLazyLoadEvent_firstevent/eventToHandle:", this.lastLazyLoadEvent_first, eventToHandle);
        thisInstance.loadDataVirtualScroller_afterTimeout(eventToHandle, thisInstance);

        // gibt es weitere ?, dann rekursiv aufrufen
        if(thisInstance.pendingLazyLoadEvents.length > 0) {
            thisInstance.loadDataVirtualScroller_handleNextPending(thisInstance);
        }
      }
    }, 200);
  }

  loadDataVirtualScroller_afterTimeout(event: LazyLoadEvent, thisInstance: any) {
    if(thisInstance.debugMode==true) console.log("ArtikelScroller.loadDataVirtualScroller() event:", event);

    thisInstance.currentPage = Math.floor(event.first / thisInstance.rows) + 1;
    if(thisInstance.debugMode==true) console.log("ArtikelScroller.loadDataVirtualScroller() currentPage:", thisInstance.currentPage);
    if(thisInstance.debugMode==true) console.log("ArtikelScroller.loadDataVirtualScroller() crudItemService:", thisInstance.crudItemService);
    if(thisInstance.currentPage < 1) { // passiert auf iOS -> der scrollt in den negativen Bereich! currentPage 0/-1
      if(thisInstance.debugMode==true) console.log("ArtikelScroller.loadDataVirtualScroller() currentPage < 1 -> set to 1:", thisInstance.currentPage);
      thisInstance.currentPage = 1;
    }
    thisInstance.crudItemService.getArtikelCollectionFiltered(/*typ*/ 'S', /*onlyForMyUser*/ false, /*geodatenId*/ null, /*maxKm*/ 0, /*artikelgruppeId*/ 0,
      /*unternehmenId*/ 0, /*artikelnummer*/ null, /*lieferarten*/ null, /*herstellerId*/ 0, /*lagerId*/ 0, "NOFAV" /* keine Favoriten unten in der Liste!!!*/,
      true /*nurAktive*/, null /*lagerbestandsflag*/,
      thisInstance.currentPage, thisInstance.rows, /*search*/"", "newest_and_distance"/*sortKey*/)
    .subscribe(
      response => {
        if(thisInstance.debugMode==true) console.log("ArtikelScroller.loadDataVirtualScroller() response:", response);

          if (thisInstance.app.benutzer != null) {
            /*response.artikel.forEach(einzelnerArtikel => { // Abgleich mit Favoriten entfällt unten, siehe "NOFAV"
              thisInstance.favoritService.getFavoritForArtikelAndBenutzer(einzelnerArtikel.id, thisInstance.app.benutzer.id).subscribe(
                (responseFav: any) => {
                  console.log("ArtikelScroller.loadDataVirtualScroller_afterTimeout() getFavoritForArtikelAndBenutzer() responseFav:", responseFav);
                  einzelnerArtikel['_meinFavorit'] = true;
                  einzelnerArtikel['_meinFavoritId'] = responseFav.id;
                },
                (error: any) => {
                  // ignore! Der Artikel ist kein Favorit!
                  //alert("error: "+error);
                }
              );
            });*/
          }
          else {
            console.log("ArtikelScroller.loadDataVirtualScroller_afterTimeout() getArtikelCollection() skip Favoriten, weil kein Benutzer!");
          }

          if(thisInstance.totalRecords == null) thisInstance.totalRecords = response.pagination.totalCount;
          if(thisInstance.virtualScrollerItems == null || thisInstance.virtualScrollerItems.length == 0) thisInstance.virtualScrollerItems = Array.from({ length: thisInstance.totalRecords });

          let arraySizeBeforeSplice = thisInstance.virtualScrollerItems.length; // Sicherheitsprüfung, s.u.
          //populate page of virtual cars
          Array.prototype.splice.apply(thisInstance.virtualScrollerItems, [
            ...[event.first, event.rows],
            ...response.artikel
          ]);
          if(arraySizeBeforeSplice != 0 && thisInstance.virtualScrollerItems.length != arraySizeBeforeSplice) {
            console.error("ArtikelScroller.loadDataVirtualScroller() Warning(Error?): Array-Grösse hat sich durch das nachladen verändert!");
            debugger;
          }
        
          //trigger change detection
          thisInstance.virtualScrollerItems = [...thisInstance.virtualScrollerItems];
      
          if(this.debugMode==true) console.log("ArtikelScroller.loadDataVirtualScroller() virtualScrollerItems:", thisInstance.virtualScrollerItems);
      },
      error => thisInstance.handleError(error)
    );
  }

  artikelTitelBildURL(artikel: IArtikel) {
    //console.log("Dashboard.artikelTitelBildURL() artikel:", artikel);
    return this.artikelbildService.getThumbDownloadUrlForTitelbildArtikel(artikel.id, artikel.rowVersion);
  }
  onImgError(event) {
    event.target.src = 'assets/layout/images/imageNotFound.png';
  }

  selectArtikel(event: Event, artikel: IArtikel): void {
    console.log("ArtikelScroller.selectArtikel() artikel:", artikel);
    // navigate mit queryParams
    let navigationExtras: NavigationExtras = {
      queryParams: { 'overviewmode': 'myRequests' }
    };
    this._router.navigate(['/suche-ansicht', artikel.id], navigationExtras);
    //this.selectedCar = car;
    //this.displayDialog = true;
    event.preventDefault();
  }

  getSortInfo(artikel: IArtikel): string { // zum debuggen: bildet die SortOrder (eigentlich per SQL berechnet nach)
    let orderGewichtungsFaktorDistanz = 0.5; // aus API kopieren!!!
    let orderGewichtungsFaktorMinutes = 0.000694444; // aus API kopieren!!!
    let orderGewichtungsFaktorWerbeanzeige = 2.0; // aus API kopieren!!!
    let sortInfo = artikel.distance + " km" + " * " + orderGewichtungsFaktorDistanz + " = " + (artikel.distance * orderGewichtungsFaktorDistanz) + " | ";
    sortInfo += moment(artikel.modified).format("DD.MM.YYYY hh:mm:ss") + " = ";
    let timeNow = moment().toDate().getTime();
    let timeModified = moment(artikel.modified).toDate().getTime();
    let timeDiffInMinutes = (timeNow - timeModified) / (1000 * 60);
    sortInfo += timeDiffInMinutes + " Minutes since Modified";
    sortInfo += " * " + orderGewichtungsFaktorMinutes + " = " + (timeDiffInMinutes * orderGewichtungsFaktorMinutes) + " | ";
    sortInfo += " = " + ((artikel.distance * orderGewichtungsFaktorDistanz) + (timeDiffInMinutes * orderGewichtungsFaktorMinutes));
    if(artikel.typ == 'W') {
      sortInfo += " + / "+orderGewichtungsFaktorWerbeanzeige+" (weil Werbeanzeige) = " + (((artikel.distance * orderGewichtungsFaktorDistanz) + (timeDiffInMinutes * orderGewichtungsFaktorMinutes))) / 2;
    }
    return sortInfo;
  }

  handleError(error: any) {
    console.log("ArtikelScroller.handleError error", error);
    //this.globalService.addFeedbackByClone("error " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.handleError()", error); // feedback

    //this.loading = false;
    //this.blockedDocument = false;
    let errorMessage = "";
    let summary = this.translate.instant('Fehler', true);

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        /*this.*/errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        /*this.*/errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      /*this.*/errorMessage = "Unauthorized";
      ///*this.*/router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      /*this.*/errorMessage = error.message;
    }

    //if(this.errorMessage != null) this.errorMessage = this.errorMessage.replace(/\n/g, "<br>");
    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: /*this.*/errorMessage }); // TO DO
  }

}
