import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { SampleDemoComponent } from './demo/view/sampledemo.component';
import { FormsDemoComponent } from './demo/view/formsdemo.component';
import { DataDemoComponent } from './demo/view/datademo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { UtilsDemoComponent } from './demo/view/utilsdemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppWizardComponent } from './pages/app.wizard.component';
import { ArtikelUebersichtComponent } from './artikel-uebersicht/artikel-uebersicht.component';
import { ProduktDetailsComponent } from './demo/view/produkt-details/produkt-details.component';
import {DetailArtikelAnsichtComponent} from './demo/view/detail-artikel-ansicht/detail-artikel-ansicht.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ArtikelimportAbrufVorlageComponent } from './artikelimport-abruf-vorlage/artikelimport-abruf-vorlage.component';
import { ArtikelgruppenImportComponent } from './artikelgruppen-import/artikelgruppen-import.component';

import { LoginComponent } from './auth/login.component';
import { RegisterComponent } from './auth/register.component';
import { LoginGuard } from './auth/login.guard';
import { AdminGuard } from './auth/admin.guard';
import { AuthorizedOnlyRouteGuard } from './auth/authorizedOnlyRoute.guard';
import { HomeComponent } from './home/home.component';

//import { ArtikelListComponent } from './artikel-list/artikel-list.component'; // TO DO
import { ArtikelDetailComponent } from './artikel-detail/artikel-detail.component';
import { ArtikelDetailGuard } from './artikel-detail/artikel-detail.guard';
import { TestObservablesComponent } from './_test/test-observables/test-observables.component';
import { ArtikelDetailAnsichtComponent } from './artikel-detail/artikel-detail-ansicht.component';
import { WerbeanzeigeDetailAnsichtComponent } from './werbeanzeige-detail-ansicht/werbeanzeige-detail-ansicht.component';

import { BenutzerListComponent } from './benutzer-list/benutzer-list.component';
import { BenutzerDetailComponent } from './benutzer-detail/benutzer-detail.component';
import { BenutzerDetailGuard } from './benutzer-detail/benutzer-detail.guard';

import { UnternehmenListComponent } from './unternehmen-list/unternehmen-list.component';
import { UnternehmenDetailComponent } from './unternehmen-detail/unternehmen-detail.component';
import { UnternehmenDetailGuard } from './unternehmen-detail/unternehmen-detail.guard';
import { AppComponent } from './app.component';
import { ArtikelScrollerComponent } from './artikel-scroller/artikel-scroller.component';
import { GetMobileAppComponent } from './get-mobile-app/get-mobile-app.component';


export const routes: Routes = [
    //{ path: '', component: /*AppMainComponent*/AppComponent,
        //children: [
            { path: '___TestObservables', component: TestObservablesComponent, data: {breadcrumb: '___TestObservables'} },
            {
              path: 'login',
              component: LoginComponent/*,
              canActivate: [LoginGuard]*/
            },
            {
              path: 'register',
              component: RegisterComponent/*,
              canActivate: [LoginGuard]*/
            },
            /*{
              path: 'home',
              //loadChildren: './random/random.module#RandomModule',
              component: HomeComponent,
              canActivate: [AuthorizedOnlyRouteGuard],
              canLoad: [AuthorizedOnlyRouteGuard]
            },*/
            { path: 'home', redirectTo: '/dashboard', pathMatch: 'full' }, // home = dashboard
            { 
              path: 'dashboard', 
              component: DashboardComponent, 
              canActivate: [AuthorizedOnlyRouteGuard], 
              canLoad: [AuthorizedOnlyRouteGuard], 
              data: {
                shouldReuse: true, shouldReuseWhenPreviousPath: ['artikel/*', 'artikel-ansicht/*'],
                breadcrumb: 'Dashboard'
              } 
            },
            { path: 'artikel-uebersicht', component: ArtikelUebersichtComponent, canActivate: [AuthorizedOnlyRouteGuard], canLoad: [AuthorizedOnlyRouteGuard], data: {shouldReuse: true, shouldReuseWhenPreviousPath: ['artikel/*', 'artikel-ansicht/*'], breadcrumb: 'Produktübersicht'} },
            { path: 'suche-uebersicht', component: ArtikelUebersichtComponent, canActivate: [AuthorizedOnlyRouteGuard], canLoad: [AuthorizedOnlyRouteGuard], data: {shouldReuse: true, shouldReuseWhenPreviousPath: ['suche/*', 'suche-ansicht/*'], breadcrumb: 'Suche-Übersicht'} },
            { path: 'werbeanzeige-uebersicht', component: ArtikelUebersichtComponent, canActivate: [AuthorizedOnlyRouteGuard], canLoad: [AuthorizedOnlyRouteGuard], data: {shouldReuse: true, shouldReuseWhenPreviousPath: ['werbeanzeige/*', 'werbeanzeige-ansicht/*'], breadcrumb: 'Werbeanzeige-Übersicht'} },
            //{ path: 'produkt-details', component: ProduktDetailsComponent, canActivate: [AuthorizedOnlyRouteGuard], canLoad: [AuthorizedOnlyRouteGuard] },
            //{ path: 'produkt-details/:id', component: ProduktDetailsComponent, canActivate: [AuthorizedOnlyRouteGuard], canLoad: [AuthorizedOnlyRouteGuard] },
            //{ path: 'artikel', canActivate: [AuthGuard], component: ArtikelListComponent, data: { shouldReuse: true, shouldReuseWhenPreviousPath: ['artikel/*'] } },
            { path: 'artikel/:id', canDeactivate: [ArtikelDetailGuard], canActivate: [AuthorizedOnlyRouteGuard, ArtikelDetailGuard/*, AuthGuard*/], component: ArtikelDetailComponent, data: {breadcrumb:'Dashboard'} },
            { path: 'suche/:id', canDeactivate: [ArtikelDetailGuard], canActivate: [AuthorizedOnlyRouteGuard, ArtikelDetailGuard/*, AuthGuard*/], component: ArtikelDetailComponent, data: {breadcrumb:'Dashboard'} },
            { path: 'werbeanzeige/:id', canDeactivate: [ArtikelDetailGuard], canActivate: [AuthorizedOnlyRouteGuard, ArtikelDetailGuard/*, AuthGuard*/], component: ArtikelDetailComponent, data: {breadcrumb:'Dashboard'} },
            // { path: 'dashboard', component: DashboardDemoComponent },
            /*{ path: 'components/sample', component: SampleDemoComponent },
            { path: 'components/forms', component: FormsDemoComponent },
            { path: 'components/data', component: DataDemoComponent },
            { path: 'components/panels', component: PanelsDemoComponent },
            { path: 'components/overlays', component: OverlaysDemoComponent },
            { path: 'components/menus', component: MenusDemoComponent },
            { path: 'components/messages', component: MessagesDemoComponent },
            { path: 'components/misc', component: MiscDemoComponent },
            { path: 'pages/empty', component: EmptyDemoComponent },
            { path: 'components/charts', component: ChartsDemoComponent },
            { path: 'components/file', component: FileDemoComponent },
            { path: 'utils', component: UtilsDemoComponent },
            { path: 'documentation', component: DocumentationComponent },
            { path: 'pages/help', component: AppHelpComponent },
            { path: 'pages/invoice', component: AppInvoiceComponent },*/
            //{ path: 'detail-artikel-ansicht', component: /*DetailArtikelAnsichtComponent*/ArtikelDetailAnsichtComponent },
            { path: 'artikel-ansicht/:id', component: ArtikelDetailAnsichtComponent, canActivate: [AuthorizedOnlyRouteGuard], canLoad: [AuthorizedOnlyRouteGuard], data: {breadcrumb:'Dashboard'} },
            { path: 'suche-ansicht/:id', component: ArtikelDetailAnsichtComponent, canActivate: [AuthorizedOnlyRouteGuard], canLoad: [AuthorizedOnlyRouteGuard], data: {breadcrumb:'Dashboard'} },
            //{ path: 'werbeanzeige-ansicht/:id', component: ArtikelDetailAnsichtComponent, canActivate: [AuthorizedOnlyRouteGuard], canLoad: [AuthorizedOnlyRouteGuard], data: {breadcrumb:'Dashboard'} }, // Bei Werbeanzeigen gibt es keine Ansicht!
            //{ path: 'benutzer', component: BenutzerListComponent},
            //{ path: 'benutzer/:id', component: BenutzerDetailComponent },
            { path: 'unternehmen', component: UnternehmenListComponent, canActivate: [AuthorizedOnlyRouteGuard, AdminGuard]},
            { path: 'unternehmen/:id', component: UnternehmenDetailComponent, canActivate: [AuthorizedOnlyRouteGuard, UnternehmenDetailGuard]},
            { path: 'import-artikel', component: ArtikelimportAbrufVorlageComponent, canActivate: [AuthorizedOnlyRouteGuard, AdminGuard]},
            { path: 'import-artikelgruppen', component: ArtikelgruppenImportComponent, canActivate: [AuthorizedOnlyRouteGuard, AdminGuard]},
            { path: 'getmobileapp', component: GetMobileAppComponent},
            { path: 'debug', component: ArtikelScrollerComponent},
            
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
            { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
        //]
    //},
    //{path: 'error', component: AppErrorComponent},
    //{path: 'accessdenied', component: AppAccessdeniedComponent},
    //{path: '404', component: AppNotfoundComponent},
    //{path: 'login', component: AppLoginComponent},
    //{path: 'wizard', component: AppWizardComponent},
    //{path: '**', redirectTo: '/404'},
    

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', useHash: false});

