import { Component, OnInit, AfterViewInit, Inject, forwardRef, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/primeng';
import * as cloneDeep from 'lodash/cloneDeep'; 

import { AppComponent } from '../app.component';
import { MessageWrapperService } from '../_services/message-wrapper.service';
import { TranslateService } from '../_services/translate.service';

import { IUnternehmen } from '../_interfaces/unternehmen';

import { _ArtikelImportService } from '../_services/_artikelimport.service';

@Component({
  selector: 'app-artikelimport-abruf-vorlage',
  templateUrl: './artikelimport-abruf-vorlage.component.html',
  styleUrls: ['./artikelimport-abruf-vorlage.component.css']
})
export class ArtikelimportAbrufVorlageComponent implements OnInit, AfterViewInit {

  artikelImportAktuellUploadingFile: File = null;
  imports: any[] = [];
  hersteller: boolean = false;
  artikel: boolean = true;
  vorschau: boolean = true;
  unternehmen: IUnternehmen;

  vorschauCRUDItem: any;
  vorschauDisplay: boolean = false;

  propertyTypeModifiedAndCreated = { name: '', type: 'DateTimeOffset', format: '' };
  
  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private messageWrapperService: MessageWrapperService,
    private artikelImportService: _ArtikelImportService,
    private translate: TranslateService  ) { }

  ngOnInit(): void {
    this._app.setPageTitle("Import: Artikel/Hersteller");
  }

  /*ngOnInit*/ngAfterViewInit(): void {
    setTimeout(() => {
      this.artikelImportService.downloadVorlage().subscribe(data => {
        //https://www.illucit.com/angular/angular-5-httpclient-file-download-with-authentication/
        var binaryData = [];
        binaryData.push(data);
        var downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData));
        downloadLink.text = "[Download Vorlage]";
        //downloadLink.target = "new";
        downloadLink.download = "Plattgruen_Artikelimport_Vorlage.xlsx";
  
        //document.body.appendChild(downloadLink);
        //downloadLink.click();
  
        let p = document.getElementById("ArtikelImportVorlage");
        p.innerHTML = "";
        p.appendChild(downloadLink);
        
      });
    }, 2000);
  }

  onNGFileInputSelect(event) {
    console.log("ArtikelImportAbruf.onNGFileInputSelect().event:", event);

    //let fileToUpload : File = event.files[0];
    for (let i : number = 0; i < /*event.files.length*/1; i++) { // nur 1 File!
      let fileToUpload : File = event.files[i];
      this.artikelImportAktuellUploadingFile = fileToUpload; // merken für die progress-Rückmeldung

      // nicht nur das file uploaden, sondern auch die Entity 
      let artikelImportForCreate = this.artikelImportService.initializeArtikelImport();
      let dateiendung = fileToUpload.name.substr(fileToUpload.name.lastIndexOf('.') + 1);
      let dateiname = fileToUpload.name.substr(0, fileToUpload.name.length - (dateiendung.length + 1));
      artikelImportForCreate.dateiname = dateiname;
      artikelImportForCreate.dateiendung = dateiendung;
      artikelImportForCreate.bezeichnung = fileToUpload.name;
      artikelImportForCreate.unternehmen = 1;

      this.artikelImportService.uploadArtikelImport(fileToUpload, artikelImportForCreate, this, this.uploadProgress, this.uploadSuccess, this.uploadError, this.hersteller, this.artikel, this.vorschau, this.unternehmen.id);
      
    }
  }

  uploadProgress(thisInstance: ArtikelimportAbrufVorlageComponent, progress: number) {
    console.log("ArtikelImportAbruf.uploadProgress() progress:", progress);
    //thisInstance.messageWrapperService.postTimedMessage({severity: 'info', summary: 'Upload: '+progress, detail: ''}); 

    if(thisInstance.artikelImportAktuellUploadingFile != null) {
      thisInstance.artikelImportAktuellUploadingFile['_percentUploaded']=progress;
    }
  }
  uploadSuccess(thisInstance: ArtikelimportAbrufVorlageComponent, eventbody : any) {
    console.log("ArtikelImportAbruf.uploadSuccess()");
    let originalFileName: string = eventbody.originalFileName;
    let guid: string = eventbody.guid;
    let errorsFound: boolean = eventbody.errorsFound;
    console.log("ArtikelImportAbruf.uploadSuccess() originalFileName:", originalFileName);
    console.log("ArtikelImportAbruf.uploadSuccess() guid:", guid);
    console.log("ArtikelImportAbruf.uploadSuccess() errorsFound:", errorsFound);
    if(errorsFound == true) {
      thisInstance.messageWrapperService.postTimedMessage({severity: 'error', summary: 'Datei '+originalFileName/*+':'+guid*/, detail: 'Es wurden Fehler gefunden. Siehe Ergebnis.'}); 
    }
    else {
      thisInstance.messageWrapperService.postTimedMessage({severity: 'info', summary: 'Datei '+originalFileName/*+':'+guid*/, detail: 'Upload erfolgreich!'}); 
    }
    
    let dateiendung = originalFileName.substr(originalFileName.lastIndexOf('.') + 1);
    let dateiname = originalFileName.substr(0, originalFileName.length - (dateiendung.length + 1));

    // das File als uploaded markieren
    //  file['_percentUploaded']=100;

    console.log("ArtikelImportAbrufComponent.uploadSuccess() artikelImportAktuellUploadingFile:", thisInstance.artikelImportAktuellUploadingFile);

    thisInstance.imports = eventbody.imports;
  }
  uploadError(thisInstance: ArtikelimportAbrufVorlageComponent, error: any) {
    console.log("ArtikelImportAbruf.uploadError() error:", error);
    thisInstance.handleError(thisInstance, error);
  }


  vorschauShow(importObj: any) {
    console.log("ArtikelImportAbrufComponent.vorschauShow() importObj:", importObj);
    this.vorschauCRUDItem = importObj.artikel;
    this.vorschauDisplay = true;
  }

  vorschauZurueckClicked() {
    this.vorschauDisplay = false;
  }

  handleError(thisInstance: ArtikelimportAbrufVorlageComponent, error: any) {
    console.log("ArtikelImportAbruf.handleError error", error);
    //this.globalService.addFeedbackByClone("error " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.handleError()", error); // feedback

    //this.loading = false;
    //this.blockedDocument = false;
    let errorMessage = "";
    let summary = thisInstance.translate.instant('Fehler', true);

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        /*this.*/errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        /*this.*/errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      /*this.*/errorMessage = "Unauthorized";
      ///*this.*/router.navigate(['/login'], { queryParams: { returnUrl: /*this.*/router.url } });
    }
    else {
      /*this.*/errorMessage = error.message;
    }

    //if(this.errorMessage != null) this.errorMessage = this.errorMessage.replace(/\n/g, "<br>");
    thisInstance.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: /*this.*/""+errorMessage }); // TO DO
  }

}
