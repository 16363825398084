import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
//import { IFirma } from '../_interfaces/firma';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '../_services/translate.service';
import { MessageWrapperService } from '../_services/message-wrapper.service';
import { Router } from '@angular/router';
import { debounceTime } from '../../../node_modules/rxjs/operators';
//import { FirmaService } from '../_services/firma.service';

//import { GlobalService } from '../_services/global.service'; 
import { LazyLoadEvent } from "primeng/api";
declare var $: any;

@Component({
  selector: 'crud-basic-select-lazy',
  templateUrl: './crud-basic-select-lazy.component.html',
  styleUrls: ['./crud-basic-select-lazy.component.css']
})
export class CRUDBasicSelectLazyComponent implements OnInit {

  // MODI im Vergleich zu CRUDBasicSelect sind das alles keine Parameter
  //options: any; 
  //optionsFiltered: any; // wie options, aber filtered
  //visible: boolean; 
  search: string = ""; // in SubKlasse NICHT überschreiben! bleibt blank!
  //title: string = ""; // entfällt, da kein Dialog
  //onClose: EventEmitter<any> = new EventEmitter<any>(); // entfällt, da kein Dialog

  // folgende Werte müssen in der SubKlasse überschrieben werden
  crudItemService: any; // in SubKlasse überschreiben!
  CRUDItemBezeichnungPluralCapitalized: string = null; // in SubKlasse überschreiben!
  CRUDItemBezeichnungPlural: string = null; // in SubKlasse überschreiben!

  crudItemLabel: string = null; // in SubKlasse überschreiben!
  crudItemLabel2: string = null; // in SubKlasse überschreiben!
  crudItemOnSelect: EventEmitter<any> = /*new EventEmitter<any>()*/null; // in SubKlasse überschreiben!

  // Kann, muss aber in den meisten Fällen nicht überschrieben werden:
  crudItemSize: number = 75; // Höhe in Pixeln (eine Zeile)
  CRUDMethodNameGetCollection: string = null; // ggf. in SubKlasse überschreiben! - nur wenn es nicht get...Collection() heissen soll
  
  @ViewChild("ds", { static: true }) dataScroller: any;
  
    errorMessage: string;
  
    items: any[];
    cols: any[];
  
    loading: boolean;
    totalRecords: number = null;
    rows: number = 30;
    currentPage: number;
  
    //search: string = '';
    searchControl = new FormControl();
    formCtrlSub: Subscription;

    hotkeyHandlerIsRegistered = false;

    pendingLazyLoadEvents: LazyLoadEvent[] = [];
    lastLazyLoadEvent_first: number = null;
    killScroller: boolean = false;

    debugMode: boolean = false;
    
    constructor(
      public translateService: TranslateService,
      protected messageWrapperService: MessageWrapperService,
      protected router: Router,
      //protected globalService: GlobalService
    ) { }
  
    ngOnDestroy() {
      if(this.debugMode==true) console.log("CRUDBasicSelectLazy.ngOnDestroy()");
      this.formCtrlSub.unsubscribe();

      this.unRegisterHotkeyHandler();
    }
  
    
    selectItem(item: any) {
      if(this.debugMode==true) console.log("CRUDBasicSelectLazy.selectItem()");
      this.crudItemOnSelect.emit(item);
      this.unRegisterHotkeyHandler();      
      //this.visible = false;
    }

    /*dialogClose() {
      if(this.debugMode==true) console.log("CRUDBasicSelectLazy.dialogClose()");
      //this.onClose.emit();
      this.unRegisterHotkeyHandler();
      //this.visible = false;
    }*/
    

    ngOnInit() {
      if(this.debugMode==true) console.log("CRUDBasicSelectLazy.ngOnInit()");
      //this.options = [];
      //this.optionsFiltered = [];
      this.items = [];
      //this.items = Array.from({ length: this.options.length });

      this.currentPage = 1;
          
      this.formCtrlSub = this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(val => {
        //this.filterItems(val);
        this.filterItemsVirtualScroller(val);
      });
    
      this.registerHotkeyHandler();
    }

    loadDataVirtualScroller(event: LazyLoadEvent) {
      if(this.debugMode==true) console.log("CRUDBasicSelectLazy.loadDataVirtualScroller() event:", event);
      //  wenn sich mehrere LazyLoads ergeben (schnell scrollen), dass dann der letzte (aktuelle) zuerst ausgeführen!
      this.pendingLazyLoadEvents.push(event);
      this.lastLazyLoadEvent_first = event.first;
      let thisInstance = this;
      thisInstance.loadDataVirtualScroller_handleNextPending(thisInstance);
    }

    loadDataVirtualScroller_handleNextPending(thisInstance: any) {
      setTimeout(() => {
        if(thisInstance.pendingLazyLoadEvents.length > 0) {
          let idx = thisInstance.pendingLazyLoadEvents.length -1; // den Event nehmen wir als nächstes! (den letzten)
          let eventToHandle = thisInstance.pendingLazyLoadEvents[idx];
          thisInstance.pendingLazyLoadEvents.splice(idx, 1);
          if(this.debugMode==true) console.log("CRUDBasicSelectLazy.loadDataVirtualScroller_handleNextPending() lastLazyLoadEvent_firstevent/eventToHandle:", this.lastLazyLoadEvent_first, eventToHandle);
          thisInstance.loadDataVirtualScroller_afterTimeout(eventToHandle, thisInstance);

          // gibt es weitere ?, dann rekursiv aufrufen
          if(thisInstance.pendingLazyLoadEvents.length > 0) {
              thisInstance.loadDataVirtualScroller_handleNextPending(thisInstance);
          }
        }
      }, 200);
    }

    loadDataVirtualScroller_afterTimeout(event: LazyLoadEvent, thisInstance: any) {
      if(thisInstance.debugMode==true) console.log("CRUDBasicSelectLazy.loadDataVirtualScroller() event:", event);
  
      thisInstance.currentPage = Math.floor(event.first / thisInstance.rows) + 1;
      if(thisInstance.debugMode==true) console.log("CRUDBasicSelectLazy.loadDataVirtualScroller() currentPage:", thisInstance.currentPage);
      if(thisInstance.debugMode==true) console.log("CRUDBasicSelectLazy.loadDataVirtualScroller() crudItemService:", thisInstance.crudItemService);
      let methodName = this.CRUDMethodNameGetCollection != null ? this.CRUDMethodNameGetCollection : 'get'+this.CRUDItemBezeichnungPluralCapitalized+'Collection';
      if(thisInstance.debugMode==true) console.log("CRUDBasicSelectLazy.loadDataVirtualScroller() methodName:", methodName);
      let searchQuery=thisInstance.search;
      thisInstance.crudItemService[methodName](thisInstance.currentPage, thisInstance.rows, thisInstance.search)
      .subscribe(
        response => {
          if(thisInstance.debugMode==true) console.log("CRUDBasicSelectLazy.loadDataVirtualScroller() response:", response);
          if(thisInstance.debugMode==true) console.log("CRUDBasicSelectLazy.loadDataVirtualScroller() searchQuery:", searchQuery);

          // wenn das searchQuery aus dem get...() nicht zu dem aktuellen search passt, dann ist das ein response, der viel zu spät ankommt!
          // in dem Fall ignorieren!
          // man könnte das alternativ machen, indem man wieder unsubscribed: https://stackoverflow.com/questions/52096111/how-to-cancel-http-request-in-angular-6
          if(searchQuery != this.search) {
            if(thisInstance.debugMode==true) console.log("CRUDBasicSelectLazy.loadDataVirtualScroller() searchQuery passt nicht zu aktuellem search. (vermutlich aus vorherigem search). Ignoriere!");
          }
          else {
            if(thisInstance.totalRecords == null) thisInstance.totalRecords = response.pagination.totalCount;
            if(thisInstance.items == null || thisInstance.items.length == 0) thisInstance.items = Array.from({ length: thisInstance.totalRecords });
  
            let arraySizeBeforeSplice = thisInstance.items.length; // Sicherheitsprüfung, s.u.
            //populate page of virtual cars
            Array.prototype.splice.apply(thisInstance.items, [
              ...[event.first, event.rows],
              ...response[this.CRUDItemBezeichnungPlural]
            ]);
            if(arraySizeBeforeSplice != 0 && thisInstance.items.length != arraySizeBeforeSplice) {
              console.error("CRUDBasicSelectLazy.loadDataVirtualScroller() Warning(Error?): Array-Grösse hat sich durch das nachladen verändert!");
              debugger;
            }
          
            //trigger change detection
            thisInstance.items = [...thisInstance.items];
        
            if(this.debugMode==true) console.log("CRUDBasicSelectLazy.loadDataVirtualScroller() items:", thisInstance.items);
          }
        },
        error => thisInstance.handleError(error)
      );
    }

    filterItemsVirtualScroller(filterValue: string) {
      if(this.debugMode==true) console.log("CRUDBasicSelectLazy.filterItemsVirtualScroller() filterValue:", filterValue);
      //if(this.debugMode==true) console.log("CRUDBasicSelectLazy.filterItemsVirtualScroller() items (before resetting):", this.items);

      this.pendingLazyLoadEvents = [];
      this.lastLazyLoadEvent_first = null;

      this.killScroller = true;

      this.currentPage = 1;
      this.search = filterValue;
      // totalRecords und items resetten, damit beides nach dem nä. get...() wieder initialisiert wird:
      this.totalRecords = null;
      this.items = [];
      //trigger change detection
      //this.items = [...this.items];
      //if(this.debugMode==true) console.log("CRUDBasicSelectLazy.filterItemsVirtualScroller() items (after resetting):", this.items);
      
      let thisInstance = this;
      setTimeout(() => {
        /*
        let lazyLoadEvent : LazyLoadEvent = {
          first: 0,
          rows: this.rows
        }
        this.dataScroller.scrollToIndex(0, 'smooth');
        this.loadDataVirtualScroller(lazyLoadEvent);   // weiss nicht mehr, was schon geladen war
        */
       this.killScroller = false;
      }, 50);

    }


    registerHotkeyHandler() {
      if(this.hotkeyHandlerIsRegistered==false) {
        if(this.debugMode==true) console.log("CRUDBasicSelectLazy registering hotkey-handler ...");      
        //this.globalService.registerHotKeyHandler(this);
        this.hotkeyHandlerIsRegistered=true;
      }
    }
    unRegisterHotkeyHandler() {
      if(this.hotkeyHandlerIsRegistered==true) {
        if(this.debugMode==true) console.log("CRUDBasicSelectLazy UN-registering hotkey-handler ...");      
        //this.globalService.unRegisterHotKeyHandler(this);
        this.hotkeyHandlerIsRegistered=false;
      }
    }

    hotkeys(event) {
      if(event.repeat == true) {
        // // repeated events interessieren uns generell überhaupt nicht!
        // z.B. bei CTRL (keydown) kommt der event endlos oft vor - so lange man auf CTRL bleibt
      }
      else {
        //if(this.debugMode == true) if(this.debugMode==true) console.log("CRUDBasicSelect.hotkeys() event:", event);
        //if(this.globalService != null) this.globalService.handleHotKeys(this, event);
      }
    }
  
    handleHotkeys(event) {
      /*if (event.keyCode == 27) {
        this.dialogClose();
        event.preventDefault();
      }*/
    }
 
  
    handleError(error: any) {
      this.loading = false;
      let summary = this.translateService.instant('Fehler', true);
  
      if (error.status === 422) {
        summary += ' (422)';
        if (error != null) {
          this.errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || error.error.PasswordTooShort || error.error.PasswordRequiresNonAlphanumeric || 'Server Error';
        }
        else {
          this.errorMessage = "Server Error";
        }
      }
      else if (error.status === 401) {
        summary += ' (401)';
        this.errorMessage = "Unauthorized";
        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
      }
      else {
        this.errorMessage = <any>error
      }
  
      this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: this.errorMessage });
  
      this.loading = false;
    }
}
