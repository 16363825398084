import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IArtikel, IArtikelForCounting } from '../_interfaces/artikel';
import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';
import { ILieferart } from '../_interfaces/lieferart';

@Injectable()
export class ArtikelService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'beschreibung', type: 'string'},
    {name: 'artikelnummer', type: 'string'},
    //{name: 'baustelle', type: 'string'},
    //{name: 'postleitzahl', type: 'string'},
    //{name: 'ort', type: 'string'},
    {name: 'preis', type: 'decimal', format: '$'},
    {name: 'menge', type: 'decimal'},
    {name: 'verlademoeglichkeit', type: 'bool'},
    {name: 'gueltigkeit', type: 'DateTimeOffset'},
    {name: 'aktiv', type: 'bool'},
    {name: 'email_anzeigen', type: 'bool'},
    {name: 'lagerbestand', type: 'bool'},
    {name: 'telefon_anzeigen', type: 'bool'},
    {name: 'mobilnummer_anzeigen', type: 'bool'},
    {name: 'ansprechpartnerId', type: 'int'},
    {name: 'ansprechpartner', type: 'object'},
    {name: 'zustandId', type: 'int'},
    {name: 'zustand', type: 'object'},
    {name: 'herstellerId', type: 'int'},
    {name: 'hersteller', type: 'object'},
    {name: 'lagerId', type: 'int'},
    {name: 'lager', type: 'object'},
    //{name: 'lieferartId', type: 'int'},
    //{name: 'lieferart', type: 'object'},
    {name: 'mengeneinheitId', type: 'int'},
    {name: 'mengeneinheit', type: 'object'},
    {name: 'pauschal', type: 'bool'},
    {name: 'verfuegbarkeitId', type: 'int'},
    {name: 'verfuegbarkeit', type: 'object'},
    {name: 'verkaufseinheitId', type: 'int'},
    {name: 'verkaufseinheit', type: 'object'},
    {name: 'verpackungId', type: 'int'},
    {name: 'verpackung', type: 'object'},
    {name: 'summary', type: 'string'},
    {name: 'distance', type: 'decimal', decimals: 1},
    //{name: 'suchanfrage', type: 'bool'},
    {name: 'typ', type: 'string'},
    {name: 'url', type: 'string'},
    {name: 'unternehmenId', type: 'int'},
    {name: 'unternehmen', type: 'object'},
    {name: 'laenge', type: 'decimal'},
    {name: 'breite', type: 'decimal'},
    {name: 'hoehe', type: 'decimal'},
    {name: 'dicke', type: 'decimal'}
  ];

  //getArtikelCollectionForThisUserAndLocation(onlyForMyUser: boolean, geodatenId: number, maxKm: number, pageNumber: number, pageSize: number, searchQuery: string): Observable<{ artikel: IArtikel[], pagination: IPagination }> {
    getArtikelCollectionFiltered(typ: string, onlyForMyUser: boolean, geodatenId: number, maxKm: number, artikelgruppeId: number, unternehmenId: number, artikelnummer: string, lieferarten: ILieferart[], herstellerId: number, lagerId: number, favoritenFlag: string, nurAktive: boolean, lagerbestandsFlag: string
      , pageNumber: number, pageSize: number, searchQuery: string, sortKey: string): Observable<{ artikel: IArtikel[], pagination: IPagination }> {

    // lieferarten kommaSep:
    console.log("ArtikelService.getArtikelCollectionFiltered() lieferarten:", lieferarten);

    let lieferartenKommaSep: string = "";
    if(lieferarten != null) {
      for(let i = 0; i < lieferarten.length; i++) {
        if(lieferartenKommaSep.length > 0) lieferartenKommaSep += ",";
        lieferartenKommaSep += lieferarten[i].id;
      }
    }

    // favoritenFlag/lagerbestandsFlag ist letzer Parm und alpha -> darf nicht blank sein!
    if(favoritenFlag == null || favoritenFlag.length == 0) favoritenFlag = "null";
    if(lagerbestandsFlag == null || lagerbestandsFlag.length == 0) lagerbestandsFlag = "null";

    //return this.httpClient.get(this.apiBaseUrl + 'artikel/getArtikelCollectionforthisUserAndLocation/'+onlyForMyUser+'/'+geodatenId+'/'+maxKm+'?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true }) 
    return this.httpClient.get(this.apiBaseUrl + 'artikel/getArtikelCollectionFiltered/'+typ+'/'+onlyForMyUser+'/'+geodatenId+'/'+(maxKm != null?maxKm:0)
    +'/'+artikelgruppeId+'/'+unternehmenId+'/'+(artikelnummer!=null && artikelnummer.length>0?artikelnummer:'%20')+'/'+(lieferartenKommaSep!=null && lieferartenKommaSep.length>0?lieferartenKommaSep:'%20')+'/'+herstellerId+'/'+lagerId+'/'+favoritenFlag+'/'+nurAktive+'/'+lagerbestandsFlag
    +'?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery + (sortKey != null ? '&orderBy=' + sortKey : ''), { headers: httpOptions, observe: 'response', withCredentials: true }) 
      .pipe(
        map((response) => {
          return { artikel: <IArtikel[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getArtikelMeineFavoriten(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ artikel: IArtikel[], pagination: IPagination }> {

    //return this.httpClient.get(this.apiBaseUrl + 'artikel/getArtikelCollectionforthisUserAndLocation/'+onlyForMyUser+'/'+geodatenId+'/'+maxKm+'?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true }) 
  return this.httpClient.get(this.apiBaseUrl + 'artikel/getArtikelMeineFavoriten'
  +'?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
    .pipe(
      map((response) => {
        return { artikel: <IArtikel[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
      }), catchError(this.handleError))
}

countArtikelForThisUser(): Observable<IArtikelForCounting> {

  //return this.httpClient.get(this.apiBaseUrl + 'artikel/getArtikelCollectionforthisUserAndLocation/'+onlyForMyUser+'/'+geodatenId+'/'+maxKm+'?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true }) 
return this.httpClient.get(this.apiBaseUrl + 'artikel/CountArtikelForThisUser', { headers: httpOptions, observe: 'response', withCredentials: true })
  .pipe(
    map((response) => {
      return <IArtikelForCounting>response.body;
    }), catchError(this.handleError))
}

  /*getArtikelCollectionForThisUser(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ artikel: IArtikel[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'artikel/getArtikelCollectionforthisUser?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { artikel: <IArtikel[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }*/

  /*getArtikelCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ artikel: IArtikel[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'artikel?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { artikel: <IArtikel[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }*/

  getArtikel(id: number): Observable<IArtikel> {

    if (id === 0) {
      return Observable.create((observer: any) => {
        observer.next(this.initializeArtikel());
        observer.complete();
      })
    }

    return this.httpClient.get<IArtikel>(this.apiBaseUrl + 'artikel/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  getArtikelBare(id: number): Observable<IArtikel> {

    if (id === 0) {
      return Observable.create((observer: any) => {
        observer.next(this.initializeArtikel());
        observer.complete();
      })
    }

    return this.httpClient.get<IArtikel>(this.apiBaseUrl + 'artikel/GetArtikelBare/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  getArtikelAndIncreaseAufrufe(id: number): Observable<IArtikel> {

    if (id === 0) {
      return Observable.create((observer: any) => {
        observer.next(this.initializeArtikel());
        observer.complete();
      })
    }

    return this.httpClient.get<IArtikel>(this.apiBaseUrl + 'artikel/GetArtikelAndIncreaseAufrufe/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError));
  }

  getArtikelDummy(id: number): Observable<IArtikel> { // gibt ein initializeArtikel() als Observable zurück - quasi als Replacement für getArtikel(), wenn man einen leeren haben will! Wird in ArtikelDetailAnsicht verwendet, wenn man den CRUDItem bereits als Parm bekommt
    let artikel = this.initializeArtikel();
    let artikelObservable = of(artikel);
    return artikelObservable;
  }



  saveArtikel(artikel: IArtikel): Observable<IArtikel> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(artikel); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("ArtikelService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("ArtikelService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (artikel.id === 0) {
      return this.createArtikel(/*artikel*/itemToSave);
    }

    return this.updateArtikel(/*artikel*/itemToSave)

  }

  updateArtikel(artikel: IArtikel): Observable<IArtikel> {

    return this.httpClient.put<IArtikel>(this.apiBaseUrl + 'artikel/' + artikel.id, artikel, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteArtikel(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'artikel/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createArtikel(artikel: IArtikel): Observable<IArtikel> {
    return this.httpClient.post<IArtikel>(this.apiBaseUrl + 'artikel', artikel, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeArtikel(): IArtikel {
    return {
      id: 0,
      createdBy: '',
      created: '',
      modifiedBy: '',
      modified: '',
      rowVersion: '',
      bezeichnung: '',
      beschreibung: '',
      artikelnummer: '',
      //baustelle: '',
      //postleitzahl: '',
      //ort: '',
      preis: 0,
      menge: 0,
      verlademoeglichkeit: false,
      gueltigkeit: null,
      aktiv: true,
      email_anzeigen: true,
      lagerbestand: false,
      telefon_anzeigen: true,
      mobilnummer_anzeigen: true,
      ansprechpartnerId: null,
      ansprechpartner: null,
      zustandId: null,
      zustand: null,
      herstellerId: null,
      hersteller: null,
      lagerId: null,
      lager: null,
      //lieferartId: null,
      //lieferart: null,
      mengeneinheitId: null,
      mengeneinheit: null,
      pauschal: false,
      verfuegbarkeitId: null,
      verfuegbarkeit: null,
      verkaufseinheitId: null,
      verkaufseinheit: null,
      verpackungId: null,
      verpackung: null,
      artikelbilder: [], // Parent+Child
      artikelLieferarten: [], // Parent+Child
      //suchanfrage: false,
      typ: "A",
      url: null,
      summary: '',
      unternehmenId: null,
      unternehmen: null,
      oberflaecheId: null,
      oberflaeche: null,
      kanteId: null,
      kante: null,
      farbeId: null,
      farbe: null,
      laenge: null,
      breite: null,
      hoehe: null,
      dicke: null
    };
  }


}
