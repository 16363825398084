<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!---->
<div class="btnArtikelToTop" (click)="scrollToTop()" [ngStyle]="{'display': vorschauDisplay == true ? 'none' : 'null'}">
    <i class="far fa-arrow-up" style="color: #ffffff;" id="neu" type="button"></i>
    </div>
<div class="produktDetailContainer" style="margin-right: 2%; margin-left:12.5%;" [ngStyle]="{'display': vorschauDisplay == true ? 'none' : 'unset'}"><br>

    <div class="p-grid">
        <div class="p-col-7 p-md-7 p-lg-7">
            <div class="box">
                <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
                    <div class="InputFieldContainer">
                        <div id="InputField">
                            <p-fieldset id="fieldSetArtikelInfos" legend="Artikeldetails">
                                <div [formGroup]="CRUDForm" class="p-field p-col"><!--US 23997 [formGroup]="CRUDForm" auf DIV-Ebene, nicht beim <input>, sonst bekommt das Feld immer class ng-invalid, auch wenn es valid ist !!!-->
                                    <label for="bezeichnung" class="control-label" [title]="CRUDItem != null && CRUDItem.typ == 'W' ? 'Bezeichnung wird nicht angezeigt, wird aber bei der Suche berücksichtigt' : null"><b
                                            id="testArtikel">{{CRUDItem != null && CRUDItem.typ == 'S' ? 'Suche nach (Artikelbezeichnung)' : (CRUDItem != null && CRUDItem.typ == 'W' ? 'Bezeichnung der Werbung' : 'Artikelbezeichnung')|translate:true}}: *</b>
                                            &nbsp;&nbsp;
                                            <i *ngIf="CRUDItem != null && CRUDItem.typ == 'W'" class="far fa-question-circle" (click)="artikelBezeichnungHelpClicked()"></i>
                                    </label>
                                    <br>
                                    <input id="bezeichnung" (ngSubmit)="onSubmit(CRUDForm.value)" name="bezeichnung" style="width: 100%; padding: none;" type="text" [required]="true" formControlName="bezeichnung" pInputText
                                    [title]="CRUDItem != null && CRUDItem.typ == 'W' ? 'Bezeichnung wird nicht angezeigt, wird aber bei der Suche berücksichtigt' : null"
                                    ><!--US 23997 [formGroup]="CRUDForm" auf DIV-Ebene, nicht beim <input>, sonst bekommt das Feld immer class ng-invalid, auch wenn es valid ist !!!-->
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 16px; color: #ffffff;"></i>
                                        {{displayMessage.bezeichnung}}
                                    </div>
                                </div>
                                <div id="dummy" style="visibility: hidden">
                                    <!-- nur , um die OriginalBreite zu erhalten -->
                                    <b>dummy:</b><label for="dummy" id="dummyLabel"></label><br>
                                    <p-autoComplete formControlName="dummy" [suggestions]="null"
                                        [placeholder]="'Auswählen'" [size]="70" [minLength]="1" [dropdown]="true">
                                        <ng-template let-brand pTemplate="item">
                                            <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5">
                                                <img src="assets/showcase/images/demo/car/{{brand}}.png"
                                                    style="width:12px;display:inline-block;margin:5px 0 2px 5px" />
                                                <div style="font-size:14px;float:right;margin:10px 10px 0 0">{{brand}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                                <div id="hersteller">
                                    <label for="hersteller" id="herstellerLabel"><b>{{'Hersteller'|translate:true}}:
                                            </b></label><br>

                                    <!--<p-autoComplete [(ngModel)]="brand" [suggestions]="filteredBrands" [placeholder]="'Auswählen'"
                                        (completeMethod)="filterBrands($event)" [size]="70" [minLength]="1" [dropdown]="true">
                                        <ng-template let-brand pTemplate="item">
                                            <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5">
                                                <img src="assets/showcase/images/demo/car/{{brand}}.png"
                                                    style="width:12px;display:inline-block;margin:5px 0 2px 5px" />
                                                <div style="font-size:14px;float:right;margin:10px 10px 0 0">{{brand}}</div>
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>-->
                                    <crud-basic-autocomplete [formGroup]="CRUDForm" style="width: 100%;"
                                        (ngSubmit)="onSubmit(CRUDForm.value)" formControlName="hersteller"
                                        id="hersteller" [required]="false" [type]="'hersteller'"
                                        [allowUnvalidatedInput]="true">
                                    </crud-basic-autocomplete>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 16px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.hersteller}}
                                    </div>
                                    <!-- <p-message severity="error" text="Hersteller is required" *ngIf="!CRUDForm.controls['hersteller'].valid && CRUDForm.controls['hersteller'].dirty"></p-message> -->
                                </div>
                                <div id="artikelnummerId" [formGroup]="CRUDForm" class="p-field p-col"><!--US 23997 [formGroup]="CRUDForm" hier auf DIV-Ebene, nicht beim <input>, sonst bekommt das Feld immer class ng-invalid, auch wenn es valid ist !!!-->
                                    <label for="Artikelnummer"><b>{{'Artikelnummer'|translate:true}}:
                                            {{ CRUDItem != null && CRUDItem.typ == 'A' ? '*' : ''}}</b></label><br>
                                    <input [required]="true" formControlName="artikelnummer" id="artikelnummer" name="artikelnummer" style="width: 100%;" type="text" [required]="true" type="text" pInputText><!--US 23997 [formGroup]="CRUDForm" hier auf DIV-Ebene, nicht beim <input>, sonst bekommt das Feld immer class ng-invalid, auch wenn es valid ist !!!-->
                                    <br>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 15px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.artikelnummer}}
                                    </div>
                                    <!-- <p-message severity="error" text="Artikelnummer is required" *ngIf="!CRUDForm.controls['artikelnummer'].valid && CRUDForm.controls['artikelnummer'].dirty"></p-message> -->
                                </div>
                                <div class="p-field p-col">
                                    <div id="artikelgruppe"><b>Artikelgruppe: {{ CRUDItem != null && CRUDItem.typ == 'A' ? '*' : ''}}</b>
                                        <!--<button type="button" class="kontaktAnzeigenBtn"
                                            (click)="artikelGruppeShowAuswahlPopUp()" pButton icon="pi pi-external-link"></button>--><br><label
                                            for="artikelgruppe" id="artikelgruppeLabel"></label>
                                        <!--<input id="artikelgruppeId" type="text" pInputText>-->
                                        <div style="border-bottom: 1px solid #aaa !important; display: inline;">
                                            <!--border unten, damit es aussieht, wie ein Eingabefeld-->
                                            <p-breadcrumb [styleClass]="'artikelGruppeBreadcrumb'"
                                                [model]="artikelGruppeBreadCrumbItems"
                                                [home]="artikelGruppeBreadCrumbHome" (onItemClick)="breadBrumbClicked($event)">
                                            </p-breadcrumb>
                                            <div class="artikelGruppeBreadcrumbButton">
                                                <button type="button" class="kontaktAnzeigenBtn" style="bottom: 11px;"
                                                    (click)="artikelGruppeShowAuswahlPopUp()" pButton
                                                    icon="pi pi-external-link"></button>
                                                <!--<span aria-hidden="true" class="ui-button-icon-left ui-clickable pi pi-caret-down"></span>-->
                                            </div>
                                        </div>
                                        <!--<i #eraseButton class="fas fa-times-circle buttonInInputField" (click)="artikelGruppeClear()" [style.display]="artikelGruppeSelected ? 'unset' : 'none'"></i>-->
                                    </div>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 16px; color: #ffffff;"></i>
                                        {{displayMessageForm.Artikelgruppe_ist_erforderlich}}
                                    </div>
                                </div>




                                <div id="laengeId" [formGroup]="CRUDForm" class="p-field p-col" style="margin-top: 0.9em;"  [style.display]="CRUDItem == null || CRUDItem.typ == 'W' ? 'none' : 'inherit'">
                                    <label for="Laenge"><b>{{'Länge'|translate:true}}:</b></label><br>
                                    <div class="box" style="display: flex;">
                                        <div style="width: 100%;">
                                            <crud-basic-input id="laenge" formControlName="laenge"
                                            [ngStyle]="{'width':'100%'}" [type]="'numeric'" [decimals]="1"
                                            [quantityUnit]="'cm'" [required]="false"></crud-basic-input>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 15px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.laenge}}
                                    </div>
                                </div>

                                <div id="breiteId" [formGroup]="CRUDForm" class="p-field p-col"  [style.display]="CRUDItem == null || CRUDItem.typ == 'W' ? 'none' : 'inherit'">
                                    <label for="Laenge"><b>{{'Breite'|translate:true}}:</b></label><br>
                                    <div class="box" style="display: flex;">
                                        <div style="width: 100%;">
                                            <crud-basic-input id="breite" formControlName="breite"
                                            [ngStyle]="{'width':'100%'}" [type]="'numeric'" [decimals]="1"
                                            [quantityUnit]="'cm'" [required]="false"></crud-basic-input>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 15px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.breite}}
                                    </div>
                                </div>

                                <div id="hoeheid" [formGroup]="CRUDForm" class="p-field p-col" [style.display]="CRUDItem == null || CRUDItem.typ == 'W' ? 'none' : 'inherit'">
                                    <label for="Hoehe"><b>{{'Höhe'|translate:true}}:</b></label><br>
                                    <div class="box" style="display: flex;">
                                        <div style="width: 100%;">
                                            <crud-basic-input id="hoehe" formControlName="hoehe"
                                            [ngStyle]="{'width':'100%'}" [type]="'numeric'" [decimals]="1"
                                            [quantityUnit]="'cm'" [required]="false"></crud-basic-input>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 15px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.hoehe}}
                                    </div>
                                </div>

                                <div id="dickeId" [formGroup]="CRUDForm" class="p-field p-col" [style.display]="CRUDItem == null || CRUDItem.typ == 'W' ? 'none' : 'inherit'">
                                    <label for="Dicke"><b>{{'Dicke'|translate:true}}:</b></label><br>
                                    <div class="box" style="display: flex;">
                                        <div style="width: 100%;">
                                            <crud-basic-input id="dicke" formControlName="dicke"
                                            [ngStyle]="{'width':'100%'}" [type]="'numeric'" [decimals]="1"
                                            [quantityUnit]="'cm'" [required]="false"></crud-basic-input>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 15px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.dicke}}
                                    </div>
                                </div>


                                <div id="oberflaeche" style="margin-top: 0.0em;" [style.display]="CRUDItem == null || CRUDItem.typ == 'W' ? 'none' : 'inherit'">
                                    <label for="farbe" id="oberflaecheLabel"><b>{{'Oberfläche'|translate:true}}:</b></label><br>
                                    <crud-basic-autocomplete [formGroup]="CRUDForm" style="width: 100%;"
                                        (ngSubmit)="onSubmit(CRUDForm.value)" formControlName="oberflaeche"
                                        id="oberflaeche" [required]="false" [type]="'oberflaeche'"
                                        [allowUnvalidatedInput]="true">
                                    </crud-basic-autocomplete>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 16px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.oberflaeche}}
                                    </div>
                                </div>

                                <div id="farbe" style="margin-top: 0.9em;" [style.display]="CRUDItem == null || CRUDItem.typ == 'W' ? 'none' : 'inherit'">
                                    <label for="farbe" id="farbeLabel"><b>{{'Farbe'|translate:true}}:</b></label><br>
                                    <crud-basic-autocomplete [formGroup]="CRUDForm" style="width: 100%;"
                                        (ngSubmit)="onSubmit(CRUDForm.value)" formControlName="farbe"
                                        id="farbe" [required]="false" [type]="'farbe'"
                                        [allowUnvalidatedInput]="true">
                                    </crud-basic-autocomplete>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 16px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.farbe}}
                                    </div>
                                </div>

                                <div id="kante" style="margin-top: 0.9em;" [style.display]="CRUDItem == null || CRUDItem.typ == 'W' ? 'none' : 'inherit'">
                                    <label for="kante" id="kanteLabel"><b>{{'Kante'|translate:true}}:</b></label><br>
                                    <crud-basic-autocomplete [formGroup]="CRUDForm" style="width: 100%;"
                                        (ngSubmit)="onSubmit(CRUDForm.value)" formControlName="kante"
                                        id="kante" [required]="false" [type]="'kante'"
                                        [allowUnvalidatedInput]="true">
                                    </crud-basic-autocomplete>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 16px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.kante}}
                                    </div>
                                </div>

                                <div class="p-field p-col"><br>
                                    <label for="artikelbeschreibung"
                                        id="artikelbeschreibungTxt"><b>{{ CRUDItem != null && CRUDItem.typ != 'S' ? (CRUDItem != null && CRUDItem.typ != 'W' ? 'Artikelbeschreibung' : 'Werbetext') : 'Beschreibung des gesuchten Artikels'}}:</b></label><br><br>
                                    <p-editor #editor class="headerFormat" formControlName="beschreibung">
                                        <!--[formats]="quillFormats"    quillFormats: string[] = ['bold', 'italic'];  funkt. nicht -->
                                        <!--https://quilljs.com/docs/formats/-->
                                        <p-header>
                                            <span class="ql-formats">
                                                <select #editor_font class="ql-font"></select>
                                                <select #editor_size class="ql-size"></select>

                                                <button class="ql-bold" aria-label="fett"></button>
                                                <button class="ql-italic" aria-label="kursiv"></button>
                                                <button class="ql-underline" aria-label="unterstrichen"></button>
                                                <button class="ql-strike" aria-label="durchgestrichen"></button>

                                                <select #editor_align class="ql-align"></select>

                                                <select #editor_color class="ql-color"></select>
                                                <select #editor_background class="ql-background"></select>
                                            </span>
                                        </p-header>
                                    </p-editor>
                                </div>

                                <br *ngIf="CRUDItem != null && CRUDItem.typ == 'W'">
                                <div *ngIf="CRUDItem != null && CRUDItem.typ == 'W'" [formGroup]="CRUDForm" class="p-field p-col">
                                    <label for="URL"><b>{{'Link'|translate:true}}:
                                            {{ CRUDItem != null && CRUDItem.typ == 'W' ? '*' : ''}}</b></label><br>
                                    <input [required]="true" formControlName="url" id="url" name="artikelnummer" style="width: 100%;" type="text" [required]="true" type="text" pInputText>
                                    <br>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 15px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.url}}
                                    </div>
                                </div>
                            </p-fieldset>
                            <div *ngIf="CRUDItem != null && CRUDItem.typ == 'A'"><br><br></div>
                            <p-fieldset class="fieldSetArtikelStandort" legend="Artikelstandort"
                            >
                              
                                <div id="lagerId" class="p-field p-col" style="display: flex; float: right; margin-bottom: 2em;">
                                    <span>neue Baustelle</span>&nbsp;
                                    <p-inputSwitch value="baustelleAnzeigen" formControlName="_baustelleAnzeigen" (onChange)="baustelleToggleAnzeigen()"></p-inputSwitch>
                                </div>
    
                                <div id="lagerId" class="p-field p-col" [ngStyle]="{'display':CRUDForm.value._baustelleAnzeigen == null || CRUDForm.value._baustelleAnzeigen == false ? 'unset' : 'none'}">
                                    <label for="lager"><b>{{'Lager'|translate:true}}{{ CRUDItem != null && CRUDItem.typ == 'A' ? ' *' : ''}}:</b></label><br>
                                    <crud-basic-autocomplete [formGroup]="CRUDForm" formControlName="lager"
                                        style="width: 100%;" id="lagerTest" [type]="'lager'"
                                        [allowUnvalidatedInput]="false" [required]="false">
                                    </crud-basic-autocomplete>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 16px; color: #ffffff; float: left;"></i>
                                        {{displayMessageForm.Lager_ist_erforderlich}}
                                    </div>
                                </div>

                                <div [ngStyle]="{'display': CRUDForm.value._baustelleAnzeigen == true ? 'unset' : 'none'}">
                                    <div id="baustelleId" class="p-field p-col">
                                        <label for="ort" id="baustelle"><b>Baustelle: *</b></label><br>
                                        <input [disabled]="false" id="baustelle" type="text" 
                                        formControlName="baustelle" [formGroup]="CRUDForm" name="baustelle" 
                                        style="width: 100%;" pInputText>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 15px; color: #ffffff; float: left;"></i>
                                            {{displayMessageForm.Baustelle_ist_erforderlich}}
                                    </div>
                                    </div>

                                    <div id="strasseId" class="p-field p-col">
                                        <label for="ort" id="strasse"><b>Strasse: *</b></label><br>
                                        <input [disabled]="false" id="strasse" type="text" 
                                        formControlName="strasse" [formGroup]="CRUDForm" name="strasse" 
                                        style="width: 100%;" pInputText>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 15px; color: #ffffff; float: left;"></i>
                                            {{displayMessageForm.Strasse_ist_erforderlich}}
                                    </div>
                                    </div>

                                    <div>
                                                <label for="geodaten"><b>{{'Postleitzahl Ort'|translate:true}}:
                                                        *</b></label><br>
            
                                                <crud-basic-autocomplete #geodaten formControlName="geodaten" [type]="'geodaten_postleitzahl'" 
                                                    [required]="false"></crud-basic-autocomplete>
                                                <div class="errorMessage">
                                                    <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                                        style="font-size: 16px; color: #ffffff; float: left;"></i>
                                                        {{displayMessageForm.Geodaten_sind_erforderlich}}
                                                </div>
                                    </div>

                                    <!--<div class="p-grid">
                                        <div class="p-col-3 p-md-3 p-lg-3">-->
                                            <div class="box" style="margin-top: 1em; display: none;">
                                                <div style="width:100%">
                                                    <label id="baustelleSpeichernBis"
                                                        for="baustelleSpeichernBis"><b>Baustelle speichern bis:</b></label>
                                                    <p-calendar [disabled]="false" [inputStyle]="{'width':'100%'}"
                                                        [style]="{'width':'100%'}" formControlName="baustelle_Gueltigkeit" id="kalenderBaustelleLabel" [locale]="DE"
                                                        dateFormat="dd.mm.yy">
                                                    </p-calendar>
                                                </div>
                                                <div class="errorMessage">
                                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                                            style="font-size: 15px; color: #ffffff; float: left;"></i>
                                                            {{displayMessageForm.Baustelle_Gueltigkeit_ist_erforderlich}}
                                                    </div>
                                            </div>
                                        <!--</div>
                                    </div>-->
                                </div>
                            </p-fieldset>
                            <div *ngIf="CRUDItem != null && CRUDItem.typ == 'A'"><br><br></div>
                            <p-fieldset class="fieldSetArtikelVerkauf" legend="Verkauf"
                            [style.display]="CRUDItem == null || CRUDItem.typ != 'A' ? 'none' : 'unset'">
                                <div class="p-grid">
                                    <div class="p-col-3 p-md-3 p-lg-3">
                                        <div class="box" style="display: flex;">
                                            <div style="width: 100%;">
                                                <label for="menge"><b>Menge:</b></label>
                                                <input id="menge" formControlName="menge"
                                                    style="width:100%; margin-bottom: 10px;" min="0,00" max="100000,00"
                                                    step="0,01" type="number" pInputText>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-9 p-md-9 p-lg-9">
                                        <div style="margin-left: 51px;">
                                            <label for="mengeneinheit"><b>Mengeneinheit:</b></label>
                                            <crud-basic-autocomplete formControlName="mengeneinheit"
                                                [inputStyle]="{'width':'100%'}" [style]="{'width':'100%'}"
                                                [type]="'mengeneinheit'" [required]="true">
                                            </crud-basic-autocomplete>
                                            <div class="errorMessage">
                                                <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                                    style="font-size: 16px; color: #ffffff; float: left;"></i>
                                                {{displayMessage.mengeneinheit}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-grid">
                                    <div class="p-col-3 p-md-3 p-lg-3">
                                        <label for="preisvorstellung"><b>Preisvorstellung
                                                (netto):</b></label>
                                        <div class="box" style="display: flex;">
                                            <div style="width: 100%;">
                                                <!--<input formControlName="preis" id="preisvorstellung" style="width: 92%;"
                                                    (focus)="formatInputFloat('preisvorstellung')"
                                                    (blur)="formatInputCurrency('preisvorstellung')" pInputText>
                                                    -->
                                                <crud-basic-input id="preisvorstellung" formControlName="preis"
                                                    [ngStyle]="{'width':'100%'}" [type]="'numeric'" [decimals]="2"
                                                    [quantityUnit]="'€'" [required]="false"></crud-basic-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-9 p-md-9 p-lg-9">
                                        <div class="box" style="display: flex;">
                                        <div style="width: 100%; margin-top: 17px; margin-left: 50px;">
                                        <p-radioButton name="group1" class="radio" value="mengeneinheit" [(ngModel)]="radioCurrentValue" [ngModelOptions]="{standalone: true}" [label]="radioBtnMengeneinheitLabel" inputId="opt1" style="padding-right: 10px;"></p-radioButton>
                                        <p-radioButton name="group1" class="radio" value="pauschal" [(ngModel)]="radioCurrentValue" [ngModelOptions]="{standalone: true}" label="Pauschal"  inputId="opt2"></p-radioButton>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </p-fieldset>
                            <div *ngIf="CRUDItem != null && CRUDItem.typ == 'A'"><br><br></div>
                            <p-fieldset class="fieldSetArtikelAbwicklung" legend="Abwicklung"
                            [style.display]="CRUDItem == null || CRUDItem.typ == 'S' || CRUDItem.typ == 'W' ? 'none' : 'unset'">
                                <!-- <div id="verkaufseinheitId" style="margin-bottom: 15px;"><b>Verkaufseinheit:</b>
                                    <label for="verkaufseinheit" id="verkaufseinheit"></label>
                                    <crud-basic-autocomplete formControlName="verkaufseinheit" [type]="'verkaufseinheit'"
                                    [required]="false" [size]="140" >
                                </crud-basic-autocomplete>
                                <div class="errorMessage">
                                    <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                        style="font-size: 16px; color: #ffffff; float: left;"></i>
                                    {{displayMessage.verkaufseinheit}}
                                </div>
                                </div> -->
                                <div id="artikelzustandDiv" style="font-weight: bold; height: auto;">{{artikelZustandLbl}}
                                    <label for="produktzustand" id="artikelzustand"></label>
                                    <crud-basic-autocomplete formControlName="zustand" [type]="'artikelzustand'"
                                        [required]="true" [size]="140">
                                    </crud-basic-autocomplete>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 16px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.zustand}}
                                    </div>
                                </div>
                                <div id="verpackungDiv"><b>Verpackung:</b>
                                    <label for="verpackung" id="verpackung"></label>
                                        <label for="verpackung" id="verpackung"></label>
                                        <crud-basic-autocomplete formControlName="verpackung" [type]="'verpackung'"
                                        [required]="false" [size]="140" >
                                    </crud-basic-autocomplete>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 16px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.verpackung}}
                                    </div>
                                </div>
                                <div id="lieferartDiv"><b>Lieferart:</b>
                                    <!--<label for="hersteller" id="lieferart"></label>
                                    <crud-basic-autocomplete #lieferart formControlName="lieferart" [type]="'lieferart'"
                                        [required]="false" [inputStyle]="{'width':'100%'}" [size]="140">
                                    </crud-basic-autocomplete>-->

                                            <br><label
                                                for="lieferartDiv" id="lieferartLabel"></label>
                                            <div style="border-bottom: 1px solid #aaa !important; margin-top: 2px;">
                                                <!--border unten, damit es aussieht, wie ein Eingabefeld-->
                                                <div class="lieferartenListe">
                                                    {{artikelLieferartenSelectedAsString}}
                                                </div>

                                                <div class="artikelGruppeBreadcrumbButtonLieferart">
                                                    <button type="button" class="kontaktAnzeigenBtn" style="bottom: 16px "
                                                        (click)="artikelLieferartShowAuswahlPopUp()" pButton
                                                        icon="pi pi-external-link"></button>
                                                    <!--<span aria-hidden="true" class="ui-button-icon-left ui-clickable pi pi-caret-down"></span>-->
                                                </div>
                                            </div>
                                            <div class="errorMessage">
                                                    <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                                        style="font-size: 16px; color: #ffffff;"></i>
                                                    {{displayMessageForm.Lieferart_ist_erforderlich}}
                                                </div>

                                </div>
                                <div class="p-grid">
                                    <div class="p-col-3 p-md-3 p-lg-3"
                                        style="margin-top: 22px; padding: 0px;">
                                        <div class="box">
                                            <div style="width:100%; margin-left: 5px;">
                                                <label id="baustelleSpeichernBis" style="font-weight: bold; height: auto;">{{gueltig}}</label>
                                                <p-calendar id="kalenderLabel" [inputStyle]="{'width':'100%'}"
                                                    [style]="{'width':'100%'}" formControlName="gueltigkeit"
                                                    [locale]="DE" dateFormat="dd.mm.yy">
                                              </p-calendar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-field p-col" id="verladeMoeglichkeitContainer">
                                    <label for="Artikelnummer" id="verladeMöglichkeitTxt" style="font-weight: bold;">{{verlademoeglichkeitLbl}}</label>

                                            <p-inputSwitch id="telefonNummerAnzeigenSwitcher toggleButtonVerlademoeglichkeit" style="margin-left: 10px;"
                                                         formControlName="verlademoeglichkeit" (onChange)="vermerkFunction()"><!--style="margin-left: 17px;"-->
                                                    </p-inputSwitch>
                                </div>
                                <div class="p-field p-col" id="verladeMoeglichkeitContainer">
                                    <label for="Artikelnummer" id="verladeMöglichkeitTxt" style="font-weight: bold;">{{vermerkLbl}}</label>
                                    <input id="menge" style="width: 100%; margin-bottom: 10px;"
                                        [disabled]="disabledVermerk" type="text" pInputText 
                                        formControlName="verlademoeglichkeitVermerk" [formGroup]="CRUDForm" name="verlademoeglichkeitVermerk" >
                                            <div class="errorMessage">
                                                <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                                    style="font-size: 15px; color: #ffffff; float: left;"></i>
                                                {{displayMessage.verlademoeglichkeitVermerk}}
                                            </div>
                                </div>
                            </p-fieldset>
                            <div *ngIf="CRUDItem != null && CRUDItem.typ == 'A'"><br><br></div>
                            <p-fieldset class="fieldSetArtikelAnsprechpartner" legend="Ansprechpartner">
                                <div class="p-field p-col" style="margin-bottom: 10px;">
                                    <label for="lager"><b>{{'Ansprechpartner'|translate:true}}</b></label><br>
                                    <crud-basic-autocomplete [formGroup]="CRUDForm" formControlName="ansprechpartner"
                                        [type]="'ansprechpartner'" [allowUnvalidatedInput]="false" [required]="true"
                                        [inputStyle]="{'width':'100%'}" [size]="140">
                                    </crud-basic-autocomplete>
                                    <div class="errorMessage">
                                        <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                            style="font-size: 16px; color: #ffffff; float: left;"></i>
                                        {{displayMessage.ansprechpartner}}
                                    </div>
                                </div>
                            </p-fieldset><br *ngIf="CRUDItem != null && CRUDItem.typ != 'W'"><br *ngIf="CRUDItem != null && CRUDItem.typ != 'W'">
                            <p-fieldset class="fieldSetArtikelAnsicht" legend="Ansicht">
                                <div class="p-grid">
                                    <div class="p-col-11 p-md-11 p-lg-11">
                                        <div class="box" style="display: flex;">
                                            <div *ngIf="CRUDItem != null && CRUDItem.typ != 'W'" class="inputSwitchContainer" style="width: 100%;">
                                                <div id="inputSwitchOne" style="margin-top: 10px;">
                                                    <span id="emailAdresseAnzeigen" style="font-weight: bold;">{{eMailAdresse}}</span>
                                                    <p-inputSwitch formControlName="email_anzeigen" 
                                                        ></p-inputSwitch><!--style="margin-left: 10px;"-->
                                                </div>
                                                <div id="inputSwitchTwo" style="margin-top: 10px;">
                                                    <span id="telefonNummerAnzeigen" style="font-weight: bold;">{{telNummer}}</span>
                                                    <p-inputSwitch id="telefonNummerAnzeigenSwitcher"
                                                         formControlName="telefon_anzeigen"><!--style="margin-left: 14px;"-->
                                                    </p-inputSwitch>
                                                </div>
                                                <div id="inputSwitchOne" style="margin-top: 10px;">
                                                    <span id="handyNummerAnzeigen" style="font-weight: bold;">{{handyNummer}}</span>
                                                    <p-inputSwitch formControlName="mobilnummer_anzeigen"><!--style="margin-left: 10px;"-->
                                                    </p-inputSwitch>
                                                </div>
                                            </div>
                                            <div class="inputSwitchContainer" style="float: right; width: 100%;">
                                                <div id="inputSwitchThree" style="margin-top: 10px;">
                                                    <span id="ArtikelAktiv" style="font-weight: bold;">{{ CRUDItem != null && CRUDItem.typ != 'S' ? (CRUDItem.typ != 'W' ? 'Artikel aktiv' : 'Werbeanzeige aktiv') : 'Suchanfrage aktiv'}}</span>
                                                    <p-inputSwitch formControlName="aktiv"
                                                        ></p-inputSwitch><!--style="margin-left: 10px;"-->
                                                </div>
                                                <div id="inputSwitchTwo" style="margin-top: 10px;"
                                                [style.display]="CRUDItem == null || CRUDItem.typ == 'S' || CRUDItem.typ == 'W' ? 'none' : ''">
                                                    <span id="lagerBestandAnzeigen" style="font-weight: bold;">{{lagerBestand}}</span>
                                                    <p-inputSwitch id="telefonNummerAnzeigenSwitcher"
                                                         formControlName="lagerbestand"><!--style="margin-left: 17px;"-->
                                                    </p-inputSwitch>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-fieldset>
                        </div>
                        <p-checkbox id="checkboxNutzungsbedingung" [binary]="true" formControlName="_AGBAkzeptiert" (onChange)="nutzungsbedingungChanged()"></p-checkbox><span id="checkboxTxt">
                            <div id="TxtContainerCheckbox">{{agbEins}}<br><b>www.plattgruen.de </b>{{agbZwei}}</div>
                        </span>
                        <div class="errorMessage">
                            <i class="pi pi-exclamation-circle" id="infoBtn" *ngIf="showWarningMsg"
                                style="font-size: 16px; color: #ffffff;"></i>
                            {{displayMessage._AGBAkzeptiert}}
                        </div>
                        <br><br>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <div class="box" style="display: flex;">
                                    <div style="width: 100%">
                                        <button id="abbrechen" pButton type="button" label="Abbrechen" (click)="abbrechBtn()"
                                            class="ui-button-success"></button>&nbsp;
                                        <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                                            (click)="deleteCRUDItem()"></button>
                
                                        <div style="float: right;">
                                            <button id="Vorschau" pButton type="button" label="Vorschau"
                                                class="ui-button-success" style="margin-right: 5px;" (click)="vorschauShow()"></button>
                                            <button styleClass="ui-button-warning" pButton type="submit"
                                                [icon]="'pi pi-check'"
                                                [label]="translate.instant('Speichern',true)"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button *ngIf="debugMode == true" pButton type="button" icon="" class="ui-button-danger" [label]="'demodata'"
                            (click)="fillDemoData()"></button>
                            <button *ngIf="debugMode == true" pButton type="button" (click)="debug(form)" icon="fa-bug"
                                title="debug form"></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="p-col-4 p-md-4 p-lg-4">
            <div id="upload" [style.margin-top]="artikelBilderDisplayGalleryOrUpload=='U' ? '30px' : '19px'" style="display: flex;"
                ><!--[style.display]="CRUDItem == null || CRUDItem.typ == 'S' ? 'none' : 'flex'"-->
                <!-- statt der bisherigen 29px im fileupload -->
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="fileUpload" [style.display]="artikelBilderDisplayGalleryOrUpload=='U' ? 'unset' : 'none'">
                                <p-fileUpload #artikelbilderFileUpload id="dateiHochladen" chooseLabel="Datei auswählen"
                                        maxFileSize="999999999" [showUploadButton]="false" [showCancelButton]="false"
                                        (onSelect)="onNGFileInputSelect($event)" (onRemove)="onNGFileInputRemove($event)"
                                        multiple="multiple" accept="image/*" maxFileSize="99999999">
                                        <ng-template let-file pTemplate="file">
                                            <div class="ui-fileupload-row ng-star-inserted p-grid">
                                                <div class="p-col-3 p-md-3 p-lg-3"><img [src]="file.objectURL" width="50" class="ng-star-inserted" (click)="galleriaShowFullscreen()"></div>
                                                <div class="p-col-6 p-md-6 p-lg-6">{{file.name}}
                                                </div>
                                                <!--<div>
                                                    <span
                                                        *ngIf="file._alreadyUploaded != null && file._alreadyUploaded == false">{{file._percentUploaded}}
                                                        %</span>
                                                </div>-->
                                                <div class="p-col-3 p-md-3 p-lg-3">
                                                    <button type="button" icon="pi pi-times" pbutton="" ng-reflect-icon="pi pi-times"
                                                        class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only"><span
                                                            aria-hidden="true" class="ui-button-icon-left ui-clickable pi pi-times"
                                                            (click)="artikelbilderDeleteFile(file)"></span><span aria-hidden="true"
                                                            class="ui-button-text ui-clickable">ui-btn</span></button>
                                                    &nbsp;
                                                    <p-button *ngIf="file._guid != null && file._guid == artikelBilderTitlePicGuid" icon="pi pi-check" label="Titelbild"></p-button>
                                                    <p-button *ngIf="file._guid == null || file._guid != artikelBilderTitlePicGuid" icon="pi pi-check" class="artikelhoverButton" label="Titelbild" (click)="artikelbilderSetTitlePic(file._guid)"></p-button>
        
                                                    <button type="button" *ngIf="false" icon="pi pi-times" pbutton="" ng-reflect-icon="pi pi-times" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only"><span aria-hidden="true" class="ui-button-icon-left ui-clickable pi pi-debug" (click)="debugFile(file)"></span><span aria-hidden="true" class="ui-button-text ui-clickable">ui-btn</span>debug!</button>
                                                </div>
                                            </div>
                                            <span>
                                                <p-progressBar *ngIf="file._alreadyUploaded != null && file._alreadyUploaded == false && file._percentUploaded>0" [value]="file._percentUploaded"></p-progressBar>
                                            </span>
                                        </ng-template>
                                    </p-fileUpload>
                                </div>

                        <div id="borderx" [style.display]="artikelBilderDisplayGalleryOrUpload=='G' ? 'unset' : 'none'">
                            <p-galleria #artikelBilderGalleria_NOT_USED id="artikelDetailGalleria" [(value)]="artikelBilderGalleryImages" [responsiveOptions]="responsiveOptions"
                                [numVisible]="5">
                                <ng-template pTemplate="item" let-item>
                                    <img [src]="item.previewImageSrc" style="width: 100%; display: block;" />
                                </ng-template>
                                <ng-template id="testThumb" pTemplate="thumbnail" let-item>
                                    <div id="thumbnailContent">
                                        <div id="thumbnail" class="p-grid p-nogutter p-justify-center">
                                            <img [src]="item.thumbnailImageSrc" style="display: block; max-height: 160px; max-width: 160px;"/>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="caption" let-item>
                                    <h3 id="text">{{item.title}}</h3>
                                    <p style="color: #ffffff;">{{item.alt}}</p>
                                </ng-template>
                            </p-galleria>
                        </div>
                    </div>
                    <!--<div class="p-col-1 p-md-1 p-lg-1">
                        <button type="button"
                            *ngIf="artikelBilderGalleryImages != null && this.artikelBilderGalleryImages.length > 0"
                            style="font-size: 2em;"
                            [style.margin-top]="artikelBilderDisplayGalleryOrUpload=='U' ? '-11px' : '0px'"
                            (click)="artikelBilderChangeView()" pButton
                            [icon]="artikelBilderDisplayGalleryOrUpload=='U' ? 'pi pi-th-large' : 'pi pi-cloud-upload'"></button>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
    <div class="ContentContainer">
        <!--Upload-->

        <!--/Upload-->
    </div>
</div>

<!--<button pButton type="button" icon="pi pi-external-link" label="GalleryFullscreen" (click)="galleriaDisplayFullscreen = true"></button>-->
<p-galleria #artikelBilderGalleria id="artikelDetailGalleriaFullscreen" [(visible)]="galleriaDisplayFullscreen" [(value)]="artikelBilderGalleryImages" [responsiveOptions]="responsiveOptions"
[numVisible]="5"
[circular]="true" [fullScreen]="true" [showItemNavigators]="true" [baseZIndex]="100000">
<ng-template pTemplate="item" let-item>
    <img [src]="item.previewImageSrc" style="max-width: 50%; max-height: 50%; display: block;" />
</ng-template>
<ng-template id="testThumbFullscreen" pTemplate="thumbnail" let-item>
    <div id="thumbnailContentFullscreen">
        <div id="thumbnail" class="p-grid p-nogutter p-justify-center">
            <img [src]="item.thumbnailImageSrc" style="display: block; max-height: 160px; max-width: 160px;"/>
        </div>
    </div>
</ng-template>
<ng-template pTemplate="caption" let-item>
    <h3 id="text">{{item.title}}</h3>
    <p style="color: #ffffff;">{{item.alt}}</p>
</ng-template>
</p-galleria>

<!--Vorschau:-->
<app-artikel-detail-ansicht *ngIf="vorschauDisplay==true && CRUDItem!=null && CRUDItem.typ!='W'" [CRUDItemAsParameter]="vorschauCRUDItem"  
(saveClicked)="vorschauSaveClicked()" (zurueckClicked)="vorschauZurueckClicked()"></app-artikel-detail-ansicht><!--[ngStyle]="{'display': vorschauDisplay == false ? 'none' : 'unset'}"-->

<app-werbeanzeige-detail-ansicht *ngIf="vorschauDisplay==true && CRUDItem!=null && CRUDItem.typ=='W'" [CRUDItemAsParameter]="vorschauCRUDItem"  
(saveClicked)="vorschauSaveClicked()" (zurueckClicked)="vorschauZurueckClicked()"></app-werbeanzeige-detail-ansicht><!--[ngStyle]="{'display': vorschauDisplay == false ? 'none' : 'unset'}"-->

<p-dialog header="Artikelgruppe" id="artikelGruppe" [(visible)]="artikelGruppeDisplayAuswahlPopUp" [modal]="true"
    [style]="{width: '30vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false" styleClass="editDialog">
    
    <div #divCardToolbar class="card">
        <span>
          <button style="width: auto;" pButton 
          (click)="artikelGruppeClear()" [icon]="'fas fa-times-circle'" [label]="translate.instant('Zurücksetzen',true)"></button>
        </span>
        <span class="floatRight">
          <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
            (click)="artikelGruppeCloseDialog()"></button>
        </span>
    </div>

    <p-tree id="artikelArtikelgruppenTree" [style]="{'width': '100%'}" [value]="artikelGruppeTreeNodes" [filter]="true"
        selectionMode="single" [propagateSelectionUp]="false" [propagateSelectionDown]="false"
        (onNodeSelect)="artikelGruppeOnSelected($event, this)"></p-tree>
</p-dialog>
<p-dialog header="Lieferart" id="Lieferarten" [(visible)]="artikelLieferartenDisplayAuswahlPopUp" [modal]="true"
    [style]="{width: '30vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
            <p-table [value]="artikelLieferartenItems" [(selection)]="artikelLieferartenSelected">
                    <ng-template pTemplate="header">
                        <!--<tr>
                            <th>
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th></th>
                        </tr>-->
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                            <td>
                                <!--<p-checkbox binary="true" value="rowData['_selected']" (onChange)="onLieferartenChangeSelection($event)"></p-checkbox>-->
                                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                            </td>
                            <td>{{rowData.summary}}</td>
                        </tr>
                    </ng-template>
                </p-table>
    <br>
    <div style="float: right;">
        <button styleClass="ui-button-warning" pButton type="button"
        [icon]="'pi pi-check'"
        [label]="translate.instant('OK',true)" (click)="artikelLieferartenOnSelected()"></button>
    </div>

</p-dialog>

<p-dialog header="Bezeichnung der Werbung" [(visible)]="artikelBezeichnungShowHelpDialog" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <p class="p-m-0">
        Die Bezeichnung der Werbung wird nicht angezeigt, wird aber bei der Suche berücksichtigt.
    </p>
        <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="artikelBezeichnungShowHelpDialog=false" label="OK" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog>