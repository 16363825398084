<!--responseOfAuthTestController: {{getResponseOfAuthTestController()}}<br>-->
<!--userReturnedFromAPI: {{userReturnedFromAPI}}<br>-->
Benutzer: {{app != null && app.benutzer != null ? app.benutzer.nachname : "..."}}<br><!--nicht angemeldet-->
<p *ngIf="authService.isLoggedIn() == true">Sie sind eingeloggt!</p>
<p *ngIf="authService.isLoggedIn() == false">Sie sind NICHT eingeloggt!</p>
<!--<br>
<button styleClass="ui-button-warning" pButton type="button"
                (click)="checkAuthTestController()">prüfe Authentifizierung</button>
                <br><br><br>


<button styleClass="ui-button-warning" pButton type="button"
        [icon]="'pi pi-check'"
        [label]="'logout'" (click)="logout()"></button>-->

<app-dashboard></app-dashboard>