<div class="login-body">
    <div class="card login-panel ui-fluid">
        <div class="ui-g">
            <div class="ui-g-12">
                <img src="assets/layout/images/logo-ultima.svg">
            </div>
            <div class="ui-g-12">
                <span class="md-inputfield">
                    <input type="text" pInputText>
                    <label>Username</label>
                </span>
            </div>
            <div class="ui-g-12">
                <span class="md-inputfield">
                    <input type="text" pInputText>
                    <label>Password</label>
                </span>
            </div>
            <div class="ui-g-12">
                <button pButton type="button" label="Sign In" style="width: 100%" icon="ui-icon-person"></button>
                <button pButton type="button" label="Forgot Password" style="width: 100%" class="pink-btn"
                        icon="ui-icon-help"></button>
            </div>
        </div>
    </div>
</div>
