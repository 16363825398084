import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, Route } from '@angular/router';
import { AuthService } from './auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedOnlyRouteGuard implements CanActivate, CanLoad {

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          // AM Modi: wenn aufgrund Rekursion nicht weiter ausgegeben wird, dann wenigstens ein Hinweis, auf
          //          was für ein Objekttyp da kommt
          let objType: string = "???";
          try {
            objType = value.constructor.name;
          }
          catch (e) { }
          return '(recursive pointer) ' + objType;
        }
        seen.add(value);
      }
      return value;
    };
  };

  constructor(private authService: AuthService, private router: Router, private deviceService:DeviceDetectorService) { }

  canActivate(route/*: Route*/) {
    //console.log("AuthorizedOnlyRouteGuard.canActivate() route:", /*route.routeConfig.path*/JSON.stringify(route, this.getCircularReplacer(), 2)); // ,2 = lesbare Formatierung
    return this.canLoad(route);
  }

  canLoad(route/*: Route*/) {
    //console.log("AuthorizedOnlyRouteGuard.canLoad() route:", /*route.routeConfig.path*/JSON.stringify(route, this.getCircularReplacer(), 2)); // ,2 = lesbare Formatierung
    if (!this.authService.isLoggedIn()) {
      let beginOfRelativePath = window.location.href.indexOf("#");
      let returnUrl = window.location.href.substring(beginOfRelativePath + ("#/").length);
      // warum auch immer kommt er bei Einstieg über   http://localhost:4600/#/artikel-ansicht/2   2mal hier rein! (Test aus Inaktiv-Mail in Chrome, wo noch nicht angemeldet), daher
      // wenn bereits ein "login?returnUrl=" enthalten, das rausscheiden
      if(returnUrl.indexOf("returnUrl=login%3FreturnUrl%3D")) {
        //console.log("AuthorizedOnlyRouteGuard.canLoad() cutting/replacing returnUrl from: ", returnUrl);
        returnUrl = returnUrl.replace("login?returnUrl=", "");
        returnUrl = returnUrl.replace("%2F", "/");
        //console.log("AuthorizedOnlyRouteGuard.canLoad() cutted/replaced returnUrl to   : ", returnUrl);
      }
debugger;

      // nicht zwangsläufig auf /login routen! - wenn mobile, dann nicht!
      let deviceInfo = this.deviceService.getDeviceInfo();
      let isMobile = this.deviceService.isMobile();
      let isTablet = this.deviceService.isTablet();
      let isDesktopDevice = this.deviceService.isDesktop();
      //console.log("AuthorizedOnlyRouteGuard.ngOnInit() deviceInfo:", this.deviceInfo);
      //console.log("AuthorizedOnlyRouteGuard.ngOnInit() isMobile:", isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
      //console.log("AuthorizedOnlyRouteGuard.ngOnInit() isTablet:", isTablet);  // returns if the device us a tablet (iPad etc)
      //console.log("AuthorizedOnlyRouteGuard.ngOnInit() isDesktopDevice:", isDesktopDevice); // returns if the app is running on a Desktop browser.

      if((isMobile==true || isTablet==true) && window.navigator.userAgent.indexOf('PlattGruenApp_IOS') < 0 && window.navigator.userAgent.indexOf('PlattGruenApp_Android') < 0) {
        this.router.navigate(['/getmobileapp']);
      }
      else {
        this.router.navigate(['/login'], {queryParams: { returnUrl: returnUrl }} );
      }
      
    }
    return this.authService.isLoggedIn();
  }
}
