import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'; // DateTimeOffset-Fix


@Pipe({
  name: 'datumOhneZeit'
})
export class DatumOhneZeitPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    //console.log("Pipe_DatumOhneZeit input="+value);
    let result = null;
    if(value!=null) {
      result = moment.utc(value).format('DD.MM.YYYY');
    }
    //console.log("Pipe_DatumOhneZeit input="+value+" output="+result);
    return result;
  }

}
