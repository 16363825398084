import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { MenuItem, TreeNode, MessageService } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
import { AutoComplete, FileUpload, Galleria } from 'primeng/primeng';
import * as cloneDeep from 'lodash/cloneDeep';

import { LocationStrategy } from '@angular/common';

import { IArtikel } from '../_interfaces/artikel';
import { ArtikelService } from '../_services/artikel.service';
import { ArtikelDetailGuard } from './artikel-detail.guard';

// Lieferat(en)
import { ILieferart } from '../_interfaces/lieferart';
import { LieferartService } from '../_services/lieferart.service';
import { IArtikelLieferart } from '../_interfaces/artikel-lieferart';
import { ArtikelLieferartService } from '../_services/artikel-lieferart.service';

// Artikelgruppe
import { IArtikelgruppe } from '../_interfaces/artikelgruppe';
import { ArtikelgruppeService } from '../_services/artikelgruppe.service';

// Artikelbild
import { IArtikelbild } from '../_interfaces/artikelbild';
import { ArtikelbildService } from '../_services/artikelbild.service';

// upload
import { Image } from '../demo/domain/image';

// Galleria
import { PhotoService } from '../demo/service/photoservice.service';
import { GalleriaFixer } from '../_helpers/galleria-fixer';

// ...
import { MessageWrapperService } from '../_services/message-wrapper.service';

import { empty, from } from 'rxjs';

// Euro
import { CurrencyPipe } from '@angular/common';
import { CustomCurrencyPipe } from '../_services/custom.currencypipe';

// Moment für zB Gültigkeit
import * as moment from 'moment';
import { LagerService } from '../_services/lager.service';
//Breadcrumb
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { HerstellerService } from '../_services/hersteller.service';
import { ArtikelGruppeFindTreeNode } from '../_helpers/artikelgruppe-find-treenode';

declare var jquery: any;
declare var $: any;


//import { ArtikelDetailComponent_Template } from './artikel-detail.component.include_template';

@Component({
  selector: 'app-artikel-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${ArtikelDetailComponent_Template}`,
  templateUrl: './artikel-detail.component.html',
  //providers: [MessageService, MessageWrapperService],
  //styles: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: {
    '(window:keydown)': 'hotkeys($event)',
    '(window:resize)': 'alignGalleriaAtBottom()'
  }
})
export class ArtikelDetailComponent extends CRUDBasicDetailComponent implements OnInit {
  @ViewChild('galleria') public galleria: Galleria;
  @ViewChild('editor') public editor: any;
  @ViewChild('editor_font') public editor_font: any;
  @ViewChild('editor_size') public editor_size: any;
  @ViewChild('editor_color') public editor_color: any;
  @ViewChild('editor_background') public editor_background: any;

  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Artikel";
  CRUDPageTitleNeu: string = this.translate.instant("Neuer Artikel", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Artikel bearbeiten", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Artikel";
  CRUDItemBezeichnungPluralCapitalized: string = "Artikel";
  CRUDItemBezeichnungSingular: string = "artikel";
  CRUDItemBezeichnungPlural: string = "artikel";
  CRUDItemRouteSingular: string = "artikel";
  CRUDItemRoutePlural: string = "artikel-uebersicht";
  CRUDItemHelpTopic: string = "Artikel";
  CRUDConfirmMessageDelete: string = "Wollen Sie diesen Artikel wirklich löschen?"; // Alternativen Text zum Standard 

  //CRUDMethodGetAfterViewInit: boolean = true; // get"CrudItem"() nicht schon im ngOnInit machen, sondern erst im ngAfterViewInit!

  //debugMode: boolean = true;

  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START

  urlParmOverviewMode: string; // wenn man von artikel-uebersicht?overviewmode=myItems kommt, dann auch wieder dahin zurück!
  urlParmItemNr: string; // bei Neuanlage aus Dashboard
  urlParmManufacturer: string; // bei Neuanlage aus Dashboard
  urlParmLabel: string; // bei Neuanlage aus Dashboard

  // Lieferart(en)
  artikelLieferartenItems: IArtikelLieferart[];
  artikelLieferartenSelected: IArtikelLieferart[];
  artikelLieferartenSelectedAsString: string = "";
  artikelLieferartenDisplayAuswahlPopUp: boolean;

  // Artikelgruppe
  artikelGruppeItems: IArtikelgruppe[];
  artikelGruppeTreeNodes: TreeNode[];
  artikelGruppeSelected: TreeNode;
  artikelGruppeDisplayAuswahlPopUp: boolean;
  artikelGruppeBreadCrumbItems: MenuItem[];
  artikelGruppeBreadCrumbHome = { icon: 'pi pi-home'/*, routerLink: '/'*/ };
  artikelGruppeFindTreeNode: ArtikelGruppeFindTreeNode = new ArtikelGruppeFindTreeNode();

  // QuillEditor / Artikelbeschreibung
  quillGeneratedHTMLElementForFont = null;
  quillGeneratedHTMLElementForSize = null;
  quillGeneratedHTMLElementForColor = null;
  quillGeneratedHTMLElementForBackground = null;

  // Artikelbilder
  // WICHTIG:
  // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
  @ViewChild('artikelbilderFileUpload') public artikelbilderFileUpload: FileUpload;
  @ViewChild('artikelBilderGalleria') public artikelBilderGalleria: Galleria;
  //artikelbilderFileUpload.files : File[] = [];
  artikelBilderGalleryImages: Image[] = null;
  artikelBilderDisplayGalleryOrUpload: string = "U";
  artikelBilderTitlePicGuid: string = "00000000"; // guid des Titelbildes
  artikelBilderAlreadySwitchedToGallery: boolean = false; // schon einmal autom. auf Gallery geswitched (das machen wir nur 1mal, danach soll der User manuell switchen)
  artikelBildAktuellUploadingFile: any = null;

  // Vorschau
  vorschauDisplay: boolean = false;
  vorschauCRUDItem: any;

  // Artikelbezeichnung
  artikelBezeichnungShowHelpDialog: boolean = false;

  // ???
  userform: FormGroup;
  submitted: boolean;
  genders: SelectItem[];
  description: string;
  showWarningMsg: boolean = false;
  // showVermerk: boolean = true;
  disabledVermerk: boolean = true;
  // Hersteller
  //hersteller: IHersteller = null;

  // Lager
  //lager: ILager = null;

  // Mengeneinheit
  //  mengeneinheit: IMengeneinheit = null;

  // Preisvorstellung
  preisvorstellungPro: any;
  preisvorstellungProItems: SelectItem[] = [
    { label: 'Pro Stück', value: 'S' },
    { label: 'Pauschal', value: 'P' },
  ];
  // Artikelzustand
  //artikelzustand: IArtikelzustand = null;


  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  items: MenuItem[];
  //Galleria endet hier

  //Euro 
  emitEvent: boolean;

  // Labels 
  gueltig: string = "Gültig bis:";
  verlademoeglichkeitLbl: string = "Verlademöglichkeit vorhanden:";
  vermerkLbl: string = "Vermerk Verlademöglichkeit:";
  eMailAdresse: string = "E-Mail Adresse anzeigen";
  telNummer: string = "Telefonnummer anzeigen";
  handyNummer: string = "Mobilnummer anzeigen";
  lagerBestand: string = "Lagerbestand";
  agbEins: string = "Mit der Speicherung stimme ich den Nutzungsbedingungen zu, die unter";
  agbZwei: string = "eingesehen werden können.";
  artikelZustandLbl: string = "Artikelzustand:";

  // Galleria
  galleriaFixer: GalleriaFixer = new GalleriaFixer();
  galleriaDisplayFullscreen: boolean = false;

  //RadioButton Mengeneinheit
  radioBtnMengeneinheitLabel: string;
  pro: string = "pro ";
  proEinheit: string = "Pro Einheit";
  /***mengeneinehit = Mengeneinheit */
  radioCurrentValue: string = "";


  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject(forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    public _crudItemService: ArtikelService,
    private _guard: ArtikelDetailGuard,




    // CHILD-spezifisch: zusätzliche Services - START
    public lieferartService: LieferartService,
    public artikelLieferartService: ArtikelLieferartService,
    private artikelgruppeService: ArtikelgruppeService,
    public messageService: MessageService,
    public messageWrapperService: MessageWrapperService,
    public photoService: PhotoService,
    private currencyPipe: CurrencyPipe,
    private customCurrencyPipe: CustomCurrencyPipe,
    private artikelbildService: ArtikelbildService,
    private lagerService: LagerService,
    private herstellerService: HerstellerService,
    private locationStrategy: LocationStrategy,
    private breadcrumbService: BreadcrumbService
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    // back button soll abgefangen werden, wenn man grad die Vorschau sieht: https://stackoverflow.com/questions/36357204/how-to-disable-browser-back-button-in-angular-2
    // siehe auch vorschauShow() / constructor()
    this.locationStrategy.onPopState(() => {
      if (this.vorschauDisplay == true) {
        this.vorschauZurueckClicked();

      }
      else {
      }
    });


    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      bezeichnung: {
        required: this._translate.instant('Bezeichnung', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Bezeichnung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      /*hersteller: {
        required: this._translate.instant('Hersteller', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },*/

      //lieferart: {
      //  required: this._translate.instant('Lieferart', true) + ' ' + this._translate.instant('ist erforderlich', true)
      //},

      beschreibung: {
        maxlength: this._translate.instant('Beschreibung', true) + ' ' + this._translate.instant('darf 1000 Zeichen nicht überschreiten', true)
      },

      //lager: {
      //  required: this.translate.instant('Lager', true) + ': ' + this._translate.instant('ist erforderlich', true)
      //},

      artikelnummer: {
        required: this.translate.instant('Artikelnummer', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Artikelnummer', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      //baustelle: {
      //  maxlength: this._translate.instant('Baustelle', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      //},

      //strasse: {
      //  maxlength: this._translate.instant('Strasse', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      //},

      //postleitzahl: {
      //  maxlength: this._translate.instant('Postleitzahl', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      //},

      //ort: {
      //  maxlength: this._translate.instant('Ort', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      //},

      url: {
        required: this.translate.instant('Link', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Link', true) + ' ' + this._translate.instant('darf 1000 Zeichen nicht überschreiten', true)
      },

      preis: {
        required: this._translate.instant('Preis', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      /*laenge: {
        required: this._translate.instant('Länge', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      breite: {
        required: this._translate.instant('Breite', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      hoehe: {
        required: this._translate.instant('Höhe', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      dicke: {
        required: this._translate.instant('Dicke', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },*/

      menge: {
        required: this._translate.instant('Menge', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      zustand: {
        required: this._translate.instant('Artikelzustand', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      verlademoeglichkeit: {
        required: this._translate.instant('Verlademöglichkeit', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      verlademoeglichkeitVermerk: {
        required: this._translate.instant('Vermerk', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Vermerk', true) + ' ' + this._translate.instant('darf 1000 Zeichen nicht überschreiten', true)
      },

      aktiv: {
        required: this._translate.instant('Aktiv', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      email_anzeigen: {
        required: this._translate.instant('Email anzeigen', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      lagerbestand: {
        required: this._translate.instant('Lagerbestand', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      telefon_anzeigen: {
        required: this._translate.instant('Telefon anzeigen', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      mobilnummer_anzeigen: {
        required: this._translate.instant('Mobilnummer anzeigen', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      mengeneinheit: {
        required: this._translate.instant('Mengeneinheit', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      ansprechpartner: {
        required: this._translate.instant('Ansprechpartner', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      _AGBAkzeptiert: {
        required: this._translate.instant('Nutzungsbedingungen', true) + ': ' + this._translate.instant('müssen akzeptiert werden', true),
      },
    };
    // CHILD-spezifisch die Validator Messages bestücken - ENDE

    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }


  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      bezeichnung: ['', [Validators.required, Validators.maxLength(255)]],
      beschreibung: ['', [Validators.maxLength(1000)]],
      artikelnummer: ['', [Validators.required, Validators.maxLength(255)]],
      url: ['', [Validators.required, Validators.maxLength(1000)]],
      baustelle: [null, [Validators.maxLength(255)]],
      baustelle_Gueltigkeit: [null],
      //postleitzahl: [null, [Validators.maxLength(255)]],
      strasse: [null, [Validators.maxLength(255)]],
      //ort: [null, [Validators.maxLength(255)]],
      geodaten: [null],
      preis: [0, [Validators.required]],
      menge: [0, [Validators.required]],
      verlademoeglichkeit: [false, [Validators.required]],
      verlademoeglichkeitVermerk: ['', [Validators.maxLength(1000)]],
      gueltigkeit: [null],
      aktiv: [false, [Validators.required]],
      email_anzeigen: [false, [Validators.required]],
      lagerbestand: [false, [Validators.required]],
      telefon_anzeigen: [false, [Validators.required]],
      mobilnummer_anzeigen: [false, [Validators.required]],
      ansprechpartner: [null],
      zustand: [null],
      hersteller: [''/*, [Validators.required]*/],
      oberflaeche: [''],
      kante: [''],
      farbe: [''],
      laenge: [null],
      breite: [null],
      hoehe: [null],
      dicke: [null],
      lager: [null/*, [Validators.required]*/],
      //lieferart: [null],
      mengeneinheit: [null],
      verfuegbarkeit: [null],
      verkaufseinheit: [null],
      verpackung: [null],

      artikelbilder: [null], // Parent+Child

      _AGBAkzeptiert: [null, [Validators.required]],
      _baustelleAnzeigen: [null, [Validators.required]],

      dummy: [''] // TO DO
    }, {
      validator: this.formValidator // auf FormEbene!
    });

    this.CRUDForm['___component'] = this; // trick, um im formValidator wieder an die component zu kommen.
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;
    this.breadcrumbService.setItems([
      { label: 'Components' },
      { label: 'Artikel anlegen', routerLink: ['/detail-artikel-ansicht'] }

    ]);

    this.route.queryParams.subscribe(params => {
      this.urlParmOverviewMode = params['overviewmode'];
      this.urlParmItemNr = params['itemnr'];
      this.urlParmManufacturer = params['manufacturer'];
      this.urlParmLabel = params['label'];
    });

    /////////////////smooth fade in/out button ScrollToUpBtn///////////////////
    $(window).scroll(function () {
      if ($(this).scrollTop() > 500) {
        $(".btnArtikelToTop").css({ "opacity": "1" })
      }
      else {
        $(".btnArtikelToTop").css({ "opacity": "0" })
      }

    });





    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START

    // ArtikelGruppen
    // es ist sinnfrei, nur die Parent zu laden - und dann nachzuladen, weil!
    // dann funktioniert die Suche nicht!
    /*
    let thisInstance = this;
    artikelgruppeService.getArtikelgruppenChildrenForParent(0).subscribe(function (response) {
      let allFetchedArtikelGruppen:IArtikelgruppe[] = response.artikelgruppen;
      console.log("ArtikelDetailComponent.constructor() getArtikelgruppenChildrenForParent reponse:", response);
      //_this.onItemsRetrieved(response.mandantenBankkonten);
      thisInstance.artikelGruppeTreeNodes = [];
      response.artikelgruppen.forEach(artikelGruppe => {
        let children:TreeNode[] = [];
        artikelGruppe.inverseArtikelgruppe.forEach(inverseArtikelgruppe => {
          let newChildTreeNode:TreeNode = {
            children: null,
            collapsedIcon: "ui-icon-folder",
            data: inverseArtikelgruppe.bezeichnung,
            expandedIcon: "ui-icon-folder-open",
            label: inverseArtikelgruppe.bezeichnung
          }            
          children.push(newChildTreeNode);
        });

        let newTreeNode:TreeNode = {
          children: children,
          collapsedIcon: "ui-icon-folder",
          data: artikelGruppe.bezeichnung,
          expandedIcon: "ui-icon-folder-open",
          label: artikelGruppe.bezeichnung
        }            
        thisInstance.artikelGruppeTreeNodes.push(newTreeNode);
      });
      console.log("ArtikelDetailComponent.constructor() getArtikelgruppenChildrenForParent artikelGruppeTreeNodes:", this.artikelGruppeTreeNodes);
    }, function (error) { return this.handleError(error); });
    */


    // alle Artikelgruppen auf einmal laden!
    let thisInstance = this;
    this.artikelgruppeService.getArtikelgruppenChildrenForTree().subscribe(function (response) {
      thisInstance.artikelGruppeItems = response.artikelgruppen;
      console.log("ArtikelDetailComponent.ngOnInit() getArtikelgruppenCollection thisInstance.artikelGruppeItems:", thisInstance.artikelGruppeItems);
      thisInstance.artikelGruppeTreeNodes = [];
      thisInstance.artikelGruppeItems.filter(f => f.artikelgruppeId == null).forEach(artikelGruppe => {
        //thisInstance.artikelGruppeTreeNodes.push(newTreeNode);
        thisInstance.artikelGruppeTreeNodes.push(thisInstance.artikelGruppePrepareTreeNode(thisInstance, 0, artikelGruppe));
      });
      console.log("ArtikelDetailComponent.ngOnInit() getArtikelgruppenChildrenForParent thisInstance.artikelGruppeTreeNodes:", thisInstance.artikelGruppeTreeNodes);
      thisInstance.ngOnInit_ArtikelDetailComponent_pt2(thisInstance); // erst NACHDEM die Artieklgruppen gelesen sind mit getCRUDitem() etc. weitermachen!
    }, function (error) { return this.handleError(error); });

    this.items = [
      { label: 'Angular.io', icon: 'pi pi-external-link', url: 'http://angular.io' },
      { label: 'Theming', icon: 'pi pi-palette' }
    ];
    this.mengenenheitSubscription();
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    //super.___ngOnInit(); // siehe oben -> passiert aus dem subscribe heraus - NACHDEM die Artieklgruppen gelesen sind!
  }
  ngOnInit_ArtikelDetailComponent_pt2(thisInstance: ArtikelDetailComponent) {
    console.log("ArtikelDetailComponent.ngOnInit_ArtikelDetailComponent_pt2() ...");
    thisInstance.lieferartService.getLieferartenCollection(1, 0, '').subscribe(function (response) {
      thisInstance.artikelLieferartenItems = [];
      response.lieferarten.forEach(lieferart => {
        let artikelForArtikelLieferart = thisInstance._crudItemService.initializeArtikel();
        artikelForArtikelLieferart.id = thisInstance.dataId;
        let artikelLieferart: IArtikelLieferart = thisInstance.artikelLieferartService.initializeArtikelLieferart();
        artikelLieferart.artikel = artikelForArtikelLieferart;
        artikelLieferart.artikelId = thisInstance.dataId;
        artikelLieferart.lieferart = lieferart;
        artikelLieferart.lieferartId = lieferart.id;
        artikelLieferart.summary = lieferart.summary;
        thisInstance.artikelLieferartenItems.push(artikelLieferart);
      });
      console.log("ArtikelDetailComponent.ngOnInit_ArtikelDetailComponent_pt2() artikelLieferartenItems:", thisInstance.artikelLieferartenItems);
      thisInstance.artikelLieferartenSelected = [];

      thisInstance.ngOnInit_ArtikelDetailComponent_pt3(thisInstance); // erst NACHDEM die Artieklgruppen gelesen sind mit getCRUDitem() etc. weitermachen!
    }, function (error) { return thisInstance.handleError(error); });
  }
  ngOnInit_ArtikelDetailComponent_pt3(thisInstance: ArtikelDetailComponent) {
    console.log("ArtikelDetailComponent.ngOnInit_ArtikelDetailComponent_pt3() calling ... super.ngOnInit()");
    super.ngOnInit();
  }

  abbrechBtn() {
    /*if(this.urlParmoverviewmode != null) {
      this.router.navigateByUrl('/artikel-uebersicht?overviewmode=' + this.urlParmoverviewmode);
    }
    else {*/
    if (this.CRUDItem.typ == 'S') {
      this.router.navigate(['/suche-uebersicht'], { queryParamsHandling: "merge" });
    }
    else if (this.CRUDItem.typ == 'W') {
      this.router.navigate(['/werbeanzeige-uebersicht'], { queryParamsHandling: "merge" });
    }
    else {
      this.router.navigate(['/artikel-uebersicht'], { queryParamsHandling: "merge" });
    }
    /*}
    event.preventDefault();*/
  }

  onCRUDItemRetrieved(CRUDItem: any): void {
    // Wenn nicht Werbeanzeige, dann im Editor einige Felder aufblenden:
    // Zugriff über getQuill() funktioniert soweit, aber nachträgliches Ändern der Controls bleibt effektlos
    let quill = this.editor.getQuill();
    console.log("ArtikelDetail.ngAfterViewInit(): quill", quill)
    //console.log("ArtikelDetail.ngAfterViewInit(): quill toolbar", quill.getModule("toolbar"));
    // Das geht nur per JS Hack, in der Quill Doku steht explizit, dass es nicht vorgesehen ist, die Controls nachträglich zu ändern.
    if (CRUDItem.typ == "W" || (this.dataId == 0 && this.urlParmOverviewMode != null && this.urlParmOverviewMode == "myAdverts")) {
      this.quillGeneratedHTMLElementForFont.style.display = null;
      this.quillGeneratedHTMLElementForSize.style.display = null;
      this.quillGeneratedHTMLElementForColor.style.display = null;
      this.quillGeneratedHTMLElementForBackground.style.display = null;

      //gleichzeitig den Hintergrund eingrauen + Farbe = weiss, damit der User die Farben ähnlich wie auf einem Bild sieht
      document.getElementsByClassName("ql-editor")[0]['style'].background = "grey";
      document.getElementsByClassName("ql-editor")[0]['style'].color = "white";
      // + das Titelbild als Hintergrund
      //document.getElementsByClassName("ql-editor")[0]['style'].backgroundImage="url('http://localhost:55386/api/artikelbilder/downloadthumbdirect/825c81fa-497e-43fb-8b7b-53a22d77bba5/jpg')";
      this.artikelbilderUseAsEditorBackground();
      document.getElementsByClassName("ql-editor")[0]['style'].backgroundRepeat = "round"; // auf gesamten Raum gezoomt
    }

    // die virtuell erstellten ArtikelLieferarten (zur Auswahl) durch die echten - bereits vorhandenen - ersetzen.
    console.log("ArtikelDetailComponent.onCRUDItemRetrieved() CRUDItem.artikelLieferarten:", CRUDItem.artikelLieferarten);
    console.log("ArtikelDetailComponent.onCRUDItemRetrieved() before: this.artikelLieferartenItems:", this.artikelLieferartenItems);
    if (this.artikelLieferartenItems != null && this.artikelLieferartenItems.length > 0) {
      if (CRUDItem.artikelLieferarten != null && CRUDItem.artikelLieferarten.length > 0) {
        let i = 0;
        this.artikelLieferartenItems.forEach(artikelLieferartItem => {
          console.log("ArtikelDetailComponent.onCRUDItemRetrieved() checking artikelLieferartItem:", artikelLieferartItem);
          let idx = CRUDItem.artikelLieferarten.findIndex(f => f.lieferartId == artikelLieferartItem.lieferartId);
          if (idx >= 0) {
            console.log("ArtikelDetailComponent.onCRUDItemRetrieved() replacing index " + i + " with existing item from CRUDItem.artikelLieferarten: ", CRUDItem.artikelLieferarten[idx]);

            this.artikelLieferartenItems.splice(i, 1, CRUDItem.artikelLieferarten[idx])
          }
          i++;
        });

      }
      console.log("ArtikelDetailComponent.onCRUDItemRetrieved() after: this.artikelLieferartenItems:", this.artikelLieferartenItems);
    }

    this.artikelLieferartenSelected = CRUDItem.artikelLieferarten; // Parent+Child
    this.artikelLieferartenOnSelected();
    console.log("ArtikelDetailComponent.onCRUDItemRetrieved() artikelLieferartenSelected:", this.artikelLieferartenSelected);

    // wenn NeuanlageModus, dann Unternehmen und ggf. Werte aus URL-Parametern einstellen
    if (this.dataId == 0) {
      CRUDItem.unternehmenId = this.app.benutzer.unternehmenId;

      // Bei Suchanfrage: Kennzeichen setzen
      if (this.urlParmOverviewMode != null) {
        if (this.urlParmOverviewMode == "myRequests") {
          CRUDItem.typ = 'S';
        }
        else if (this.urlParmOverviewMode == "myAdverts") {
          CRUDItem.typ = 'W';
        }
      }

      if (this.urlParmItemNr != null) CRUDItem.artikelnummer = this.urlParmItemNr;
      if (this.urlParmLabel != null) CRUDItem.bezeichnung = this.urlParmLabel;
      if (this.urlParmManufacturer != null) {
        let thisInstance = this;
        let manufacturer = parseInt(this.urlParmManufacturer);
        this.herstellerService.getHersteller(manufacturer).subscribe(function (response) {
          CRUDItem.hersteller = response;
          CRUDItem.herstellerId = manufacturer;
          setTimeout(() => {
            thisInstance.CRUDForm.patchValue({ hersteller: CRUDItem.hersteller });
          }, 500);
        }, function (error) { return this.handleError(error); });
      }
    }

    // Bei Suchanfrage: Kennzeichen, andere Überschrift, Prüfungen deaktivieren
    if (this.urlParmOverviewMode != null) {
      if (this.urlParmOverviewMode == "myRequests") {
        this.CRUDItemKurzform = "Suchanfrage";
        this.CRUDPageTitleNeu = this.translate.instant("Neue Suchanfrage", true);
        this.CRUDPageTitleBearbeiten = this.translate.instant("Suchanfrage bearbeiten", true);
        this.CRUDItemBezeichnungSingularCapitalized = "Suchanfrage";
        this.CRUDItemBezeichnungPluralCapitalized = "Suchanfragen";
        this.CRUDConfirmMessageDelete = "Wollen Sie diese Suchanfrage wirklich löschen?"; // Alternativen Text zum Standard 

        // Validator deaktivieren
        this.CRUDForm.controls.artikelnummer.setErrors(null);
        this.CRUDForm.controls.artikelnummer.clearValidators();
        this.CRUDForm.controls.artikelnummer.updateValueAndValidity();
        this.CRUDForm.controls.mengeneinheit.setErrors(null);
        this.CRUDForm.controls.mengeneinheit.clearValidators();
        this.CRUDForm.controls.mengeneinheit.updateValueAndValidity();
        this.CRUDForm.controls.zustand.setErrors(null);
        this.CRUDForm.controls.zustand.clearValidators();
        this.CRUDForm.controls.zustand.updateValueAndValidity();
        this.CRUDForm.controls.ansprechpartner.setErrors(null);
        this.CRUDForm.controls.ansprechpartner.clearValidators();
        this.CRUDForm.controls.ansprechpartner.updateValueAndValidity();
        this.CRUDForm.controls.url.setErrors(null);
        this.CRUDForm.controls.url.clearValidators();
        this.CRUDForm.controls.url.updateValueAndValidity();

        // MethodNames heissen aber torzdem "...Artikel..."
        this.CRUDMethodNameGet = "getArtikel";
        this.CRUDMethodNameSave = "saveArtikel";
        this.CRUDMethodNameDelete = "deleteArtikel";
      }
      else if (this.urlParmOverviewMode == "myAdverts") {
        this.CRUDItemKurzform = "Werbeanzeige";
        this.CRUDPageTitleNeu = this.translate.instant("Neue Werbeanzeige", true);
        this.CRUDPageTitleBearbeiten = this.translate.instant("Werbeanzeige bearbeiten", true);
        this.CRUDItemBezeichnungSingularCapitalized = "Werbeanzeige";
        this.CRUDItemBezeichnungPluralCapitalized = "Werbeanzeigen";
        this.CRUDConfirmMessageDelete = "Wollen Sie diese Werbeanzeige wirklich löschen?"; // Alternativen Text zum Standard 

        // Validator deaktivieren
        this.CRUDForm.controls.artikelnummer.setErrors(null);
        this.CRUDForm.controls.artikelnummer.clearValidators();
        this.CRUDForm.controls.artikelnummer.updateValueAndValidity();
        this.CRUDForm.controls.mengeneinheit.setErrors(null);
        this.CRUDForm.controls.mengeneinheit.clearValidators();
        this.CRUDForm.controls.mengeneinheit.updateValueAndValidity();
        this.CRUDForm.controls.zustand.setErrors(null);
        this.CRUDForm.controls.zustand.clearValidators();
        this.CRUDForm.controls.zustand.updateValueAndValidity();
        this.CRUDForm.controls.ansprechpartner.setErrors(null);
        this.CRUDForm.controls.ansprechpartner.clearValidators();
        this.CRUDForm.controls.ansprechpartner.updateValueAndValidity();

        // MethodNames heissen aber torzdem "...Artikel..."
        this.CRUDMethodNameGet = "getArtikel";
        this.CRUDMethodNameSave = "saveArtikel";
        this.CRUDMethodNameDelete = "deleteArtikel";
      }
      else { // Artikel
        this.CRUDForm.controls.url.setErrors(null);
        this.CRUDForm.controls.url.clearValidators();
        this.CRUDForm.controls.url.updateValueAndValidity();
      }

    }

    super.onCRUDItemRetrieved(CRUDItem);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    // Wenn nicht Werbeanzeige, dann im Editor einige Felder aufblenden:
    // Das geht nur per JS Hack, in der Quill Doku steht explizit, dass es nicht vorgesehen ist, die Controls nachträglich zu ändern.
    // Hier zunäcsht schnell ausblenden ... im CRUDItemReceived ggf. wieder einblenden
    this.quillGeneratedHTMLElementForFont = this.editor_font.nativeElement.previousElementSibling;
    console.log("ArtikelDetail.ngAfterViewInit(): quillGeneratedHTMLElementForFont:", this.quillGeneratedHTMLElementForFont);
    this.quillGeneratedHTMLElementForFont.style.display = "none";
    this.quillGeneratedHTMLElementForSize = this.editor_size.nativeElement.previousElementSibling;
    this.quillGeneratedHTMLElementForSize.style.display = "none";
    this.quillGeneratedHTMLElementForColor = this.editor_color.nativeElement.previousElementSibling;
    this.quillGeneratedHTMLElementForColor.style.display = "none";
    this.quillGeneratedHTMLElementForBackground = this.editor_background.nativeElement.previousElementSibling;
    this.quillGeneratedHTMLElementForBackground.style.display = "none";

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    //console.log("ArtikelDetailComponent.ngAfterViewInit() artikelbilderFileUpload.files:", this.artikelbilderFileUpload.files);
    //console.log("ArtikelDetailComponent.ngAfterViewInit() artikelbilderFileUpload:", this.artikelbilderFileUpload);
    // das galleriaitem finden + höhe setzen: p-galleria > p-galleriacontent > div > div > p-galleriaitem
    let thisInstance = this;
    setTimeout(() => {
      thisInstance.alignGalleriaAtBottom();
    }, 300);
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE
    //super.ngOnInit();
  }

  onSubmit(value: string) {
    this.submitted = true;
    this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Form Submitted' });
  }
  get diagnostic() { return JSON.stringify(this.userform.value); }

  getValuesFromForm() {
    let a: IArtikel = super.getValuesFromForm(); // Standard! do not change!

    return this.getValuesFromForm_Artikel(a, false);
  }

  getValuesFromForm_Artikel(a: any, vorschauMode: boolean) { // Child-spez. In separate Routine ausgelagert, damit das auch für die Vorschau direkt aufgerufen werden kann.
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    a.ansprechpartnerId = a.ansprechpartner != null ? a.ansprechpartner.id : null;
    a.zustandId = a.zustand != null ? a.zustand.id : null;
    a.herstellerId = a.hersteller != null ? a.hersteller.id : null;
    a.oberflaecheId = a.oberflaeche != null ? a.oberflaeche.id : null;
    a.kanteId = a.kante != null ? a.kante.id : null;
    a.farbeId = a.farbe != null ? a.farbe.id : null;
    //a.lieferartId = a.lieferart != null ? a.lieferart.id : null;
    a.mengeneinheitId = a.mengeneinheit != null ? a.mengeneinheit.id : null;
    a.verfuegbarkeitId = a.verfuegbarkeit != null ? a.verfuegbarkeit.id : null;
    a.verkaufseinheitId = a.verkaufseinheit != null ? a.verkaufseinheit.id : null;
    a.verpackungId = a.verpackung != null ? a.verpackung.id : null;
    a.ansprechpartnerId = a.ansprechpartner != null ? a.ansprechpartner.id : null;

    // specials:
    a.artikelLieferarten = this.artikelLieferartenSelected != null ? this.artikelLieferartenSelected : 0;
    a.artikelgruppeId = this.artikelGruppeSelected != null ? this.artikelGruppeSelected.data.id : 0;
    a.preis = parseFloat(a.preis); // numerisch mit Dezimal

    // Hersteller: wenn manuell eingegeben, dann Unternehmen nachtragen
    if (a.hersteller != null && (a.hersteller.id == null || a.hersteller.id == 0)) {
      a.hersteller.unternehmenId = this.app.benutzer.unternehmenId;
    }
    // Hersteller: wenn aber nur blank eingegeben (passiert zB auch, wenn man im Dashboard die combobox öffnet, dann aber Nichts auswählt) -> hersteller löschen!
    if (a.hersteller != null && a.hersteller.bezeichnung.length == 0) {
      a.hersteller = null;
      a.herstellerId = null;
    }

    // Oberfläche: wenn manuell eingegeben, dann Unternehmen nachtragen
    if (a.oberflaeche != null && (a.oberflaeche.id == null || a.oberflaeche.id == 0)) {
      a.oberflaeche.unternehmenId = this.app.benutzer.unternehmenId;
    }
    // oberflaeche: wenn aber nur blank eingegeben (passiert zB auch, wenn man im Dashboard die combobox öffnet, dann aber Nichts auswählt) -> oberflaeche löschen!
    if (a.oberflaeche != null && a.oberflaeche.bezeichnung.length == 0) {
      a.oberflaeche = null;
      a.oberflaecheId = null;
    }

    // Farbe: wenn manuell eingegeben, dann Unternehmen nachtragen
    if (a.farbe != null && (a.farbe.id == null || a.farbe.id == 0)) {
      a.farbe.unternehmenId = this.app.benutzer.unternehmenId;
    }
    // farbe: wenn aber nur blank eingegeben (passiert zB auch, wenn man im Dashboard die combobox öffnet, dann aber Nichts auswählt) -> farbe löschen!
    if (a.farbe != null && a.farbe.bezeichnung.length == 0) {
      a.farbe = null;
      a.farbeId = null;
    }

    // Kante: wenn manuell eingegeben, dann Unternehmen nachtragen
    if (a.kante != null && (a.kante.id == null || a.kante.id == 0)) {
      a.kante.unternehmenId = this.app.benutzer.unternehmenId;
    }
    // kante: wenn aber nur blank eingegeben (passiert zB auch, wenn man im Dashboard die combobox öffnet, dann aber Nichts auswählt) -> kante löschen!
    if (a.kante != null && a.kante.bezeichnung.length == 0) {
      a.kante = null;
      a.kanteId = null;
    }

    // Lager: Wenn neue baustelle eingegeben ...
    if (this.CRUDForm.value._baustelleAnzeigen == true) {
      let newLager = this.lagerService.initializeLager();
      newLager.baustelle = true;

      newLager.bezeichnung = a.baustelle;
      //newLager.ort = a.ort;
      //newLager.postleitzahl = a.postleitzahl;
      newLager.geodatenId = a.geodaten.id;
      newLager.strasse = a.strasse;
      newLager.unternehmenId = this.app.benutzer.unternehmenId;

      a.lager = newLager;
      console.log("ArtikelDetailComponent.getValuesFromForm_Artikel() this.CRUDForm.value.gueltigkeit_Baustelle:", this.CRUDForm.value.gueltigkeit_Baustelle);
      if (a.baustelle_Gueltigkeit != null) {
        a.lager.baustelle_Gueltigkeit = moment.utc(this.getUTCDateFromForm(this.CRUDForm.value.baustelle_Gueltigkeit)); // kommt von p-calendar schon mit Zeit 0 - aber mit Zeitzone (und die wiederum in die Studnen eingerechnet) -> das anpassen, es soll 0h ohne Zeitzone ankommen!
        console.log("ArtikelDetailComponent.getValuesFromForm_Artikel() a.lager.baustelle_Gueltigkeit:", a.lager.baustelle_Gueltigkeit);
      }
      a.lagerId = 0;
    }
    else {
      a.lagerId = a.lager != null ? a.lager.id : null;
    }

    // pauschal soll binary, nicht alpha sein!
    //a.pauschal = a.pauschal == "true" ? true : false;

    a.pauschal = this.radioCurrentValue == 'pauschal' ? true : false;

    console.log("ArtikelDetailComponent.getValuesFromForm_Artikel() this.CRUDForm.value.gueltigkeit:", this.CRUDForm.value.gueltigkeit);
    if (a.gueltigkeit != null) {
      a.gueltigkeit = moment.utc(this.getUTCDateFromForm(this.CRUDForm.value.gueltigkeit)); // kommt von p-calendar schon mit Zeit 0 - aber mit Zeitzone (und die wiederum in die Studnen eingerechnet) -> das anpassen, es soll 0h ohne Zeitzone ankommen!
      console.log("ArtikelDetailComponent.getValuesFromForm_Artikel() a.gueltigkeit:", a.gueltigkeit);
    }

    // Artikelbilder aus der fileUpload-Liste aktualisieren
    // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
    console.log("ArtikelDetailComponent.getValuesFromForm_Artikel() artikelbilderFileUpload.files:", this.artikelbilderFileUpload.files);
    a.artikelbilder = [];
    let bestehendeSortierung: number = 0;
    this.artikelbilderFileUpload.files.forEach(file => {
      if (file['_artikelbild'] != undefined) { // das Artikelbild gab es schon (Update-Modus, per GetArtikel bekommen)
        let existingArtikelbild: IArtikelbild = file['_artikelbild']
        console.log("ArtikelDetailComponent.getValuesFromForm_Artikel() adding existing existingArtikelbild:", existingArtikelbild);
        a.artikelbilder.push(existingArtikelbild);
        if (file['_artikelbild'].sortierung > bestehendeSortierung) bestehendeSortierung = file['_artikelbild'].sortierung;
      }
      else { // ein neues Artikelbild
        let dateiendung = file.name.substr(file.name.lastIndexOf('.') + 1);
        let dateiname = file.name.substr(0, file.name.length - (dateiendung.length + 1));
        let newArtikelbild: IArtikelbild = this.artikelbildService.initializeArtikelbild();
        delete newArtikelbild._bild; // sonst kommen die Parameter beim Controller nicht an!
        newArtikelbild.dateiname = dateiname;
        newArtikelbild.dateiendung = dateiendung;
        newArtikelbild.bezeichnung = file.name;
        newArtikelbild.guid = file['_guid'];
        newArtikelbild.sortierung = bestehendeSortierung + 1;
        bestehendeSortierung++;
        newArtikelbild.id = 0;
        newArtikelbild.artikelId = this.CRUDItem.id;
        console.log("ArtikelDetailComponent.getValuesFromForm_Artikel() adding new newArtikelbild:", newArtikelbild);
        a.artikelbilder.push(newArtikelbild);
      }
    });

    // Titelbild
    if (this.artikelBilderTitlePicGuid != null && this.artikelBilderTitlePicGuid != "00000000") {
      console.log("ArtikelDetailComponent.getValuesFromForm_Artikel() searching titelbild in a.artikelbilder:", a.artikelbilder);
      let foundTitelbild = a.artikelbilder.find(f => f['guid'] == this.artikelBilderTitlePicGuid);
      a.titelbild = foundTitelbild;
      a.titelbildId = a.titelbild.id;
    }
    else {
      a.titelbild = null;
      a.titelbildId = 0;
    }
    this.artikelbilderUseAsEditorBackground();

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    console.log("ArtikelDetailComponent.getValuesFromForm_Artikel() a.artikelbilder/a:", a.artikelbilder, a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    console.log("ArtikelDetailComponent.sendValuesToForm()");
    console.log("ArtikelDetailComponent.sendValuesToForm() artikelbilderFileUpload.files:", this.artikelbilderFileUpload.files);


    if (this.dataId != null && this.dataId == '0') { // create mode
      console.log("ArtikelDetailComponent.sendValuesToForm() create-mode");
      // anhängende Entities:
      // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
      this.CRUDItem.artikelbilder = []; // Parent+Child
      this.artikelbilderFileUpload.files = [];
      this.artikelBilderGalleryImages = [];
    }
    else {
      console.log("ArtikelDetailComponent.sendValuesToForm() upd-mode: this.CRUDItem.artikelbilder:", this.CRUDItem.artikelbilder);
      if (this.artikelBilderGalleryImages == null) {
        // Artikelbilder in gallery und die fileUpload-Liste eintragen
        // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
        this.artikelbilderFileUpload.files = [];
        this.artikelBilderGalleryImages = [];
        this.CRUDItem.artikelbilder.forEach(artikelbild => {
          let newImage: Image = new Image();
          newImage.title = artikelbild.bezeichnung;
          newImage.thumbnailImageSrc = this.artikelbildService.getThumbDownloadUrl(artikelbild.guid, artikelbild.dateiendung);
          newImage.previewImageSrc = this.artikelbildService.getDownloadUrl(artikelbild.guid, artikelbild.dateiendung);
          console.log("ArtikelDetailComponent.sendValuesToForm() adding image to artikelBilderGalleryImages:", newImage);
          this.artikelBilderGalleryImages.push(newImage);

          let newFile = new File([], artikelbild.dateiname + "." + artikelbild.dateiendung, {
            type: "image/" + (artikelbild.dateiendung),
          });
          newFile['objectURL'] = this.artikelbildService.getThumbDownloadUrl(artikelbild.guid, artikelbild.dateiendung);
          newFile['_alreadyUploaded'] = true;
          newFile['_percentUploaded'] = 100;
          newFile['_guid'] = artikelbild.guid;
          newFile['_artikelbild'] = artikelbild; // gleich die entity mit ranhängen, erleichtert das Aktualisieren des CRUDItems bei Save
          console.log("ArtikelDetailComponent.sendValuesToForm() adding file to artikelbilderFileUpload.files:", newFile);
          this.artikelbilderFileUpload.files.push(newFile);
        });

        // Titelbild GUID ermitteln und in Galleria das richtig Bild voreinstellen
        if (this.artikelBilderTitlePicGuid == null || this.artikelBilderTitlePicGuid == "00000000") {
          if (this.CRUDItem.titelbild != null) {
            this.artikelBilderTitlePicGuid = this.CRUDItem.titelbild.guid;

            // Galleria
            let indexOfTitelbild = this.artikelbilderFileUpload.files.findIndex(f => f['_guid'] == this.CRUDItem.titelbild.guid);
            if (indexOfTitelbild >= 0) this.artikelBilderGalleria.activeIndex = indexOfTitelbild;
          }
        }
        this.artikelbilderUseAsEditorBackground();
      }

      if (this.artikelBilderGalleryImages != null && this.artikelBilderGalleryImages.length > 0) {
        /*if(this.artikelBilderAlreadySwitchedToGallery == false) {
          this.artikelBilderDisplayGalleryOrUpload = 'G';
          this.artikelBilderAlreadySwitchedToGallery = true;
        }*/
        //this.artikelBilderTitlePicGuid = this.artikelbilderFileUpload.files[0]['_guid'];
        //console.log("ArtikelDetailComponent.sendValuesToForm() artikelBilderTitlePicGuid:", this.artikelBilderTitlePicGuid);
      }
    }

    try {
      this.CRUDForm.patchValue({
        bezeichnung: this.CRUDItem.bezeichnung,
        beschreibung: this.CRUDItem.beschreibung,
        artikelnummer: this.CRUDItem.artikelnummer,
        url: this.CRUDItem.url,
        baustelle: this.CRUDItem.baustelle,
        strasse: this.CRUDItem.strasse,
        //postleitzahl: this.CRUDItem.postleitzahl,
        //ort: this.CRUDItem.ort,
        geodaten: this.CRUDItem.geodaten,
        preis: this.CRUDItem.preis,
        menge: this.CRUDItem.menge,
        verlademoeglichkeit: this.CRUDItem.verlademoeglichkeit,
        verlademoeglichkeitVermerk: this.CRUDItem.verlademoeglichkeitVermerk,
        gueltigkeit: this.CRUDItem.gueltigkeit != null ? new Date(this.CRUDItem.gueltigkeit) : null, // AM Modi: bei SQL "datetimeoffset"
        baustelle_Gueltigkeit: this.CRUDItem.baustelle_Gueltigkeit != null ? new Date(this.CRUDItem.baustelle_Gueltigkeit) : null, // AM Modi: bei SQL "datetimeoffset"
        aktiv: this.CRUDItem.aktiv,
        email_anzeigen: this.CRUDItem.email_anzeigen,
        lagerbestand: this.CRUDItem.lagerbestand,
        telefon_anzeigen: this.CRUDItem.telefon_anzeigen,
        mobilnummer_anzeigen: this.CRUDItem.mobilnummer_anzeigen,
        ansprechpartner: this.CRUDItem.ansprechpartner,
        zustand: this.CRUDItem.zustand,
        hersteller: this.CRUDItem.hersteller,
        oberflaeche: this.CRUDItem.oberflaeche,
        kante: this.CRUDItem.kante,
        farbe: this.CRUDItem.farbe,
        lager: this.CRUDItem.lager,
        laenge: this.CRUDItem.laenge,
        breite: this.CRUDItem.breite,
        hoehe: this.CRUDItem.hoehe,
        dicke: this.CRUDItem.dicke,
        //lieferart: this.CRUDItem.lieferart,
        mengeneinheit: this.CRUDItem.mengeneinheit,
        verfuegbarkeit: this.CRUDItem.verfuegbarkeit,
        verkaufseinheit: this.CRUDItem.verkaufseinheit,
        verpackung: this.CRUDItem.verpackung,
        vermerk: this.CRUDItem.vermerk,

        artikelbilder: this.CRUDItem.artikelbilder, // Parent+Child

        //_AGBAkzeptiert: null // gar nicht bestücken! Ist nur ich der Form, damit validated werden kann
      });
      if (this.CRUDForm.value._baustelleAnzeigen == null) this.CRUDForm.patchValue({
        _baustelleAnzeigen: false // nur mit false vorbestücken, wenn noch gar nicht bestückt
      });
      if (this.CRUDItem != null && this.CRUDItem.pauschal) {
        this.radioCurrentValue = "pauschal";
      }
      else {
        this.radioCurrentValue = "mengeneinheit";
      }

    }
    catch (e) {
      console.error("ArtikelDetailComponent.sendValuesToForm() ERROR patchValue():", e);
    }

    this.artikelGruppeSelected = this.artikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds(this.artikelGruppeTreeNodes, this.CRUDItem.artikelgruppeId);
    this.artikelGruppeBuildBreadCrumb();

    console.log("ArtikelDetailComponent.sendValuesToForm() end ... calling super.sendValuesToForm()");

    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("ArtikelDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  // ArtikelLieferart(en)
  artikelLieferartShowAuswahlPopUp() {
    this.artikelLieferartenDisplayAuswahlPopUp = true;
  }

  artikelLieferartenOnSelected() {
    this.artikelLieferartenSelectedAsString = "";
    this.artikelLieferartenSelected.forEach(artikelLieferart => {
      if (this.artikelLieferartenSelectedAsString.length > 0) this.artikelLieferartenSelectedAsString += ', ';
      this.artikelLieferartenSelectedAsString += artikelLieferart.summary;
    });
    this.artikelLieferartenDisplayAuswahlPopUp = false;

    this.displayMessageForm = {}; // validity für Artikelgruppe neu prüfen
    this.CRUDForm.updateValueAndValidity(); // validity für Artikelgruppe neu prüfen
  }

  // Artikelgruppe
  artikelGruppeShowAuswahlPopUp() {
    this.artikelGruppeDisplayAuswahlPopUp = true;

  }
  
  artikelGruppePrepareTreeNode(instance: any, level: number, artikelGruppe: IArtikelgruppe) {
    //console.log("ArtikelDetailComponent.prepareArtikelGruppeTreeNode() level/artikelGruppe", level, artikelGruppe.bezeichnung);
    let children: TreeNode[] = [];
    artikelGruppe.inverseArtikelgruppe.forEach(child => {
      children.push(this.artikelGruppePrepareTreeNode(this, level + 1, child));
    });
    let newTreeNode: TreeNode = {
      children: children,
      collapsedIcon: "ui-icon-folder",
      data: { // data enthält id und parentId der artikelGruppe
        id: artikelGruppe.id,
        artikelgruppeId: artikelGruppe.artikelgruppeId
      },
      expandedIcon: "ui-icon-folder-open",
      label: artikelGruppe.bezeichnung /*+ " (id: "+artikelGruppe.id+")"*/,
      key: "" + artikelGruppe.id // ohne den "key" funktioniert die Children-Auswahl NACH FILTER nicht! https://forum.primefaces.org/viewtopic.php?t=58524
    };
    return newTreeNode;
  }

  /*artikelGruppeFindTreeNodeInChilds(artikelGruppeId:number) { // treeNode finden, egal wie tief verschachtelt (childs)
    //console.log("ArtikelDetailComponent.artikelGruppeFindTreeNodeInChilds() artikelGruppeId/artikelGruppeTreeNodes:", artikelGruppeId, this.artikelGruppeTreeNodes);
    if(this.artikelGruppeTreeNodes == null) return null;
    let foundTree = this.artikelGruppeTreeNodes.find(f => f.data.id == artikelGruppeId);
    if(foundTree != null) {
      //console.log("ArtikelDetailComponent.artikelGruppeFindTreeNodeInChilds() found:", foundTree);
      return foundTree; // gefunden !
    }
    else { // nicht gefunden -> childs durchsuchen
      let treesWithChilds = this.artikelGruppeTreeNodes.filter(f => f.children != null && f.children.length > 0);
      for(let i:number = 0; i < treesWithChilds.length; i++ ) { // forEach "not breakable / return"
        let child = treesWithChilds[i];
        let foundTreeInChild:TreeNode = this.artikelGruppeFindTreeNodeInChilds_pt2(1, child, artikelGruppeId);
        if(foundTreeInChild != null) {
          //console.log("ArtikelDetailComponent.artikelGruppeFindTreeNodeInChilds() found (in children):", foundTreeInChild);
          return foundTreeInChild;
        }
      }
    }
    //console.log("ArtikelDetailComponent.artikelGruppeFindTreeNodeInChilds() didn't found tree!");
    return null; // nicht gefunden!
  }
  artikelGruppeFindTreeNodeInChilds_pt2(level:number, treeNode:TreeNode, artikelGruppeId:number) { 
    //console.log("ArtikelDetailComponent.artikelGruppeFindTreeNodeInChilds_pt2() level/treeNode/artikelGruppeId:", level, treeNode, artikelGruppeId);
    let foundTree = treeNode.children.find(f => f.data.id == artikelGruppeId);
    if(foundTree != null) {
      //console.log("ArtikelDetailComponent.artikelGruppeFindTreeNodeInChilds_pt2() found (in children) level/tree:", level, foundTree);
      return foundTree; // gefunden !
    }
    else { // nicht gefunden -> childs durchsuchen
      let treesWithChilds = treeNode.children.filter(f => f.children != null && f.children.length > 0);
      for(let i:number = 0; i < treesWithChilds.length; i++) { // forEach "not breakable / return"
      let child = treesWithChilds[i];
        let foundTreeInChild:TreeNode = this.artikelGruppeFindTreeNodeInChilds_pt2(level+1, child, artikelGruppeId);
        if(foundTreeInChild != null) {
          //console.log("ArtikelDetailComponent.artikelGruppeFindTreeNodeInChilds_pt2() found (in children) level/tree:", level, foundTreeInChild);
          return foundTreeInChild;
        }
      }
    }
    //console.log("ArtikelDetailComponent.artikelGruppeFindTreeNodeInChilds_pt2() didn't found tree!");
    return null; // nicht gefunden!
  }*/

  artikelGruppeOnSelected(event, thisInstance: any) {
    console.log("ArtikelDetailComponent.artikelGruppeOnSelected() event/thisInstance", event, thisInstance);
    this.artikelGruppeSelected = event.node;
    this.artikelGruppeDisplayAuswahlPopUp = false;
    this.artikelGruppeBuildBreadCrumb();

    thisInstance.displayMessageForm = {}; // validity für Artikelgruppe neu prüfen
    thisInstance.CRUDForm.updateValueAndValidity(); // validity für Artikelgruppe neu prüfen
  }

  artikelGruppeBuildBreadCrumb() {
    console.log("ArtikelDetailComponent.artikelGruppeBuildBreadCrumb()");
    this.artikelGruppeBreadCrumbItems = [];
    let item = this.artikelGruppeSelected;
    if (item == null) return;
    this.artikelGruppeBreadCrumbItems.push({
      label: item.label,
      id: item.data.id
    });
    console.log("ArtikelDetailComponent.artikelGruppeBuildBreadCrumb() item:", item);
    while (item.data.artikelgruppeId != null) {
      console.log("ArtikelDetailComponent.artikelGruppeBuildBreadCrumb() searching for id=" + item.data.artikelgruppeId + " in this.artikelGruppeItems:", this.artikelGruppeItems);
      let artikelGruppeParent = this.artikelGruppeItems.find(f => f.id == item.data.artikelgruppeId);
      if (artikelGruppeParent != null) {
        item = {
          data: { // data enthält id und parentId der artikelGruppe
            id: artikelGruppeParent.id,
            artikelgruppeId: artikelGruppeParent.artikelgruppeId
          },
          label: artikelGruppeParent.bezeichnung
        }
        this.artikelGruppeBreadCrumbItems.unshift({
          label: item.label,
          id: item.data.id
        }); // unshift statt push -> an ANFANG des Arrays!
        console.log("ArtikelDetailComponent.artikelGruppeBuildBreadCrumb() unshift item:", item);
      }
      else { // parent (warum auch immer) nicht gefunden -> break
        break;
      }
    }
    console.log("ArtikelDetailComponent.artikelGruppeBuildBreadCrumb() artikelGruppeBreadCrumbItems:", this.artikelGruppeBreadCrumbItems);
  }

  artikelGruppeClear() {
    //console.log("ArtikelDetailComponent.artikelGruppeClear()");
    this.artikelGruppeSelected = null;
    this.artikelGruppeDisplayAuswahlPopUp = false;
    this.artikelGruppeBuildBreadCrumb();

    this.displayMessageForm = {}; // validity für Artikelgruppe neu prüfen
    this.CRUDForm.updateValueAndValidity(); // validity für Artikelgruppe neu prüfen
  }

  artikelGruppeCloseDialog() {
    //console.log("ArtikelDetailComponent.artikelGruppeCloseDialog()");
    this.artikelGruppeDisplayAuswahlPopUp = false;
  }

  formatInputCurrency(inputId: string) {
    $('#' + inputId).val(this.customCurrencyPipe.transform(Number($('#' + inputId).val()), 'money'));
  }
  formatInputFloat(inputId: string) {
    let formatedValue = this.customCurrencyPipe.retransform($('#' + inputId).val())
    $('#' + inputId).val(formatedValue);
  }
  validateAndSaveCRUDItem(close: boolean): void {
    //this.messageWrapperService.postTimedMessage({severity:'success', summary: 'Summary Text Timed', detail: 'Detail Text'});
    //this.messageWrapperService.postStaticMessage({severity:'success', summary: 'Summary Text Static', detail: 'Detail Text'});
    this.messageService.add({ severity: 'success', summary: 'Service Message', detail: 'Via MessageService' });

    // US 21964: Cache des Titelbilds löschen! - Ist noch Wirkungslos (vermutlich wegen CORS) - muss noch vollends gelöst werden! // TO DO
    // inzw. vorübergehend anders gelöst: wir hängen an die URL immer die rowVersion mit an -> neue URL = no cache!
    /*if(this.dataId != null) {
      let urlTitelBild = this.artikelbildService.getThumbDownloadUrlForTitelbildArtikel(this.dataId);
      let a = caches.keys();
      caches.open('v1').then(function(cache) {
        cache.delete(urlTitelBild).then(function(response) {
          //alert("cache deleted for "+urlTitelBild);
        });
      })
    }*/

    super.validateAndSaveCRUDItem(close);
  }
  debug(obj) {
    console.log("ArtikelDetailComponent.debug() artikelLieferartenSelected:", this.artikelLieferartenSelected);
    console.log("ArtikelDetailComponent.debug() editor_font:", this.editor_font);
    console.log("ArtikelDetailComponent.debug() artikelbilderFileUpload.files:", this.artikelbilderFileUpload.files);
    console.log("ArtikelDetailComponent.debug() CRUDForm:", this.CRUDForm);
    console.log("ArtikelDetailComponent.debug() displayMessage:", this.displayMessage);
    console.log("ArtikelDetailComponent.debug() fileUpload.files:", this.artikelbilderFileUpload.files);
    super.debug(null);
  }


  saveArtikel() { // NOT IN USE // TO DO
    console.log("ArtikelDetailComponent.saveArtikel() CRUDForm:", this.CRUDForm);
  }








  // upload
  onNGFileInputSelect__app_like_for_debug(event) {
    console.log("ArtikelDetail.onNGFileInputSelect() (***app-like DEBUG Version***) event:", event);

    for (let i: number = 0; i < event.files.length; i++) {
      let fileToUpload: File = event.files[i];
      let artikelbilderFilesOhneKennzeichnungAlreadyUploaded = this.artikelbilderFileUpload.files.filter(f => f['_alreadyUploaded'] == undefined);
      let artikelbilderFilesOhneKennzeichnungAlreadyUploadedMitDemNamen = artikelbilderFilesOhneKennzeichnungAlreadyUploaded.filter(f => f.name == fileToUpload.name);
      artikelbilderFilesOhneKennzeichnungAlreadyUploadedMitDemNamen.forEach(file => {
        file['_alreadyUploaded'] = false;
        file['_percentUploaded'] = 0;
        file['_guid'] = null;
      });

      // nicht nur das file uploaden, sondern auch die Entity "Artikelbild"
      let artikelbildForCreate = this.artikelbildService.initializeArtikelbild();
      artikelbildForCreate._bild = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAAaADAAQAAAABAAAAAQAAAAD5Ip3+AAAAC0lEQVQIHWNgAAIAAAUAAY27m/MAAAAASUVORK5CYII="; // https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png

      let dateiendung = fileToUpload.name.substr(fileToUpload.name.lastIndexOf('.') + 1);
      let dateiname = fileToUpload.name.substr(0, fileToUpload.name.length - (dateiendung.length + 1));
      artikelbildForCreate.dateiname = dateiname;
      artikelbildForCreate.dateiendung = dateiendung;
      artikelbildForCreate.bezeichnung = fileToUpload.name;
      artikelbildForCreate.sortierung = 99;
      artikelbildForCreate.guid = '00000000-0000-0000-0000-000000000000';

      console.log("ArtikelDetailComponent.onNGFileInputSelect() createArtikelbild ... artikelbildForCreate:", artikelbildForCreate);
      let thisInstance = this;
      this.artikelbildService.createArtikelbildTemp(artikelbildForCreate).subscribe(function (response) {
        console.log("ArtikelDetailComponent.onNGFileInputSelect() createArtikelbild ... response:", response);

        let originalFileName: string = response.dateiname + "." + response.dateiendung;
        let guid: string = response.guid;
        let id: number = response.id;
        console.log("ArtikelDetailComponent.onNGFileInputSelect() createArtikelbild ... originalFileName/guid:", originalFileName, guid);

        // das File als uploaded markieren
        let artikelbilderFilesMitDemDateinamen = thisInstance.artikelbilderFileUpload.files.filter(f => f.name == originalFileName);
        artikelbilderFilesMitDemDateinamen.forEach(file => {
          file['_alreadyUploaded'] = true;
          file['_percentUploaded'] = 100;
          file['_guid'] = guid;
          //file['_artikelbild']=response; // wichtig: über createArtikelBild kommt ja eine GUID zurück, weil er den Satz bereits in der DB anlegt!
          // doch nicht mehr wichtig, weil wir nicht createArtikel, sondern createArtikelTemp aufrufen, also wie bei uploadToTemp NOCH kein DB-Satz erzeugen!
        });

        console.log("ArtikelDetailComponent.onNGFileInputSelect() fileUpload.files:", thisInstance.artikelbilderFileUpload.files);

        // in gallery eintragen
        let newImage: Image = new Image();
        newImage.title = originalFileName;
        newImage.thumbnailImageSrc = thisInstance.artikelbildService.getThumbDownloadUrl(guid, dateiendung);
        newImage.previewImageSrc = thisInstance.artikelbildService.getDownloadUrl(guid, dateiendung);
        thisInstance.artikelBilderGalleryImages.push(newImage);

        // war das das 1. Bild ? dann einmalig auf Gallery switchen (nur beim 1. Mal, danach soll User manuell switchen)
        /*if(thisInstance.artikelBilderAlreadySwitchedToGallery == false && thisInstance.artikelBilderGalleryImages.length == 1) {
          thisInstance.artikelBilderDisplayGalleryOrUpload = 'G';
        }
        thisInstance.artikelBilderAlreadySwitchedToGallery = true;*/

        // 1. Bild ? Dann als Titelbild markieren
        if (thisInstance.artikelBilderGalleryImages.length == 1) {
          console.log("ArtikelDetailComponent.uploadSuccess() setting artikelBilderTitlePicGuid:", guid);
          thisInstance.artikelBilderTitlePicGuid = guid;
        }
      }, function (error) {
        console.log("ArtikelDetailComponent.onNGFileInputSelect() error:", error);
        //return thisInstance.handleError(error); 
      });
    }
  }

  onNGFileInputSelect(event) {
    console.log("ArtikelDetail.onNGFileInputSelect().event:", event);

    //let fileToUpload : File = event.files[0];
    for (let i: number = 0; i < event.files.length; i++) {
      let fileToUpload: File = event.files[i];

      /*const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      let httpClient = this.artikelbildService.getHTTPClient();
      let apiBaseURL = this.artikelbildService.getApiBaseUrl();
      httpClient.post(apiBaseURL + 'artikelbilder', formData, {reportProgress: true, observe: 'events'})
        .subscribe(event => {
          console.log("ArtikelDetail.uploadFile() event:", event);
          //if (event.type === HttpEventType.UploadProgress) {
            //let progress = Math.round(100 * event.loaded / event.total);
            //console.log("ArtikelDetail.uploadFile() progress:", progress);
          //}
          //else if (event.type === HttpEventType.Response) {
          //  console.log("ArtikelDetail.uploadFile() success!");
          //}
        });
      */

      /*
      // nicht nur das file uploaden, sondern auch die Entity "Artikelbild"
      let artikelbildForCreate = this.artikelbildService.initializeArtikelbild();
      let dateiendung = fileToUpload.name.substr(fileToUpload.name.lastIndexOf('.') + 1);
      let dateiname = fileToUpload.name.substr(0, fileToUpload.name.length - (dateiendung.length + 1));
      artikelbildForCreate.dateiname = dateiname;
      artikelbildForCreate.dateiendung = dateiendung;
      artikelbildForCreate.bezeichnung = fileToUpload.name;
      artikelbildForCreate.sortierung = 99;
      */

      // in die fileUpload-Liste eintragen - bzw. eingetragen ist es schon (von primeNg) - also:
      // nur noch das eingetragene suchen - und als "noch nicht upgeloaded" markieren
      // ACHTUNG HIER AUSNAHME ZU
      // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
      // GRUND: artikelBilderGalleryImages wird beim onSuccess aktualisiert
      let artikelbilderFilesOhneKennzeichnungAlreadyUploaded = this.artikelbilderFileUpload.files.filter(f => f['_alreadyUploaded'] == undefined);
      let artikelbilderFilesOhneKennzeichnungAlreadyUploadedMitDemNamen = artikelbilderFilesOhneKennzeichnungAlreadyUploaded.filter(f => f.name == fileToUpload.name);
      artikelbilderFilesOhneKennzeichnungAlreadyUploadedMitDemNamen.forEach(file => {
        file['_alreadyUploaded'] = false;
        file['_percentUploaded'] = 0;
        file['_guid'] = null;
      });

      //this.artikelbildService.uploadArtikelbild(fileToUpload, artikelbildForCreate, this, this.uploadProgress, this.uploadSuccess);
    }
    console.log("ArtikelDetailComponent.onNGFileInputSelect() fileUpload.files:", this.artikelbilderFileUpload.files);

    let thisInstance = this;
    setTimeout(() => {
      thisInstance.uploadNextArtikelbild();
    }, 200);
  }
  uploadNextArtikelbild() {
    console.log("ArtikelDetailComponent.uploadNextArtikelbild()");
    let index = this.artikelbilderFileUpload.files.findIndex(i => i['_alreadyUploaded'] == false);
    if (index >= 0) {
      let fileToUpload = this.artikelbilderFileUpload.files[index];
      this.artikelBildAktuellUploadingFile = fileToUpload; // merken für die progress-Rückmeldung
      console.log("ArtikelDetailComponent.uploadNextArtikelbild() next fileToUpload:", fileToUpload);

      // nicht nur das file uploaden, sondern auch die Entity "Artikelbild"
      let artikelbildForCreate = this.artikelbildService.initializeArtikelbild();
      delete artikelbildForCreate._bild; // sonst kommen die Parameter beim Controller nicht an!
      let dateiendung = fileToUpload.name.substr(fileToUpload.name.lastIndexOf('.') + 1);
      let dateiname = fileToUpload.name.substr(0, fileToUpload.name.length - (dateiendung.length + 1));
      artikelbildForCreate.dateiname = dateiname;
      artikelbildForCreate.dateiendung = dateiendung;
      artikelbildForCreate.bezeichnung = fileToUpload.name;
      artikelbildForCreate.sortierung = 99;

      this.artikelbildService.uploadArtikelbildToTemp(fileToUpload, artikelbildForCreate, this, this.uploadProgress, this.uploadSuccess);
    }
  }

  uploadProgress(thisInstance: ArtikelDetailComponent, progress: number) {
    console.log("ArtikelDetail.uploadProgress() progress:", progress);
    //thisInstance.messageWrapperService.postTimedMessage({severity: 'info', summary: 'Upload: '+progress, detail: ''}); 

    if (thisInstance.artikelBildAktuellUploadingFile != null) {
      thisInstance.artikelBildAktuellUploadingFile['_percentUploaded'] = progress;
    }
  }
  uploadSuccess(thisInstance: ArtikelDetailComponent, eventbody: any) {
    console.log("ArtikelDetail.uploadSuccess()");
    let originalFileName: string = eventbody.originalFileName;
    let guid: string = eventbody.guid;
    console.log("ArtikelDetail.uploadSuccess() originalFileName:", originalFileName);
    console.log("ArtikelDetail.uploadSuccess() guid:", guid);
    thisInstance.messageWrapperService.postTimedMessage({ severity: 'info', summary: 'Datei ' + originalFileName + ': Upload erfolgreich!'/*+guid*/, detail: '' });

    let dateiendung = originalFileName.substr(originalFileName.lastIndexOf('.') + 1);
    let dateiname = originalFileName.substr(0, originalFileName.length - (dateiendung.length + 1));
    // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
    /*
    let newArtikelbild : IArtikelbild = thisInstance.artikelbildService.initializeArtikelbild();
    newArtikelbild.dateiname = dateiname;
    newArtikelbild.dateiendung = dateiendung;
    newArtikelbild.bezeichnung = originalFileName;
    newArtikelbild.guid = guid;
    newArtikelbild.sortierung = 99;
    newArtikelbild.id = 0;
    thisInstance.CRUDItem.artikelbilder.push(newArtikelbild);
    */

    // das File als uploaded markieren
    let artikelbilderFilesMitDemDateinamen = thisInstance.artikelbilderFileUpload.files.filter(f => f.name == originalFileName);
    artikelbilderFilesMitDemDateinamen.forEach(file => {
      file['_alreadyUploaded'] = true;
      file['_percentUploaded'] = 100;
      file['_guid'] = guid;
    });

    console.log("ArtikelDetailComponent.uploadSuccess() fileUpload.files:", thisInstance.artikelbilderFileUpload.files);

    // in gallery eintragen
    let newImage: Image = new Image();
    newImage.title = originalFileName;
    newImage.thumbnailImageSrc = thisInstance.artikelbildService.getThumbDownloadUrl(guid, dateiendung);
    newImage.previewImageSrc = thisInstance.artikelbildService.getDownloadUrl(guid, dateiendung);
    thisInstance.artikelBilderGalleryImages.push(newImage);

    // war das das 1. Bild ? dann einmalig auf Gallery switchen (nur beim 1. Mal, danach soll User manuell switchen)
    /*if(thisInstance.artikelBilderAlreadySwitchedToGallery == false && thisInstance.artikelBilderGalleryImages.length == 1) {
      thisInstance.artikelBilderDisplayGalleryOrUpload = 'G';
    }
    thisInstance.artikelBilderAlreadySwitchedToGallery = true;*/

    // 1. Bild ? Dann als Titelbild markieren
    if (thisInstance.artikelBilderGalleryImages.length == 1) {
      console.log("ArtikelDetailComponent.uploadSuccess() setting artikelBilderTitlePicGuid:", guid);
      thisInstance.artikelBilderTitlePicGuid = guid;
      thisInstance.artikelbilderUseAsEditorBackground();
    }

    thisInstance.artikelBildAktuellUploadingFile = null; // merken für die progress-Rückmeldung

    // jetzt (nachdem 1 File fertig ist) -> das nächste uploaden.
    setTimeout(() => {
      thisInstance.uploadNextArtikelbild();
    }, 200);
  }
  onNGFileInputRemove($event) {
    console.log("ArtikelDetail.onNGFileInputRemove()");
  }
  artikelbilderDeleteFile(file) {
    console.log("ArtikelDetail.artikelbilderDeleteFile() file:", file);
    let index = this.artikelbilderFileUpload.files.findIndex(f => f == file);
    if (index >= 0) {
      console.log("ArtikelDetail.artikelbilderDeleteFile() deleting file:", file);

      // zuvor: ist das zu löschende Bild das Titelbild ? Dann autom. das 1. verbleibende als Titelbild wählen
      let titelbidlGeloescht: boolean = this.artikelbilderFileUpload.files[index]['_guid'] == this.artikelBilderTitlePicGuid;

      //delete this.artikelbilderFileUpload.files[index];
      this.artikelbilderFileUpload.files.splice(index, 1);

      // von Gallery löschen
      this.artikelBilderGalleryImages.splice(index, 1);

      // NICHT aus CRUDItem löschen - weil:
      // this.artikelbilderFileUpload.files + this.artikelBilderGalleryImages IMMER GLEICHZIEHEN, this.CRUDItem.artikelbilder nur bei getValuesFromForm akualisieren!
      //this.CRUDItem.artikelbilder.splice(index, 1);

      // war das zu löschende Bild das Titelbild ? Dann autom. das 1. verbleibende als Titelbild wählen
      if (titelbidlGeloescht == true) {
        if (this.artikelbilderFileUpload.files.length > 0) this.artikelBilderTitlePicGuid = this.artikelbilderFileUpload.files[0]['_guid'];
        else this.artikelBilderTitlePicGuid = "00000000";
      }
    }
    else {
      console.log("ArtikelDetail.artikelbilderDeleteFile() file to delete NOT FOUND!");
    }
  }
  debugFile(file) {
    console.log("ArtikelDetail.debugFile() file:", file);
  }
  /*artikelBilderChangeView() {
    console.log("ArtikelDetail.artikelBilderChangeView()");
    if(this.artikelBilderDisplayGalleryOrUpload == 'U' && this.artikelBilderGalleryImages != null && this.artikelBilderGalleryImages.length > 0)
      this.artikelBilderDisplayGalleryOrUpload = 'G';
    else 
      this.artikelBilderDisplayGalleryOrUpload = 'U';
  }*/
  artikelbilderSetTitlePic(guid: string) {
    console.log("ArtikelDetail.artikelbilderSetTitlePic()");
    this.artikelBilderTitlePicGuid = guid;
    this.artikelbilderUseAsEditorBackground();
  }

  /*public uploadFile = (files) => { // https://code-maze.com/upload-files-dot-net-core-angular/
    console.log("ArtikelDetailComponent.uploadFile() files:", files);
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    let httpClient = this.artikelbildService.getHTTPClient();
    let apiBaseURL = this.artikelbildService.getApiBaseUrl();
    httpClient.post(apiBaseURL + 'artikelbilder', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        console.log("ArtikelDetail.uploadFile() event:", event);
        //if (event.type === HttpEventType.UploadProgress) {
          //let progress = Math.round(100 * event.loaded / event.total);
          //console.log("ArtikelDetail.uploadFile() progress:", progress);
        //}
        //else if (event.type === HttpEventType.Response) {
        //  console.log("ArtikelDetail.uploadFile() success!");
        //}
      });
  }*/






  /*uploadedFiles: any[] = [];
  onUpload(onUpload) {
    console.log("ArtikelDetailComponent.onUpload() onUpload:", onUpload);

    //for(let file of event.files) {
    //    this.uploadedFiles.push(file);
    //}

    this.messageWrapperService.postTimedMessage({severity: 'info', summary: 'File Uploaded', detail: ''}); 
  }*/
  toggleTag() {
    this.showWarningMsg = true;
    if (this.CRUDItem.bezeichnung && this.CRUDItem.Hersteller && this.CRUDItem.Artikelnummer != null) {
      this.showWarningMsg = false;
    }
  }
  vermerkFunction() {
    this.CRUDItem.verlademoeglichkeit = !this.CRUDItem.verlademoeglichkeit;
    console.log(this.CRUDItem.verlademoeglichkeit)
    if (this.CRUDItem.verlademoeglichkeit == true) {

      this.disabledVermerk = false;

    } else {
      this.disabledVermerk = true;
    }
  }

  nutzungsbedingungChanged() {
    if (this.CRUDForm.value._AGBAkzeptiert == false) {
      this.CRUDForm.patchValue({ // wieder auf null, damit greift wieder der validator (der null nicht akzeptiert) -> man muss neu anklicken
        _AGBAkzeptiert: null
      });
    }
  }

  formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors: any[] = [];
    let errorsFound: boolean = false;

    //console.log("ArtikelDetailComponent.formValidator() form:", form);

    // dazu erstmal an die in der Form verlinkte Component kommen
    let thisInstance: ArtikelDetailComponent = null;
    if (form['___component'] != null && form['___component'].dataId != null && form['___component'].dataId == 0) {
      thisInstance = form['___component'];

      if (thisInstance.CRUDItem != null && thisInstance.CRUDItem.typ == 'A') { // nur bei Artikel
        let valueArtikelGruppe = thisInstance.artikelGruppeSelected;
        if (valueArtikelGruppe == null) {
          errors['Artikelgruppe_ist_erforderlich'] = true;
          errorsFound = true;
        }

        let valueartikelLieferarten = thisInstance.artikelLieferartenSelected;
        if (valueartikelLieferarten == null || valueartikelLieferarten.length == 0) {
          errors['Lieferart_ist_erforderlich'] = true;
          errorsFound = true;
        }
      }
    }

    let valueBaustelleAnzeigen = form.value._baustelleAnzeigen;
    let valueLager = form.value.lager;
    let valueBaustelle = form.value.baustelle;
    let valueStrasse = form.value.strasse;
    //let valuePostleitzahl = form.value.postleitzahl;
    //let valueOrt = form.value.ort;
    let valueGeodaten = form.value.geodaten;
    let valueBaustelle_Gueltigkeit = form.value.baustelle_Gueltigkeit;
    //console.log("ArtikelDetailComponent.formValidator() valueBaustelleAnzeigen:", valueBaustelleAnzeigen);

    if (thisInstance != null && thisInstance.CRUDItem != null && thisInstance.CRUDItem.typ == 'A') { // nur bei Artikel
      if (valueBaustelleAnzeigen != null && valueBaustelleAnzeigen == false && valueLager == null) {
        errors['Lager_ist_erforderlich'] = true;
        errorsFound = true;
      }
      if (valueBaustelleAnzeigen != null && valueBaustelleAnzeigen == true) {
        if (valueBaustelle == null) {
          errors['Baustelle_ist_erforderlich'] = true;
          errorsFound = true;
        }
        if (valueStrasse == null) {
          errors['Strasse_ist_erforderlich'] = true;
          errorsFound = true;
        }
        /*if(valuePostleitzahl == null) {
          errors['Postleitzahl_ist_erforderlich']=true;
          errorsFound = true;
        }
        if(valueOrt == null) {
          errors['Ort_ist_erforderlich']=true;
          errorsFound = true;
        }*/
        if (valueGeodaten == null) {
          errors['Geodaten_sind_erforderlich'] = true;
          errorsFound = true;
        }
        if (valueBaustelle_Gueltigkeit == null) {
          errors['Baustelle_Gueltigkeit_ist_erforderlich'] = true;
          errorsFound = true;
        }
      }
    }

    //form.setErrors(errorsFound ? errors : null);
    if (errorsFound) return errors;
    else return null;
  }

  baustelleToggleAnzeigen() {
    //this.baustelleAnzeigen = this.baustelleAnzeigen == true ? false : true;
    //console.log("ArtikelDetailComponent.baustelleToggleAnzeigen() baustelleAnzeigen:", this.baustelleAnzeigen);
    if (this.CRUDForm.value._baustelleAnzeigen == true) {
      if (this.CRUDForm.value.baustelle_Gueltigkeit == null) {
        this.CRUDForm.patchValue({
          baustelle_Gueltigkeit: moment('9999-12-31T00:00:00+00:00').toDate()
        });

      }
    }
  }


  vorschauShow() {
    console.log("ArtikelDetailComponent.vorschauShow()");
    let vorschauCRUDItemHelper: any;
    if (this.dataId != null) {
      vorschauCRUDItemHelper = cloneDeep(this.CRUDItem);
    }
    else {
      vorschauCRUDItemHelper = this.crudItemService.initializeArtikel();
    }
    let a = Object.assign({}, vorschauCRUDItemHelper, this.CRUDForm.value);

    this.vorschauCRUDItem = this.getValuesFromForm_Artikel(a, true);
    // über getValuesFromForm() bekommen wir die artikelGruppe nicht, daher:
    let artikelgruppe: IArtikelgruppe = this.artikelgruppeService.initializeArtikelgruppe();
    if (this.artikelGruppeSelected != null) {
      artikelgruppe.id = this.artikelGruppeSelected.data.id;
      artikelgruppe.bezeichnung = this.artikelGruppeSelected.label;
      artikelgruppe['summary'] = this.artikelGruppeSelected.label;
      this.vorschauCRUDItem.artikelgruppe = artikelgruppe;
    }

    if (this.debugMode == true) {
      console.log("ArtikelDetailComponent.vorschauShow() vorschauCRUDItem:", this.vorschauCRUDItem);
      //console.log("ArtikelDetailComponent.vorschauShow() artikelGruppeSelected:", this.artikelGruppeSelected);
    }

    // back button soll abgefangen werden, wenn man grad die Vorschau sieht: https://stackoverflow.com/questions/36357204/how-to-disable-browser-back-button-in-angular-2
    // siehe auch vorschauShow() / constructor()
    history.pushState(null, null, window.location.href);

    this.vorschauDisplay = true;
    switch (true) {
      case this.CRUDItem.typ == 'W': {
        this.pageTitle = this.translate.instant("Vorschau Werbeanzeige", true) + ': ' + /*this.CRUDItem.bezeichnung*/this.getCRUDBezeichnung();
        break;
      }
      case this.CRUDItem.typ == 'S': {
        this.pageTitle = this.translate.instant("Vorschau Suchanzeige", true) + ': ' + /*this.CRUDItem.bezeichnung*/this.getCRUDBezeichnung();
        break;
      }
      default: {
        this.pageTitle = this.translate.instant("Vorschau Artikel", true) + ': ' + /*this.CRUDItem.bezeichnung*/this.getCRUDBezeichnung();
        break;
      }
    }
    this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;

    this.scrollToTop();
  }
  vorschauZurueckClicked() {
    this.vorschauDisplay = false;
    if (this.CRUDItem.id === 0 || this.CRUDItem.id === '0' || this.CRUDItem.id == null) { // '0' wegen Sonderfall aspnet_Roles, wo id = alpha ist (id = virtuell = eine Kopie von roleId)) 
      this.pageTitle = this.translate.instant(this.CRUDPageTitleNeu, true);
    }
    else {
      this.pageTitle = this.translate.instant(this.CRUDPageTitleBearbeiten, true) + ': ' + /*this.CRUDItem.bezeichnung*/this.getCRUDBezeichnung();
    }
    this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;

    this.scrollToTop();
  }
  vorschauSaveClicked() {
    this.vorschauDisplay = false;
    if (this.CRUDItem.id === 0 || this.CRUDItem.id === '0' || this.CRUDItem.id == null) { // '0' wegen Sonderfall aspnet_Roles, wo id = alpha ist (id = virtuell = eine Kopie von roleId)) 
      this.pageTitle = this.translate.instant(this.CRUDPageTitleNeu, true);
    }
    else {
      this.pageTitle = this.translate.instant(this.CRUDPageTitleBearbeiten, true) + ': ' + /*this.CRUDItem.bezeichnung*/this.getCRUDBezeichnung();
    }
    this.app.setPageTitle(this.pageTitle); //this.app.pageTitle = this.pageTitle;

    this.scrollToTop();
    this.validateAndSaveCRUDItem(true);
  }

  fillDemoData() {
    this.CRUDForm.patchValue({
      bezeichnung: "DemoArtikel",
      artikelnummer: "4711",
      _AGBAkzeptiert: true,
      ansprechpartner: {
        id: 2,
        nachname: "Buck",
        summary: "Tanja Buck",
        vorname: "Tanja"
      },
      zustand: {
        bezeichnung: "Gebraucht",
        id: 2,
        summary: "Gebraucht"
      },
      //hersteller: { // fehlt rowversion
      //  bezeichnung: "Diephaus",
      //  id: 3,
      //  summary: "Diephaus"
      //},
      /*lager: {
        baustelle: false,
        bezeichnung: "Aussenlager Steinheim",
        id: 1,
        ort: "Steinheim",
        postleitzahl: "71711",
        strasse: "Im Kreuzwegäcker 11",
        summary: "Aussenlager Steinheim"
      },*/
      mengeneinheit: {
        bezeichnung: "Stück",
        id: 4,
        pauschal: false,
        summary: "Stück"
      }
    })

    /*this.artikelGruppeSelected = {
      children: [],
      data: {id: 12, artikelgruppeId: 10},
      key: "12",
      label: "Beschläge"
    }
    this.CRUDForm.updateValueAndValidity();*/
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  alignGalleriaAtBottom() {
    this.galleriaFixer.alignGalleriaAtBottom('artikelDetailGalleria', this.artikelBilderGalleryImages, false);
  }

  galleriaShowFullscreen() {
    this.galleriaDisplayFullscreen = true
    setTimeout(() => {
      this.galleriaFixer.alignGalleriaAtBottom('artikelDetailGalleriaFullscreen', this.artikelBilderGalleryImages, true);
    }, 300);
  }

  mengenenheitSubscription() {
    this.CRUDForm.get('mengeneinheit').valueChanges.subscribe(
      mengeneinheitValue => {
        console.log("ArtikelDetail.mengenenheitSubscription() Mengeneinheit Value", mengeneinheitValue);
        if (mengeneinheitValue != null && mengeneinheitValue != false) {
          this.radioBtnMengeneinheitLabel = this.pro + mengeneinheitValue.summary;
        } else if (this.radioBtnMengeneinheitLabel == null) {
          this.radioBtnMengeneinheitLabel = this.proEinheit;
        }

      })
  }

  breadBrumbClicked(event: any) {
    console.log("ArtikelDetail.breadBrumbClicked() event:", event);

    this.artikelGruppeSelected = this.artikelGruppeFindTreeNode.artikelGruppeFindTreeNodeInChilds(this.artikelGruppeTreeNodes, event.item.id);
    this.artikelGruppeBuildBreadCrumb();

  }

  artikelbilderUseAsEditorBackground() {
    console.log("ArtikelDetail.artikelbilderUseAsEditorBackground() artikelbilderFileUpload.files:", this.artikelbilderFileUpload.files);
    console.log("ArtikelDetail.artikelbilderUseAsEditorBackground() artikelBilderTitlePicGuid:", this.artikelBilderTitlePicGuid);
    console.log("ArtikelDetail.artikelbilderUseAsEditorBackground() this.CRUDItem.artikelbilder:", this.CRUDItem != null ? this.CRUDItem.artikelbilder : null);
    /*if(this.artikelbilderFileUpload.files != null && this.artikelbilderFileUpload.files.length > 0) {
      // das Titelbild als Hintergrund des Quill-Editors
      document.getElementsByClassName("ql-editor")[0]['style'].backgroundImage="url('"+this.artikelbilderFileUpload.files[0]['objectURL']+"')";
    }
    else {
      document.getElementsByClassName("ql-editor")[0]['style'].backgroundImage=null;
    }*/

    let backgroundImageSet = false;
    if (this.CRUDItem != null && this.CRUDItem.typ == 'W') {
      if (this.artikelBilderTitlePicGuid != null) {
        let indexOfTitelbild = this.artikelbilderFileUpload.files.findIndex(f => f['_guid'] == this.artikelBilderTitlePicGuid);
        if (indexOfTitelbild >= 0) {
          console.log("ArtikelDetail.artikelbilderUseAsEditorBackground() indexOfTitelbild:", indexOfTitelbild);
          let artikelbild = this.artikelbilderFileUpload.files[indexOfTitelbild]
          console.log("ArtikelDetail.artikelbilderUseAsEditorBackground() artikelbild:", artikelbild);
          //document.getElementsByClassName("ql-editor")[0]['style'].backgroundImage="url('"+this.artikelbilderFileUpload.files[indexOfTitelbild]['objectURL']+"')";
          let guid = artikelbild['_guid'];
          let dateiendung = artikelbild.name.substr(artikelbild.name.lastIndexOf('.') + 1);
          let url = this.artikelbildService.getDownloadUrl(guid, dateiendung);
          document.getElementsByClassName("ql-editor")[0]['style'].backgroundImage = "url('" + url + "')";
          backgroundImageSet = true;
        }
      }
    }
    if (backgroundImageSet == false) {
      console.log("ArtikelDetail.artikelbilderUseAsEditorBackground() clear background");
      document.getElementsByClassName("ql-editor")[0]['style'].backgroundImage = null;
    }
  }

  artikelBezeichnungHelpClicked() {
    console.log("ArtikelDetail.artikelBezeichnungHelpClicked()");
    //this.messageService.add({severity: 'info', summary: 'Bezeichnung der Werbung', detail: 'Bezeichnung wird nicht angezeigt, wird aber bei der Suche berücksichtigt'});
    this.artikelBezeichnungShowHelpDialog = true;
  }
}


