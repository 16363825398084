import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { format } from 'path';

@Pipe({
    name: 'mycurrency',
})
export class CustomCurrencyPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (args != null || args != undefined) {
            switch (args) {
                case 'money':
                    if (value == null) return null;
                    else return this.formatMoney(value, 2, ',', '.') + '\xa0' + '€' + '\xa0'
                case 'menge':
                    if (value == null) return null;
                    else return this.formatMoney(value, 3, ',', '.');
                case 'ust':
                    if (value < 1) return value * 100
                    else return value;
                case 'dbprozent':
                    if (isNaN(value)) {
                        if (value == undefined || value == null || isNaN(value)) {
                            let zero = 0;
                            return zero.toFixed(2) + ' %';
                        }
                        value = Number(value);
                    }
                    if (value != null) {
                        value = Number(value);
                        return value.toFixed(2) + ' %';
                    }

                    else
                        return 0.00 + ' %';
                default:
                    return this.formatMoney(value, 2, ',', '.');
            }
        }
    }
    retransform(value: any): any {
        return value.split("\xa0")[0].replaceAll(".","").replaceAll(",",".");
    }
    formatMoney(Betrag, gerundeteStellen, dezimalTrennzeichen, tausenderTrennzeichen) {
        var n: any = this.rundeKaufmaennisch(Betrag, gerundeteStellen),
            gerundeteStellen: any = isNaN(gerundeteStellen = Math.abs(gerundeteStellen)) ? 2 : gerundeteStellen,
            dezimalTrennzeichen: any = dezimalTrennzeichen == undefined ? "." : dezimalTrennzeichen,
            tausenderTrennzeichen: any = tausenderTrennzeichen == undefined ? "," : tausenderTrennzeichen,
            vorzeichen: any = n < 0 ? "-" : "",
            i: any = parseInt(n = Math.abs(+n || 0).toFixed(gerundeteStellen)) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;
        return vorzeichen + (j ? i.substr(0, j) + tausenderTrennzeichen : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + tausenderTrennzeichen) + (gerundeteStellen ? dezimalTrennzeichen + Math.abs(n - i).toFixed(gerundeteStellen).slice(2) : "");
    }

    rundeKaufmaennisch(betrag, gerundeteStellen) {
        if (betrag != null) {
            if (!betrag.isNaN) {
                gerundeteStellen = isNaN(gerundeteStellen = Math.abs(gerundeteStellen)) ? 2 : gerundeteStellen;
                let ergebnis = Math.round(betrag * Math.pow(10, gerundeteStellen)) / Math.pow(10, gerundeteStellen);
                return ergebnis;
            }
        }
    }

}