<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
        <div #divCardToolbar class="card">
          <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
          [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
          [class.card-toolbar-slim-active]="app.isSlim()"-->
          <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <span>
            <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
            <button style="width: auto;" pButton type="submit" [icon]="CRUDButtonSaveIcon" [label]="translate.instant(CRUDButtonSaveLabel,true)"></button>
          </span>
          <span class="floatRight">
            <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
            <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
              (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
          </span>
        </div>

        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card">
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
      </form>
    </div>
  </div>
</div>
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>

<!-- ^ hierhin das gesamte CRUDBasicDetail kopieren ! ^ -->
<ng-template #CRUDChildTemplate>
            <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText [formGroup]="CRUDForm" formControlName="aspNetUserId">
                <label>{{'Asp Net User Id'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.aspNetUserId}}
              </div>
            </div>-->

            <div [formGroup]="CRUDForm" class="p-col-6 inputTextForm" [style.display]="CRUDForm.value.istBenutzer == true ? 'unset' : 'none'">
                <span class="md-inputfield">
                  <input style="width:90%" type="text" pInputText [formGroup]="CRUDForm" formControlName="_username"
                  [readonly]="CRUDItem.istBenutzer == true && CRUDItem.id > 0">
                  <label>{{'Benutzername'|translate:true}} *</label>
                </span>
              </div>
              <div class="p-col-6" [style.display]="CRUDForm.value.istBenutzer == true ? 'unset' : 'none'">
                <div class="errorMessage">
                  {{displayMessage._username}}<!-- funktioniert bei async validator so nicht, weil displayMessage vermutlich erst nach verlassen des Feldes aktualisiert wird, daher:-->
                  {{CRUDForm != null && (CRUDForm.controls._username.dirty || CRUDForm.controls._username.touched) && CRUDForm.controls._username.errors != null && CRUDForm.controls._username.errors.Benutzername_exists != null && displayMessage._username == "" ? translate.instant('Benutzername_exists',true) : null}}

                  {{displayMessageForm.Benutzername_ist_erforderlich}}
                  {{displayMessageForm.Benutzername_enthaelt_ungueltige_Zeichen}}
                  {{displayMessageForm.Benutzername_enthaelt_ungueltige_Zeichen != null ? ": "+benutzerService.validateUserName(CRUDForm.value._username) : null}}
                </div>
              </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input style="width:90%" type="text" pInputText [formGroup]="CRUDForm" formControlName="vorname">
                  <label>{{'Vorname'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.vorname}}
                </div>
              </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText [formGroup]="CRUDForm" formControlName="nachname">
                <label>{{'Nachname'|translate:true}} *</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.nachname}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="md-inputfield">
                <input style="width:90%" type="text" pInputText formControlName="telefon">
                <label>{{'Telefon'|translate:true}}</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.telefon}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input style="width:90%" type="text" pInputText formControlName="mobil">
                  <label>{{'Mobil'|translate:true}}</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.mobil}}
                </div>
              </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <input style="width:90%" type="text" pInputText [formGroup]="CRUDForm" formControlName="email">
                  <label>{{'E-Mail'|translate:true}} *</label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.email}}<!-- funktioniert bei async validator so nicht, weil displayMessage vermutlich erst nach verlassen des Feldes aktualisiert wird, daher:-->
                  {{CRUDForm != null && (CRUDForm.controls.email.dirty || CRUDForm.controls.email.touched) && CRUDForm.controls.email.errors != null && CRUDForm.controls.email.errors.eMail_exists != null && displayMessage.email == "" ? translate.instant('eMail_exists',true) : null}}
                </div>
              </div>

              <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <span id="">Benutzer mit Login-Berechtigung</span>&nbsp;<p-inputSwitch formControlName="istBenutzer"
                  ></p-inputSwitch><!--style="margin-left: 10px; (onChange)="istBenutzer_changed()""-->
                </span>
              </div>
            
              
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.istBenutzer}}
                </div>
              </div>

              <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
                <span class="md-inputfield">
                  <span id="">Ansprechpartner für die Artikel&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <p-inputSwitch formControlName="istAnsprechpartner" 
                  ></p-inputSwitch><!--style="margin-left: 10px; (onChange)="istBenutzer_changed()""-->
                </span>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="errorMessage">
                  {{displayMessage.istAnsprechpartner}}
                </div>
              </div>

              <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" [style.display]="CRUDForm.value.istBenutzer == true && (CRUDItem.istBenutzer == false || CRUDItem.id <= 0) ? 'unset' : 'none'">
                  <span class="md-inputfield">
                      <input formControlName="_password" autocomplete="new-password" [type]="passwordVisible ? 'text' : 'password'" class="inputFields"
                      promptLabel="Bitte tragen Sie ein Passwort ein" weakLabel="schwach" mediumLabel="mittel"
                      strongLabel="stark" pPassword style="width: 92% !important" />
                    <!-- width 92%, damit noch das auge dahinterpasst -->&nbsp;<i class="fa fa-fw"
                      [ngClass]="{'fa-eye-slash': passwordVisible, 'fa-eye': !passwordVisible}" (click)="passwordVisible = !passwordVisible"
                      title="{{ passwordVisible ? translate.instant('verstecken',true) : translate.instant('anzeigen',true)}}"
                      style="cursor: pointer;"></i>
                      <label>{{'Passwort'|translate:true}} *</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-6" [style.display]="CRUDForm.value.istBenutzer == true && (CRUDItem.istBenutzer == false || CRUDItem.id <= 0) ? 'unset' : 'none'">
                  <div class="errorMessage">
                    {{displayMessage._password}}
                    {{displayMessageForm.Passwort_ist_erforderlich}}
                  </div>
                </div>

                <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" [style.display]="CRUDForm.value.istBenutzer == true && (CRUDItem.istBenutzer == false || CRUDItem.id <= 0) ? 'unset' : 'none'">
                    <span class="md-inputfield">
                        <input formControlName="_passwordBestaetigen" [type]="passwordVisible ? 'text' : 'password'"
                        autocomplete="new-password" [feedback]="false" class="inputFields"
                        pPassword style="width: 92% !important" />
                        <label>{{'Passwort best&auml;tigen'|translate:true}} *</label>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-6" [style.display]="CRUDForm.value.istBenutzer == true && (CRUDItem.istBenutzer == false || CRUDItem.id <= 0) ? 'unset' : 'none'">
                    <div class="errorMessage">
                      {{displayMessage._passwordBestaetigen}}
                      {{displayMessageForm.Die_Passwoerter_stimmen_nicht_ueberein}}
                    </div>
                  </div>

</ng-template>
    
