import {AppMainComponent} from './app.main.component';
import { AppComponent } from './app.component';
import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import {trigger, state, transition, style, animate} from '@angular/animations';
import {AuthService} from './auth/auth.service';
import { Router } from '@angular/router';
import { IBenutzer } from './_interfaces/benutzer';

@Component({
    selector: 'app-inline-profile',
    template: `
    `,
    animations: [
        trigger('menu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppInlineProfileComponent {

    active: boolean;
    benutzer: IBenutzer = null;

    constructor(
        @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
        public appMain: AppMainComponent, 
        public authService: AuthService,
        private router: Router
        ) {}

    onClick(event) {
        console.log("AppInlineProfileComponent.onClick() event:", event);
        this.active = !this.active;
        event.preventDefault();

        //this.logout(); 
    }

    logout() {
        this.authService.logout()
        .subscribe(success => {
          if (success) {
            this.router.navigate(['/login']);
          }
        });
      }

      meinBenutzerClicked() {
        this.router.navigate(['/unternehmen', this.app.benutzer.unternehmenId]);
      }
}
