import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IArtikel } from '../_interfaces/artikel';
import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';
import { ILieferart } from '../_interfaces/lieferart';

@Injectable()
export class _ArtikelgruppenImportService extends CRUDBasicService {

  uploadArtikelgruppenImport(fileToUpload: File, artikelgruppenImportForCreate: /*IArtikelbild*/any, callbackInstance:any, callbackProgress:any, callbackSuccess:any, vorschau: boolean) {
    //https://code-maze.com/upload-files-dot-net-core-angular/
    //let fileToUpload : File = event.files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    
    // nicht nur das file uploaden, sondern auch die Entity 
    formData.append('artikelgruppenImport.dateiname', artikelgruppenImportForCreate.dateiname);
    formData.append('artikelgruppenImport.dateiendung', artikelgruppenImportForCreate.dateiendung);
    formData.append('artikelgruppenImport.bezeichnung', artikelgruppenImportForCreate.bezeichnung);

    this.httpClient.post(this.apiBaseUrl + 'artikelgruppenImport/upload/' + (vorschau ? 'true' : 'false'), formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        console.log("artikelgruppenImportService.upload() event:", event);
        if (event.type === HttpEventType.UploadProgress) {
          let progress = Math.round(100 * event.loaded / event.total);
          console.log("artikelgruppenImportService.upload() progress:", progress);
          callbackProgress(callbackInstance, progress);
        }
        else if (event.type === HttpEventType.Response) {
          console.log("ArtikelbildService.upload() success!");
          callbackSuccess(callbackInstance, event.body);
        }
      });
    }

    initializeartikelgruppenImport(): /*IartikelgruppenImport*/any {
      return {
        bezeichnung: '',
        dateiname: '',
        dateiendung: ''
      };
    }
}
