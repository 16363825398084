
//import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ViewChild, ElementRef, HostListener, Inject, forwardRef, Injector, ComponentFactoryResolver } from '@angular/core';
//import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
//import { ActivatedRoute, Router, ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy } from '@angular/router';

import { Component, OnInit } from '@angular/core';
//import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';

import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { TranslateService } from '../_services/translate.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  loginForm: FormGroup;
  disableFields: boolean = false;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router, 
    public translate: TranslateService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    });
  }

  get f() { return this.loginForm.controls; }

  login(username, email) {
    //this.router.navigate(['/unternehmen/0?email='+email+'&username='+username]);
  }

}

