import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Car } from '../domain/car';
import { Lagerbestand } from '../domain/lagerbestand';

@Injectable()
export class CarService {

    constructor(private http: HttpClient) { }
    getLagerbestand() {
        return this.http.get<any>('assets/demo/data/lagerbestand.json')
            .toPromise()
            .then(res => res.data as Lagerbestand[])
            .then(data => data);
    }
    getCarsSmall() {
        return this.http.get<any>('assets/demo/data/cars-small.json')
            .toPromise()
            .then(res => res.data as Car[])
            .then(data => data);
    }

    getCarsMedium() {
        return this.http.get<any>('assets/demo/data/cars-medium.json')
            .toPromise()
            .then(res => res.data as Car[])
            .then(data => data);
    }

    getCarsLarge() {
        return this.http.get<any>('assets/demo/data/cars-large.json')
            .toPromise()
            .then(res => res.data as Car[])
            .then(data => data);
    }
}
