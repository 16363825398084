import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { AppComponent } from '../app.component';
import { IArtikel } from '../_interfaces/artikel';
import { ArtikelbildService } from '../_services/artikelbild.service';

@Component({
  selector: 'app-werbeanzeige-darstellung-carousel',
  templateUrl: './werbeanzeige-darstellung-carousel.component.html',
  styleUrls: ['./werbeanzeige-darstellung-carousel.component.css']
})
export class WerbeAnzeigeDarstellungCarouselComponent implements OnInit {
  @Input('CRUDItem') CRUDItem: any;
  @ViewChild('uppermostDiv', { static: true }) public uppermostDiv/*: Element*/; 

  public mobileAnsicht: boolean = false;

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private artikelbildService: ArtikelbildService,
  ) {}

  ngOnInit(): void {
    this.mobileAnsicht = window.innerWidth <= 540;

    window.onresize = () => {
      this.mobileAnsicht = window.innerWidth <= 540;
    }
  }

  ngAfterViewInit() { 
    // dafür sorgen, dass die app-werbeanzeige-darstellung-carousel nicht in ein <span ng_content_xxx> verpackt wird, sonst
    // funktionieren die ganzen Styles nicht! Gelöst durch replace "span" by "child"
    // saubere Lösung könnte sein:
    // "containerless components" - dazu gibt es jede Menge feature-requests bei angular (ging wohl mit AngularJS "replace")
    // :host { display: contents; }   -> funktioniert aber (noch???) nicht, ist ein experimental feature in chrome
    let thisUppermostDiv = this.uppermostDiv.nativeElement;
    let parentNode = thisUppermostDiv.parentNode;
    let parentparentNode = parentNode.parentNode;
    console.log("WerbeAnzeigeDarstellungCarousel.ngAfterViewInit() thisUppermostDiv:", thisUppermostDiv);
    console.log("WerbeAnzeigeDarstellungCarousel.ngAfterViewInit() parentNode:", parentNode);
    console.log("WerbeAnzeigeDarstellungCarousel.ngAfterViewInit() parentparentNode:", parentparentNode);
    //parentparentNode.replaceChild(parentNode, thisUppermostDiv);
    let isIE = false || !!document['documentMode'];
    if (isIE == true) {
      parentNode.replaceNode(thisUppermostDiv); // IE!
    }
    else {
      parentNode.replaceWith(thisUppermostDiv); // nicht IE!
    }
  }

  artikelTitelBildURL(artikel: IArtikel) {
    //console.log("WerbeAnzeigeDarstellungCarousel.artikelTitelBildURL() artikel:", artikel);
    if(artikel.titelbild != null) {
      let dateiendung = artikel.titelbild.dateiendung;
      let guid = artikel.titelbild.guid;
      return this.artikelbildService.getThumbDownloadUrl(guid, dateiendung);
    }
    else return this.artikelbildService.getThumbDownloadUrlForTitelbildArtikel(artikel.id, artikel.rowVersion); // funktioniert nur, wenn Artikel bereits eine ID hat (bereits gespeichert ist)
  }
  onImgError(event) {
    event.target.src = 'assets/layout/images/imageNotFound.png';
  }
  /*moveImage(artikelnummer: string) {
    let image = document.getElementById("carouselImage_" + artikelnummer);
    //console.log("DashboardComponent.isPortrait() artikelnummer/image:", artikelnummer, image);
    let imgRect = image.getBoundingClientRect()

    //console.log("Dashboard.isElementVisible() imgRect:", imgRect);

    if (imgRect.width >= imgRect.height) {
      let overlayHeight = 25;
      return '-30px';
    }
    else return 'unset';
  }*/
}
