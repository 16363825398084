import {Component, OnInit, OnDestroy, Inject, forwardRef,} from '@angular/core';
import { AppconfigService } from './_services/appconfig.service';
import {AppMainComponent} from './app.main.component';
import { AppComponent } from './app.component';
import { MenuItem } from 'primeng';
import { BreadcrumbService } from './breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {TreeNode} from 'primeng/api';
import * as cloneDeep from 'lodash/cloneDeep'; 

// Artikelgruppe
import { IArtikelgruppe } from './_interfaces/artikelgruppe';
import { ArtikelgruppeService } from './_services/artikelgruppe.service';
import { timeout } from 'rxjs/operators';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, OnDestroy {

    model: /*MenuItem*/any[]; 
    breadCrumbItems: MenuItem[]; 

    // Artikelgruppe
    artikelGruppeItems: IArtikelgruppe[];
    artikelGruppeTreeNodes: TreeNode[];
    artikelGruppeSelected: TreeNode; 

    // Import Vorlage
    urlAPIDownloadArtikelImportTemplate: string;
    
    constructor(@Inject(forwardRef(() => AppComponent)) public app: AppComponent,
        private config: AppconfigService,
        private router: Router,
        public appMain: AppMainComponent, 
        private artikelgruppeService: ArtikelgruppeService,
        public breadcrumb: BreadcrumbService
        ) {}

    ngOnInit() {
        this.app.setAppMenu(this);

        this.urlAPIDownloadArtikelImportTemplate = this.config.get('apiBaseUrl') + "artikelimport/downloadtemplate";

        // alle Artikelgruppen auf einmal laden!
        let thisInstance = this;
        this.artikelgruppeService.getArtikelgruppenChildrenForTree().subscribe(function (response) {
            thisInstance.artikelGruppeItems = response.artikelgruppen;
            console.log("AppMenu.ngOnInit() getArtikelgruppenCollection thisInstance.artikelGruppeItems:", thisInstance.artikelGruppeItems);
            thisInstance.artikelGruppeTreeNodes = [];
            thisInstance.artikelGruppeItems.filter(f => f.artikelgruppeId == null).forEach(artikelGruppe => {
                thisInstance.artikelGruppeTreeNodes.push(thisInstance.artikelGruppePrepareTreeNode(thisInstance, 0, artikelGruppe));
            });
            console.log("AppMenu.ngOnInit() getArtikelgruppenChildrenForParent thisInstance.artikelGruppeTreeNodes:", thisInstance.artikelGruppeTreeNodes);
        }, function (error) { return this.handleError(error); });

    }

    ngAfterViewInit() {
      this.model = [
        {label: 'Dashboard', icon: 'dashboard', routerLink: ['/dashboard'], breadcrumb: '/dashboard'},
        {label: 'Übersicht', icon: 'search', /*routerLink: ['/artikel-uebersicht'],*/ command: this.routeToUebersicht, component: this, breadcrumb: '/artikel-uebersicht'},
        {label: 'Meine Artikel', icon: 'layers', /*routerLink: ['/artikel-uebersicht'], queryParams:{type:'myItems'},*/ command: this.routeToMyItems, component: this, breadcrumb: '/artikel-uebersicht'},
        {label: 'Meine Werbeanzeigen', icon: 'featured_video', /*routerLink: ['/artikel-uebersicht'], queryParams:{type:'myItems'},*/ command: this.routeToMyAdverts, component: this, breadcrumb: '/werbeanzeige-uebersicht'},
        {label: 'Meine Suchen', icon: 'pageview', /*routerLink: ['/artikel-uebersicht'], queryParams:{type:'myItems'},*/ command: this.routeToMyRequests, component: this, breadcrumb: '/suche-uebersicht'},
      ];

      // Admin ? dann weitere Menüpunkte
      setTimeout(() => {
        if(this.app.isAdmin() == true) {
          this.model.push(
            {label: '', icon: '', routerLink: [''], breadcrumb: ''}
          );
          this.model.push(
            {label: 'unternehmen', icon: 'warning', routerLink: ['/unternehmen'], breadcrumb: '/unternehmen'},
          );
          this.model.push(
            {label: 'import-artikelgruppen', icon: 'warning', routerLink: ['/import-artikelgruppen'], breadcrumb: '/import-artikelgruppen'},
          );
          this.model.push(
            {label: 'import-artikel/hersteller', icon: 'warning', routerLink: ['/import-artikel'], breadcrumb: '/import-artikel'}, 
          );
        }
      }, 3000);
    }
    
    artikelGruppePrepareTreeNode(instance: any, level:number, artikelGruppe:IArtikelgruppe) {
        //console.log("ArtikelDetailComponent.prepareArtikelGruppeTreeNode() level/artikelGruppe", level, artikelGruppe.bezeichnung);
        let children:TreeNode[] = [];
        artikelGruppe.inverseArtikelgruppe.forEach(child => {
          children.push(this.artikelGruppePrepareTreeNode(this, level+1, child));
        });
        let newTreeNode:TreeNode = {
          children: children,
          collapsedIcon: "ui-icon-folder",
          data: { // data enthält id und parentId der artikelGruppe
            id: artikelGruppe.id, 
            artikelgruppeId: artikelGruppe.artikelgruppeId
          },
          expandedIcon: "ui-icon-folder-open",
          label: artikelGruppe.bezeichnung /*+ " (id: "+artikelGruppe.id+")"*/,
          key: ""+artikelGruppe.id, // ohne den "key" funktioniert die Children-Auswahl NACH FILTER nicht! https://forum.primefaces.org/viewtopic.php?t=58524
          //expanded: artikelGruppe.artikelgruppeId == null // alle auf 1. Ebene aufklappen
        };
        return newTreeNode;
      }
      
      artikelGruppeOnSelected(event, thisInstance:any) {
        console.log("AppMenu.artikelGruppeOnSelected() event/thisInstance", event, thisInstance);
        this.artikelGruppeSelected = event.node;

        let artikelUebersicht = thisInstance.app.getArtikelUebersicht();

        if(artikelUebersicht == null) { // Dashboard noch nicht geöffnet: erst routen, dann einstellen
            /*this.router.navigate(['/artikel-uebersicht']);

            setTimeout(() => {
                artikelUebersicht = thisInstance.app.getArtikelUebersicht();
                if(artikelUebersicht != null) { // ArtikelUebersicht bereits offen: neue Suchkriterien einstellen
                    let eventClone = cloneDeep(event);
                    artikelUebersicht.artikelGruppeOnSelected(eventClone, artikelUebersicht);
                    artikelUebersicht.refresh();
                }
            }, 500);*/

            this.router.navigate(['/artikel-uebersicht'], { queryParams: { artikelgruppeId: event.node.data.id } });
        }
        else { // ArtikelUebersicht bereits offen: neue Suchkriterien einstellen
            let eventClone = cloneDeep(event);
            artikelUebersicht.openFilter();
            artikelUebersicht.artikelGruppeOnSelected(eventClone, artikelUebersicht);
            artikelUebersicht.refresh();
        }
      }

      public getArtikelGruppe() { // wird aus ArtikelUebersicht aufgerufen
        return cloneDeep(this.artikelGruppeSelected);
      }

      ngOnDestroy() {
        this.app.setAppMenu(null);
      }

      shouldReuse() {
        return true;
      }

      routeToUebersicht(event: any) {
        let thisInstance = event.item.component;
        // force reload! Falls man schon aus Artikel-Übersicht kommt, soll er das reloaden! // https://github.com/angular/angular/issues/13831
        thisInstance.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          thisInstance.router.navigateByUrl('/artikel-uebersicht');
        });
      }

      routeToMyItems(event: any) {
        let thisInstance = event.item.component;
        // force reload! Falls man schon aus Artikel-Übersicht kommt, soll er das reloaden! // https://github.com/angular/angular/issues/13831
        thisInstance.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          thisInstance.router.navigateByUrl('/artikel-uebersicht?overviewmode=myItems');
        });
      }

      routeToMyRequests(event: any) {
        let thisInstance = event.item.component;
        // force reload! Falls man schon aus Artikel-Übersicht kommt, soll er das reloaden! // https://github.com/angular/angular/issues/13831
        thisInstance.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          thisInstance.router.navigateByUrl('/suche-uebersicht?overviewmode=myRequests');
        });
      }

      routeToMyAdverts(event: any) {
        let thisInstance = event.item.component;
        // force reload! Falls man schon aus Artikel-Übersicht kommt, soll er das reloaden! // https://github.com/angular/angular/issues/13831
        thisInstance.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          thisInstance.router.navigateByUrl('/werbeanzeige-uebersicht?overviewmode=myAdverts');
        });
      }

    // Hier AM fragen zwecks Breadcrumb Funktion
    // breadCrumbUpdate() {
    //     this.breadCrumbItems = [];
    //     this.breadCrumbItems.push()
    //     this.breadcrumb.setItems()
    // }
}

