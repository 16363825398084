<input #inputWriteable id="inputWriteable" [(ngModel)]="editValue" (focus)="focus()" (blur)="blur()" (keydown.enter)="$event.target.blur();"
(click)="$event.target.select()" [readonly]="false" 
[ngStyle]="ngStyle" 
[style.display]="showWriteable ? '' :'none'" 
type="text" pInputText 
oninput="onInput___funktioniert_so_nicht___wird_aus_AfterViewInit_gesetzt($event)" 
(change)="onChange()" [disabled]="disabled"><!--[style.backgroundColor]="'green'"-->
<!-- type="number" step="1" -->
<!-- (keypress)="onKeyPress($event)"  -->
<!-- [style.textAlign]="'left'" -->
<input #inputFormatted id="inputFormatted" [readonly]="true" [value]="getFormatedValue()" (focus)="switchToWriteable()" pInputText 
[ngStyle]="ngStyle" 
[style.display]="showWriteable ? 'none' : ''" 
[style.color]="'black'">