import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    let isAdmin = this.authService.isAdmin();
    console.log("AdminGuard.canActivate() isAdmin:", isAdmin);

    // wenn man auf eine Admin-Seite wollte, aber nicht darf -> dashboard
    // das passiert z.B. bei "MeinBenutzer -> Save" -> statt in die Unternehmenübersicht (darf nur Admin) -> dashboard
    if(isAdmin == false) this.router.navigate(['/']); 
    
    return isAdmin; // gehört zum Unternehmen #Administration#
  }
}
