//import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';
import { CRUDBasicDetailForChildComponent } from '../crud-basic-detail-for-child/crud-basic-detail-for-child.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { SimpleChange, SimpleChanges } from '@angular/core';

import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
import { AutoComplete } from 'primeng/primeng';

//import { IAnsprechpartner } from '../_interfaces/ansprechpartner';
import { AnsprechpartnerService } from '../_services/ansprechpartner.service';
//import { AnsprechpartnerDetailGuard } from './ansprechpartner-detail.guard';
import { IBenutzer } from '../_interfaces/benutzer';

import { IUnternehmen } from '../_interfaces/unternehmen';
import { UnternehmenDetailComponent } from '../unternehmen-detail/unternehmen-detail.component';

import * as cloneDeep from 'lodash/cloneDeep'; 

//import { AnsprechpartnerDetailComponent_Template } from './ansprechpartner-detail.component.include_template';

@Component({
  selector: 'app-ansprechpartner-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${AnsprechpartnerDetailComponent_Template}`,
  templateUrl: './ansprechpartner-detail.component.html',
  
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class AnsprechpartnerDetailComponent extends CRUDBasicDetailForChildComponent implements OnInit {
  @Input('parentCrudItem') parentCrudItem: IUnternehmen; // AM Parent-ChildTab-CRUD
  @Input('parentCrudForm') parentCrudForm: any; // AM Parent-ChildTab-CRUD
  @Input('parentComponent') parentComponent: UnternehmenDetailComponent; 

  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Ansprechpartner";
  CRUDPageTitleNeu: string = this.translate.instant("Neuer Ansprechpartner", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Ansprechpartner bearbeiten", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Ansprechpartner";
  CRUDItemBezeichnungPluralCapitalized: string = "Ansprechpartner";
  CRUDItemBezeichnungSingular: string = "ansprechpartner";
  CRUDItemBezeichnungPlural: string = "ansprechpartner";
  CRUDItemRouteSingular: string = "ansprechpartner";
  CRUDItemRoutePlural: string = "ansprechpartner";
  CRUDItemHelpTopic: string = null;

  //debugMode: boolean = true;
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START

  benutzerForAutoComplete: IBenutzer[] = []; // Liste der Benutzer ds Unternehmens: enthält parentCrudItem.benutzer + parentComponent(also UnternehmenDetail.eigenerBenutzer).
  benutzerShowAutoComplete: boolean = false; // Sicherstellen, dass Autocomplete nicht angezeigt wird, bevor die options = benutzer gefüllt sind
  eigenerBenutzer: IBenutzer; // ggf. CLONE von this.parentComponent.eigenerBenutzer -> aber mit aktuellem summary etc.

  // wird immer dynamisch aufgebaut - Für Autocomplete der Benutzer 

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: AnsprechpartnerService,
    //private _guard: AnsprechpartnerDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    //this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      vorname: {
        required: this._translate.instant('Vorname', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Vorname', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      nachname: {
        required: this._translate.instant('Nachname', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Nachname', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      telefon: {
        maxlength: this._translate.instant('Telefon', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      },

      mobil: {
        maxlength: this._translate.instant('Mobil', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      },

      email: {
        maxlength: this._translate.instant('Email', true) + ' ' + this._translate.instant('darf NaN Zeichen nicht überschreiten', true)
      },

};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  /*ngOnChanges(changes: SimpleChanges) { // monitoring Input-Changes - nur monitoring/debug - ohne weitere Funktion!!! (kann man weglassen!)
    if(this.debugMode==true) console.log("AnsprechpartnerDetail.ngOnChanges():", changes);

    if(changes.parentCrudItem != null) {
      if(this.debugMode==true) console.log("AnsprechpartnerDetail.ngOnChanges().it's parentCrudItem!");
    }
    else {
      if(this.debugMode==true) console.log("AnsprechpartnerDetail.ngOnChanges().something else changed.");      
    }
  }*/

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      vorname: ['', [Validators.required, Validators.maxLength(255)]],
      nachname: ['', [Validators.required, Validators.maxLength(255)]],
      telefon: ['', [Validators.maxLength(NaN)]],
      mobil: ['', [Validators.maxLength(NaN)]],
      email: ['', [Validators.maxLength(NaN)]],
      benutzer: [null],
      _ansprechpartner_ist_auch_benutzer: [null],
      //unternehmen: [null],
    },{
      validator: this.formValidator // auf FormEbene!
    });
    
    this.CRUDForm['___component'] = this; // trick, um im formValidator wieder an die component zu kommen.
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    //let benutzerForAutoComplete = cloneDeep(this.parentCrudItem.benutzer);
    let benutzerForAutoComplete: IBenutzer[] = [];
    //this.parentCrudItem.benutzer.forEach(element => {
    //  benutzerForAutoComplete.push(element);
    //});
    benutzerForAutoComplete = this.parentCrudItem.benutzer.filter(f => this.parentComponent.eigenerBenutzer == null || f.id != this.parentComponent.eigenerBenutzer.id); // alle ausser der "eignerBenutzer"
    
    if(/*this.parentComponent.eigenerBenutzer != null*/this.parentComponent.eigenerBenutzerShow == true) {
      this.eigenerBenutzer = cloneDeep(this.parentComponent.eigenerBenutzer);
      // summary aus aktuellen Form-Werten bilden
      this.eigenerBenutzer.vorname = this.parentCrudForm.value._benutzer_vorname;
      this.eigenerBenutzer.nachname = this.parentCrudForm.value._benutzer_nachname;
      this.eigenerBenutzer.summary = this.parentCrudForm.value._benutzer_vorname + " " + this.parentCrudForm.value._benutzer_nachname;
      benutzerForAutoComplete.push(this.eigenerBenutzer);
    }
    this.benutzerForAutoComplete = benutzerForAutoComplete;
    let thisInstance = this;
    //setTimeout(function() {
      thisInstance.benutzerShowAutoComplete = true;
      console.log("AnsprechpartnerDetailComponent.ngOnInit() benutzerForAutoComplete:", thisInstance.benutzerForAutoComplete);
    //}, 5000);
    console.log("AnsprechpartnerDetailComponent.ngOnInit() this.parentCrudItem.benutzer:", this.parentCrudItem.benutzer);
    
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    a.benutzerId = a.benutzer != null ? a.benutzer.id : null;
    //a.unternehmenId = a.unternehmen != null ? a.unternehmen.id : null;
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("AnsprechpartnerDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      vorname: this.CRUDItem.vorname,
      nachname: this.CRUDItem.nachname,
      telefon: this.CRUDItem.telefon,
      mobil: this.CRUDItem.mobil,
      email: this.CRUDItem.email,
      //benutzer: this.CRUDItem.benutzer, // s.u.
      _ansprechpartner_ist_auch_benutzer: this.CRUDItem.benutzer != null,
      //unternehmen: this.CRUDItem.unternehmen
    });

    // warum auch immer muss der AutoComplete "mit vorbestückten options" zeitverzögert gesetzt werden, sonst geht der Wert unter
    let thisInstance = this;
    setTimeout(function(){
      debugger;
      let patchObject = {
        // wenn eigenerBenutzer, dann den CLONE davon einstellen, weil der hat aktuelle summary etc.
        benutzer: thisInstance.eigenerBenutzer != null && thisInstance.CRUDItem.benutzer != null && thisInstance.eigenerBenutzer.id == thisInstance.CRUDItem.benutzer.id ? thisInstance.eigenerBenutzer : thisInstance.CRUDItem.benutzer,
      };
      console.log("AnsprechpartnerDetailComponent.sendValuesToForm() patchObject:", patchObject);
      thisInstance.CRUDForm.patchValue(patchObject);
    }, 500);

    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("AnsprechpartnerDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  ansprechpartner_als_benutzer_changed() {
    console.log("AnsprechpartnerDetailComponent.ansprechpartner_als_benutzer_changed() parentCrudItem:", this.parentCrudItem);
    console.log("AnsprechpartnerDetailComponent.ansprechpartner_als_benutzer_changed() parentCrudForm:", this.parentCrudForm);
    if(this.CRUDForm.value._ansprechpartner_ist_auch_benutzer == true) { // Schalter wird aktiviert
      // nichts
    }
    else { // Schalter wird deaktiviert
      this.CRUDForm.patchValue({
        benutzer: null
      });
      this.CRUDForm.markAsDirty();
    }
  }

  formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors : any[] = [];
    let errorsFound : boolean = false;

    console.log("AnsprechpartnerDetailComponent.formValidator() form:", form);

    let valueBenutzer = form.value.benutzer;

    // dazu erstmal an die in der Form verlinkte Component kommen
    if(form['___component'] != null/* && form['___component'].dataId != null && form['___component'].dataId == 0*/) {
      let thisInstance = form['___component'];
      
      // Prüfen, ob der Benutzer nicht schon bei einem anderen Ansprechpartner hinterlegt ist
      if(valueBenutzer != null) {
        let idx = thisInstance.parentCrudItem.ansprechpartner.findIndex(a => a.id != thisInstance.CRUDItem.id && a.benutzer != null && a.benutzer.id == valueBenutzer.id);
        if(idx >= 0) {
          errors['Benutzer_ist_bereits_bei_anderem_Ansprechpartner_hinterlegt']=true;
          errorsFound = true;
        }
      }
    }

    //form.setErrors(errorsFound ? errors : null);
    if(errorsFound) return errors;
    else return null;
  }

  debug(obj) {
    console.log("AnsprechpartnerDetailComponent.debug() obj:", obj);
    console.log("AnsprechpartnerDetailComponent.debug() displayMessageForm:", this.displayMessageForm);
    super.debug(obj);
  }

}
