import { Component, OnInit, Inject, forwardRef,Injector,Input } from '@angular/core';
import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';
import { AppComponent } from '../app.component';

import { CarService } from '../demo/service/carservice';
import { CarouselModule } from 'primeng/carousel';
import { MenuItem } from 'primeng';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { TabMenuModule } from 'primeng/tabmenu';
import { PaginatorModule } from 'primeng/paginator';
import { templateJitUrl } from '@angular/compiler';
import { NavigationEnd } from '@angular/router';
//import { DashboardTimeline } from '../../domain/dashboardTimeline';
import { element } from 'protractor';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { ArtikelService } from '../_services/artikel.service';
import { ArtikelbildService } from '../_services/artikelbild.service';
import { IArtikel, IArtikelForCounting } from '../_interfaces/artikel';
import { IArtikelbild } from '../_interfaces/artikelbild';
import { ILieferart } from '../_interfaces/lieferart';
import { ArtikelLieferartService } from '../_services/artikel-lieferart.service';
import { IArtikelLieferart } from '../_interfaces/artikel-lieferart';
import { LieferartService } from '../_services/lieferart.service';
import { RSSService } from '../_services/rss.service';
import { FavoritService } from '../_services/favorit.service';
import {TooltipModule} from 'primeng/tooltip';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { ILager } from '../_interfaces/lager';
import { IArtikelgruppe } from '../_interfaces/artikelgruppe';
import { TranslateService } from '../_services/translate.service';
import { MessageWrapperService } from '../_services/message-wrapper.service'; 

import { LazyLoadEvent } from "primeng/api";
import { Form, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { IRSS } from '../_interfaces/RSS';
import * as moment from 'moment';


declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  host: {
    '(window:resize)': 'VirtualScrollerFixer()'
  }
})
export class DashboardComponent /*extends CRUDBasicDetailComponent*/ implements OnInit {
  @Input('CRUDItemAsParameter') CRUDItemAsParameter: any;
  pageTitle: string = null;

  isActive = false;
  cities1: SelectItem[];
 

  items: MenuItem[];
  activeItem: MenuItem;

  cars: any[];
  artikel: IArtikel[];
  rss: IRSS[];
  responsiveOptions;
  lager: ILager[];
  text: Text;
  timelineElements: any/*DashboardTimeline*/[];
  checked: boolean = true;
  unchecked: boolean = false;
  uncheckedTwo: boolean = false;
  cutTextLength: number = 200;
  artikelLieferartenSelectedAsString: string = "";
  artikelLieferartenSelected: IArtikelLieferart[];
  artikelLieferartenDisplayAuswahlPopUp: boolean;
  public mobileAnsicht: boolean = false;

  displayMessageForm: {};

  // VirtualScroller 
  virtualScrollerItems: any[];
  pendingLazyLoadEvents: LazyLoadEvent[] = [];
  lastLazyLoadEvent_first: number = null;
  killScroller: boolean = false;
  loading: boolean;
  totalRecords: number = null;
  rows: number = 30;
  currentPage: number;
  scrollHeight: string = null;
  lastScrollPosition: number; // bei lazyLoad / virtualScroll: nach dem reuse Component: wieder dort hinscrollen, wo er war

  // neuen Artikel einstellen
  newArtikelForm: FormGroup = null;

  // Aktive / Lagerbestand / Gesamt / Favoriten
  counters: IArtikelForCounting = null;

  debugMode: boolean = false;

  hideCarouselImg: boolean = false;

  constructor(@Inject(forwardRef(() => AppComponent))public _app, public app: AppComponent, 
  private _injector: Injector,
    private carService: CarService,
    private _router: Router,
    public artikelService: ArtikelService,
    private lieferartService: LieferartService,
    private artikelLieferartService: ArtikelLieferartService,
    private favoritService: FavoritService,
    private breadcrumbService: BreadcrumbService,
    private rssService: RSSService,
    private artikelbildService: ArtikelbildService,
    private fb: FormBuilder,
    private router: Router,
    protected translate: TranslateService,
    private messageWrapperService: MessageWrapperService)  
    {
      //super(_app, _injector); // US 21040
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: '1',
        numScroll: 1
      }
    ];

    this.timelineElements = [
      {
        datum: new Date(),
        ueberschrift: 'Testüberschrift',
        showFull: false,
        fullText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'
      },
      {
        datum: new Date(),
        ueberschrift: 'Test Zwei',
        showFull: false,
        fullText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'
      },
      {
        datum: new Date(),
        ueberschrift: 'Test Drei',
        showFull: false,
        fullText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'
      }
    ]
    this.timelineElements.forEach((element) => {
      element.shortText = element.fullText.substring(0, this.cutTextLength);
    })

    this.cities1 = [
      { label: 'New York', value: { id: 1, name: 'New York', code: 'NY' } },
      { label: 'Rome', value: { id: 2, name: 'Rome', code: 'RM' } }
    ];
    this.text = new Text();
    // this.text.= "fas";


  }
 
  // getSomeTextYo(vorname?: string): string {
  //   var name = "";
  //   if (1 == 1) {
  //     name = "Marcel";
  //   } else {
  //     name = "Giuseppe";
  //   }
  //             //Bedingung ? Dann : else
  //   var name2 = (1 == 1 ? "Marcel" : 2);

  //   var name3 = vorname ?? "Keine Angabe";

  //   if (1 == 1) name = "Marcel";

  //   return "lol";
  // }
 
  ngOnInit(): void {
    this.pageTitle = "Dashboard";
    this.app.setPageTitle(this.pageTitle);

    this.carService.getCarsSmall().then(cars => {
      this.cars = cars
    });
    this.items = [
      { label: 'Produkte' },
      { label: 'Unternehmen' }
    ];
    this.activeItem = this.items[0];

    // virtualScroller
    this.virtualScrollerItems = [];
    this.currentPage = 1;

    //this.artikelService.getArtikelCollection(1, 50, "").subscribe( // nur 50 Stück
    this.artikelService.getArtikelCollectionFiltered("A_W"/*typ*/, false/*onlyForMyUser*/, 0/*geodatenId*/, 0/*maxkm*/,
      0/*artikelgruppeId*/, 0/*unternehmenId*/, ''/*artikelnummer*/, null/*lieferarten*/, 0/*herstellerId*/, 0/*lagerId*/, "ALLFAV" /*mindestens ALLE Favoriten zurückgeben!*/,
      true/*nurAktive*/, "NOLAG"/*lagerbestandsFlag*/,
       1/*pageNumber*/, 50/*pageSize*/, ""/*searchQuery*/, "favorite_distance"/*sortKey*/).subscribe( // nur 50 Stück
      (response: any) => {
        console.log("Dashboard.ngOnInit() getArtikelCollection() response:", response);
        console.log("Dashboard.ngOnInit() getArtikelCollection() this.app.benutzer:", this.app.benutzer);
        this.artikel = response.artikel;
        if (this.app.benutzer != null) {
          this.artikel.forEach(einzelnerArtikel => {
            try {

              this.favoritService.getFavoritForArtikelAndBenutzer(einzelnerArtikel.id, this.app.benutzer.id).subscribe(
                (response: any) => {
                  console.log("Dashboard.ngOnInit() getFavoritForArtikelAndBenutzer() response:", response);
                  einzelnerArtikel['_meinFavorit'] = true;
                  einzelnerArtikel['_meinFavoritId'] = response.id;
                },
                (error: any) => {
                  // ignore! Der Artikel ist kein Favorit!
                  //alert("error: "+error);
                }
              );

            }
            catch(e) {
              console.log("Dashboard.ngOnInit() getFavoritForArtikelAndBenutzer() exception:", e);
            }
          });
        }
        else {
          console.log("Dashboard.ngOnInit() getArtikelCollection() skip Favoriten, weil kein Benutzer!");
        }
      },
      (error: any) => {
        alert("error: " + error);
      }
    );
    this.breadcrumbService.setItems([
      { label: 'Components' },
      { label: 'Dashboard', routerLink: ['/dashboard'] }
      
    ]);

    
    // auf reuse (RouteReuseStrategy) reagieren: https://stackoverflow.com/questions/50392691/angular-5-how-to-reload-current-data-when-i-use-routereusestrategy
    this.router.onSameUrlNavigation = 'reload';
    this.router.events.subscribe(event => {
      if ((event instanceof NavigationEnd)) {
        let navigationEnd: NavigationEnd = <NavigationEnd>event;
        if (navigationEnd.url != null) {
          //console.log("DashboardComponent event NavigationEnd.url:", navigationEnd.url);

          let url: string = navigationEnd.url;
          if (url.startsWith("/" + "dashboard") && !url.startsWith("/" + "dashboard" + "/")) {
            // beim reUse wird der pageTitle nicht autom. gesetzt -> übernehmen!
            //console.log("DashboardComponent.NavigationEnd... resetting pageTitle", event);
            /*if (this.CRUDDisablePageTitleUpdates == false)*/ this.app.setPageTitle(this['pageTitle']);

            if (this._app.inAppMode == true) {
              setTimeout(() => {
                console.log("Dashboard.NavigationEnd... resetting scrollTop  this.lastScrollPosition:", this.lastScrollPosition);
                //let scrollableTableBody = document.getElementsByClassName('ui-table-scrollable-body')[0];
                let scrollableTableBody = document.getElementsByClassName('cdk-virtual-scroll-viewport')[0]; // ng10 / primeNg10
                //scrollableTableBody.scrollTop = this.lastScrollPosition;
                scrollableTableBody.scrollTo(0, this.lastScrollPosition);
                // Es kommt aber vor, dass es inzw. einige Pixel-Verschiebungen gab, weil sich zB. die Daten geändert haben
                // in grossen Tab. wie Bankleitzahlen
                // daher: check, ob damit wirklich wieder sichtbar, ansonsten die TR.scrollIntoView() 
                // => funktioniert leider nicht!
                //if(!this.isElementVisible(this.lastClickedTR, scrollableTableBody)) {
                //  console.log("Dashboard.NavigationEnd... resetted scrollTop, but TR is still not visible. scrolling into View ...");
                //  this.lastClickedTR.scrollIntoView();
                //}
              }, 50);
            }
          }
          else { // woanders hin-navigiert - also NICHT zurück zu hier! -> scrollTop merken
            if (this._app.inAppMode == true) {
              //let scrollableTableBody = document.getElementsByClassName('ui-table-scrollable-body')[0];
              let scrollableTableBody = document.getElementsByClassName('cdk-virtual-scroll-viewport')[0];  // ng10 / primeNg10
              this.lastScrollPosition = scrollableTableBody.scrollTop;
              console.log("Dashboard.NavigationEnd... saving  this.lastScrollPosition:", this.lastScrollPosition);
            }
          }
        }
      }
    });

    // neuen Artikel einstellen
    this.newArtikelForm = this.fb.group({
      newArtikelBezeichnung: ['', [Validators.maxLength(255)]],
      newArtikelArtikelnummer: ['', [Validators.maxLength(255)]],
      newArtikelHersteller: [null],
    },{
      //validator: this.formValidator // auf FormEbene!
    });

    // Aktive / Lagerbestand / Gesamt / Favoriten
    this.artikelService.countArtikelForThisUser().subscribe( 
      (response: any) => {
        this.counters = response
      },
      (error: any) => {
        alert("error: " + error);
      }
    );
    this.mobileAnsicht = window.innerWidth <= 540;

    window.onresize = () => {
      this.mobileAnsicht = window.innerWidth <= 540;
    }
    
    this.rssService.getRSSCollection(1/*pageNumber*/, 0/*pageSize*/, ""/*searchQuery*/).subscribe( 
      (response: any) => {
        console.log("Dashboard.ngOnInit() getRSSCollection() response:", response);
        console.log("Dashboard.ngOnInit() getRSSCollection() this.app.benutzer:", this.app.benutzer);
        this.rss = response.RSS;
      },
      (error: any) => {
        alert("error: " + error);
      }
    );
  }

  ngAfterViewInit(): void {
    // overwrite CrudBasicDetail
    //if(this.CRUDMethodGetAfterViewInit == true) this.getCRUDItem(this.dataId);

    // TEST getArtikelMeineFavoriten
    /*this.artikelService.getArtikelMeineFavoriten(1, 0, "")
    .subscribe( 
      (response: any) => {
        console.log("Dashboard.ngAfterViewInit() getArtikelMeineFavoriten() response:", response);
      },
      (error: any) => {
        alert("error: " + error);
      }
    );*/

    this.VirtualScrollerFixer();
  }
  //produktDetailsButtonClick(): void {
  //  this._router.navigate(['/produkt-details']);
 // }
  detailArtikelAnsichtButtonClick(): void {
    this._router.navigate(['/detail-artikel-ansicht'])
  }

  produktUebersicht(): void {
    this._router.navigate(['/artikel-uebersicht']);
  }

  artikelTitelBildURL(artikel: IArtikel) {
    //console.log("Dashboard.artikelTitelBildURL() artikel:", artikel);
    return this.artikelbildService.getThumbDownloadUrlForTitelbildArtikel(artikel.id, artikel.rowVersion);
  }
  onImgError(event) {
    event.target.src = 'assets/layout/images/imageNotFound.png';
  }

  // selectArtikel(event: Event, artikel: IArtikel):void {
  //   this._router.navigate(['/artikel-ansicht', artikel.id]);
  //   event.preventDefault();
  // }
  favBtn(artikel: IArtikel, calledAt: string) {
    console.log("DashboardComponent.favBtn() artikel: ", artikel);
    //artikel.favorit = !artikel.favorit;
    if (this.app.benutzer == null) {
      alert("Bitte zuerst anmelden!");
    }
    else {
      // toDo: Disable Icon!
      if (artikel['_meinFavorit'] == null) artikel['_meinFavorit'] = false;
      artikel['_meinFavorit'] = !artikel['_meinFavorit'];
      if (artikel['_meinFavorit'] == true) {
        let neuerFavorit = this.favoritService.initializeFavorit();
        neuerFavorit.artikelId = artikel.id;
        neuerFavorit.benutzerId = this.app.benutzer.id;
        let thisInstance = this;
        this.favoritService.createFavorit(neuerFavorit).subscribe(function (response) {
          console.log("DashboardComponent.favBtn() ... artikel/response:", artikel, response);
          artikel['_meinFavoritId'] = response.id;
          thisInstance.favBtnSync(artikel.id, true, calledAt == "carousel" ? "scroller" : "carousel", response.id);
          // toDo: Enable Icon!
        }, function (error) {
          console.log("DashboardComponent.favBtn() error:", error);
          //return thisInstance.handleError(error); 
        });
      }
      else {
        let favoritId = artikel['_meinFavoritId'];
        this.favoritService.deleteFavorit(favoritId).subscribe(
          () => {
            delete artikel['_meinFavorit'];
            delete artikel['_meinFavoritId'];
            this.favBtnSync(artikel.id, false, calledAt == "carousel" ? "scroller" : "carousel", 0);
            // toDo: Enable Icon!
          },
          (error: any) => {
            console.log("DashboardComponent.favBtn() error:", error);
          }
        );
      }
    }
  }
  favBtnSync(artikelId: number, status: boolean, syncTo: string, favoritId: number) { // wenn im Scroller geklickt -> auch im carousel aktualisieren - und andersrum
    let artikel: any = null;
    if(syncTo == "carousel") {
      artikel = this.artikel.find(f => f.id == artikelId);
    }
    else {
      artikel = this.virtualScrollerItems.find(f => f != null && f.id == artikelId);
    }
    if(artikel != null) {
      if(status == true) {
        artikel['_meinFavorit'] = true;
        artikel['_meinFavoritId'] = favoritId;
      }
      else {
        delete artikel['_meinFavorit'];
        delete artikel['_meinFavoritId'];
      }
    }
  }

  isAbholung(artikel: IArtikel) {
    var isAbholbar = false;
    if (artikel.artikelLieferarten != null && artikel.artikelLieferarten.length > 0) {
      artikel.artikelLieferarten.forEach(artikelLieferart => {
        if (artikelLieferart.lieferart != null && artikelLieferart.lieferart.versand == false) {
          isAbholbar = true;
        }
      })
    }
    return isAbholbar;
  }

  isLieferung(artikel: IArtikel) {
    var isVersand = false;
    if (artikel.artikelLieferarten != null && artikel.artikelLieferarten.length > 0) {
      artikel.artikelLieferarten.forEach(artikelLieferart => {
        if (artikelLieferart.lieferart != null && artikelLieferart.lieferart.versand == true) {
          isVersand = true;
        }
      })
    }
    return isVersand;
  }
  
  

  moveImage(artikelnummer: string) {
    let image = document.getElementById("carouselImage_" + artikelnummer);
    //console.log("DashboardComponent.isPortrait() artikelnummer/image:", artikelnummer, image);
    let imgRect = image.getBoundingClientRect()

    //console.log("Dashboard.isElementVisible() imgRect:", imgRect);

    if (imgRect.width >= imgRect.height) {
      let overlayHeight = 25;
      return '-30px';
    }
    else return 'unset';

  }
  selectArtikel(event: Event, artikel: IArtikel): void {
    if(artikel.typ == "W") {
      if (this._app.inAppMode == true) {
        let eventData = {
          eventType: "advertisement",
          data: ""+artikel.url
        };
        this._app.invokeXamarinEvent(eventData);
      }
      else {
        if(this.debugMode==true) console.log("Dashboard.selectArtikel() artikel.url:", artikel.url);
        window.open(artikel.url, artikel.url /*windowname*/);
      }
    }
    else {
      this._router.navigate(['/artikel-ansicht', artikel.id]);
      //this.selectedCar = car;
      //this.displayDialog = true;
      event.preventDefault();
    }
  }

  loadDataVirtualScroller(event: LazyLoadEvent) {
    if(this.debugMode==true) console.log("Dashboard.loadDataVirtualScroller() event:", event);
    //  wenn sich mehrere LazyLoads ergeben (schnell scrollen), dass dann der letzte (aktuelle) zuerst ausgeführen!
    this.pendingLazyLoadEvents.push(event);
    this.lastLazyLoadEvent_first = event.first;
    let thisInstance = this;
    thisInstance.loadDataVirtualScroller_handleNextPending(thisInstance);
  }

  loadDataVirtualScroller_handleNextPending(thisInstance: any) {
    setTimeout(() => {
      if(thisInstance.pendingLazyLoadEvents.length > 0) {
        let idx = thisInstance.pendingLazyLoadEvents.length -1; // den Event nehmen wir als nächstes! (den letzten)
        let eventToHandle = thisInstance.pendingLazyLoadEvents[idx];
        thisInstance.pendingLazyLoadEvents.splice(idx, 1);
        if(this.debugMode==true) console.log("Dashboard.loadDataVirtualScroller_handleNextPending() lastLazyLoadEvent_firstevent/eventToHandle:", this.lastLazyLoadEvent_first, eventToHandle);
        thisInstance.loadDataVirtualScroller_afterTimeout(eventToHandle, thisInstance);

        // gibt es weitere ?, dann rekursiv aufrufen
        if(thisInstance.pendingLazyLoadEvents.length > 0) {
            thisInstance.loadDataVirtualScroller_handleNextPending(thisInstance);
        }
      }
    }, 200);
  }

  loadDataVirtualScroller_afterTimeout(event: LazyLoadEvent, thisInstance: DashboardComponent) {
    if(thisInstance.debugMode==true) console.log("Dashboard.loadDataVirtualScroller() event:", event);

    thisInstance.currentPage = Math.floor(event.first / thisInstance.rows) + 1;
    if(thisInstance.debugMode==true) console.log("Dashboard.loadDataVirtualScroller() currentPage:", thisInstance.currentPage);
    if(thisInstance.debugMode==true) console.log("Dashboard.loadDataVirtualScroller() artikelService:", thisInstance.artikelService);
    if(thisInstance.currentPage < 1) { // passiert auf iOS -> der scrollt in den negativen Bereich! currentPage 0/-1
      if(thisInstance.debugMode==true) console.log("Dashboard.loadDataVirtualScroller() currentPage < 1 -> set to 1:", thisInstance.currentPage);
      thisInstance.currentPage = 1;
    }
    thisInstance.artikelService.getArtikelCollectionFiltered(/*typ*/ "A_W", /*onlyForMyUser*/ false, /*geodatenId*/ null, /*maxKm*/ 0, /*artikelgruppeId*/ 0,
      /*unternehmenId*/ 0, /*artikelnummer*/ null, /*lieferarten*/ null, /*herstellerId*/ 0, /*lagerId*/ 0, "NOFAV" /* keine Favoriten unten in der Liste!!!*/,
      true /*nurAktive*/, null /*lagerbestandsflag*/,
      thisInstance.currentPage, thisInstance.rows, /*search*/"", "favorite_distance"/*sortKey*/)
    .subscribe(
      response => {
        if(thisInstance.debugMode==true) console.log("Dashboard.loadDataVirtualScroller() response:", response);

          if (thisInstance.app.benutzer != null) {
            /*response.artikel.forEach(einzelnerArtikel => { // Abgleich mit Favoriten entfällt unten, siehe "NOFAV"
              thisInstance.favoritService.getFavoritForArtikelAndBenutzer(einzelnerArtikel.id, thisInstance.app.benutzer.id).subscribe(
                (responseFav: any) => {
                  console.log("Dashboard.loadDataVirtualScroller_afterTimeout() getFavoritForArtikelAndBenutzer() responseFav:", responseFav);
                  einzelnerArtikel['_meinFavorit'] = true;
                  einzelnerArtikel['_meinFavoritId'] = responseFav.id;
                },
                (error: any) => {
                  // ignore! Der Artikel ist kein Favorit!
                  //alert("error: "+error);
                }
              );
            });*/
          }
          else {
            console.log("Dashboard.loadDataVirtualScroller_afterTimeout() getArtikelCollection() skip Favoriten, weil kein Benutzer!");
          }

          if(thisInstance.totalRecords == null) thisInstance.totalRecords = response.pagination.totalCount;
          if(thisInstance.virtualScrollerItems == null || thisInstance.virtualScrollerItems.length == 0) thisInstance.virtualScrollerItems = Array.from({ length: thisInstance.totalRecords });

          let arraySizeBeforeSplice = thisInstance.virtualScrollerItems.length; // Sicherheitsprüfung, s.u.
          //populate page of virtual cars
          Array.prototype.splice.apply(thisInstance.virtualScrollerItems, [
            ...[event.first, event.rows],
            ...response.artikel
          ]);
          if(arraySizeBeforeSplice != 0 && thisInstance.virtualScrollerItems.length != arraySizeBeforeSplice) {
            console.error("Dashboard.loadDataVirtualScroller() Warning(Error?): Array-Grösse hat sich durch das nachladen verändert!");
            debugger;
          }
        
          //trigger change detection
          thisInstance.virtualScrollerItems = [...thisInstance.virtualScrollerItems];
      
          if(this.debugMode==true) console.log("Dashboard.loadDataVirtualScroller() virtualScrollerItems:", thisInstance.virtualScrollerItems);
      },
      error => thisInstance.handleError(error)
    );
  }

  VirtualScrollerFixer() {
    let thisInstance = this;
    setTimeout(() => {
      let virtualscrollerDiv = document.getElementById("virtualscrollerDiv");
      let virtualscrollerDivBoundingRect = virtualscrollerDiv.getBoundingClientRect();
  
      let availableHeight = Math.floor(window.innerHeight - virtualscrollerDivBoundingRect.top - 2 /*sicherheitshalber*/);
      
      //virtualscrollerDiv['style']['height'] = availableHeight+'px';
      thisInstance.scrollHeight = availableHeight+'px';
      //console.log("Dashboard.VirtualScrollerFixer() scrollHeight:", thisInstance.scrollHeight);
    }, 750);
  }

  newArtikelCreate() {
    console.log("Dashboard.newArtikelCreate() newArtikelForm:", this.newArtikelForm);
    this._router.navigate(['/artikel/0'], { queryParams: { itemnr: this.newArtikelForm.value.newArtikelArtikelnummer, manufacturer: this.newArtikelForm.value.newArtikelHersteller != null ? this.newArtikelForm.value.newArtikelHersteller.id : null, label: this.newArtikelForm.value.newArtikelBezeichnung } });
    //this.newArtikelForm
  }

  routeToMyItems() {
    //let thisInstance = event.item.component;
    // force reload! Falls man schon aus Artikel-Übersicht kommt, soll er das reloaden! // https://github.com/angular/angular/issues/13831
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/artikel-uebersicht?overviewmode=myItems');
    });
  }
  routeToMyActive() {
    //let thisInstance = event.item.component;
    // force reload! Falls man schon aus Artikel-Übersicht kommt, soll er das reloaden! // https://github.com/angular/angular/issues/13831
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/artikel-uebersicht?overviewmode=myActive');
    });
  }
  routeToMyStock() {
    //let thisInstance = event.item.component;
    // force reload! Falls man schon aus Artikel-Übersicht kommt, soll er das reloaden! // https://github.com/angular/angular/issues/13831
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/artikel-uebersicht?overviewmode=myStock');
    });
  }
  routeToMyFavorites() {
    //let thisInstance = event.item.component;
    // force reload! Falls man schon aus Artikel-Übersicht kommt, soll er das reloaden! // https://github.com/angular/angular/issues/13831
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/artikel-uebersicht?overviewmode=myFavorites');
    });
  }

  getSortInfo(artikel: IArtikel): string { // zum debuggen: bildet die SortOrder (eigentlich per SQL berechnet nach)
    let orderGewichtungsFaktorDistanz = 0.5; // aus API kopieren!!!
    let orderGewichtungsFaktorMinutes = 0.000694444; // aus API kopieren!!!
    let orderGewichtungsFaktorWerbeanzeige = 2.0; // aus API kopieren!!!
    let sortInfo = artikel.distance + " km" + " * " + orderGewichtungsFaktorDistanz + " = " + (artikel.distance * orderGewichtungsFaktorDistanz) + " | ";
    sortInfo += moment(artikel.modified).format("DD.MM.YYYY hh:mm:ss") + " = ";
    let timeNow = moment().toDate().getTime();
    let timeModified = moment(artikel.modified).toDate().getTime();
    let timeDiffInMinutes = (timeNow - timeModified) / (1000 * 60);
    sortInfo += timeDiffInMinutes + " Minutes since Modified";
    sortInfo += " * " + orderGewichtungsFaktorMinutes + " = " + (timeDiffInMinutes * orderGewichtungsFaktorMinutes) + " | ";
    sortInfo += " = " + ((artikel.distance * orderGewichtungsFaktorDistanz) + (timeDiffInMinutes * orderGewichtungsFaktorMinutes));
    if(artikel.typ == 'W') {
      sortInfo += " + / "+orderGewichtungsFaktorWerbeanzeige+" (weil Werbeanzeige) = " + (((artikel.distance * orderGewichtungsFaktorDistanz) + (timeDiffInMinutes * orderGewichtungsFaktorMinutes))) / 2;
    }
    return sortInfo;
  }

  getArtikelbeschreibungForInnerHtml(artikel: IArtikel) {
    console.log("Dashboard.getArtikelbeschreibungForInnerHtml artikel", artikel);
    return artikel.beschreibung;
  }

  handleError(error: any) {
    console.log("Dashboard.handleError error", error);
    //this.globalService.addFeedbackByClone("error " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.handleError()", error); // feedback

    //this.loading = false;
    //this.blockedDocument = false;
    let summary = this.translate.instant('Fehler', true);

    let errorMessage: string = null;
    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        /*this.*/errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        /*this.*/errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      /*this.*/errorMessage = "Unauthorized";
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      /*this.*/errorMessage = error.message;
    }

    //if(this.errorMessage != null) this.errorMessage = this.errorMessage.replace(/\n/g, "<br>");
    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: /*this.*/errorMessage }); // TO DO
  }
  hideCarousel(){
   
    this.hideCarouselImg =!this.hideCarouselImg;
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
 
}

