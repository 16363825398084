<div class="DetailArtikelAnsichtContainer">
<div style="padding: 25px" class="containerDetailArtikelAnsicht p-grid">
    <div style="margin-left: 40px" class="p-col-6">
        <div class="favouriteBtnStar">
        <i class="pi" [class.pi-star-o]="!isActive" [class.pi-star]="isActive" (click)="changeActivity()" style="font-size: 25px;
            color: #000000;
            right: 0;
            cursor: pointer;
            padding: 15px 10px 0px 0px;
            display: block;
            top: 0;"></i><h2 id="headerName">Muschelkalkpflaster/ Eibelstädter Muschelkalk</h2>
            </div>

       <p-galleria id="galleriaFloat" [changeItemOnIndicatorHover]="true" [(value)]="images" [thumbnailsPosition]="'bottom'"
        [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '550px'}">
        <ng-template pTemplate="item" let-item>

            <img [src]="item.previewImageSrc" style="width: 100%; display: block;" />
        </ng-template>
        <ng-template id="testThumb" pTemplate="thumbnail" let-item>
            <div id="thumbnailContent">
                <div id="thumbnail" class="p-grid p-nogutter p-justify-center">
                    <img [src]="item.thumbnailImageSrc" style="display: block;" />
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="caption" let-item>
            <!-- <h2 id="textCaption">{{item.title}}</h2> -->
            <p style="color: #ffffff; font-size: 16px;">{{item.alt}}</p>
        </ng-template>
    </p-galleria> 
    </div>
    
    <div class="textFloatRight detailArtikelinformationWidth p-col-6">
        <p class="textRight" style="font-size: 24px; font-weight: 600;">71717 Beilstein,</p>
        <p class="textRight" style="font-size: 18px; color: green">200,00 €, 1 Pauschal</p>
        <div style="margin-top: 10px;"></div>
        <br>
        <p class="textRight">Der Artikel ist super und sollte gekauft werden.</p>
        <br>
      
        <p class="textRight">Hersteller: <span id="tabSize0"><b>Borst Würzburg | (#551146)</b></span></p>
        <p class="textRight">Artikelgruppe 1:<span id="tabSize-1">Straßen-und Wegebau</span></p>
        <p class="textRight">Artikelgruppe 2:<span id="tabSize-2">Pflastersteine</span></p>
        <p class="textRight">Produktzustand:<span id="tabSize1">Benutzt</span></p>
        <p class="textRight">Verpackung:<span id="tabSize2">Gitterbox</span></p>
        <p class="textRight">Lieferart:<span id="tabSize3">Abholung</span></p>
        <p class="textRight">Verlademöglichkeit:<span id="tabSize4">Vorhanden</span><button type="button" class="infoAnzeigenBtn" pButton
            icon="pi pi-info" pTooltip="Tragen Sie bitte eine Verlademöglichkeit ein."
            tooltipStyleClass="toolTip"></button></p>
    </div>
</div>
<p-tabView id="tabViewDetailArtikelAnsicht" [style]="{'background-color': '#f7f7f7', 'margin-left': '65px'}">
    <p-tabPanel [headerStyle]="{'background-color': '#f7f7f7'}" header="Beschreibung" [selected]="true">
        <b>Zustand:</b> Produziert August 2020, sehr gut, keine Verunreinigungen oder Verfärbungen.<br>
        <b>Hinweis:</b> Pflastersteine sind gerumpelt
        (Gitterbox guter Zustand im Tausch oder gegen Bezahlung 70 Euro)<br>
        <b>Maße:</b> 28x10x6 cm.<br>
        <b>Menge:</b> 4,5 qm.
        
    </p-tabPanel>
    <p-tabPanel [headerStyle]="{'background-color': '#f7f7f7'}" header="Kontaktdaten">
        <div class="textFloat" style="font-weight: 600;">
            <p>Voigt Software und Beratung AG</p>
            <p><a href="http://www.voigtsoftware.com/">http://www.voigtsoftware.com/</a></p>
            <p>Telefon: 07062 9155 100</p>
            <p>E-Mail: support@bautraeger-software.de</p>
            
        </div>
    </p-tabPanel>
</p-tabView>
</div>