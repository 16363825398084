import { Component, OnInit } from '@angular/core';

import { Observable, of, from } from 'rxjs';
import { map, tap, share, switchMap } from 'rxjs/operators';


export interface IPerson {
  name: string;
}

@Component({
  selector: 'app-test-observables',
  templateUrl: './test-observables.component.html',
  styleUrls: ['./test-observables.component.css']
})
export class TestObservablesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    // 10 most common rxjs-operators in Angular: https://www.youtube.com/watch?v=5TnWFaI49aw

    if(false) {
      console.log("TestObservables: Test 1: of() - aus Object (kann auch ein string sein) ein Observable erzeugen:");
      let person: IPerson = {
        name: 'david'
      };
      let myObservable: Observable<IPerson> = of(person);
      myObservable.subscribe(data => console.log("TestObservables: data:", data));
    }

    if(false) {
      console.log("TestObservables: Test 2: from() - aus Promise ein Observable erzeugen:");
      let person: IPerson = {
        name: 'david'
      };
      let myPromise: Promise<IPerson> = Promise.resolve(person);
      let myObservable: Observable<IPerson> = from(myPromise);
      myObservable.subscribe(data => console.log("TestObservables: data:", data));
    }

    if(false) {
      console.log("TestObservables: Test 3: map() - die Daten des Events verändern:");
      let person: String = 'david';
      let myObservable: Observable<String> = of(person);
      myObservable
      .pipe(
        map(myString => {
          console.log("TestObservables: map() myString:", myString);
          return myString.toUpperCase();
        })
        // KURZFORM, dann brauchts auch kein return: map(myString => myString.toUpperCase())
      )
      .subscribe(data => {
        console.log("TestObservables: data (now uppercase):", data);
        console.log("TestObservables: person (still lowercase):", person);
      });
    }

    if(false) {
      console.log("TestObservables: Test 4: tap() - mit den Daten des Events irgendwas machen, sie aber NICHT verändern:");
      let person: String = 'david';
      let myObservable: Observable<String> = of(person);
      myObservable
      .pipe(
        tap(myString => {
          console.log("TestObservables: tap() myString:", myString);
          //return myString.toUpperCase(); // völlig effektlos! es braucht hier gar kein return!
        })
      )
      .subscribe(data => {
        console.log("TestObservables: data (now uppercase):", data);
        console.log("TestObservables: person (still lowercase):", person);
      });
    }

    if(true) {
      console.log("TestObservables: Test 7: switchMap() bricht den ursprünglichen Request ab, startet stattdessen einen 2.request");
      let person: String = 'david';
      let myObservable: Observable<String> = of(person);
      let person2: String = 'dave';
      let myObservable2: Observable<String> = of(person2);

      myObservable
      .pipe(
        switchMap(myString => {
          console.log("TestObservables: switchMap() myString:", myString);
          return myObservable2
          .pipe(
            tap(myString2 => {
              console.log("TestObservables: in swtichMap ... Observable 2: myString/myString2:", myString, myString2);
            })
          )
        })
      )
      .subscribe(data => {
        console.log("TestObservables: subscribe() data:", data);
      });
    }

    // ... weiter ab 11:25


    if(false) {
      console.log("TestObservables: Test 8: share() - soll z.B. bei einem HTTPRequest, dessen Ergebnis man 2mal benötigt verhindern, dass es auch 2mal aufgerufen wird.");
      console.log("TestObservables: siehe dazu das Video - nachgebauter Test funktioniert leider nicht, verhält sich auch ohne share() genauso !?!?!?");
      let myObservable = this.test8getHTTPObservableDummy();

      myObservable
      .subscribe(data => {
        console.log("TestObservables: data for 1. query:", data);
      });
      
      setTimeout(() => {
        myObservable
        .subscribe(data => {
          console.log("TestObservables: data for 2. query - after 3 seconds:", data);
        });
      }, 5000);
    }


    // pipe ?? zusammen mit map/tap ...
    // was ist ein promise ?
    // was ist ein subject ?
  }

  test8getHTTPObservableDummy(): Observable<String> {
    let timeStamp: String = new Date().toString();
    let myObservable: Observable<String> = of(timeStamp);
    return myObservable/*.pipe(share())*/;
  }


}
