<!--
<p-growl [(value)]="msgsTimed" [life]="5000">
</p-growl>
<p-growl [(value)]="msgsStatic" [sticky]="true">
</p-growl> 
-->

<p-toast position="top-left" key="messages"></p-toast>

<!--<router-outlet></router-outlet>-->
<app-main></app-main>
