//import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';
import { CRUDBasicDetailForChildComponent } from '../crud-basic-detail-for-child/crud-basic-detail-for-child.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
import { AutoComplete } from 'primeng/primeng';

//import { ILager } from '../_interfaces/lager';
import { LagerService } from '../_services/lager.service';
import { LagerDetailGuard } from './lager-detail.guard';

import * as moment from 'moment';

//import { LagerDetailComponent_Template } from './lager-detail.component.include_template';

@Component({
  selector: 'app-lager-detail',
  templateUrl: './lager-detail.component.html',
  
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class LagerDetailComponent extends CRUDBasicDetailForChildComponent implements OnInit {
  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Lager";
  CRUDPageTitleNeu: string = this.translate.instant("Neues Lager", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Lager bearbeiten", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Lager";
  CRUDItemBezeichnungPluralCapitalized: string = "Lager";
  CRUDItemBezeichnungSingular: string = "lager";
  CRUDItemBezeichnungPlural: string = "lager";
  CRUDItemRouteSingular: string = "lager";
  CRUDItemRoutePlural: string = "lager";
  CRUDItemHelpTopic: string = "Lager";
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START
  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: LagerService,
    private _guard: LagerDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      bezeichnung: {
        required: this._translate.instant('Bezeichnung', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Bezeichnung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      strasse: {
        required: this._translate.instant('Strasse', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Strasse', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      /*postleitzahl: {
        required: this._translate.instant('Postleitzahl', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Postleitzahl', true) + ' ' + this._translate.instant('darf 6 Zeichen nicht überschreiten', true)
      },

      ort: {
        required: this._translate.instant('Ort', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Ort', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },*/

      /*latitude: {
        required: this._translate.instant('latitude', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      longitude: {
        required: this._translate.instant('longitude', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      datenherkunft: {
        required: this._translate.instant('Datenherkunft', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Datenherkunft', true) + ' ' + this._translate.instant('darf 2 Zeichen nicht überschreiten', true)
      },

      accuracy: {
        required: this._translate.instant('accuracy', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('accuracy', true) + ' ' + this._translate.instant('darf 1 Zeichen nicht überschreiten', true)
      },*/

      geodaten: {
        required: this._translate.instant('Postleitzahl Ort', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      baustelle: {
        required: this._translate.instant('Baustelle', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      bezeichnung: ['', [Validators.required, Validators.maxLength(255)]],
      strasse: ['', [Validators.required, Validators.maxLength(255)]],
      //postleitzahl: ['', [Validators.required, Validators.maxLength(6)]],
      //ort: ['', [Validators.required, Validators.maxLength(255)]],
      latitude: [0/*, [Validators.required]*/],
      longitude: [0/*, [Validators.required]*/],
      datenherkunft: ['', [/*Validators.required, */Validators.maxLength(2)]],
      accuracy: ['', [/*Validators.required, */Validators.maxLength(1)]],
      baustelle: [false, [Validators.required]],
      geodaten: [null, [Validators.required]],
      baustelle_Gueltigkeit: [null],
      unternehmen: [null],
    },{
      validator: this.formValidator // auf FormEbene!
    });
    
    this.CRUDForm['___component'] = this; // trick, um im formValidator wieder an die component zu kommen.
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    a.geodatenId = a.geodaten != null ? a.geodaten.id : null;
    a.unternehmenId = a.unternehmen != null ? a.unternehmen.id : null;
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("LagerDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START

    debugger;
    if(this.CRUDItem != null && this.CRUDItem.id == 0) { // wird vorübergehend ausgeblendet und vorbestückt
      this.CRUDItem.baustelle_Gueltigkeit = moment('9999-12-31T00:00:00+00:00').toDate()
    }

    this.CRUDForm.patchValue({
      bezeichnung: this.CRUDItem.bezeichnung,
      strasse: this.CRUDItem.strasse,
      //postleitzahl: this.CRUDItem.postleitzahl,
      //ort: this.CRUDItem.ort,
      latitude: this.CRUDItem.latitude,
      longitude: this.CRUDItem.longitude,
      datenherkunft: this.CRUDItem.datenherkunft,
      accuracy: this.CRUDItem.accuracy,
      baustelle: this.CRUDItem.baustelle,
      geodaten: this.CRUDItem.geodaten,
      baustelle_Gueltigkeit: this.CRUDItem.baustelle_Gueltigkeit != null ? new Date(this.CRUDItem.baustelle_Gueltigkeit) : null, // AM Modi: bei SQL "datetimeoffset"
      unternehmen: this.CRUDItem.unternehmen
});
    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("LagerDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors : any[] = [];
    let errorsFound : boolean = false;

    console.log("LagerDetailComponent.formValidator() form:", form);

    // dazu erstmal an die in der Form verlinkte Component kommen
    if(form['___component'] != null && form['___component'].dataId != null && form['___component'].dataId == 0) {
      let thisInstance = form['___component'];
    }

    let valueBaustelle = form.value.baustelle;
    let valueBaustelle_Gueltigkeit = form.value.baustelle_Gueltigkeit;

    if(valueBaustelle == true) {
      if(valueBaustelle_Gueltigkeit == null) {
        errors['Baustelle_Gueltigkeit_ist_erforderlich']=true;
        errorsFound = true;
      }
    }

    //form.setErrors(errorsFound ? errors : null);
    if(errorsFound) return errors;
    else return null;
  }

}
