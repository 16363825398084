<p-blockUI [blocked]="true">

    <div style="background-color: white; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">

        <br>
        <br>
        <div style="text-align:center; font-size:1.375em; margin-left: 20px; margin-right: 20px;">Zur Nutzung von<br>
            <img src="assets/layout/images/plattGruenZweiLogo.png" width="176px"><br><br>auf mobilen Geräten nutzen Sie bitte unsere frei verfügbaren apps:</div>
        <br>
        <br>
        <!-- 
        apple: 250x83 ohne Rahmen! 
        google: 646x250 - aber auf jeder Seite 40 Pixel Rand 
        daher google angepasst auf height = 123 (- Rand prozentuell ) sind es dann ca. 83.
        fast nebeneinander dargestellt: apple dafür per margin-top weiter runter gesetzt
        -->
        <div style="text-align: center;">
            <!--<div id="ArtikelAnsichtGridLinks" class="p-col-12 p-md-12 p-lg-12" style="text-align: center;">-->
                <!--https://tools.applemediaservices.com/app/1497136136?country=us-->
                <a href="https://apps.apple.com/us/app/imke-mobil-baudokumentation/id1497136136?itsct=apps_box_badge&amp;itscg=30200">
                    <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/de-de?size=250x83&amp;releaseDate=1586304000" alt="Download vom App Store" style="height: 83px;">
                </a>
            <!--</div>-->
            <br>
            <!--<div id="ArtikelAnsichtGridLinks" class="p-col-12 p-md-12 p-lg-12" style="text-align: center;">-->
                <!--https://play.google.com/intl/en_us/badges/-->
                <a href="https://play.google.com/store/apps/details?id=com.voigtsoftware.IMKE&hl=en">
                    <img src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png" alt="Download von Google Play" style="height: 123px;">
                </a>
            <!--</div>-->
        </div>
        <br>
    
    </div>
      
</p-blockUI>
