import { Component, OnInit, AfterViewInit, Inject, forwardRef, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/primeng';
import * as cloneDeep from 'lodash/cloneDeep'; 

import { AppComponent } from '../app.component';
import { MessageWrapperService } from '../_services/message-wrapper.service';

import { _ArtikelgruppenImportService } from '../_services/_artikelgruppenimport.service';

@Component({
  selector: 'app-artikelgruppen-import',
  templateUrl: './artikelgruppen-import.component.html',
  styleUrls: ['./artikelgruppen-import.component.css']
})
export class ArtikelgruppenImportComponent implements OnInit/*, AfterViewInit*/ {

  artikelgruppenImportAktuellUploadingFile: File = null;
  imports: any[] = [];
  vorschau: boolean = true;

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private messageWrapperService: MessageWrapperService,
    private artikelgruppenImportService: _ArtikelgruppenImportService,
  ) { }

  ngOnInit(): void {
    this._app.setPageTitle("Import: Artikelgruppen");
  }

  //ngAfterViewInit(): void {
  //}

  onNGFileInputSelect(event) {
    console.log("ArtikelgruppenImport.onNGFileInputSelect().event:", event);

    //let fileToUpload : File = event.files[0];
    for (let i : number = 0; i < /*event.files.length*/1; i++) { // nur 1 File!
      let fileToUpload : File = event.files[i];
      this.artikelgruppenImportAktuellUploadingFile = fileToUpload; // merken für die progress-Rückmeldung

      // nicht nur das file uploaden, sondern auch die Entity 
      let artikelgruppenImportForCreate = this.artikelgruppenImportService.initializeartikelgruppenImport();
      let dateiendung = fileToUpload.name.substr(fileToUpload.name.lastIndexOf('.') + 1);
      let dateiname = fileToUpload.name.substr(0, fileToUpload.name.length - (dateiendung.length + 1));
      artikelgruppenImportForCreate.dateiname = dateiname;
      artikelgruppenImportForCreate.dateiendung = dateiendung;
      artikelgruppenImportForCreate.bezeichnung = fileToUpload.name;
            
      this.artikelgruppenImportService.uploadArtikelgruppenImport(fileToUpload, artikelgruppenImportForCreate, this, this.uploadProgress, this.uploadSuccess, this.vorschau);
      
    }
  }

  uploadProgress(thisInstance: ArtikelgruppenImportComponent, progress: number) {
    console.log("ArtikelgruppenImport.uploadProgress() progress:", progress);
    //thisInstance.messageWrapperService.postTimedMessage({severity: 'info', summary: 'Upload: '+progress, detail: ''}); 

    if(thisInstance.artikelgruppenImportAktuellUploadingFile != null) {
      thisInstance.artikelgruppenImportAktuellUploadingFile['_percentUploaded']=progress;
    }
  }
  uploadSuccess(thisInstance: ArtikelgruppenImportComponent, eventbody : any) {
    console.log("ArtikelgruppenImport.uploadSuccess() eventbody:", eventbody);
    let originalFileName: string = eventbody.originalFileName;
    let guid: string = eventbody.guid;
    console.log("ArtikelgruppenImport.uploadSuccess() originalFileName:", originalFileName);
    console.log("ArtikelgruppenImport.uploadSuccess() guid:", guid);
    thisInstance.messageWrapperService.postTimedMessage({severity: 'info', summary: 'Datei '+originalFileName+': Upload erfolgreich!'/*+guid*/, detail: ''}); 
    
    let dateiendung = originalFileName.substr(originalFileName.lastIndexOf('.') + 1);
    let dateiname = originalFileName.substr(0, originalFileName.length - (dateiendung.length + 1));

    // das File als uploaded markieren
    //  file['_percentUploaded']=100;

    console.log("ArtikelgruppenImportComponent.uploadSuccess() artikelgruppenImportAktuellUploadingFile:", thisInstance.artikelgruppenImportAktuellUploadingFile);

    thisInstance.imports = eventbody.imports;
  }

}
