export class GalleriaFixer {

  alignGalleriaAtBottom(idOfGalleria: string, artikelBilderGalleryImages: any, fullScreenMode: boolean) {
    if(artikelBilderGalleryImages != null && artikelBilderGalleryImages.length > 0) {
      // das galleriaitem finden + höhe setzen: p-galleria > p-galleriacontent > div > div > p-galleriaitem
      let galleria = document.getElementById(idOfGalleria);
      console.log("GalleriaFixer.alignGalleriaAtBottom galleria:", galleria);
      let galleriaBoundingRect = galleria.getBoundingClientRect();
      console.log("GalleriaFixer.alignGalleriaAtBottom galleriaBoundingRect:", galleriaBoundingRect);
    
      let galleriacontent = galleria.querySelectorAll("p-galleriacontent");
      let div = galleriacontent[0].querySelectorAll("div");
      let div2 = div[0].querySelectorAll("div");
      let _galleriaitems/*:NodeList*/ = div2[0].querySelectorAll("p-galleriaitem");
      let galleriaitem = _galleriaitems[0];
  
      //if(window.innerHeight < window.innerWidth) { // Bildschirm ist Landscape
        let _galleriathumbnails = div2[0].getElementsByTagName("p-galleriathumbnails");
        let galleriathumbnails = _galleriathumbnails[0];
      
        let galleriathumbnailsBoundingRect = galleriathumbnails.getBoundingClientRect();
        console.log("GalleriaFixer.alignGalleriaAtBottom galleriathumbnailsBoundingRect:", galleriathumbnailsBoundingRect);
      
        let newgalleriacontentheight = 0;
        if(fullScreenMode == true) {
          newgalleriacontentheight = window.innerHeight /*- galleriaBoundingRect.top*/ - galleriathumbnailsBoundingRect.height - 30;
        }
        else {
          newgalleriacontentheight = window.innerHeight - galleriaBoundingRect.top - galleriathumbnailsBoundingRect.height - 30;
        }
      
        galleriaitem['style']['height'] = newgalleriacontentheight+'px';
        //galleriaitem['style']['background-color'] = 'yellow'; // just for debugging

        // Fullscreen: auch das Image innen auf die selbe Grösse bringen
        if(fullScreenMode == true) {
          let div3 = galleriaitem.querySelectorAll("div")[0];
          let galleriaitemslot = div3.querySelectorAll("p-galleriaitemslot")[0];
          let img = galleriaitemslot.querySelectorAll("img")[0];
          img['style']['max-height'] = newgalleriacontentheight+'px';
          img['style']['max-width'] = (160*3)+(2*20)+'px';  // 160 = Breite eines Thumbs   20 = Breite des rechts/links-Buttons
  
          console.log("GalleriaFixer.alignGalleriaAtBottom galleriaitemslot/img:", galleriaitemslot, img);
        }

        console.log("GalleriaFixer.alignGalleriaAtBottom galleria/galleriaItem:", galleria, galleriaitem);
        console.log("GalleriaFixer.alignGalleriaAtBottom galleriathumbnails:", galleriathumbnails);
        console.log("GalleriaFixer.alignGalleriaAtBottom window.innerHeight:", window.innerHeight);
      //}
      //else { // Bildschirm ist Portrait
      //  galleriaitem['style']['height'] = 'unset'
      //  console.log("GalleriaFixer.alignGalleriaAtBottom galleriaItem.style.height = unset!");
      //}
    }
  }
}
