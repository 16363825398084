<i class="fas fa-construction fa-10x"></i><br><br>

<p id="ArtikelImportVorlage">Vorlage wird erstellt ...</p>
<br>
<br>

<p-checkbox binary="true" [(ngModel)]="hersteller"></p-checkbox><!--(onChange)="onVorschauChange($event)-->
Hersteller
<br>
<p-checkbox binary="true" [(ngModel)]="artikel"></p-checkbox><!--(onChange)="onVorschauChange($event)-->
Artikel
<br>
<br>
<p-checkbox binary="true" [(ngModel)]="vorschau"></p-checkbox><!--(onChange)="onVorschauChange($event)-->
Vorschau-Modus
<br><br><br>
<div style="width: 400px;">
Unternehmen:<br>
<crud-basic-autocomplete [(ngModel)]="unternehmen" 
[required]="true" [type]="'unternehmen'"
>
</crud-basic-autocomplete>
</div>
<br>
<br>
<br>

<div id="upload">
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
                <div class="fileUpload">
                    <p-fileUpload [disabled]="unternehmen == null" #artikelImportFileUpload id="dateiHochladen" chooseLabel="Datei auswählen"
                            maxFileSize="999999999" [showUploadButton]="false" [showCancelButton]="false"
                            (onSelect)="onNGFileInputSelect($event)" 
                            multiple="false" accept="application/vnd.oasis.opendocument.spreadsheet/*" maxFileSize="99999999">
                            <ng-template let-file pTemplate="file">
                                <div class="ui-fileupload-row ng-star-inserted p-grid">
                                    <div class="p-col-3 p-md-3 p-lg-3"></div>
                                    <div class="p-col-6 p-md-6 p-lg-6">{{file.name}}
                                    </div>
                                    <!--<div>
                                        <span
                                            *ngIf="file._alreadyUploaded != null && file._alreadyUploaded == false">{{file._percentUploaded}}
                                            %</span>
                                    </div>-->
                                    <div class="p-col-3 p-md-3 p-lg-3">
                                        
                                        &nbsp;

                                    </div>
                                </div>
                                <span>
                                    <p-progressBar *ngIf="file._alreadyUploaded != null && file._alreadyUploaded == false && file._percentUploaded>0" [value]="file._percentUploaded"></p-progressBar>
                                </span>
                            </ng-template>
                        </p-fileUpload>

                    </div></div>
                </div></div>

                <div style="display: block; overflow-x: auto; white-space: nowrap;">
                <p-table *ngIf="imports.length>0" [value]="imports" [tableStyle]="{'width': 'max-content'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Z</th>
                            <th>I/U</th>
                            <th>Bezeichnung</th>

                            <th>Vorschau</th>
                            <th>Fehler</th>

                            <th>Hersteller</th>
                                    <th>Artikelnr.</th>
                                            <th>Artikelgruppe</th>
                                                    <th>Ansprechpartner</th>
                                                            <th>Beschreibung</th>
                                                                    <th>Lager</th>
                                                                            <th>Menge</th>
                                                                                    <th>MEh</th>
                                                                                            <th>preis</th>
                                                                                            <th>Pauschal</th>
                                                                                            <th>Zustand</th>
                                                                                            <th>Verpackung</th>
                                                                                            <th>Lieferart1</th>
                                                                                            <th>Lieferart2</th>
                                                                                            <th>Gueltigkeit</th>
                                                                                            <th>Verladem.</th>
                                                                                            <th>Verl.Vermerk</th>
                                                                                            <th>Aktiv</th>
                                                                                            <th>Lagerbestand</th>
                                                                                            <th>E-Mail</th>
                                                                                            <th>Telefon</th>
                                                                                            <th>Mobil</th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-import>
                        <tr>
                            <td>{{import.zeilennummer}}</td>
                            <td>{{import.aktion}}</td>
                            <td>{{import.bezeichnung}}</td>

                            <td>
                                <button id="Vorschau" pButton type="button" label="Vorschau"
                                class="ui-button-success" style="margin-right: 5px;" (click)="vorschauShow(import)"></button>
                            </td>

                            <td><p style="white-space: pre-wrap;
                                color: red;">{{import.fehler}}</p></td>

                            <td>{{import.hersteller}}</td>
                                    <td>{{import.artikelnummer}}</td>
                                            <td>{{import.artikelgruppe}}</td>
                                                    <td>{{import.ansprechpartner}}</td>
                                                            <td>{{import.beschreibung}}</td>
                                                                    <td>{{import.lager}}</td>
                                                                            <td>{{import.menge}}</td>
                                                                                    <td>{{import.mengeneinheit}}</td>
                                                                                            <td>{{import.preis}}</td>
                                                                                            <td><crud-basic-checkbox [disabled]="true" [nullable]="false" [ngModel]="import.pauschal"></crud-basic-checkbox></td>
                                                                                            <td>{{import.zustand}}</td>
                                                                                            <td>{{import.verpackung}}</td>
                                                                                            <td>{{import.lieferart1}}</td>
                                                                                            <td>{{import.lieferart2}}</td>
                                                                                            <!--<td>{{import.gueltigkeit | crudBasic:'':propertyTypeModifiedAndCreated }}</td>-->
                                                                                            <td>{{import.gueltigkeit | datumOhneZeit }}</td>
                                                                                            <td><crud-basic-checkbox [disabled]="true" [nullable]="false" [ngModel]="import.verlademoeglichkeit"></crud-basic-checkbox></td>
                                                                                            <td>{{import.verlademoeglichkeitVermerk}}</td>
                                                                                            <td><crud-basic-checkbox [disabled]="true" [nullable]="false" [ngModel]="import.aktiv"></crud-basic-checkbox></td>
                                                                                            <td><crud-basic-checkbox [disabled]="true" [nullable]="false" [ngModel]="import.lagerbestand"></crud-basic-checkbox></td>
                                                                                            <td><crud-basic-checkbox [disabled]="true" [nullable]="false" [ngModel]="import.email_anzeigen"></crud-basic-checkbox></td>
                                                                                            <td><crud-basic-checkbox [disabled]="true" [nullable]="false" [ngModel]="import.telefon_anzeigen"></crud-basic-checkbox></td>
                                                                                            <td><crud-basic-checkbox [disabled]="true" [nullable]="false" [ngModel]="import.mobilnummer_anzeigen"></crud-basic-checkbox></td>

                        </tr>
                    </ng-template>
                </p-table>
            </div>

<div style="position: relative">
    <p-dialog *ngIf="vorschauDisplay==true" modal="true" closeOnEscape="true" [style]="{'width': '99%'}" [contentStyle]="{'overflow':'auto'}" [responsive]="true" [resizable]="true" [closable]="false" [visible]="true">
        <p-header>
        </p-header>
        <app-artikel-detail-ansicht  [CRUDItemAsParameter]="vorschauCRUDItem" [mode]="'import'" 
        (saveClicked)="vorschauZurueckClicked()" (zurueckClicked)="vorschauZurueckClicked()"></app-artikel-detail-ansicht><!--[ngStyle]="{'display': vorschauDisplay == false ? 'none' : 'unset'}"-->
    </p-dialog>
</div>


