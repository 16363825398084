<div *ngIf="_app !=null && _app.inAppMode != true" class="btnArtikelAnlegen" (click)="newArtikel()">
    <i class="far fa-plus" style="color: #ffffff;" id="neu" type="button"></i>
</div>

<div *ngIf="typ == 'A' || typ == 'A_W'" class="card" [ngStyle]="_app != null && _app.inAppMode == true && !mobileAnsicht || showElements ? {'height' : '100%'} : {'height' : '170px'}">
        <div class="p-col-12 p-md-12 p-sm-2">
           
            
            <!-- NICHT LÖSCHEN, nur auskommentieren ! -->
            <!--<div style="text-align: left; padding-top: 7px; float: right;">
                <label for="Artikelnummer" style="font-weight: bold;">nur meine Artikel</label>
                &nbsp;<p-inputSwitch [(ngModel)]="onlyMyUser"></p-inputSwitch>
            </div>
            <div style="text-align: left; padding-top: 7px; float: right;">
                <label for="Artikelnummer" style="font-weight: bold;">nur Aktive</label>
                &nbsp;<p-inputSwitch [(ngModel)]="onlyAktive"></p-inputSwitch>
            </div>
            <div style="text-align: left; padding-top: 7px; float: right;">
                <label for="Artikelnummer" style="font-weight: bold;">nur Lagerbestand</label>
                &nbsp;<p-inputSwitch [(ngModel)]="onlyLagerbestand"></p-inputSwitch>
            </div>
            <div style="text-align: left; padding-top: 7px; float: right;">
                <label for="Artikelnummer" style="font-weight: bold;">nur Favoriten</label>
                &nbsp;<p-inputSwitch [(ngModel)]="onlyFavoriten"></p-inputSwitch>
            </div>-->
            <!-- Volltextsuche -->
            <span class="vollTextMargin" style="margin-top: 0;" *ngIf="_app != null && _app.inAppMode == true">
                <label for="Volltext" style="font-weight: bold;">Volltextsuche</label><br>
                <input [required]="false" class="p-col-12 p-md-6 p-sm-12" [(ngModel)]="volltextSuche" id="volltext" placeholder="suche" name="volltext"
                    style="width: 49.2%;" type="text" pInputText><i class="fas fa-search searchIconMarginHandyAnsicht" style="margin-left: -16px; color: gray;"></i>
                    
             </span>
             <!-- Sortieren für die App Ansicht -->
             <div class="p-grid sortierFilter" style="float: right;" *ngIf="!mobileAnsicht || showElements">
                <div class="p-col-12 p-md-6 p-sm-12 filterSortOptionDropDown" style="margin-top: -8px;" *ngIf="_app != null && _app.inAppMode == true">
                    
                    <p-dropdown class="sortierDropdownAppAnsicht" [options]="sortOptions" [(ngModel)]="sortKey" [style]="{'width': '100%'}"
                    
                        placeholder="Sortieren" (onChange)="onSortChange($event)">
                    </p-dropdown>
                  <br>
                </div>
             </div>
             <!-- Volltextsuche -->
             <span class="vollTextMargin" style="margin-top: 0;"*ngIf="_app != null && _app.inAppMode == false">
                <label for="Volltext" style="font-weight: bold;">Volltextsuche</label><br>
                <input [required]="false" class="p-col-12 p-md-6 p-sm-12" [(ngModel)]="volltextSuche" id="volltext" placeholder="suche" name="volltext"
                    style="width: 59.6%;" type="text" pInputText><i class="fas fa-search" style="margin-left: -16px; color: gray;"></i>
             </span>
            <div class="sortierElement" style="margin-top: -10px; float: right; padding-top: 10px;" >
               
                <!-- Sortieren -->
                <div class="p-grid sortierFilter">
                    <div class="p-col-12 p-md-3 filterSortOptionDropDown" style="margin-top: -8px;" *ngIf="_app != null && _app.inAppMode == false ">
                        
                        <p-dropdown class="sortierDropdown" [options]="sortOptions" [(ngModel)]="sortKey" [style]="{'width': '100%'}"
                        
                            placeholder="Sortieren" (onChange)="onSortChange($event)">
                        </p-dropdown>
                      <br>
                    </div>
                </div>
            </div>
        </div><br><br>

        <div class="inputSwitch" style="display: flex; flex-direction: row; flex-wrap: wrap;">
            <div *ngIf="_app !=null && _app.inAppMode == false" class="p-col-6 p-md-6 p-sm-4" style="margin-top: -35px; width: 60%;">
                <!-- Artikelgruppe -->
                <label for="artikelGruppeEins" style="font-weight: bold;">{{artikelGruppeLbl}}</label><br>
                <div id="artikelgruppe_artikeluebersicht" style="border-bottom: 1px solid #aaa !important; height:20px">
                    <!--border unten, damit es aussieht, wie ein Eingabefeld-->
                    <p-breadcrumb [styleClass]="'artikelGruppeBreadcrumb'"
                        [style]="{'padding': '0px', 'font-size': '14px'}" 
                        [model]="artikelGruppeBreadCrumbItems"
                        [home]="artikelGruppeBreadCrumbHome" (onItemClick)="breadBrumbClicked($event)">
                    </p-breadcrumb>
                    <button style="display:block;float:right;margin-top:-41px !important" type="button"
                        class="artikelgruppeBtn" (click)="artikelGruppeShowAuswahlPopUp()" pButton
                        icon="pi pi-external-link"></button>
                </div><br>

                <!-- Artikelnummer -->
                <div *ngIf="showElements" class="artikelNummerFilter">
                    <label for="Artikelnummer" style="font-weight: bold;">{{artikelNumLbl}}</label><br>
                    <input [required]="false" [(ngModel)]="artikelnummerNg" id="artikelnummer" name="artikelnummerNg"
                        style="width: 100%;" type="text" pInputText>
                </div><br>

                <!-- Hersteller -->
                <div *ngIf="showElements" class="herstellerFilter">
                    <label for="hersteller" style="font-weight: bold;">{{herstellerLbl}}</label><br>
                    <crud-basic-autocomplete style="width: 100%;" [(ngModel)]="hersteller" [type]="'hersteller'">
                    </crud-basic-autocomplete>
                </div>
                <!-- Ort -->
                <div *ngIf="showElements" class="filterShowElements" style="margin-top: 19px;">
                    <label for="Geodaten" style="font-weight: bold;">{{ortLbl}}</label><br>
                    <crud-basic-autocomplete name="Geodaten" style="width: 100%; margin-top: 10px;" [(ngModel)]="geodaten" id=""
                        [type]="'geodaten_postleitzahl'">
                    </crud-basic-autocomplete>
               
                  
                   
                </div>

            </div>
            <!-- Wenn App Modus Aktiviert ist wird ein anderes Grid verwendet-->
           <div *ngIf="_app != null && _app.inAppMode == true && (!mobileAnsicht || showElements)" class="p-col-12 p-md-12 p-sm-12 artikelGruppeHandyAnsicht" style="margin-top: -40px;">
            <div class="artikelGruppeApp">
                <label for="artikelGruppeEins" style="font-weight: bold;">{{artikelGruppeLbl}}</label><br>
                <div style="border-bottom: 1px solid #aaa !important; height:20px">
                    <!--border unten, damit es aussieht, wie ein Eingabefeld-->
                    <!--<i class="fas fa-home-lg-alt" style="margin-left: 5px;"></i>--><p-breadcrumb [styleClass]="'artikelGruppeBreadcrumb'"
                        [style]="{'padding': '0px', 'font-size': '14px'}" [model]="artikelGruppeBreadCrumbItems">
                    </p-breadcrumb>
                    <button style="display:block;float:right;margin-top:-42px !important" type="button"
                        class="artikelgruppeBtn" (click)="artikelGruppeShowAuswahlPopUp()" pButton
                        icon="pi pi-external-link"></button>
                </div>
            </div>
           </div>
            <div *ngIf="_app !=null && _app.inAppMode == true && (!mobileAnsicht || showElements)" class="p-col-12 p-md-6 p-sm-12" style="margin-top: 4px;">
                <!--Umkreis-->
                <div class="handyAnsichtUmkreis">
                    <label for="umkreis" style="font-weight: bold;">{{umkreisLbl}}<span
                        style="float: right;">{{umkreisKm}}
                        Km</span></label><br><br>
                <p-slider [(ngModel)]="umkreisKm" class="sliderFilter" [style]="{'margin-top':'-2px'}" [min]="0" [max]="100"></p-slider>
                <p class="umkreis" style="color: #000000;"></p>
            </div>
                    <!-- Artikelnummer -->
                    <div *ngIf="showElements" class="artikelNummerFilter" style="margin-top: 21px;">
                        <label for="Artikelnummer" style="font-weight: bold;">{{artikelNumLbl}}</label><br>
                        <input [required]="false" [(ngModel)]="artikelnummerNg" id="artikelnummer" name="artikelnummerNg"
                            style="width: 100%;" type="text" pInputText>
                    </div><br>
                    <!-- LieferartHandy -->
                <div *ngIf="showElements" class="lieferArt" style="margin-top: -2px;" >
                    <label for="versand" style="font-weight: bold;">{{lieferartLbl}}</label>
                    <div style="border-bottom: 1px solid #aaa !important;">
                        <!--border unten, damit es aussieht, wie ein Eingabefeld-->
                        <div class="lieferartenListe">
                            {{artikelLieferartenSelectedAsString}}
                        </div>
                        <button type="button" class="lieferartBtn"
                            style="display:blocK;float:right;margin-top:-39px !important"
                            (click)="artikelLieferartShowAuswahlPopUp()" pButton icon="pi pi-external-link"></button>
    
                    </div>
                </div><br>
                <div class="filterShowElements" *ngIf="showElements" >
                    
                  
                <!-- Verkäufer -->
                <div class="verkaeuferApp">
                    <label for="verkaeufer" style="font-weight: bold; margin-top: 0px;">{{verkaeuferLbl}}</label><br>
                    <crud-basic-autocomplete [disabled]="onlyMyUser" style="width: 100%; margin-top: 10px;"
                    [class.verkaeuferApp]="_app !=null && _app.inAppMode == true"
                        [(ngModel)]="unternehmen" id="hersteller" [type]="'unternehmen'">
                    </crud-basic-autocomplete>
                </div>

                </div>
            </div>
            
            <!-- zweite Spalte -->
            <div *ngIf="_app !=null && _app.inAppMode == false" class="p-col-6 p-md-6 p-sm-4" style="margin-top: -35px; width: 40%;">
                 <!-- Umkreis -->
                 <label for="umkreis" style="font-weight: bold;">{{umkreisLbl}}<span
                    style="float: right;">{{umkreisKm}}
                    Km</span></label><br><br>
            <p-slider [(ngModel)]="umkreisKm" class="sliderFilter" [min]="0" [max]="100"></p-slider>
            <p class="umkreis" style="color: #000000;"></p>

                
                <!-- Lieferart -->
                <div *ngIf="showElements" class="lieferArt" style="margin-top: 18px;">
                    <label for="versand" style="font-weight: bold;">{{lieferartLbl}}</label>
                    <div style="border-bottom: 1px solid #aaa !important; height:20px">
                        <!--border unten, damit es aussieht, wie ein Eingabefeld-->
                        <div class="lieferartenListe">
                            {{artikelLieferartenSelectedAsString}}
                        </div>
                        <button type="button" class="lieferartBtn"
                            style="display:blocK;float:right;margin-top:-35px !important"
                            (click)="artikelLieferartShowAuswahlPopUp()" pButton icon="pi pi-external-link"></button>

                    </div>
                </div><br>
               
                <!-- Lager -->
                <div *ngIf="showElements" class="filterShowElements" style="margin-top: 1px;"
                    [style.visibility]="onlyMyUser == false && onlyFavoriten == false ? 'hidden' : null">
                    <label for="standort" style="font-weight: bold;">{{lagerLbl}}</label><br>
                    <crud-basic-autocomplete style="width: 100%;" [(ngModel)]="lager" id="hersteller" [type]="'lager'">
                    </crud-basic-autocomplete>
                </div>
                <div *ngIf="showElements" class="filterShowElements" style="margin-top: 19px;">
                    <!-- Verkäufer -->
                    <label for="verkaeufer" style="font-weight: bold; margin-top: 0px;">{{verkaeuferLbl}}</label><br>
                    <crud-basic-autocomplete [disabled]="onlyMyUser" style="width: 100%; margin-top: 10px;"
                    [(ngModel)]="unternehmen" id="hersteller" [type]="'unternehmen'">
                    </crud-basic-autocomplete>
                </div>

                <div style="text-align: left; padding-top: 7px; float: right;" [ngStyle]="{'display':showElements == true && (onlyLagerbestand == null || onlyLagerbestand == false) ? 'unset' : 'none'}">
                    <br>
                    <label for="" style="font-weight: bold;">auch Lagerbestand</label>
                    &nbsp;<p-inputSwitch [(ngModel)]="auchLagerbestand"></p-inputSwitch>
                </div>

                <!--<div style="text-align: left; padding-top: 7px; float: right;" [ngStyle]="{'display':true ? 'unset' : 'none'}">
                    <br>
                    <label for="" style="font-weight: bold;">Suchanfrage</label>
                    &nbsp;<p-inputSwitch [(ngModel)]="suchanfragen"></p-inputSwitch>
                </div>-->

            </div>
            <!-- Wenn Appmodus verwendet wird dann wird hier ein anderes Grid angewendet -->
            <div *ngIf="_app !=null && _app.inAppMode == true" class="p-col-12 p-md-6 p-sm-12" style="margin-top: 1px;">

            <!-- Lager -->
            <div *ngIf="showElements" class="filterShowElements lagerApp"
                [style.visibility]="onlyMyUser == false && onlyFavoriten == false ? 'hidden' : null">
                <label for="standort" style="font-weight: bold;">{{lagerLbl}}</label><br>
                <crud-basic-autocomplete [(ngModel)]="lager" style="width: 100%;" id="hersteller" [type]="'lager'"
                [class.lagerApp]="_app !=null && _app.inAppMode == true">
                </crud-basic-autocomplete>

            </div><br>
            <div *ngIf="showElements" class="ortHandy">
            <label for="Geodaten" style="font-weight: bold;">{{ortLbl}}</label><br>
            <crud-basic-autocomplete name="Geodaten" style="width: 100%;" [(ngModel)]="geodaten"
                [class.ortHandy]="_app !=null && _app.inAppMode == true"
                [type]="'geodaten_postleitzahl'">
            </crud-basic-autocomplete>
        </div><br>
            <!-- Hersteller -->
            <div *ngIf="showElements" class="herstellerApp">
                <label for="hersteller" style="font-weight: bold;">{{herstellerLbl}}</label>
                <crud-basic-autocomplete style="width: 100%;" [class.herstellerApp]="_app !=null && _app.inAppMode == true"
                [(ngModel)]="hersteller" [type]="'hersteller'">
                </crud-basic-autocomplete>
            </div>

            <div style="text-align: left; padding-top: 7px; float: right;" [ngStyle]="{'display':showElements == true && (onlyLagerbestand == null || onlyLagerbestand == false) ? 'unset' : 'none'}">
                <br>
                <label for="" style="font-weight: bold;">auch Lagerbestand</label>
                &nbsp;<p-inputSwitch [(ngModel)]="auchLagerbestand"></p-inputSwitch>
            </div>

            <br>
            </div>

            <div style="width: 100%;"  *ngIf="_app !=null && _app.inAppMode == false || _app !=null && _app.inAppMode == true && !mobileAnsicht" [ngStyle]="showElements != false ? {'margin-top': 'auto'} : {'margin-top': '-40px'}">
            <p pButton (click)="mehrAnzeigenToggle()" label="weniger Anzeigen" style="cursor: pointer; float: left; margin-left: 5px; margin-right: 5px; width: 190px;" 
            *ngIf="showElements"><i class="fas fa-filter filterPositionBtn" style="float: left; margin-top: 9px;"></i></p>
            <p pButton (click)="mehrAnzeigenToggle()" label="mehr Anzeigen" style="cursor: pointer; float: left; margin-left: 5px; margin-right: 5px; width: 170px;" 
            *ngIf="!showElements"><i class="far fa-filter" style="float: left; margin-top: 9px;"></i></p>

            <button pButton (click)="clearAll()" [ngStyle]="_app !=null && _app.inAppMode == true && !mobileAnsicht ? {'margin-top': '16px'} : {'margin-top': '14px'}" style="width: 190px; margin-top: 14px" class="filterResetHover" label="Filter zurücksetzen"><i class="fas fa-times-circle"style="float: left;
                margin-top: 8.5px"></i></button>

            <span class="rightBtnsFilter" style="float: right; margin-right: 5px; margin-top: 14px;">
                <div class="btnFilter" style="float: right; margin-right: 5px;">
                    <div class="btnFilter" style="margin-right: -11px;">
                        <button pButton (click)="refresh()" label="Aktualisieren" style="margin-right: 5px; width: 150px;"><i class="far fa-sync-alt" style="float: left;
                            margin-top: 8.5px;"></i></button>
                    </div>
                </div>
            </span>
        </div>
        <div id="showElementsButtonRow" class="buttonsMobileApp"  style="width: 100%;" *ngIf="app !=null && _app.inAppMode == true && mobileAnsicht" [ngClass]="marginForMobileView()">
        <p pButton (click)="mehrAnzeigenToggle()" class="mehrAnzeigeButtonMobileApp" style="float: left; margin-left: 5px; margin-right: 5px;
        width: 40px; height: 40px; border-radius: 50%;" *ngIf="showElements"><i class="fas fa-filter" style="margin-top: 12px; margin-left: -4px;"></i></p>
        <p pButton (click)="mehrAnzeigenToggle()" class="mehrAnzeigeButtonMobileApp" style="float: left; margin-left: 5px; margin-right: 5px;
        width: 40px; height: 40px; border-radius: 50%;" *ngIf="!showElements"><i class="far fa-filter" style="margin-top: 12px; margin-left: -4px;"></i></p>

                <p pButton (click)="clearAll()"style=" width: 40px; height: 40px; border-radius: 50%; margin-right: 5px; float: left" class="clearAllMobileApp">
                    <i class="fas fa-times-circle" style="margin-top: 12px; margin-left: -3.7px;"></i>
                </p>
               
                    <p pButton (click)="refresh()" style="width: 40px; height: 40px; border-radius: 50%;" class="refreshMobileApp">
                    <i class="far fa-sync-alt" style="margin-top: 11px; margin-left: -4px;"></i></p>
        </div>
       
        </div>
        
        
 


    </div>
<!--<p-dataView id="DataView" #dv [value]="cars" [paginator]="true" layout="grid" [rows]="12" paginatorPosition="both" filterBy="brand"-->
<p-dataView class="DataView" #dv [value]="artikel" [lazy]="true" [totalRecords]="totalRecords" [rows]="rows"
    (onLazyLoad)="loadDataVirtualScroller($event)" layout="grid" [paginator]="true" [paginatorPosition]="paginatorPosition"
    (onPage)="onPage($event)" showCurrentPageReport="true" currentPageReportTemplate="{{paginationText}}"
    [loading]="loading">


    <ng-template let-artikel pTemplate="gridItem" >

        <!-- Darstellung für Artikel und Suchen -->
        <div class="ui-g-12 ui-md-3" *ngIf="artikel.typ != 'W'">
            <div [style]="{'border': '0.5px darkgrey solid'}">

                <!--, 'background-color': 'yellow'-->
               
                <div class="artikelUebersichtImageDiv">
                    <img *ngIf="typ != 'S' || artikel.artikelbilder != null && artikel.artikelbilder.length>0" (click)="selectArtikel($event, artikel)"
                        class="artikelUebersichtImageImg" [src]="artikelTitelBildURL(artikel)"
                        (error)="onImgError($event)">
                        <div class="blurArtikel">
                        <img class="artikelUebersichtImageImg" *ngIf="artikel.aktiv != true" style="filter: blur(3px) grayscale(100%);" [src]="artikelTitelBildURL(artikel)">
                        <span *ngIf="artikel.aktiv != true">INAKTIV</span>
                        </div>
                  
                       
                    <div *ngIf="artikel.typ == 'A'" class="iconBtn" style="float: right; margin: 5px 10px 0px 0px; z-index: 1;">
                        <button (click)="favBtn(artikel)" pButton type="button" class="ui-button-secondary"
                            [style]="{'text-align':'center', 'display': 'contents'}"><i
                                class="fa-star fa-lg"
                                [class.far]="artikel._meinFavorit == null || artikel._meinFavorit == false"
                                [class.fas]="artikel._meinFavorit != null && artikel._meinFavorit == true"
                                style="margin-top: 8px; z-index: 1;" id="btnStar"></i></button>
                    </div>
                    <div class="uebersichtInfo" 
                        (click)="selectArtikel($event, artikel)">

                        <ng-container *ngIf="typ == 'S'; then thenTemplateSuchanfragen; else elseTemplateSuchanfragen">
                            <!-- wird niemals angezeigt, entweder "then" order "else"-->
                        </ng-container>
                                
                        <ng-template #thenTemplateSuchanfragen>
                                <b>{{artikel != null ? artikel.bezeichnung: ''}}</b><br>
                                <span *ngIf="artikel.distance != 0">{{artikel.distance | crudBasic:null:(artikelService.propertyTypes!=null&&artikelService.propertyTypeOf!=null?artikelService.propertyTypeOf('distance'):null)}} km</span>
                                <span *ngIf="artikel.distance == 0">0 km</span> |
                                <span *ngIf="artikel != null && artikel.lager != null && artikel.lager.geodaten != null" style="display: inline-flex;"> {{artikel.lager.geodaten.ort}}</span>
                                <span *ngIf="artikel != null && artikel.lager == null && artikel.unternehmen != null && artikel.unternehmen.geodaten != null" style="display: inline-flex;"> {{artikel.unternehmen.geodaten.ort}}</span>
                                <br>
                                <span *ngIf="artikel != null"><small>{{artikel.modified | datumOhneZeit}}</small></span>
                                <br>
                                <br *ngIf="artikel != null && artikel.beschreibung != null && artikel.beschreibung.length > 0">
                                <p *ngIf="artikel != null && artikel.beschreibung != null && artikel.beschreibung.length > 0" class="textRight artikelBeschreibung" style="display: table-cell;"
                                    [innerHTML]="artikel.beschreibung"></p>
                        </ng-template>
                                
                        <ng-template #elseTemplateSuchanfragen>
                                <div class="artikelBezeichnung"
                                [class.uebersichtInfo_desktop]="_app !=null && _app.inAppMode == false"
                                [class.uebersichtInfo_app]="_app !=null && _app.inAppMode == true"
                                    [style]="{'text-align':'left', 'font-weight': 'bold', 'margin-left': '5px', 'width': 'auto'}">
                                    {{artikel != null ? artikel.bezeichnung: ''}}</div>
                                <span *ngIf="artikel != null && artikel.distance != 0"
                                    style="margin-left: 5px;">{{artikel.distance | crudBasic:null:(artikelService.propertyTypes!=null&&artikelService.propertyTypeOf!=null?artikelService.propertyTypeOf('distance'):null)}}
                                    km</span>
                                 
                                <span *ngIf="artikel != null && artikel.distance == 0" [style]="{'margin-left': '5px'}">0 km
                                </span> |
                                <span *ngIf="artikel != null && artikel.lager != null && artikel.lager.geodaten != null"
                                    style="display: inline-flex;"> {{artikel.lager.geodaten.ort}}
                                </span>
                            
                                <div style="margin-left: 5px;  overflow: hidden;
                                text-overflow: ellipsis;
                                -o-text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 70%;">{{artikel != null ? artikel.artikelnummer : ''}}</div>
                                <div class="lieferung" style="margin-right: 5px">
                                    <i *ngIf="isAbholung(artikel)" class="fa fa-warehouse"
                                        style="float: right;" pTooltip="Abholung" tooltipPosition="bottom"></i>
                                    <i *ngIf="isLieferung(artikel)" class="fa fa-truck"
                                        style="float: right;margin-right: 5px;" pTooltip="Lieferung"
                                        tooltipPosition="bottom"></i>
                                </div>
                        </ng-template>

                    </div>

                </div>
           


                <!--<div *ngIf="lastSearch_warMitUmkreis == true && artikel != null && artikel.distance != 0" class="car-detail">{{artikel.distance | crudBasic:null:(artikelService.propertyTypes!=null&&artikelService.propertyTypeOf!=null?artikelService.propertyTypeOf('distance'):null)}} km</div>
                <div *ngIf="lastSearch_warMitUmkreis == true && artikel != null && artikel.distance == 0" class="car-detail">0 km</div>-->
            </div>
        </div>
        <!-- ENDE Darstellung für Artikel und Suchen -->

        <!-- Darstellung für Werbeanzeigen -->
        <div class="ui-g-12 ui-md-3" *ngIf="artikel.typ == 'W'" (click)="selectArtikel($event, artikel)">
            <app-werbeanzeige-darstellung-uebersicht [CRUDItem]="artikel">
            </app-werbeanzeige-darstellung-uebersicht>
        </div>
        <!-- ENDE Darstellung für Werbeanzeigen -->

    </ng-template>
</p-dataView>

<!-- Dialog Breadcrumb -->
<p-dialog header="Artikelgruppe" id="artikelGruppe" [(visible)]="artikelGruppeDisplayAuswahlPopUp" [modal]="true" *ngIf="_app !=null && _app.inAppMode == false"
    [style]="{width: '30vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false" styleClass="editDialog">

    <div #divCardToolbar class="card">
            <span>
              <button style="width: auto;" pButton 
              (click)="artikelGruppeClear()" [icon]="'fas fa-times-circle'" [label]="_translate.instant('Zurücksetzen',true)"></button>
            </span>
            <span class="floatRight">
              <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="_translate.instant('Abbrechen',true)"
                (click)="artikelGruppeCloseDialog()"></button>
            </span>
    </div>

    <p-tree [style]="{'width': '100%'}" [value]="artikelGruppeTreeNodes" id="artikelUebersichtArtikelgruppenTree" [filter]="true"
        selectionMode="single" [propagateSelectionUp]="false" [propagateSelectionDown]="false"
        (onNodeSelect)="artikelGruppeOnSelected($event, this)"></p-tree>
</p-dialog>

<!-- Dialog für Mobileansicht -->
<p-dialog header="Artikelgruppe" id="artikelGruppe" styleClass="handyAnsichtDialog" [(visible)]="artikelGruppeDisplayAuswahlPopUp" [modal]="true" *ngIf="_app !=null && _app.inAppMode == true"
     [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <p-tree [style]="{'width': '100%'}" [value]="artikelGruppeTreeNodes" id="kontaktInfo" [filter]="true"
        selectionMode="single" [propagateSelectionUp]="false" [propagateSelectionDown]="false"
        (onNodeSelect)="artikelGruppeOnSelected($event, this)"></p-tree>
</p-dialog>


<!-- Dialog Lieferart -->
<p-dialog header="Lieferart" id="Lieferarten" styleClass="lieferartenHandyAnsicht" [(visible)]="artikelLieferartenDisplayAuswahlPopUp" [modal]="true"
    [style]="{width: '30vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <p-table [value]="artikelLieferartenItems" [(selection)]="artikelLieferartenSelected">
        <ng-template pTemplate="header">
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                <td>
                    <!--<p-checkbox binary="true" value="rowData['_selected']" (onChange)="onLieferartenChangeSelection($event)"></p-checkbox>-->
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td>{{rowData.summary}}</td>
            </tr>
        </ng-template>
    </p-table>
    <br>
    <div style="float: right;">
        <button styleClass="ui-button-warning" pButton type="button" [icon]="'pi pi-check'"
            [label]="_translate.instant('OK',true)" (click)="artikelLieferartenOnSelected()"></button>
    </div>

</p-dialog>